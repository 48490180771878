import { cloneDeep } from 'lodash-es';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Account,
  AccountService,
  ConfigurationService,
  TollCategoryInfo,
  TransponderFee,
  TransponderRequest,
} from '@ibitoll/toll-core';
import { switchMap, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'bluk-transponder',
  templateUrl: './bluk-transponder.component.html',
  styleUrls: ['./bluk-transponder.component.scss'],
})
export class BlukTransponderComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  account: Account;

  tollCategories: Array<TollCategoryInfo> = [];

  transponderRequest = new TransponderRequest();

  blukTransponderInfoFormGroup: FormGroup;
  public transponderFees: TransponderFee[] = [];
  public transponderFeesOg: TransponderFee[] = [];

  public hideTransponderType = true;
  public transponderTypeId: number;

  private subs = new SubSink();

  @Output() blukTransponderStatus = new EventEmitter<any>();
  @Output() validationStatus: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  unique = this.constructor['ɵcmp'].id;

  hideCategory = true;
  componentId: number;

  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private cd: ChangeDetectorRef
  ) {
    this.componentId = Math.random();
  }

  ngOnInit(): void {
    console.log('ngOnInit accProfileId=' + this.account?.AccountProfileID);
    this.blukTransponderInfoFormGroup = this.formBuilder.group({
      transponderType: [''],
      category: ['', Validators.required],
      quantity: [
        '',
        [Validators.required, Validators.min(1), Validators.max(999)],
      ],
    });

    this.subs.add(
      this.accountService.activeAccount
        .pipe(
          tap((account) => {
            this.account = account;
          }),
          switchMap((account) => {
            return this.configurationService.getTransponderFees();
          }),
          switchMap((transponderFee: TransponderFee[]) => {
            this.transponderFeesOg = cloneDeep(transponderFee);
            this.transponderFees = cloneDeep(transponderFee).filter(
              (o) =>
                o.CurrenctType == this.account.CurrencyType &&
                o.AccountProfileId === this.account.AccountProfileID
            );
            this.cd.detectChanges();
            return this.configurationService.GetTollCategoryInfoByAccountProfileID(
              this.account.AccountProfileID.toString()
            );
          })
        )
        .subscribe((tollCategories) => {
          const distinctCat = new Array<TollCategoryInfo>();
          const flags = new Array<any>();
          const len = tollCategories.length;
          let i: number;
          for (i = 0; i < len; i++) {
            if (flags[tollCategories[i].GroupName]) {
              continue;
            }
            flags[tollCategories[i].GroupName] = true;
            distinctCat.push(tollCategories[i]);
          }
          this.tollCategories = distinctCat;

          this.tollCategories = distinctCat
            .sort((a, b) => a.Id - b.Id)
            .filter((o) => {
              return o.GroupId == this.account.AccountProfileID;
            });

          this.tollCategories = distinctCat;

          this.checkTransponderType();
          this.checkTollCategories();
        })
    );

    this.blukTransponderInfoFormGroup.valueChanges.subscribe((data) => {
      if (this.blukTransponderInfoFormGroup.valid) {
        this.validationStatus.emit(true);
      } else {
        this.validationStatus.emit(false);
      }

      this.transponderRequest.TransponderTypeId = data.transponderType;
      this.transponderRequest.TollCategoryId = data.category;
      this.transponderRequest.TransponderQuantity = data.quantity;
    });

    this.blukTransponderStatus.emit(this);
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit accProfileId=' + this.account?.AccountProfileID);
    if (this.transponderFeesOg.length == 0 && !this.account?.AccountID) {
      this.configurationService
        .getTransponderFees()
        .subscribe((transponderFee: TransponderFee[]) => {
          this.transponderFeesOg = cloneDeep(transponderFee);
          this.transponderFees = cloneDeep(transponderFee);
          this.checkTransponderType();
          this.cd.detectChanges();
        });

      setTimeout(() => {
        this.checkTransponderType();
        this.checkTollCategories();
      }, 100);
    }

    this.onLoaded.emit(true);
  }

  resetActiveForm() {
    this.blukTransponderInfoFormGroup.controls['quantity'].reset();
  }

  checkTransponderType() {
    console.log('checkTransponderType: transponderFees['+this.transponderFees?.length+']=' + JSON.stringify(this.transponderFees));
    // var transpFeesForAcc = this.transponderFees.filter(
    //   (fee) =>
    //     fee.CurrenctType == this.account.CurrencyType &&
    //     fee.AccountProfileId === this.account.AccountProfileID
    //   );
    // if (!transpFeesForAcc) { 
    //   this.hideTransponderType = false;
    // } else {
       if (this.transponderFees.length === 1) {
        this.transponderRequest.TransponderTypeId =
          this.transponderFees[0].TransponderTypeId;
        if (this.blukTransponderInfoFormGroup) {
          this.blukTransponderInfoFormGroup.controls.transponderType.setValue(
            this.transponderFees[0]?.TransponderTypeId
          );
          this.blukTransponderInfoFormGroup.controls.transponderType.setValidators(
            []
          );
          this.blukTransponderInfoFormGroup.controls.transponderType.updateValueAndValidity();
        }
        this.hideTransponderType = true;
      } else if (this.transponderFees.length > 0) {
        var macpassIndex = 1; // this is actually the type sticker which pretends to be an macpass (see HHB-1114) 
        if (
          this.blukTransponderInfoFormGroup &&
          this.transponderFees[macpassIndex]?.TransponderTypeId
        ) {
          this.blukTransponderInfoFormGroup.controls.transponderType.setValue(
            this.transponderFees[macpassIndex]?.TransponderTypeId
          );
          this.blukTransponderInfoFormGroup.controls.transponderType.setValidators(
            Validators.required
          );
          this.blukTransponderInfoFormGroup.controls.transponderType.updateValueAndValidity();
        }
        this.hideTransponderType = false;
      }
    // }
  }

  incrementQ() {
    const cv = Number(
      this.blukTransponderInfoFormGroup.controls['quantity'].value
    );
    this.blukTransponderInfoFormGroup.controls['quantity'].setValue(cv + 1);
  }

  decremenQ() {
    const cv = Number(
      this.blukTransponderInfoFormGroup.controls['quantity'].value
    );
    if (cv && cv > 0) {
      this.blukTransponderInfoFormGroup.controls['quantity'].setValue(cv - 1);
    } else {
      this.blukTransponderInfoFormGroup.controls['quantity'].setValue(0);
    }
  }

  filterTransponderType(currenyType: string, accountProfileId: number) {
    this.transponderFees = cloneDeep(this.transponderFeesOg).filter(
      (o) =>
        o.CurrenctType == currenyType && o.AccountProfileId === accountProfileId
    );
    this.cd.detectChanges();
    this.checkTransponderType();
  }

  checkTollCategories() {
    console.log('tollCats: ' + JSON.stringify(this.tollCategories));
    if (this.tollCategories.length === 1) {
      this.transponderRequest.TollCategoryId = this.tollCategories[0].Id;
      if (this.blukTransponderInfoFormGroup) {
        this.blukTransponderInfoFormGroup
          .get('category')
          .setValue(this.tollCategories[0].Id);
        this.blukTransponderInfoFormGroup.get('category').setValidators([]);
        this.blukTransponderInfoFormGroup
          .get('category')
          .updateValueAndValidity();
      }
      this.hideCategory = true;
    } else {
      if (this.blukTransponderInfoFormGroup) {
        if (this.tollCategories[0]?.Id) {
          this.blukTransponderInfoFormGroup
            .get('category')
            .setValue(this.tollCategories[0].Id);
        }
        this.blukTransponderInfoFormGroup
          .get('category')
          .setValidators(Validators.required);
      }
      if (this.tollCategories.length > 0)
        this.hideCategory = false;
    }
    console.log('CatLen='+this.tollCategories?.length+'; hideCategory: ' + this.hideCategory);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
