import { Injectable, OnDestroy } from '@angular/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { map, Observable, of, Subject, Subscription } from 'rxjs';
import { ThemeService } from '../providers/theme.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cacheable } from 'ts-cacheable';
import { ProxyService } from './proxy.service';
import { SubSink } from 'subsink';

@Injectable({
  providedIn: 'root',
})
export class ReCaptchaService implements OnDestroy {
  gTokenSubscription: Subscription;
  public gToken = '';
  private endpointUrl = '';
  private subs = new SubSink();


  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    public themeService: ThemeService,
    private http: HttpClient,
    private proxy: ProxyService,
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  // public refresh(pageName: string): Observable<boolean> {
  //   const subject = new Subject<boolean>();
  //   if (this.themeService.active.GoogleRecaptch) {
  //     const time = setTimeout(() => {
  //       subject.error('Unable to verify');
  //       subject.complete();
  //     }, 5000);

  //     this.recaptchaV3Service.execute(
  //       this.themeService.active.GoogleRecaptch,
  //       pageName,
  //       (token) => {
  //         if (token) {
  //           clearTimeout(time);
  //           this.gToken = token;
  //           subject.next(true);
  //           subject.complete();
  //         } else {
  //           subject.error('Unable to verify');
  //           subject.complete();
  //         }
  //       },
  //       {
  //         useGlobalDomain: false,
  //       }
  //     );
  //   } else {
  //     return of(true);
  //   }

  //   return subject.asObservable();
  // }

  public refresh(pageName: string): Observable<boolean> {
    const subject = new Subject<boolean>();
    //debugger
    this.getGoogleRecaptchaSiteKey()
      .subscribe((recaptchSiteKey: string) => {
        //debugger
        if (recaptchSiteKey){
          console.log('recaptcha site key ' + recaptchSiteKey);

          const time = setTimeout(() => {
            subject.error('Unable to verify');
            subject.complete();
          }, 5000);
          return this.recaptchaV3Service.execute(
            recaptchSiteKey,
            pageName,
            (token) => {
              if (token) {
                clearTimeout(time);
                this.gToken = token;
                subject.next(true);
                subject.complete();
              } else {
                subject.error('Unable to verify');
                subject.complete();
              }
            },
            {
              useGlobalDomain: false,
            }
          ); 

        } else {
          console.log('recaptcha return true');
          subject.next(true);
          subject.complete();
        }
      })
    
    console.log('recaptcha return subject');
    return subject.asObservable();
  }

  @Cacheable({
    maxAge: 86400000, // 24 h 
    slidingExpiration: true,
    shouldCacheDecider: (response) => response.length > 0,
  })
  
  getGoogleRecaptchaSiteKey(): Observable<string> {
    const nheaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<string>(
        this.endpointUrl + 'api/Configuration/GetConfigParameter',
        JSON.stringify('GoogleRecaptchaSiteKey'),
        { headers: nheaders }
      )
      .pipe(
        map((resp) => {
          console.log(
            'Parameter(GoogleRecaptchaSiteKey) read: ' + JSON.stringify(resp)
          );
          return resp;
        })
      );
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (this.gTokenSubscription) {
      this.gTokenSubscription.unsubscribe();
    }
  }
}
