<div class="modal-header">
  <h4 class="modal-title mb-0">Customer Agreement and Privacy Policy</h4>
</div>
<pdf-viewer [src]="pdfSrc" 
            [rotation]="0"
            [original-size]="false"
            [show-all]="true"
            [fit-to-page]="false"
            [zoom]="0.9"
            [zoom-scale]="'page-width'"
            [stick-to-page]="true"
            [render-text]="true"
            [external-link-target]="'blank'"
            [autoresize]="true"
            [show-borders]="false"
            style="width: 100%; height: 86vh">
</pdf-viewer>
<div class="modal-footer">
  <button type="button"
          class="btn btn-danger"
          (click)="reject()">Reject</button>

  <button type="button"
          class="btn btn-app-primary"
          (click)="confirm()">Accept</button>
</div>
