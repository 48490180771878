import { FocusDirective } from './../core/Directive/appFocus.directive';
import { PipeModule, TollCoreModule } from '@ibitoll/toll-core';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountBalanceComponent } from '../core/account-balance/account-balance.component';
import { AddressComponent } from '../core/address/address.component';
import { ContactComponent } from '../core/contact/contact.component';
import { PasswordComponent } from '../core/password/password.component';
import { AccountTypeInfoComponent } from '../core/account-type-info/account-type-info.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { TransponderComponent } from '../core/transponder/transponder.component';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { MessageboardComponent } from '../core/messageboard/messageboard.component';
import { NgxMaskModule } from 'ngx-mask';
import { AutoRebillComponent } from '../core/auto-rebill/auto-rebill.component';
import { AccountAgreementComponent } from '../core/account-agreement/account-agreement.component';
import { OnlyNumber } from '../core/directives/onlynumber.directive';
import { TransactionOrder } from '../core/pipes/transaction.order.pipe';

import { NgpSortModule } from 'ngp-sort-pipe';
import { NgxPrintModule } from 'ngx-print';
import { InputRestrictionDirective } from '../core/directives/inputrestriction.directive';
import { CopyDirective } from '../core/directives/no-copy.directive';
import { PreventKeyseDirective } from '../core/directives/preventkeys.directive';
import { TransponderInputDirective } from '../core/directives/transponderinput.directive';
import { TransponderInputFormDirective } from '../core/directives/transponderinputform.directive';
import { OnsideContainerComponent } from '../core/onside-container/onside-container.component';
import { LanguageSelectorComponent } from '../core/language-selector/language-selector.component';
import { NewtransponderComponent } from '../core/newtransponder/newtransponder.component';
import { HeaderTypeTwoComponent } from '../core/header-type-two/header-type-two.component';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OwntransponderComponent } from '../core/owntransponder/owntransponder.component';
import { BlukTransponderComponent } from '../core/bluk-transponder/bluk-transponder.component';
import { NgxTranslateCutModule } from 'ngx-translate-cut';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AriaCurrent } from '../core/Directive/aria-current.directive';
import { A11yModule } from '@angular/cdk/a11y';
import { ClickStopPropagation } from '../core/Directive/disabled-btn.directive';
import { OrderConfirmationComponent } from '../core/transponder/modals/order-confirmation/order-confirmation.component';
import { MinBalanceModalComponent } from '../core/transponder/modals/min-balance-modal/min-balance-modal.component';
import { SupendAlertModalComponent } from '../core/transponder/modals/supend-alert-modal/supend-alert-modal.component';
import { UnsupendAlertModalComponent } from '../core/transponder/modals/unsupend-alert-modal/unsupend-alert-modal.component';
import { TagAddConfirmationComponent } from '../core/transponder/modals/tag-add-confirmation/tag-add-confirmation.component';
import { RemoveTransponderModalComponent } from '../core/transponder/modals/remove-transponder-modal/remove-transponder-modal.component';
import { EditTransponderModalComponent } from '../core/transponder/modals/edit-transponder-modal/edit-transponder-modal.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { StepperComponentComponent } from '../core/stepper/stepper-component/stepper-component.component';
import { AgmCoreModule } from '@agm/core';
import { AddressConfirmationComponent } from '../core/modals/address-confirmation/address-confirmation.component';
import { OutdateBrowserCheckService } from '../core/services/outdate-browser-check.service';
import { OutDatedBrowserComponent } from '../core/modals/out-dated-browser/out-dated-browser.component';
import { OrderModule } from 'ngx-order-pipe';
import { ChaseOneTimeComponent } from '../modules/user-payment/chase-one-time/chase-one-time.component';
import { ChaseSavedCardComponent } from '../modules/user-payment/chase-saved-card/chase-saved-card.component';
import { ChasePreauthComponent } from '../modules/user-payment/chase-preauth/chase-preauth.component';
import { ChaseReceiptComponent } from '../modules/user-payment/chase-receipt/chase-receipt.component';
import { NgxLoadingModule } from 'ngx-loading';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { NgSelectModule } from '@ng-select/ng-select';
import { PadAgreementComponent } from '../core/pad-agreement/pad-agreement.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ChaseWrapperComponent } from '../modules/user-payment/chase-wrapper/chase-wrapper.component';


@NgModule({
  providers: [OutdateBrowserCheckService],
  declarations: [
    OnsideContainerComponent,
    AccountBalanceComponent,
    AddressComponent,
    ContactComponent,
    PasswordComponent,
    AccountTypeInfoComponent,
    TransponderComponent,
    MessageboardComponent,
    AutoRebillComponent,
    AccountAgreementComponent,
    LanguageSelectorComponent,
    NewtransponderComponent,
    OwntransponderComponent,
    BlukTransponderComponent,
    HeaderTypeTwoComponent,
    OrderConfirmationComponent,
    MinBalanceModalComponent,
    SupendAlertModalComponent,
    UnsupendAlertModalComponent,
    TagAddConfirmationComponent,
    RemoveTransponderModalComponent,
    EditTransponderModalComponent,
    StepperComponentComponent,
    AddressConfirmationComponent,
    OutDatedBrowserComponent,
    ChaseOneTimeComponent,
    ChaseSavedCardComponent,
    ChasePreauthComponent,
    ChaseReceiptComponent,
    PadAgreementComponent,
    ChaseWrapperComponent,

    // Directives
    OnlyNumber,
    InputRestrictionDirective,
    CopyDirective,
    PreventKeyseDirective,
    TransponderInputDirective,
    TransponderInputFormDirective,
    ClickStopPropagation,
    AriaCurrent,
    FocusDirective,

    // Pipes
    TransactionOrder,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    PdfViewerModule,

    //IBI Lib
    PipeModule,
    TollCoreModule,

    // Thrid party
    TranslateModule.forChild(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    MatProgressButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    PopoverModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgpSortModule,
    NgxPrintModule,
    NgxTranslateCutModule,
    TooltipModule.forRoot(),
    CrystalLightboxModule,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: 'AIzaSyA0qk4PI9yZjp2IGK6XlK6gkV5RCBIan50',
    }),

    // Angular Material
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    MatInputModule,
    MatRadioModule,
    MatTableModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    A11yModule,
    OrderModule,
    CdkStepperModule,
    NgxLoadingModule,
    NgSelectModule

  ],
  exports: [
    OnsideContainerComponent,
    AccountBalanceComponent,
    AddressComponent,
    ContactComponent,
    PasswordComponent,
    AccountTypeInfoComponent,
    TransponderComponent,
    MessageboardComponent,
    AutoRebillComponent,
    LanguageSelectorComponent,
    NewtransponderComponent,
    OwntransponderComponent,
    BlukTransponderComponent,
    HeaderTypeTwoComponent,
    OrderConfirmationComponent,
    MinBalanceModalComponent,
    SupendAlertModalComponent,
    UnsupendAlertModalComponent,
    TagAddConfirmationComponent,
    RemoveTransponderModalComponent,
    EditTransponderModalComponent,
    StepperComponentComponent,
    AddressConfirmationComponent,
    OutDatedBrowserComponent,
    ChaseOneTimeComponent,
    ChaseSavedCardComponent,
    ChasePreauthComponent,
    ChaseReceiptComponent,
    PdfViewerModule,
    ChaseWrapperComponent,
    // Directives
    OnlyNumber,
    InputRestrictionDirective,
    CopyDirective,
    PreventKeyseDirective,
    TransponderInputDirective,
    TransponderInputFormDirective,
    ClickStopPropagation,
    AriaCurrent,
    FocusDirective,

    // Pipe
    TransactionOrder,

    // IBI Lib
    PipeModule,
    TollCoreModule,

    // Angular Material
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    MatInputModule,
    MatRadioModule,
    MatTableModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    EditTransponderModalComponent,
    AgmCoreModule,
    ModalModule,
    TabsModule,
    PopoverModule,
    NgxMaskModule,
    NgpSortModule,
    NgxPrintModule,
    NgxTranslateCutModule,
    MatProgressButtonsModule,
    TooltipModule,
    A11yModule,
    CdkStepperModule,
    OrderModule,
    NgxLoadingModule,
    CrystalLightboxModule,
    NgSelectModule

  ],
})
export class SharedModule {}
