import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { AccountCommentTitleDto } from '../models/accountCommentTitleDto';
import { AccountTicketDto } from '../models/accountTicketDto';
import { ProxyService } from './proxy.service';

@Injectable({
  providedIn: 'root',
})
export class AccountTicketService implements OnDestroy {
  private subs = new SubSink();

  private endpointUrl: string;

  private endpoint = 'api/ticket';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient, private proxy: ProxyService) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  getAccountCommentTitle(
    AccountId: number
  ): Observable<AccountCommentTitleDto[]> {
    return this.http.get<AccountCommentTitleDto[]>(
      this.endpointUrl + this.endpoint + '/GetAccountCommentTitle/',
      {
        headers: this.headers,
      }
    );
  }

  getAccountTickets(AccountId: number): Observable<AccountTicketDto[]> {
    return this.http.get<AccountTicketDto[]>(
      this.endpointUrl + this.endpoint + '/GetAccountTicket/' + AccountId,
      {
        headers: this.headers,
      }
    );
  }

  getAccountTicketsById(commentId: number): Observable<AccountTicketDto[]> {
    return this.http.get<AccountTicketDto[]>(
      this.endpointUrl + this.endpoint + '/GetAccountTicketById/' + commentId,
      {
        headers: this.headers,
      }
    );
  }

  addNewAccountTicket(accountTicketDto: AccountTicketDto): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.endpoint + '/NewAccountTicket',
      JSON.stringify(accountTicketDto),
      { headers: this.headers }
    );
  }

  addSubAccountTickets(
    accountTicketDto: AccountTicketDto
  ): Observable<boolean> {
    //debugger
    return this.http.post<boolean>(
      this.endpointUrl + this.endpoint + '/AddAccountTicket',
      JSON.stringify(accountTicketDto),
      { headers: this.headers }
    );
  }

  checkFileType(accountTicketDto: AccountTicketDto): Observable<string> {
    return this.http.post<string>(
      this.endpointUrl + this.endpoint + '/CheckFileType',
      JSON.stringify(accountTicketDto),
      { headers: this.headers }
    );
  }

  AccountDeactiveTicket(
    accountTicketDto: AccountTicketDto
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.endpoint + '/AccountDeactiveTicket',
      JSON.stringify(accountTicketDto),
      { headers: this.headers }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
