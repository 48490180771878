export class PlazaFaresInfo {
  PlazaId: number;
  PlazaName: string;
  PlazaDescription: string;
  TollFareList: TollFareInfo[];
}

export class TollFareInfo {
  CategoryId: number;
  CategoryName: string;
  CategoryDescription: string;
  CashFare: number;
  EtcFare: number;
  SmartCardFare: number;
}
