export class TransactionResponse {
  UserLogonId: string;
  UserID: string;
  AccountID: number;
  NodeID: number;
  LDateTime: string;
  UDateTime: Date;
  SeqNumber: number;
  TransactionType: string;
  PaymentMethod: string;
  PaymentCardType: number;
  PaymentNumber: string;
  // string CardHolder { get; set; } //? not in query
  ResponseOrderID: string;
  BankApprovalCode: string;
  ResponseCodeDB: string;
  ResponseMsg: string;
  TxnNumber: number;
  BankTransactionType: number;
  BankTransactionLDateTime: Date;
  BankTransactionUDateTime: Date;
  BankTransactionId: string;
  ActionCode: number;
  ChargeTotal: number;
  Message: string;
  BatchNumber: number;
  BankAuthorizationStatusDB: string;
  CardExpiryYear: number;
  CardExpiryMonth: number;

  TraceNumber: string;
  TransactionErrorMessage: string; // error message in back end
}
