import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { AccountService } from '../providers/account.service';
import { SubSink } from 'subsink';
import { Account } from '../models/account';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[InActiveAccount]',
})
export class InActiveAccountDirective implements OnInit, OnDestroy {
  private subs = new SubSink();

  constructor(
    private elementRef: ElementRef,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.accountService.getActiveAccount().subscribe((account: Account) => {
        if (account) {
          if (account.AccountStatus === 5) {
            this.elementRef.nativeElement.style.display = 'none';
          } else {
            this.elementRef.nativeElement.style.display = 'block';
          }
        } else {
          this.elementRef.nativeElement.style.display = 'none';
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
