import { Directive, Input } from '@angular/core';
import { FormControl, Validator } from '@angular/forms';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appCustomMinMax]',
})
export class CustomMinMaxDirective implements Validator {
  @Input()
  customMax: number;

  @Input()
  customMin: number;

  validate(c: FormControl): { [key: string]: any } {
    const v = c.value;
    return v > this.customMax ? { customMax: true } : null;
  }
}
