import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService, ToastContainerDirective, ActiveToast } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { Alert, AlertType } from '../../models/alert';
import { AlertService } from '../../providers/alert.service';
import { ConfigurationService } from '../../providers/configuration.service';
import { ToastComponent } from '../toast-component/toast-component.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  @ViewChildren(ToastContainerDirective) inlineContainers!: QueryList<ToastContainerDirective>;

  alert: Alert;
  activeToast: ActiveToast<any>;
  private subs = new SubSink();
  inlinePositionIndex = 0;

  constructor(
    private alertService: AlertService,
    private toastr: ToastrService,
    private configuration: ConfigurationService,
    private liveAnnouncer: LiveAnnouncer,
    public translateService: TranslateService,
  ) { }


  ngOnInit() {
    // this.toastr.overlayContainer = this.inlineContainers.toArray()[this.inlinePositionIndex];

    this.subs.add(
      this.alertService.getAlert().subscribe((alert: Alert) => {
        this.alert = alert;
        if (alert) {
          //this.toastr.clear();
          const alertConfig = {
            toastComponent: ToastComponent,
            toastClass: 'ngx-toastr',
            disableTimeOut: !alert.autoClose,
            timeOut: 20000,
            extendedTimeOut: 10000,
            progressBar: true,
            closeButton: true,
            positionClass: alert.position,
            enableHtml: true
          };
          const hideTitle = this.configuration.config.HideTitlesOnalert;

          switch (alert.type) {
            case AlertType.Success:
              var title = hideTitle ? null : 'Success!';
              this.activeToast = this.toastr.success(alert.message, this.translateService.instant(title), alertConfig);
              break;
            case AlertType.Error:
              var title = hideTitle ? null : 'Error!';
              this.activeToast = this.toastr.error(alert.message, this.translateService.instant(title), alertConfig);
              break;
            case AlertType.Info:
              var title = hideTitle ? null : 'Info!';
              this.activeToast = this.toastr.info(alert.message, this.translateService.instant(title), alertConfig);
              break;
            case AlertType.Warning:
              var title = hideTitle ? null : 'Warning!';
              this.activeToast = this.toastr.warning(alert.message, this.translateService.instant(title), alertConfig);
              break;
          }

          this.subs.add(this.activeToast.onShown.subscribe(data => {
            this.alertService.messageCode = '';
            // setTimeout(() => {
            //   this.liveAnnouncer.announce('Alert!' + this.alert.message, 'assertive')
            // }, 200);
          }))

          this.subs.add(this.activeToast.onHidden.subscribe(() => {
            this.alertService.messageCode = '';
            this.liveAnnouncer.clear()
            //this.toastr.clear();
          }));


        } else {
          this.toastr.clear();
        }
      })
    )

  }

  closeAlert() {
    this.liveAnnouncer.clear()
    this.toastr.clear();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
}
