import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthServiceProvider, ConfigurationService } from '@ibitoll/toll-core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  currentLanguage = 'en';
  hasLanguage = false;

  @Input() swipMode: boolean = false;

  constructor(
    public configService: ConfigurationService,
    private translate: TranslateService,
    public authService: AuthServiceProvider,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.currentLanguage = this.configService.GetClientLanguage();
    this.translate.setDefaultLang(this.currentLanguage);
  }

  ngOnInit() {
    if (this.configService.config.HasLanguage.toString() === 'false') {
      this.configService.config.HasLanguage = false; // it seems that reading from config value 'false' is not boolean false
    }

    this.hasLanguage = this.configService.config.HasLanguage;
    this.translate.setDefaultLang(this.configService.GetClientLanguage());
    this.translate.use(this.configService.GetClientLanguage());
  }

  loggedIn(): boolean {
    return this.authService.loggedIn();
  }

  updateLanguage(languageCode: string) {
    this.configService.SetClientLanguage(languageCode);
    this.currentLanguage = languageCode;
    this.translate.setDefaultLang(languageCode);
    this.translate.use(languageCode);
  }
}
