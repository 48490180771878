export class MonerisPreLoadQuery {
  amount: number;
  accountId: string;
  preLoadType: number;
  language: number;
  cust_id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}
