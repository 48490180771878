export class PaymentRequest {
  firstName: string;
  lastName: string;
  paymentType: number;
  accountType: number;
  amount: number | undefined;
  card_num: string;
  nameOnCard: string;
  transit: string;
  exp_month: number;
  exp_year: number;
  cvd: string;
  preauthRequired: boolean;

  constructor(
    amount: number | undefined,
    card_number: string,
    exp_month: number,
    exp_year: number,
    cvd: string
  ) {
    this.amount = amount;
    this.card_num = card_number;
    this.exp_month = exp_month;
    this.exp_year = exp_year;
    this.cvd = cvd;
    this.preauthRequired = false;
  }

  public static createBlank() {
    return new PaymentRequest(undefined, '', 1, 1, '');
  }
}
