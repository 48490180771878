import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[transponderInput]',
  host: {
    '(keydown)': 'onKeyUp($event)',
  },
})
export class TransponderInputDirective {
  inputElement: ElementRef;

  @Input('transponderInput') transponderInput: any;

  constructor(Element: ElementRef) { }

  onKeyUp($event) {
    const value = ($event.target as HTMLInputElement).value;
    if ([46, 8, 9, 27, 13, 110, 124].indexOf($event.keyCode) === -1) {

      if (this.transponderInput) {
        if (value.length >= this.transponderInput) {
          $event.preventDefault();
        }
      } else {
        if (value.startsWith('104')) {
          if (value.length >= 14) {
            $event.preventDefault();
          }
        } else if (value.startsWith('124')) {
          if (value.length >= 14) {
            $event.preventDefault();
          }
        } else {
          if (value.length >= 12) {
            $event.preventDefault();
          }
        }
      }

    }
  }
}
