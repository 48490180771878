import { Injectable, OnDestroy } from '@angular/core';
import { MessageBoard } from '../models/messageboard';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProxyService } from '@ibitoll/toll-core';
import { SubSink } from 'subsink';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
  providedIn: 'root',
})
export class MessageboardService implements OnDestroy {
  private endpointUrl: string;

  private messageboardEndpoint = 'api/MessageBoard';

  private subs = new SubSink();

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient, private proxy: ProxyService) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  @Cacheable({
    maxAge: 300000,
    slidingExpiration: true,
    shouldCacheDecider: (response) => response.length > 0,
  })
  getMessageBoardMsg(): Observable<MessageBoard> {
    return this.http.get<MessageBoard>(
      this.endpointUrl + this.messageboardEndpoint + '/GetMessageBoardMsg',
      { headers: this.headers }
    );
  }

  setMessageBoardMsg(msgb: MessageBoard): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.messageboardEndpoint + '/SetMessageBoardMsg',
      JSON.stringify(msgb),
      { headers: this.headers }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
