/* eslint-disable @angular-eslint/component-selector */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  Account,
  AccountProfileConfig,
  AccountService,
  AlertService,
  AuthServiceProvider,
  ChasePaymentService,
  ComponentMode,
  ConfigurationService,
  LogService,
  ThemeService,
  TransactionResponse,
} from '@ibitoll/toll-core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { switchMap } from 'rxjs';
import { SubSink } from 'subsink';
import { ChaseReceiptComponent } from '../chase-receipt/chase-receipt.component';

@Component({
  selector: 'hhb-chase-one-time',
  templateUrl: './chase-one-time.component.html',
  styleUrls: ['./chase-one-time.component.scss'],
})
export class ChaseOneTimeComponent implements OnInit, OnDestroy {
  @ViewChild('errorModal') errorModal: ElementRef;

  @Input() componentMode: ComponentMode = ComponentMode.Self;
  @Input() cardMode = true;

  public chaseFormGroup: FormGroup;
  public paymentForm: FormGroup;
  public mode = ComponentMode.Emit;

  account: Account;
  ticketNo: string;
  isTicketAvailable = false;
  cardExist = false;
  accountProfile: AccountProfileConfig;
  isAccountProfileLoaded = false;
  processing = false;
  requestProcessed: boolean;
  enableCCSubmit = false;
  errorObj: { title: string; message: string };
  showConfirmPayment = false;

  UID = '';

  link = this.themeService.active.ChaseHPFLink; // 'https://chase-var.hostedpaymentservice.net/hpf/?uid=';
  url = '';
  urlSafe: SafeResourceUrl;
  private subs = new SubSink();
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  constructor(
    public logService: LogService,
    private accountService: AccountService,
    public authService: AuthServiceProvider,
    public formBuilder: FormBuilder,
    public configService: ConfigurationService,
    public sanitizer: DomSanitizer,
    public chasePaymentService: ChasePaymentService,
    private alertService: AlertService,
    private modalService: NgbModal,
    public themeService: ThemeService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.paymentForm = this.formBuilder.group({
      amount: ['', Validators.required],
    });

    this.chaseFormGroup = this.formBuilder.group({
      amount: ['', [Validators.required]],
    });

    if (this.authService.loggedIn()) {
      this.accountService.getActiveAccount().subscribe((account: Account) => {
        this.account = account;
        this.cardExist = account.BankCardTokenId != null;
      });

      this.accountService
        .getActiveAccount()
        .pipe(
          switchMap((account: Account) => {
            this.account = cloneDeep(account);
            this.cardExist = account.BankCardTokenId != null;
            return this.configService.getAccountProfiles();
          })
        )
        .subscribe((accountProfiles: AccountProfileConfig[]) => {
          this.accountProfile = accountProfiles?.find(
            (o) => o.AccountProfileID == this.account.AccountProfileID
          );
          this.paymentForm.controls['amount'].setValidators(
            // Validators.min(this.accountProfile.BalanceReplenishAmount * -1)
            Validators.min(10)
          );
          this.chaseFormGroup.controls['amount'].setValidators(
            // Validators.min(this.accountProfile.BalanceReplenishAmount * -1)
            Validators.min(10)
          );
          this.isAccountProfileLoaded = true;
        });
    }
  }

  @HostListener('window:message', ['$event'])
  init(paymentResult: any): void {
    console.log('[OT]one-time pay Chase resp', paymentResult);

    if (paymentResult.data['subject'] == 'init') {
      console.log('init', paymentResult);
    }

    if (paymentResult.data['subject'] == 'startPayment') {
      console.log('startPayment', paymentResult);
    }

    if (paymentResult.data['subject'] == 'completePayment') {
      this.postResponse();
    }

    if (paymentResult.data['subject'] == 'cancelPayment') {
      this.isTicketAvailable = false;
      console.log('cancelPayment', paymentResult.data.content);
      this.logService.logMessage('[OT]cancel: acc=' + this.account?.AccountID + ' data: ' + JSON.stringify(paymentResult.data));
    }

    if (paymentResult.data['subject'] == 'handlePaymentErrors') {
      //this.isTicketAvailable = false;
      //this.postResponse();
      console.log('handlePaymentErrors', paymentResult.data.content);
      this.logService.logMessage('[OT]handlePaymentErrors: acc=' + this.account?.AccountID + ' data:' + JSON.stringify(paymentResult.data));
      this.alertService.error(
        this.displayError(paymentResult),
        true
      );
    }

    if (paymentResult.data['subject'] == 'handleTransactionErrors') {
      //this.isTicketAvailable = false;
      //this.postResponse();
      console.log('handleTransactionErrors', paymentResult.data.content);
      this.logService.logMessage('[OT]handleTransactionErrors: acc=' + this.account?.AccountID + ' data:' + JSON.stringify(paymentResult.data));
      this.alertService.error(
        this.displayError(paymentResult),
        true
      );
    }

  }

  displayError(msg: any) {
    try {
      let error_message;
      if (msg.data.content && Array.isArray(msg.data.content) && msg.data.content.length > 0)
      {
        if (msg.data.content[0].status_code == '2300'){
          error_message = msg.data.content[0].gateway_message
        }
        else{
          error_message = msg.data.content[0].status_message
        }
        return `Onetime Transaction declined: ${error_message}. Please try again.`
      }
      else if (msg.data.content && msg.data.content.status_code) {

        if (msg.data.content.status_code == '2300'){
          error_message = msg.data.content.gateway_message
        }
        else{
          error_message = msg.data.content.status_message
        }
        return `Onetime Transaction declined: ${error_message}. Please try again.`
      }
      else{
        return `Onetime Transaction declined, Please try again.`
      }
    } catch (error) {
      return `Onetime Transaction declined, Please try again.`
    }
  }

  ngAfterViewInit(): void {
    this.chaseFormGroup.valueChanges.subscribe(() => {
      this.enableCCSubmit = this.chaseFormGroup.valid;
    });

    if (this.isAccountProfileLoaded) {
      this.paymentForm.controls['amount'].setValidators(
        Validators.min(10)
      );
      this.chaseFormGroup.controls['amount'].setValidators(
        Validators.min(10)
      );
    }

    this.cdr.detectChanges();
  }

  preLoad() {
    this.processing = true;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          return this.chasePaymentService.preLoad(
            this.chaseFormGroup.controls['amount'].value,
            this.account.AccountID,
            1,
            this.account.Language,
            'OneTimePaymentMobile'
          );
        })
      )
      .subscribe(
        (ticket) => {
          this.processing = false;
          this.ticketNo = ticket;
          this.url = this.link + this.ticketNo;
          console.log('OTP: Using URL: ' + this.url);
          this.isTicketAvailable = true;
        },
        (error) => {
          this.processing = false;
          this.isTicketAvailable = false;

          this.hideChase();
          this.logService.logMessage('preLoad failed' + JSON.stringify(error));
          this.alertService.error(
            'Unable to process your request at this time please try again later!',
            true
          );
        }
      );
  }

  replaceWithValue(text: any, textValue: any): string {
    //console.log('text='+text+'; textValue=' + textValue);
    var outp = this.translateService.instant(text)
    var regEx = /==AMOUNT==/gi;
    var amount = parseFloat(textValue);
    textValue = amount.toLocaleString('en-US', {style: 'currency',currency: 'USD'});
    outp = outp.replace(regEx, '$' + textValue);
    //console.log('outp='+outp);
    return outp;
  }

  postResponse() {
    this.processing = true;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          return this.chasePaymentService.postResponse(
            this.chaseFormGroup.controls['amount'].value,
            this.account.AccountID,
            1,
            this.account.Language,
            this.ticketNo
          );
        })
      )
      .subscribe({
        next: (receiptResponse) => {
          this.accountService
            .loadActiveAccount(this.account.AccountID)
            .subscribe({
              next: () => {
                this.isTicketAvailable = false;
                this.processing = false;
                this.displayReceipt(receiptResponse);
                this.hideChase();
                this.paymentForm.reset();
                this.chaseFormGroup.reset();
              },
            });
        },
        error: (error) => {
          this.isTicketAvailable = false;
          this.processing = false;
          this.hideChase();
          this.paymentForm.reset();
          this.chaseFormGroup.reset();
        },
      });
  }


  confirmPayment() {
    this.showConfirmPayment = true;
    console.log("Conirm ?");
  }

  cancelSavedPayment() {
    this.showConfirmPayment = false;
  }
  processSavedPayment() {
    this.processing = true;
    this.configService.CheckDbStatus().subscribe(
      (data) => {
        this.chasePaymentService
          .processSavedCardPayment(
            this.account.AccountID,
            this.paymentForm.controls['amount'].value
          )
          .subscribe(
            (receiptResponse) => {
              this.accountService
                .loadActiveAccount(this.account.AccountID)
                .subscribe(
                  () => {
                    this.processing = false;
                    this.showConfirmPayment = false;
                    this.paymentForm.reset();
                    this.displayReceipt(receiptResponse);
                  },
                  (error) => {
                    this.displayError(error);
                    this.logService.logMessage(
                      'ProcessSavedPayment failed' + JSON.stringify(error)
                    );
                  }
                );
            },
            (error) => {
              this.processing = false;
              this.showConfirmPayment = false;
              this.paymentForm.reset();
              this.logService.logMessage(
                'ProcessPayment failed' + JSON.stringify(error)
              );
            }
          );
      },
      (error) => {
        this.processing = false;
        this.showConfirmPayment = false;
        this.paymentForm.reset();
        this.logService.logMessage(
          'CheckDbStatus failed' + JSON.stringify(error)
        );
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hideChase() {}

  showChase() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ursl = this.url + this.ticketNo;
  }

  displayReceipt(txnResp: TransactionResponse): any {
    this.showConfirmPayment = false;
    if (txnResp.ResponseOrderID) {
      const softHyphen = '-'; // &shy; does not work here for some reasons
      let a = txnResp.ResponseOrderID;
      let count = Math.floor(a.length / 12);
      while (count > 0) {
        const position = count * 12;
        if (a.length > position) {
          a = [a.slice(0, position), softHyphen, a.slice(position)].join('');
        }
        count--;
      }
      txnResp.ResponseOrderID = a;
    }

    const modalRef = this.modalService.open(ChaseReceiptComponent, {
      animation: true,
      centered: true,
    });
    modalRef.componentInstance.transactionResponse = txnResp;
    modalRef.componentInstance.currentDate = new Date();
  }

  displayError2(msg: any) {
    if (msg) {
      this.requestProcessed = false; // hide form and show error
      this.errorObj = {
        title: '',
        message: '',
      };

      this.errorObj.title = 'Payment Incomplete!';
      this.errorObj.message = `The payment information provided in the authorization request appears to be invalid.
      Please check is Card is valid and retry.`;

      if (msg.errorMessage) {
        this.errorObj.title = msg.errorName;
        this.errorObj.message = msg.errorMessage;
      }

      if (msg.ErrorCode || msg.errorMessage) {
        this.errorObj.title = 'Payment Incomplete!';

        switch (msg.ErrorCode || msg.errorCode) {
          case '5106' || '5105':
            this.errorObj.message = `The bank information provided in the authorization request appears to be invalid.
             Please check account and transit/routing number details and retry.`;
            break;

          case '5001':
            this.errorObj.message =
              'The credit card expiration date entered is invalid';
            break;

          case '5000':
            this.errorObj.message = 'The credit card number entered is invalid';
            break;

          case '5021':
            this.errorObj.message =
              'The CVV2 number should be 3 or 4 digits in length';
            break;

          case '5022':
            this.errorObj.message =
              'The CVV2 number should be numeric character only.';
            break;

          default:
            this.errorObj.message = `Payment incomplete. The payment information provided in the authorization
             request appears to be invalid.
            Please check payment details and try again.`;
            break;
        }
      }

      if (msg?.error?.Message) {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = this.translateService.instant(
          msg?.error?.Message
        );
      }

      if (msg === 'Payment Failed') {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = `The payment declined. Please check payment details and try again.`;
      }

      if (msg.ssl_result_message === 'DECLINED') {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = `The payment declined. Please check payment details and try again.`;
      }

      if (msg.ssl_result_message === 'DECLINED: NSF') {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = `The payment declined. Please check payment details and try again.`;
      }

      if (msg.ssl_result_message === 'SERV NOT ALLOWED') {
        this.errorObj.title = 'Invalid Payment';
        this.errorObj.message = `The payment information provided appears to be invalid.
       Please check payment details and try again.`;
      }

      setTimeout(() => {
        this.modalService.open(this.errorModal, { size: 'lg' });
      });
    }

    this.processing = false;
    this.showConfirmPayment = false;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
