<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div class="modal-header">
    <div class="modal-title mb-0 h3" id="exampleModalLabel">
      {{ 'TRANSPONDER_REQUEST_SUSPEND_NO' | translate
      }} ...{{ transponder?.TransponderDisplayId?.substring(transponder?.TransponderDisplayId?.length-6) }}
    </div>
    <button type="button " class="btn-close btn-close-white pull-right" (click)="activeModal.close()"
      aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <span>{{ 'TRANSPONDER_REQUEST_SUSPEND' | translate }}</span>
  </div>
  <div class="modal-footer">
    <button class="btn btn-app-secondary float-start me-auto" data-dismiss="modal" (click)="activeModal.dismiss()">
      {{ 'CANCEL' | translate }}
    </button>
    <button class="btn btn-app-primary float-end" (click)="confirmSuspension()">
      {{ 'CONFIRM' | translate }}
      <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isRequesting"></i>
    </button>
  </div>
</div>
