import { cloneDeep } from 'lodash-es';
import { AuthServiceProvider } from './../../providers/auth.service';
import {
  Component,
  ElementRef,
  isDevMode,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
  NgZone,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ScriptService } from 'ngx-script-loader';
import { Account } from '../../models/account';
import { AccountService } from '../../providers/account.service';
import { ConfigurationService } from '../../providers/configuration.service';
import { LogService } from '../../providers/log.service';
import { UserService } from '../../providers/user.service';
import { AlertService } from '../../providers/alert.service';
import { TransactionResponse } from '../../models/transactionResponse';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MonerisService } from '../../providers/moneris.service';
import { MonerisReceiptComponent } from '../moneris-receipt/moneris-receipt.component';
import { Subject } from 'rxjs';
import { ComponentMode } from '../../enum/component-mode.enum';
import { AccountProfileConfig } from '../../models/idNameConfig';

declare const monerisCheckout: any;

@Component({
  selector: 'moneris-one-time',
  templateUrl: './moneris-one-time.component.html',
  styleUrls: ['./moneris-one-time.component.scss'],
})
export class MonerisOneTimeComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() componentMode: ComponentMode = ComponentMode.Self;
  processing: boolean = false;
  requestProcessed: boolean;
  enableCCSubmit: boolean = false;
  account: Account;
  callback: any;

  invoiceNumber: string;
  sequenceNumber: string;

  private subs = new SubSink();

  errorObj: { title: string; message: string };
  newUserLogonId: any;

  @ViewChild('errorModal') errorModal: ElementRef;
  myCheckout: any;

  public monerisFormGroup: FormGroup;
  public paymentForm: FormGroup;

  ticketNo: string;
  isTicketAvailable: boolean = false;
  cardExist: boolean = false;

  public mode = ComponentMode.Emit
  accountProfile: AccountProfileConfig;
  isAccountProfileLoaded: boolean = false;


  constructor(
    public formBuilder: FormBuilder,
    private accountService: AccountService,
    public configService: ConfigurationService,
    public logService: LogService,
    public monerisService: MonerisService,
    public userService: UserService,
    private modalService: NgbModal,
    private alertService: AlertService,
    public authService: AuthServiceProvider,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private scriptService: ScriptService
  ) {
    if (isDevMode()) {
      this.scriptService
        .loadScript('https://gatewayt.moneris.com/chkt/js/chkt_v1.00.js')
        .subscribe(() => { });
    } else {
      this.scriptService
        .loadScript('https://gateway.moneris.com/chkt/js/chkt_v1.00.js')
        .subscribe(() => { });
    }
  }

  monerisInit() {
    console.log('monerisInit');
    this.myCheckout = new monerisCheckout();

    if (isDevMode()) {
      this.myCheckout.setMode('qa');
    } else {
      this.myCheckout.setMode('prod');
    }

    this.myCheckout.setCheckoutDiv('monerisCheckout');

    this.myCheckout.setCallback('page_loaded', this.myPageLoad.bind(this));
    this.myCheckout.setCallback(
      'cancel_transaction',
      this.myCancelTransaction.bind(this)
    );
    this.myCheckout.setCallback('error_event', this.myErrorEvent.bind(this));
    this.myCheckout.setCallback(
      'payment_receipt',
      this.myPaymentReceipt.bind(this)
    );
    this.myCheckout.setCallback(
      'payment_complete',
      this.myPaymentComplete.bind(this)
    );
  }

  ngOnInit(): void {
    this.paymentForm = this.formBuilder.group({
      amount: ['', Validators.required]
    });

    this.monerisFormGroup = this.formBuilder.group({
      amount: ['', [Validators.required]],
    });

    if (this.authService.loggedIn()) {
      this.accountService.getActiveAccount().subscribe((account: Account) => {
        this.account = account;
        this.cardExist = account.BankCardTokenId != null;

      });


      this.accountService.getActiveAccount().pipe(switchMap((account: Account) => {
        this.account = cloneDeep(account);
        this.cardExist = account.BankCardTokenId != null;
        return this.configService.getAccountProfiles();
      })).subscribe((accountProfiles: AccountProfileConfig[]) => {

        this.accountProfile = accountProfiles?.find(o => o.AccountProfileID == this.account.AccountProfileID);
        this.paymentForm.controls['amount'].setValidators(Validators.min(this.accountProfile.BalanceReplenishAmount * -1));
        this.monerisFormGroup.controls['amount'].setValidators(Validators.min(this.accountProfile.BalanceReplenishAmount * -1));
        this.isAccountProfileLoaded = true;
      });

    }
  }

  ngAfterViewInit(): void {
    this.monerisFormGroup.valueChanges.subscribe(() => {
      this.enableCCSubmit = this.monerisFormGroup.valid;
    });

    if( this.isAccountProfileLoaded){
      this.paymentForm.controls['amount'].setValidators(Validators.min(this.accountProfile.BalanceReplenishAmount * -1));
      this.monerisFormGroup.controls['amount'].setValidators(Validators.min(this.accountProfile.BalanceReplenishAmount * -1));
    }

    this.cdr.detectChanges();
  }

  myPageLoad(data) {
    console.log('myPageLoad', data);
  }

  myCancelTransaction(data) {
    this.hideMonerisMCO();
    console.log('myCancelTransaction', data);
  }

  myErrorEvent(data) {
    console.log('myErrorEvent', data);
  }

  myPaymentReceipt(data) {
    console.log('myPaymentReceipt', data);
  }

  myPaymentComplete(res: any) {
    var strIntoObj = JSON.parse(res);
    this.processing = true;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          return this.monerisService.receiptRequest(
            strIntoObj.ticket,
            this.account.AccountID,
            this.monerisFormGroup.controls['amount'].value
          );
        })
      )
      .subscribe(
        (receiptResponse) => {
          this.accountService
            .loadActiveAccount(this.account.AccountID)
            .subscribe(
              (data) => {
                this.processing = false;
                this.displayReceipt(receiptResponse);
                this.hideMonerisMCO();
                this.paymentForm.reset();
                this.monerisFormGroup.reset();
              },
              (error) => {
                this.displayError(error);
                this.hideMonerisMCO();
                this.logService.logMessage(
                  'CheckDbStatus failed' + JSON.stringify(error)
                );
                this.alertService.error(
                  'Unable to process your request at this time please try again later!',
                  true
                );
              }
            );
        },
        (error) => {
          this.displayError(error);
          this.hideMonerisMCO();
          this.logService.logMessage(
            'CheckDbStatus failed' + JSON.stringify(error)
          );
          this.alertService.error(
            'Unable to process your request at this time please try again later!',
            true
          );
        }
      );
  }

  hideMonerisMCO() {
    this.myCheckout.closeCheckout(' ');
    this.isTicketAvailable = false;
    this.processing = false;
  }

  processSavedPayment() {
    this.processing = true;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          return this.monerisService.processPayment(
            this.account.AccountID,
            this.paymentForm.controls['amount'].value
          );
        })
      ).subscribe(
        (receiptResponse) => {
          this.accountService
            .loadActiveAccount(this.account.AccountID)
            .subscribe(
              (data) => {
                this.processing = false;
                this.paymentForm.reset();
                this.displayReceipt(receiptResponse);
              },
              (error) => {
                this.displayError(error);
                this.logService.logMessage(
                  'CheckDbStatus failed' + JSON.stringify(error)
                );
                this.alertService.error(
                  'Unable to process your request at this time please try again later!',
                  true
                );
              }
            );
        }, error => {
          this.displayError(error);
          this.logService.logMessage(
            'CheckDbStatus failed' + JSON.stringify(error)
          );
          this.alertService.error(
            'Unable to process your request at this time please try again later!',
            true
          );
        });

  }

  preLoad() {
    this.processing = true;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          return this.monerisService.preLoad(
            this.monerisFormGroup.controls['amount'].value,
            this.account.AccountID,
            1,
            this.account.Language
          );
        })
      )
      .subscribe(
        (ticket) => {
          this.processing = false;
          this.ticketNo = ticket;
          this.isTicketAvailable = true;
          this.monerisInit();
          setTimeout(() => {
            this.myCheckout.startCheckout(this.ticketNo);
          }, 100);
        },
        (error) => {
          this.hideMonerisMCO();
          this.logService.logMessage('preLoad failed' + JSON.stringify(error));
          this.alertService.error(
            'Unable to process your request at this time please try again later!',
            true
          );
        }
      );
  }

  displayReceipt(txnResp: TransactionResponse): any {
    var subject = new Subject<any>();

    if (txnResp.ResponseOrderID) {
      const softHyphen = '-'; // &shy; does not work here for some reasons
      let a = txnResp.ResponseOrderID;
      let count = Math.floor(a.length / 12);
      while (count > 0) {
        const position = count * 12;
        if (a.length > position) {
          a = [a.slice(0, position), softHyphen, a.slice(position)].join('');
        }
        count--;
      }
      txnResp.ResponseOrderID = a;
    }
    const dialogRef = this.dialog.open(MonerisReceiptComponent, {
      height: '285px',
      width: '450px',
      data: {
        transactionResponse: txnResp,
        currentDate: new Date(),
      },
      panelClass: 'moneris-receipt-dialog',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      subject.next(null);
      subject.complete();
    });

    return subject.asObservable();
  }

  displayError(msg: any) {
    if (msg) {
      this.requestProcessed = false; // hide form and show error
      this.errorObj = {
        title: '',
        message: '',
      };

      this.errorObj.title = 'Payment Incomplete!';
      this.errorObj.message = `The payment information provided in the authorization request appears to be invalid.
      Please check account and transit/routing number details and retry.`;

      if (msg.errorMessage) {
        this.errorObj.title = msg.errorName;
        this.errorObj.message = msg.errorMessage;
      }

      if (msg.ErrorCode || msg.errorMessage) {
        this.errorObj.title = 'Payment Incomplete!';

        switch (msg.ErrorCode || msg.errorCode) {
          case '5106' || '5105':
            this.errorObj.message = `The bank information provided in the authorization request appears to be invalid.
             Please check account and transit/routing number details and retry.`;
            break;

          case '5001':
            this.errorObj.message =
              'The credit card expiration date entered is invalid';
            break;

          case '5000':
            this.errorObj.message = 'The credit card number entered is invalid';
            break;

          case '5021':
            this.errorObj.message =
              'The CVV2 number should be 3 or 4 digits in length';
            break;

          case '5022':
            this.errorObj.message =
              'The CVV2 number should be numeric character only.';
            break;

          default:
            this.errorObj.message = `Payment incomplete. The payment information provided in the authorization
             request appears to be invalid.
            Please check payment details and try again.`;
            break;
        }
      }

      if (msg.ssl_result_message === 'DECLINED') {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = `The payment declined. Please check payment details and try again.`;
      }

      if (msg.ssl_result_message === 'DECLINED: NSF') {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = `The payment declined. Please check payment details and try again.`;
      }

      if (msg.ssl_result_message === 'SERV NOT ALLOWED') {
        this.errorObj.title = 'Invalid Payment';
        this.errorObj.message = `The payment information provided appears to be invalid.
       Please check payment details and try again.`;
      }

      setTimeout(() => {
        this.modalService.open(this.errorModal, { size: 'lg' });
      });
    }

    this.processing = false;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}


