import { NgZone } from '@angular/core';
/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import {
  TransponderRequest,
  TollCategoryInfo,
  ProvinceInfo,
  Account,
  AccountService,
  AlertService,
  ConfigurationService,
  Transponder,
  TransponderFee,
  CarMake,
  CarModel,
} from '@ibitoll/toll-core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { find, remove, cloneDeep, filter as f } from 'lodash-es';
import { SubSink } from 'subsink';
import { tap, switchMap } from 'rxjs/operators';
import { uniqBy } from 'lodash';

@Component({
  selector: 'newtransponder',
  templateUrl: './newtransponder.component.html',
  styleUrls: ['./newtransponder.component.scss'],
})
export class NewtransponderComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  order = 'id';
  account: Account = new Account();

  @Input() transponder: Transponder;

  @Input() isEditTransponder = false;

  @Input() transponderFeesOg: TransponderFee[] = [];
  @Input() transponderFees: TransponderFee[] = [];

  @Output() validationStatus: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() onLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  public transponderInfoFormGroup: FormGroup;

  tollCategories: Array<TollCategoryInfo> = [];
  tollCategoriesOg: Array<TollCategoryInfo> = [];
  provinceOptions: Array<ProvinceInfo> = [];
  transponderRequestList: Array<TransponderRequest> = [];
  transponderRequest = new TransponderRequest();
  public preferedProvinces: ProvinceInfo[] = [];

  private subs = new SubSink();
  public hideCategory = true;
  public hideTransponderType = true;
  transponderTypeId: number;

  @Output() newtransponderStatus = new EventEmitter<any>();
  unique = this.constructor['ɵcmp'].id;

  currentTime = new Date();
  isLoading: boolean;
  carMakes: CarMake[] = [];
  carMakesOg: CarMake[] = [];

  carModel: CarModel[] = [];
  carModelOg: CarModel[] = [];

  tokensAreLoading = false;

  public model: CarMake;

  constructor(
    private cdr: ChangeDetectorRef,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private configurationService: ConfigurationService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    console.log('ngInit.hideCategory=' + this.hideCategory);
    var currYear = (new Date()).getFullYear();
    this.transponderInfoFormGroup = this.formBuilder.group({
      transponderType: [''],
      category: ['', Validators.required],
      province: [''],
      plate: [''],
      make: [''],
      model: [''],
      color: [''],
      year: ['', [Validators.max(currYear + 1), Validators.min(1900)]],
    });

    this.transponderInfoFormGroup.valueChanges.subscribe((data) => {
      this.transponderRequest.TransponderTypeId = data.transponderType;
      this.transponderRequest.TollCategoryId = data.category;
      this.transponderRequest.PlateProvinceId = data.province;
      this.transponderRequest.PlateNum = data.plate;
      this.transponderRequest.VehicleMake = data?.make;
      this.transponderRequest.VehicleModel = data.model;
      this.transponderRequest.VehicleColor = data.color;
      this.transponderRequest.VehicleYear = data.year;

      console.log(this.transponderInfoFormGroup);
      if (this.transponderInfoFormGroup.valid) {
        this.validationStatus.emit(true);
      } else {
        this.validationStatus.emit(false);
      }
    });

    this.transponderInfoFormGroup.controls['make'].valueChanges.subscribe(
      (make) => {
        this.ngZone.run(() => {
          this.tokensAreLoading = true;
          //debugger;
          if (make) {
            const selectedCarMakeId = f(this.carMakesOg, (o) => {
              return make.includes(o.Name);
            });

            const carModel: CarModel[] = f(this.carModelOg, (o) => {
              return (
                selectedCarMakeId.find((x) => x.Id == o.IdCarMake) !== undefined
              );
            });

            this.carModel = uniqBy(carModel, 'Name');

            this.tokensAreLoading = false;
          } else {
            this.carModel = uniqBy(this.carModelOg, 'Name');
            this.tokensAreLoading = false;
          }
        });
      }
    );

    this.newtransponderStatus.emit(this);

    this.subs.add(
      this.accountService.activeAccount
        .pipe(
          tap((account) => {
            this.isLoading = true;
            this.account = account;
          }),
          switchMap((account) => {
            return this.configurationService.getTransponderFees();
          }),
          switchMap((transponderFee: TransponderFee[]) => {
            this.transponderFeesOg = cloneDeep(transponderFee);
            this.transponderFees = cloneDeep(transponderFee).filter(
              (o) =>
                o.CurrenctType == this.account.CurrencyType &&
                o.AccountProfileId === this.account.AccountProfileID
            ); // .sort((a,b) => b.TransponderTypeName?.localeCompare(a.TransactionTypeName))
            return this.configurationService.getTollCategories();
          }),
          switchMap((tollCategories) => {
            this.tollCategoriesOg = tollCategories;
            console.log('tollCategoriesOg: ' + JSON.stringify(this.tollCategoriesOg));
            return this.configurationService.GetTollCategoryInfoByAccountProfileID(
              this.account.AccountProfileID.toString()
            );
          })
        )
        .subscribe((tollCategories) => {
          this.isLoading = false;
          const distinctCat = new Array<TollCategoryInfo>();
          const flags = new Array<any>();
          const len = tollCategories.length;
          let i: number;
          for (i = 0; i < len; i++) {
            if (flags[tollCategories[i].GroupName]) {
              continue;
            }
            flags[tollCategories[i].GroupName] = true;
            distinctCat.push(tollCategories[i]);
          }
          this.tollCategories = distinctCat;
          console.log('distinctCat: ' + JSON.stringify(distinctCat));

          this.checkTollCategories();
          this.checkTransponderType();
          this.cdr.detectChanges();
          this.onLoaded.emit(true);
        })
    );

    this.subs.add(
      this.configurationService
        .GetCarModel()
        .pipe(
          switchMap((model) => {
            var sortModel = model.sort((a,b)=> a.Name.localeCompare( b.Name));
            this.carModel = uniqBy(sortModel, 'Name');
            this.carModelOg = cloneDeep(sortModel);
            return this.configurationService.GetCarMake();
          })
        )
        .subscribe({
          next: (makes) => {
            console.log('Loaded makes. Count=' + makes.length);
            var sortMakes = makes.sort((a,b)=> a.Name.localeCompare( b.Name));
            this.carMakes = uniqBy(sortMakes, 'Name');
            this.carMakesOg = cloneDeep(sortMakes);
          },
          error: (err) => {
            console.log(err);
          },
        })
    );
  }

  ngAfterViewInit(): void {
    if (this.transponderFeesOg.length == 0 && !this.account?.AccountID) {
      this.configurationService
        .getTransponderFees()
        .subscribe((transponderFee: TransponderFee[]) => {
          this.transponderFeesOg = cloneDeep(transponderFee);
          this.transponderFees = cloneDeep(transponderFee);
          this.checkTransponderType();
        });
    }

    this.subs.add(
      this.configurationService.getProvinces().subscribe((data) => {
        const province = cloneDeep(data);
        this.provinceOptions = province.sort((a, b) => {
          return a.ProvinceOrderId - b.ProvinceOrderId; 
        }); 

        this.preferedProvinces = province.filter((p) => {
          return p.Code == 'NS';
        });

        setTimeout(() => {
          remove(this.provinceOptions, (p) => {
            p.Code == 'NS' || p.Name == 'Nova Scotia';
          });
        });

        this.preferedProvinces.forEach((v, i) => {
          if (v.Code == 'NS') {
            this.transponderInfoFormGroup.get('province').setValue(v.Id);
            this.transponderInfoFormGroup
              .get('province')
              .updateValueAndValidity();
          }
        });

        this.cdr.detectChanges();
      })
    );

    this.checkTollCategories();
    this.checkTransponderType();
   
    //this.transponderFees = this.transponderFees.sort((a,b) => b.TransponderTypeName?.localeCompare(a.TransponderTypeName));
    //console.log('Fee after:' + JSON.stringify(this.transponderFees));
    console.log('ngAfterInit.hideCategory=' + this.hideCategory);

  }

  get tagInfo() {
    return this.transponderInfoFormGroup.controls;
  }

  lockTransponderProvince(MailingProvinceID: number) {
    if (MailingProvinceID) {
      if (
        this.transponderInfoFormGroup.controls['province'].value !==
        MailingProvinceID
      ) {
        this.transponderInfoFormGroup.controls['province'].setValue(
          MailingProvinceID
        );
      }
    }
  }

  checkTollCategories() {
    console.log('checkTollCategories: ' + JSON.stringify(this.tollCategories));
    //debugger;
    if (this.tollCategories.length === 1) {
      this.transponderRequest.TollCategoryId = this.tollCategories[0].Id;
      if (this.transponderInfoFormGroup) {
        this.transponderInfoFormGroup
          .get('category')
          .setValue(this.tollCategories[0].Id);
        this.transponderInfoFormGroup.get('category').setValidators([]);
        this.transponderInfoFormGroup.get('category').updateValueAndValidity();
      }
      this.hideCategory = true;
      this.cdr.detectChanges();
    } else {
      if (this.transponderInfoFormGroup) {
        this.transponderInfoFormGroup
          .get('category')
          .setValidators(Validators.required);
      }
      this.hideCategory = false;
      this.cdr.detectChanges();
    }
    console.log('hideCategory=' + this.hideCategory);
  }

  checkTransponderType() {
    if (this.transponderFees.length === 1) {
      this.transponderRequest.TransponderTypeId =
        this.transponderFees[0].TransponderTypeId;
      if (this.transponderInfoFormGroup) {
        this.transponderInfoFormGroup
          .get('transponderType')
          .setValue(this.transponderFees[0].TransponderTypeId);
        this.transponderInfoFormGroup.get('transponderType').setValidators([]);
        this.transponderInfoFormGroup
          .get('transponderType')
          .updateValueAndValidity();
      }
      this.hideTransponderType = true;
    } else if (this.transponderFees.length > 1) {
      var macpassIndex = 1; // this is actually the type sticker which pretends to be an macpass (see HHB-1114)
      if (this.transponderInfoFormGroup) {
        this.transponderInfoFormGroup
          .get('transponderType')
          .setValue(this.transponderFees[macpassIndex].TransponderTypeId);
        this.transponderInfoFormGroup
          .get('transponderType')
          .setValidators(Validators.required);
      }
      this.hideTransponderType = false;
    }
    console.log('checkTranspType: ' + this.hideTransponderType);
  }

  filterTransponderType(currencyType: string, accountProfileId: number) {
    console.log('1Fee (CT='+currencyType+',APid='+accountProfileId+') before:' + JSON.stringify(this.transponderFees));
    this.transponderFees = cloneDeep(this.transponderFeesOg).filter(
      (o) =>
        o.CurrenctType == currencyType && o.AccountProfileId === accountProfileId
    ); //.sort((a,b) => a.TransponderTypeName?.localeCompare(b.TransactionTypeName));
    console.log('1Fee after:' + JSON.stringify(this.transponderFees));
    this.checkTransponderType();
  }

  addNewVehicle() {
    if (
      !find(this.transponderRequestList, {
        PlateNum: this.transponderRequest.PlateNum.toUpperCase(),
        PlateProvinceId: this.transponderRequest.PlateProvinceId,
      })
    ) {
      this.transponderRequest.PlateNum =
        this.transponderRequest.PlateNum.toUpperCase();
      this.transponderRequestList.push(this.transponderRequest);
      this.transponderRequest = new TransponderRequest();
      this.resetActiveForm();
    } else {
      this.alertService.error(
        `Vehicle with plate number ${this.transponderRequest.PlateNum} is already exists.`,
        true
      );
    }
  }

  resetActiveForm() {
    this.transponderInfoFormGroup.controls['category'].reset();
    this.transponderInfoFormGroup.controls['plate'].reset();
    this.transponderInfoFormGroup.controls['make'].reset();
    this.transponderInfoFormGroup.controls['model'].reset();
    this.transponderInfoFormGroup.controls['color'].reset();
    this.transponderInfoFormGroup.controls['year'].reset();

    this.checkTransponderType();
    this.checkTollCategories();
  }

  checkStatus(fromConName: string) {
    return this.transponderInfoFormGroup.controls[fromConName].disabled;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
