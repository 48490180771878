import { Component, OnInit } from '@angular/core';
import { ConfigurationService, ThemeService } from '@ibitoll/toll-core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'onside-container',
  templateUrl: './onside-container.component.html',
  styleUrls: ['./onside-container.component.scss'],
})
export class OnsideContainerComponent implements OnInit {
  currentLanguage = 'en';

  constructor(
    private translate: TranslateService,
    public themeService: ThemeService,
    public configService: ConfigurationService
  ) {
    this.currentLanguage = this.themeService.GetClientLanguage();
    this.translate.setDefaultLang(this.currentLanguage);
  }

  ngOnInit(): void {}

  updateLanguage(languageCode: string) {
    this.themeService.SetClientLanguage(languageCode);
    this.currentLanguage = languageCode;
    this.translate.setDefaultLang(languageCode);

    this.translate.use(languageCode).subscribe(() => {
      this.translate
        .get(this.themeService.active.LogoImageUrl)
        .subscribe((res: string) => {
          // document.getElementById('favicon').setAttribute('href', res);
        });
    });
  }
}
