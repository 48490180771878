import { Pipe, PipeTransform } from '@angular/core';
import { TransactionRecord } from '@ibitoll/toll-core';

@Pipe({ name: 'transactionOrder' })
export class TransactionOrder implements PipeTransform {
  transform(transactionList: TransactionRecord[]) {
    if (transactionList) {
      return transactionList.sort(
        (x, y) =>
          new Date(y.SysPostLDateTime).getTime() -
          new Date(x.SysPostLDateTime).getTime()
      );
    } else {
      return transactionList;
    }
  }
}
