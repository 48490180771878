import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { SubSink } from 'subsink';
import { endOfMonth, parse } from 'date-fns';
import { DdlItem } from '../../models/dateItem';
import { UtilsService } from '../../providers/utils.service';
import { ConfigurationService } from '../../providers/configuration.service';

@Component({
  selector: 'credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent implements OnInit, AfterViewInit, OnDestroy {
  public creditCardFormGroup: FormGroup;

  public showAmount: boolean = true;

  @Input() cardMode: boolean = true;

  @Input() cardModeBm: number = 1;

  @Input() hideAmount: boolean = true;

  @Output() creditCardStatus = new EventEmitter<any>();

  @Output() onLoaded: EventEmitter<any> = new EventEmitter();

  public componentId: number;

  unique = this.constructor['ɵcmp'].id;

  years = [];

  months = [
    { id: -1, name: 'Select' },
    { id: 1, name: '01' },
    { id: 2, name: '02' },
    { id: 3, name: '03' },
    { id: 4, name: '04' },
    { id: 5, name: '05' },
    { id: 6, name: '06' },
    { id: 7, name: '07' },
    { id: 8, name: '08' },
    { id: 9, name: '09' },
    { id: 10, name: '10' },
    { id: 11, name: '11' },
    { id: 12, name: '12' },
  ];

  initItem: DdlItem = new DdlItem(-1, 'Select');

  private subs = new SubSink();
  minAmount: number = 20;

  get creditCardForm() {
    return this.creditCardFormGroup.controls;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private utilsService: UtilsService,
    public configurationService: ConfigurationService,
    public formBuilder: FormBuilder
  ) {
    const d = new Date();
    const iY = d.getFullYear();

    this.years.push(this.initItem);
    for (let i = 0; i < 9; i++) {
      this.years.push(new DdlItem(iY + i, '' + (iY + i)));
    }

    this.componentId = Math.random();
  }

  ngOnInit(): void {
    this.creditCardFormGroup = this.formBuilder.group({
      amount: ['', []],
      nameOnCard: ['', [Validators.required]],
      cardNumber: [
        '',
        [Validators.required, this.creditCardValidator.bind(this)],
      ],
      cardmonth: ['-1', [Validators.required]],
      cardyear: ['-1', [Validators.required]],
      cardcvv: ['', [Validators.required, Validators.min(3)]],
    });

    this.creditCardFormGroup.valueChanges.subscribe((paymentInfo) => {
      if (paymentInfo.cardyear !== '-1') {
        var expiryDate = endOfMonth(
          parse(
            paymentInfo.cardyear + '-' + paymentInfo.cardmonth + '-01',
            'yyyy-MM-dd',
            new Date()
          )
        );
        if (expiryDate < new Date()) {
          this.creditCardFormGroup.controls['cardmonth'].setErrors({
            invalidmonth: true,
          });
        } else {
          this.creditCardFormGroup.controls['cardmonth'].setErrors(null);
        }
      }
    });

    if (this.hideAmount) {
      this.creditCardFormGroup.controls['amount'].setValidators([]);
      this.creditCardFormGroup.controls['amount'].updateValueAndValidity();
      this.showAmount = false;
      this.cd.detectChanges();
    } else {
      this.creditCardFormGroup.controls['amount'].setValidators([
        Validators.required,
      ]);
      this.creditCardFormGroup.controls['amount'].updateValueAndValidity();
      this.showAmount = true;
      this.cd.detectChanges();
    }

    this.creditCardStatus.emit(this);
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
    setTimeout(() => {
      this.onLoaded.emit(true);
    });
  }

  hideAmt() {
    this.creditCardFormGroup.controls['amount'].setValidators([]);
    this.creditCardFormGroup.controls['amount'].updateValueAndValidity();
    this.showAmount = false;
    this.cd.detectChanges();
  }

  showAmt() {
    this.creditCardFormGroup.controls['amount'].setValidators([
      Validators.required,
    ]);
    this.creditCardFormGroup.controls['amount'].updateValueAndValidity();
    this.showAmount = true;
    this.cd.detectChanges();
  }

  creditCardValidator(control: FormControl) {
    let cardNumber = control.value;
    if (cardNumber) {
      let ccType = this.utilsService.getCardType(cardNumber);
      if (
        ccType === 1 ||
        ccType === 2 ||
        ccType === 3 ||
        ccType === 4 ||
        ccType === 7
      ) {
        return null;
      } else {
        return {
          invalidCreditCard: {
            invalidCreditCard: true,
          },
        };
      }
    } else {
      return {
        invalidCreditCard: {
          invalidCreditCard: true,
        },
      };
    }
  }

  enableMinAmount(minAmount: number) {
    this.minAmount = minAmount;
    if (this.showAmount) {
      this.creditCardFormGroup.controls['amount'].setValidators([
        Validators.min(minAmount),
      ]);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
