import { WebAccessQuery } from './webAccessQuery';

export class ChangePasswordQuery {
  UserLogonId: string;
  AccountId: string;
  AccountEmail: string;
  UserId: number;
  Email: string;
  LinkExpiryDateTime: string;
  Password: string;
  VerificationCode: string;
  Language: string;
  CurrentPassword: string;
  Uid: string;
  Status: boolean;
  Message: string;
  AccessCode: string;
  ClientId: string;

  CopyFrom(fromQuery: WebAccessQuery) {
    this.UserLogonId = fromQuery.UserLogonId;
    this.AccountId = fromQuery.AccountId;
    this.AccountEmail = fromQuery.AccountEmail;
    this.LinkExpiryDateTime = fromQuery.LinkExpiryDateTime;
    this.VerificationCode = fromQuery.VerificationCode;
    this.AccessCode = fromQuery.AccessCode;
    this.Uid = fromQuery.Uid;
    this.Email = fromQuery.UserEmail;
  }
}
