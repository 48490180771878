import { TranslateService } from '@ngx-translate/core';
import { Account } from './../../models/account';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { Transponder } from '../../models/transponder';
import { TollCategoryInfo } from '../../models/tollCategoryInfo';
import { ConfigurationService } from '../../providers/configuration.service';
import { AccountService } from '../../providers/account.service';
import { UserService } from '../../providers/user.service';
import { AlertService } from '../../providers/alert.service';
import { DatabaseMapService } from '../../providers/databasemap.service';
import { SuspendTagQuery } from '../../models/suspendTagQuery';

declare var jQuery: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'transponders',
  templateUrl: './transponders.component.html',
  styleUrls: ['./transponders.component.scss'],
})
export class TranspondersComponent implements OnInit, OnDestroy {
  @Input()
  integrated: boolean;

  transponders: Transponder[];
  pendingTransponders: Transponder[];
  account: Account = new Account();
  transponder: Transponder = null;
  isEmployee: boolean;
  isAO = false;
  showPendingOrder = true;

  accountSub: Subscription;

  vehicleCategories: TollCategoryInfo[];

  isLoading = true;
  enableTableRead: boolean = false;

  constructor(
    public configurationService: ConfigurationService,
    public accountService: AccountService,
    public userService: UserService,
    public alertService: AlertService,
    public databaseMapService: DatabaseMapService,
    public translateService: TranslateService
  ) {}

  ngOnInit() {
    this.configurationService
      .getTollCategories()
      .subscribe(
        (vehicleCategories) => (this.vehicleCategories = vehicleCategories)
      );

    this.accountSub = this.accountService
      .getActiveAccount()
      .subscribe((account: Account) => {
        if (account) {
          this.account = account;
          this.isEmployee =
            account.AccountSubType ===
            this.databaseMapService.eAccountSubTypeMap.Employee;
          this.getTransponderVehicleInfo(account.AccountID);
        }
      });
  }

  getCategoryName(categoryId: number): string {
    let categoryName = '-';

    if (this.vehicleCategories != null) {
      const categories = this.vehicleCategories.filter(
        (x) => x.Id === categoryId
      );

      if (categories.length > 0) {
        if (this.isAO) {
          categoryName = categories[0].Name;
        } else {
          categoryName = categories[0].GroupName;
        }
      }
    }

    return categoryName;
  }

  getTransponderVehicleInfo(accountID: number, forceRefresh: boolean = false) {
    this.isLoading = true;
    this.accountService
      .getTransponderVehicleInfo(accountID, forceRefresh)
      .subscribe(
        (resp) => {
          this.transponders = resp;
          this.accountService.getRequestedVehicleInfo(accountID).subscribe(
            (sresp) => {
              this.pendingTransponders = sresp;
              this.isLoading = false;
              setTimeout(() => {
                this.enableTableRead = true
              }, 500);
            },
            (error) => {
              this.enableTableRead = true
              this.isLoading = false;
              console.log('error in GetTransponderVehicleInfo: ' + error);
            }
          );
        },
        (error) => {
          this.enableTableRead = true
          this.isLoading = false;
          console.log('error in GetTransponderVehicleInfo: ' + error);
        }
      );
  }

  launchTransponderRequest() {
    this.alertService.clear();
    jQuery('#newTransponderModal').modal({ show: true });
  }

  launchSuspendTransponderConfirmation() {
    jQuery('#suspendTransponderModal').modal({ show: true });
  }

  onTransponderSuspended(transponder: Transponder) {
    const accId = this.userService.getCurrentUser().AccountId; // this.accountService.currentAccount.AccountID

    const suspendTagQuery: SuspendTagQuery = new SuspendTagQuery();
    suspendTagQuery.AccountId = accId;
    suspendTagQuery.ManufacturerId = transponder.ManufacturerId;
    suspendTagQuery.TransponderId = transponder.TransponderId;

    this.accountService.updateSuspendTag(suspendTagQuery).subscribe(
      () => {
        this.alertService.success(
          this.translateService.instant('TRANSPONDER_SUSPENDED_MSG'),
          true
        );
        this.getTransponderVehicleInfo(accId, true); // this.accountService.currentAccount.AccountID
      },
      (error) => {
        this.alertService.error(
          this.translateService.instant('TRANSPONDER_SUSPENDED_FAILED_MSG'),
          false
        );
      }
    );
  }

  suspendTransponder(transponder: Transponder) {
    // confirmation if want to be suspended
    this.transponder = transponder;
    jQuery('#suspendTransponderModal').modal({ show: true });
  }

  editTransponder(transponder: Transponder) {
    // confirmation if want to be suspended
    this.transponder = transponder;
    jQuery('#suspendTransponderModal').modal({ show: true });
  }

  ngOnDestroy(): void {
    if (this.accountSub !== undefined) {
      this.accountSub.unsubscribe();
    }
  }

  onTransponderRequest(res: boolean) {
    if (res) {
      this.accountService.resetCachedRequestedVehicleInfo();
      this.accountService
        .getRequestedVehicleInfo(this.account.AccountID)
        .subscribe(
          (resp) => {
            this.pendingTransponders = resp;
          },
          (error) => {
            console.log('error in GetTransponderVehicleInfo: ' + error);
          }
        );
    }
  }
}
