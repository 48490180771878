import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { AuthServiceProvider } from './auth.service';
import { ConfigurationService } from './configuration.service';
import { AlertMobileService } from './alertmobile.service';
import { Router } from '@angular/router';
import { MobileVersionService } from './mobile.version.service';

declare var jQuery: any;

@Injectable({
  providedIn: 'root',
})
export class ErrorService implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    public translateService: TranslateService,
    private authService: AuthServiceProvider,
    private config: ConfigurationService,
    private alertMobileService: AlertMobileService,
    private injector: Injector,
    private router: Router,
    private mobileVersionService: MobileVersionService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {

            if (event.status === 210){
              if (this.config.config.Type === 'mobile') {
                  this.alertMobileService.showMaintenance('MAINTENANCE_BODY', 'MAINTENANCE_TITLE');
                  throw new Error('Maintenance process');
                }
              else {
                this.router.navigateByUrl('/maintenance');
                throw new Error('Maintenance process');
              }
            }

            this.mobileVersionService.checkVersion(event)
          }
        },
        (errorResp) => {
          if (errorResp instanceof HttpErrorResponse) {
            let errorCode: string;
            var xmsg = errorResp.headers.get('x-customerrortitle');
            if (
              errorResp.error?.Message?.includes('traffic information update failed')
            ) {
              return;
            }
            if (
              errorResp.status === 400 &&
              errorResp.error?.error?.includes('otp_required')
            ) {
              return;
            }
            if (
              errorResp.status === 400 &&
              errorResp.error?.error?.includes('user_not_found')
            ) {
              errorCode = 'API_ERR_WEB_USER_NOT_FOUND';
            }
            if (
              errorResp.status === 400 &&
              errorResp.error?.error?.includes('invalid_otp')
            ) {
              return;
            } else if (errorResp.status === 401) {
              return;
            } else if (
              errorResp.status === 504 &&
              !errorResp.url.includes('LogMessage')
            ) {
              errorCode = 'ERR_MESSAGE_504';
            } else if (errorResp.status === 502) {
              errorCode = 'ERR_MESSAGE_504';
            } else if (
              errorResp.status === 403 &&
              errorResp.error === 'ERR_MESSAGE_403_IP'
            ) {
              errorCode = errorResp.error;
            } else if (
              errorResp.status === 406 &&
              errorResp.error?.Message?.includes('not valid')
            ) {
              errorCode = 'ERR_MESSAGE_406';
            } else if (
              (errorResp.status === 400 && xmsg === 'token_expired') ||
              xmsg === 'token_invalid'
            ) {
              errorCode = 'LOGIN_ERROR_SESSION_ENDED';
            } else {
              let msg: string;

              if (errorResp.error instanceof Blob) {
                const reader = new FileReader();
                reader.addEventListener('loadend', (e) => {
                  const r = e.srcElement['result'] as string;
                  const js = JSON.parse(r);
                  msg = js.Message;
                  const errCode = this.getErrorCode(msg);
                  this.alertService.errorTranslate(errCode);
                });
                reader.readAsText(errorResp.error);
              } else {
                msg = errorResp.error.error
                  ? errorResp.error.error
                  : errorResp.error.Message;

                this.authService = this.injector.get(AuthServiceProvider);
                if (
                  this.authService.loggedIn() &&
                  msg === 'API_ERR_INVALID_WEB_USER_STATUS'
                ) {
                  jQuery('#alertLogoutModal').modal({ show: true });
                  return;
                }
                errorCode = this.getErrorCode(msg);
              }
            }
            if (this.config.config.Type === 'mobile') {
              if (errorCode != 'UNEXPECTED_ERROR_USER_MESSAGE'){
                this.alertMobileService.errorTranslate(errorCode);
              }
            } else {
              this.alertService.errorTranslate(errorCode);
            }
          } else {
            console.log('NOT a HttpErrorResponse error');
          }
        }
      )
    );
  }
  getErrorCode(msg: string) {
    let errorCode = 'UNEXPECTED_ERROR_USER_MESSAGE';

    if (msg) {
      if (msg?.startsWith('API_ERR')) {
        errorCode = msg;
      } else if (msg === 'account_locked') {
        errorCode = 'LOGIN_ERROR_ACCOUNT_LOCKED';
      } else if (msg === 'account_locked_permanently') {
        errorCode = 'LOGIN_ERROR_ACCOUNT_LOCKED_PERMANENTLY';
      } else if (msg === 'account_temp_suspended') {
        errorCode = 'LOGIN_ERROR_ACCOUNT_SUSPENDED';
      } else if (msg === 'Closed') {
        errorCode = 'LOGIN_ERROR_ACCOUNT_CLOSED';
      } else if (msg === 'Expired') {
        errorCode = 'LOGIN_ERROR_ACCOUNT_EXPIRED';
      } else if (msg === 'invalid_grant') {
        errorCode = 'LOGIN_ERROR_WRONG_PASSWORD';
      } else if (msg === 'invalid_grant_400') {
        errorCode = 'LOGIN_ERROR_SESSION_ENDED';
      } else if (msg === 'token_expired') {
        errorCode = 'LOGIN_ERROR_TOKEN_EXPIRED';
      }else if (msg === 'user_not_found') {
        errorCode = 'API_ERR_WEB_USER_NOT_FOUND';
      }
    }
    return errorCode;
  }
}
