<div class="container pt-3">
  <div class="row">
    <div class="col-12 px-4">
      <div class="card mb-3">
        <div class="card-header">
          <h1 class="p-0 m-0 d-inline h3">
            <b>International Bridge Administration Customer Agreement</b>
          </h1>

          <button class="float-end d-inline btn btn-app-primary" color="primary" role="button" type="button"
            printSectionId="print-section" ngxPrint>
            Print
          </button>
        </div>
        <div class="card-body px-4" id="print-section">
          <div class="text-center">
            <b>International Bridge Administration Customer Agreement</b>
            <br />
          </div>
          <br />
          THESE terms and conditions, along with your application, constitute
          your International Bridge Administration CUSTOMER AGREEMENT (the "Agreement"), made and
          entered into by and between you (the "User") and the Atlantic Highways
          Management Corporation Limited through its International Bridge Administration Customer
          Service Center ("AHMCL").<br />
          <br />
          Failure to abide by the provisions contained herein may result in the
          cancellation of User's privileges to use the AHMCL electronic toll
          collection system known as the E-PASS. Nothing in this agreement
          releases the User from fines resulting from failure to abide by
          applicable Provincial or local laws or AHMCL's constating documents or
          regulations.<br />
          <br />
          <ol class="Bullet">
            <li>
              <strong>AGREEMENT</strong>
              <br />
              Subject to the terms of this Agreement, AHMCL, agrees to provide
              the E-Pass standard tags (The "Tag") to the User
              free of charge, which may be used to obtain passage on toll
              facilities operated by AHMCL.
              <br />
              <br />
            </li>
            <li>
              <strong>USE OF THE TRANSPONDER</strong><br />
              User agrees:<br />
              a) To mount, display, and use the Tag in accordance with
              Tag mounting instructions received from AHMCL.
              <br />
              b) Not to proceed through any traffic lane if the signal light is
              red. If the signal light remains red, User must pay the full cash
              fare in order to proceed.
              <br />
              c) Not to exceed posted speed limits through traffic lanes
              designated for E-Pass Use.
              <br />
              d) Not to assign or in any way transfer this Agreement and the
              Tag.
              <br />
              e) To be fully responsible for any and all charges arising from
              the use of each Tag until such time as User surrenders it
              to AHMCL.
              <br />
              f) To use the Tag only on a vehicle for which it is valid.
              Use of the Tag on a vehicle of a different toll
              classification will result in an incorrect toll charge, and may
              result in termination of this Agreement by AHMCL.
            </li>
            <li>
              <strong>DEFAULT</strong><br />
              The following events shall constitute and event of default: a)
              User fails to make any payments owed under this Agreement when
              due; b) User fails to perform or observe any terms or conditions
              under this Agreement; c) User has provided incorrect information
              to AHMCL under this Agreement; Application Form, or in any
              document or certificate furnished to AHMCL or does not report any
              changes of such information to AHMCL within the specified time; or
              d) User becomes insolvent or bankrupt. AHMCL can terminate this
              Agreement immediately without notice upon the occurrence of one or
              more of the foregoing events of default.
            </li>
            <li>
              <strong>PREPAID ACCOUNT</strong> User agrees to maintain a prepaid
              account with AHMCL to cover User's applicable tolls, charges and
              fees as described in this Agreement (the "Prepaid Account"). No
              interest shall be paid on Prepaid Account balances or deposits.
            </li>
            <li>
              <strong>OPENING A PREPAID ACCOUNT</strong><br />
              a) User must open a Prepaid Account by payment to AHMCL by credit
              card or cash (the "Initial Account Deposit").
              <br />
              b) Commercial Users may prepay using a company check, credit card
              or direct bank.
            </li>
            <li>
              <strong>LOW ACCOUNT LEVEL</strong> A Prepaid Account is considered
              by AHMCL to be at a low account level whenever its balance falls
              to less than 1/3 of Users Initial Account Deposit ("Low Account
              Level"). Users will be notified of a Low Account level by a LOW
              BAL message in the toll lane.
            </li>
            <li>
              <strong>ZERO BALANCE PREPAID ACCOUNT</strong><br />
              If the Prepaid Account reaches or falls below a Zero Balance, User
              will be required to PAY FULL CASH FARE without the discounted
              price of the E-PASS, unless the User's account is replenished
              above a Zero Balance prior to using the International Bridge Administration. Prepaid
              Accounts that fall below Zero Balance may result in User's Account
              being Suspended.
            </li>
            <li>
              <strong>ACCOUNT REPLENISHMENT</strong><br />
              a) Automatic Credit Card Replenishment. Whenever the prepaid
              balance falls below the Low Account Level, User's account will be
              automatically replenished to the amount of the Initial Account
              Deposit by charging the User's credit card.
              <br />
              b) Manual Replenishment. Whenever the prepaid balance falls bellow
              the Low Account Level, User must promptly visit the Customer
              Service Center and replenish the Prepaid Account directly using
              cash, debit card or credit card.
              <br />
              c) Commercial Users may replenish by company check.
              <br />
              d) Telephone, online and ABM banking are now available to our
              E-Pass Customers at the following financial institutions: Bank of
              Nova Scotia (BNS), CIBC, RBC Financial Group, TD Canada Trust,
              Bank of Montreal, Tangerine, Simplii and Credit Union. If you
              misplaced your account number please contact the Customer Service
              Center at or 902- 668-2211, to obtain your account number to
              access this banking service.
            </li>
            <li>
              <strong>FORFEITURE OF PREPAID BALANCE</strong><br />
              If there is no activity recorded on User's Prepaid Account for a
              period of 24 consecutive months, the Prepaid Account shall be
              terminated and the balance of User's account shall be considered
              as payment of an account maintenance charge and deducted from the
              account. Written notice of this proposed forfeiture will be given
              to User at their last known email address following the expiration
              of 18 consecutive month of no activity being recorded on User's
              Prepaid Account.
            </li>
            <li>
              <strong>TOLLS, CHARGES AND FEES</strong><br />
              User's Prepaid Account will be reduced by charges for applicable
              tolls charged each time User's Tag is used to obtain
              passage on, continue upon, or exit from an E-PASS collection area.
            </li>
            <li>
              <strong>ADDITIONAL CHARGES</strong><br />
              User requesting a printed statement of account or trip record
              shall be charged $0.50 per page with a minimum charge of $3.00 per
              request. All such charges shall be deducted from the User's
              Prepaid Account.<br />
              Users prepaid account will be charged $30.00 for an NSF cheque.
            </li>
            <li>
              <strong>LOST OR STOLEN TRANSPONDERS</strong><br />
              User must notify AHMCL of any lost or stolen Tag(s),
              identifying such transponder(s) by serial number. Until AHMCL is
              notified, USER WILL CONTINUE TO BE RESPONSIBLE FOR CHARGES
              RESULTING FROM USAGE. Once notified, AHMCL will invalidate the
              Tag(s). AHMCL will issue User a replacement Tag(s)
              upon request.
            </li>
            <li>
              <strong>TERMINATION</strong><br />
              a) AHMCL and/or User may terminate this Agreement at any time.
              <br />
              b) Upon termination, a refund of any balance remaining in User's
              Prepaid Account less any amount owed under this Agreement will be
              processed within 30 days from receipt of written notice, subject
              to the provisions of Section 9 herein.
            </li>
            <li>
              <strong>DEFECTIVE E-PASS</strong><br />
              If a Tag is defective for any reason, other than as a
              result of abuse or improper use, and is returned to AHMCL, it will
              be replaced at no charge.
            </li>
            <li>
              <strong>PRE-AUTHORIZED PAYMENTS</strong><br />
              It is expressly understood and agreed that User authorizes AHMCL
              to access User's credit card account and make charges authorized
              under this Agreement. User expressly understands and agrees that
              AHMCL shall not be liable to User for any damages resulting from
              these actions.
            </li>
            <li>
              <strong>INDEMNIFICATION AND LIABILITY</strong><br />
              a) User releases AHMCL, its directors, officers, employees, agents
              or representatives (collectively in this Section, "AHMCL") from
              all loss, damage, or injury whatsoever from the use or performance
              of E-PASS and the Tag. AHMCL shall not have any obligation
              or liability to User with respect to the use or the performance of
              E-PASS and the Tag. The only recourse available to User
              shall be replacement by AHMCL of any defective Tag. User
              agrees to indemnify, protect, and hold harmless AHMCL from all
              liability for all loss, damage, or injury to persons or property
              arising from or related to the use of E-PASS and the Tag.
              <br />
              b) Additionally, AHMCL shall not be liable for (a) any incidental,
              indirect, special or consequential damages, including but not
              limited to, loss of use, revenues, profits, or savings, even if
              AHMCL knew or should have known of the possibility of such
              damages, or, (b) claims, demands, or actions against User by any
              person, corporation, or other legal entity resulting from the use
              of E-PASS, Tag(s), credit report inquiry, check
              authorization and/or charging of User's credit card.
            </li>
            <li>
              <strong>ALTERATION OF TERMS</strong><br />
              AHMCL may change the terms of this Agreement at any time by
              providing written notice to User. If the Tag is used after
              User receives notice of the new terms of this Agreement, then User
              shall be bound by the new terms.
            </li>
            <li>
              <strong>DEEMED NOTICE</strong><br />
              For purposes of this Agreement, User shall be deemed to have
              received notice 10 days after any notice is deposited with Canada
              Post postage prepaid, addressed to User at the last address that
              appears on AHMCL's records.
            </li>
            <li>
              <strong>PAYMENT OF COST OF ENFORCEMENT</strong><br />
              User agrees to pay all costs, including legal fees, incurred by
              AHMCL in enforcing this Agreement.
            </li>
            <li>
              <strong>INSUFFICIENT FUNDS</strong><br />
              If for any reason, User's Prepaid Account is insufficient to pay
              any amounts owed to AHMCL, User will remain liable to AHMCL for
              such amounts.&nbsp;
            </li>

          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
