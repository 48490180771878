import { AccountProfileConfig } from '@ibitoll/toll-core';
import { cloneDeep } from 'lodash-es';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { iif, Observable, of, Subject, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  retryWhen,
  switchMap,
} from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Account } from '../../models/account';
import { CreditCard } from '../../models/creditCard';
import { TransactionResponse } from '../../models/transactionResponse';
import { AccountService } from '../../providers/account.service';
import { AlertService } from '../../providers/alert.service';
import { AuthServiceProvider } from '../../providers/auth.service';
import { ConfigurationService } from '../../providers/configuration.service';
import { EvalonPaymentService } from '../../providers/evalonpayment.service';
import { LogService } from '../../providers/log.service';
import { UserService } from '../../providers/user.service';
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { clone } from 'lodash';
import { ComponentMode } from '../../enum/component-mode.enum';
import { CardQueryWithExpiry } from '../../models/cardQuery';
import { EvalonReceiptComponent } from '../evalon-receipt/evalon-receipt.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var ConvergeEmbeddedPayment: any;

@Component({
  selector: 'evalon-one-time',
  templateUrl: './evalon-one-time.component.html',
  styleUrls: ['./evalon-one-time.component.scss'],
})
export class EvalonOneTimeComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('ccCard', { static: false }) creditCard: CreditCardComponent;

  @ViewChild('errorModal') errorModal: ElementRef;

  @Input() cardMode: boolean = true;

  @Input() componentMode: ComponentMode = ComponentMode.Self;

  public mode = ComponentMode.Emit

  processing: boolean = false;
  requestProcessed: boolean;
  enableCCSubmit: boolean = false;
  account: Account;
  creditCardObj: CreditCard;
  callback: any;
  subject: Subject<any> = new Subject<any>();

  editMode = false;
  hideButtons: boolean = false;
  hideAmt: boolean = false;

  invoiceNumber: string;
  sequenceNumber: string;
  cardExist: boolean = false;

  private subs = new SubSink();

  errorObj: { title: string; message: string };
  newUserLogonId: any;


  paymentForm: FormGroup;
  componentId: number;
  accountProfile: AccountProfileConfig;
  creditCardLoaded = false;

  constructor(
    private evalonPaymentService: EvalonPaymentService,
    private accountService: AccountService,
    public configService: ConfigurationService,
    public logService: LogService,
    public userService: UserService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public matDialog: MatDialog,
    private alertService: AlertService,
    public authService: AuthServiceProvider,
    private cdr: ChangeDetectorRef
  ) {
    this.componentId = Math.random()

  }

  ngOnInit(): void {

    this.paymentForm = this.formBuilder.group({
      amount: ['', Validators.required]
    });



    this.callback = {};
    this.callback.onError = this.PaymentCallbackError.bind(this);
    this.callback.onDeclined = this.PaymentCallbackDeclined.bind(this);
    this.callback.onApproval = this.PaymentCallbackApproval.bind(this);


  }

  ngAfterViewInit(): void {

    if (this.authService.loggedIn()) {
      this.accountService.getActiveAccount().pipe(switchMap((account: Account) => {
        this.account = cloneDeep(account);
        this.cardExist = account.BankCardTokenId != null;
        return this.accountService.getCreditCard(this.account.AccountID)
      }), switchMap((cc: CreditCard) => {
        this.creditCardObj = cc;
        return this.configService.getAccountProfiles();
      })).subscribe((accountProfiles: AccountProfileConfig[]) => {


        this.accountProfile = accountProfiles?.find(o => o.AccountProfileID == this.account.AccountProfileID);
        this.paymentForm.controls['amount'].setValidators(Validators.min(this.accountProfile.BalanceReplenishAmount * -1));
        if (this.creditCardLoaded) {
          this.creditCard.enableMinAmount(this.accountProfile?.BalanceReplenishAmount * -1)
        }

      });
    }

    if (this.componentMode == ComponentMode.Emit) {
      this.editMode = true;
      this.hideButtons = true;
      this.hideAmt = true;
    }

    this.cdr.detectChanges();

  }


  onCreditCardLoaded(status: boolean) {
    this.creditCardLoaded = true;
    if (this.accountProfile) {
      this.creditCard.enableMinAmount(this.accountProfile?.BalanceReplenishAmount * -1)
    }
  }

  creditCardStatus(creditCard: CreditCardComponent) {
    this.creditCard = creditCard;

    this.subs.add(
      this.creditCard.creditCardFormGroup.valueChanges.subscribe(() => {
        this.enableCCSubmit = this.creditCard.creditCardFormGroup.valid;
      })
    );

  }

  processSavedPayment() {
    let paymentToken = '';
    this.processing = true;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          if (data && this.authService.loggedIn()) {
            return this.accountService.getInvoiceNumber(this.account.AccountID);
          }

          if (data && !this.authService.loggedIn()) {
            return this.configService.getInitialInvoiceNumber();
          }

          if (!data) {
            throwError;
          }
        }),
        switchMap((resp) => {
          this.invoiceNumber = resp.InvoiceNumber;
          this.sequenceNumber = resp.SequenceNumber;
          return this.evalonPaymentService.getPaymentToken(this.paymentForm.controls['amount'].value, 2, this.account.CurrencyType);
        })
      )
      .subscribe(
        (resp) => {
          paymentToken = resp;
          var paymentData = {
            ssl_first_name: this.account?.FirstName,
            ssl_last_name: this.account?.LastName,
            ssl_txn_auth_token: paymentToken,
            ssl_token: this.account.BankCardTokenId,
            ssl_merchant_txn_id: this.sequenceNumber,
            ssl_partial_auth_indicator: 0,
            ssl_verify: 'Y',
            ssl_merchant_initiated_unscheduled: 'Y',
            ssl_email: this.account.Email,
            custom_reference_number: this.invoiceNumber,
            custom_userloginid: this.account.UserLogonId,
            ssl_avs_address: this.account?.SameBillingAsMailingAddress
              ? this.account?.MailingAddress
              : this.account.BillingAddress,
            ssl_avs_zip: this.account?.SameBillingAsMailingAddress
              ? this.account?.MailingPostalCode?.replace('-', '')
              : this.account.BillingPostalCode?.replace('-', ''),
          };

          // if (this.configService.config.Name === 'iba') {
          //   paymentData['ssl_transaction_currency'] =
          //     this.account.CurrencyType === 'D' ? 'USD' : 'CAD';
          // }

          ConvergeEmbeddedPayment.pay(paymentData, this.callback);
        },
        (error) => {
          this.processing = false;
          this.logService.logMessage(
            'payment process failed at getInvoiceNumber' + JSON.stringify(error)
          );
          // this.alertService.error(
          //   'Unable to process your request at this time please try again later!',
          //   true
          // );
        }
      );
  }

  processPayment() {
    let paymentToken = '';
    this.processing = true;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          if (data && this.authService.loggedIn()) {
            return this.accountService.getInvoiceNumber(this.account.AccountID);
          }

          if (data && !this.authService.loggedIn()) {
            return this.configService.getInitialInvoiceNumber();
          }

          if (!data) {
            throwError;
          }
        }),
        switchMap((resp) => {
          this.invoiceNumber = resp.InvoiceNumber;
          this.sequenceNumber = resp.SequenceNumber;
          return this.evalonPaymentService.getPaymentToken(
            this.creditCard.creditCardFormGroup.controls['amount'].value,
            2,
            this.account.CurrencyType
          );
        })
      )
      .subscribe(
        (resp) => {
          paymentToken = resp;
          let year2d =
            this.creditCard.creditCardFormGroup.controls['cardyear'].value;
          if (year2d > 2000) {
            year2d = year2d - 2000;
          }

          var paymentData = {
            ssl_first_name: this.account.AccountType === 'P' ? this.account.FirstName : null,
            ssl_last_name: this.account.LastName,
            ssl_txn_auth_token: paymentToken,
            ssl_card_number:
              this.creditCard.creditCardFormGroup.controls['cardNumber'].value,
            ssl_exp_date:
              this.creditCard.creditCardFormGroup.controls['cardmonth'].value
                .toString()
                .padStart(2, '0') + year2d.toString().padStart(2, '0'),
            ssl_cvv2cvc2:
              this.creditCard.creditCardFormGroup.controls['cardcvv'].value,
            ssl_merchant_txn_id: this.sequenceNumber,
            ssl_partial_auth_indicator: 0,
            ssl_verify: 'Y',
            ssl_merchant_initiated_unscheduled: 'Y',
            ssl_email: this.account.Email,
            custom_reference_number: this.invoiceNumber,
            custom_userloginid: this.account.UserLogonId,
            ssl_avs_address: this.account?.SameBillingAsMailingAddress
              ? this.account?.MailingAddress
              : this.account.BillingAddress,
            ssl_avs_zip: this.account?.SameBillingAsMailingAddress
              ? this.account?.MailingPostalCode?.replace('-', '')
              : this.account.BillingPostalCode?.replace('-', ''),
          };

          // if (this.configService.config.Name === 'iba') {
          //   paymentData['ssl_transaction_currency'] =
          //     this.account.CurrencyType === 'D' ? 'USD' : 'CAD';
          // }

          ConvergeEmbeddedPayment.pay(paymentData, this.callback);
        },
        (error) => {
          this.processing = false;
          this.logService.logMessage(
            'payment process failed at getInvoiceNumber' + JSON.stringify(error)
          );
          // this.alertService.error(
          //   'Unable to process your request at this time please try again later!',
          //   true
          // );
        }
      );
  }

  processLocalPayment(account: Account) {
    this.subject = new Subject<any>();
    let paymentToken = '';
    this.processing = true;
    this.account = account;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          if (!data) {
            throwError;
          }
          return this.configService.getInitialInvoiceNumber();
        }),
        switchMap((resp) => {
          this.invoiceNumber = resp.InvoiceNumber;
          this.sequenceNumber = resp.SequenceNumber;
          return this.evalonPaymentService.getPaymentToken(
            account.TotalInitialAmount,
            2,
            account.CurrencyType
          );
        })
      )
      .subscribe(
        (resp) => {
          paymentToken = resp;
          let year2d =
            this.creditCard.creditCardFormGroup.controls['cardyear'].value;
          if (year2d > 2000) {
            year2d = year2d - 2000;
          }

          var paymentDataDual = {
            ssl_first_name: account.AccountType === 'P' ? account.FirstName : null,
            ssl_last_name: account.LastName,
            ssl_txn_auth_token: paymentToken,
            ssl_card_number:
              this.creditCard.creditCardFormGroup.controls['cardNumber'].value,
            ssl_exp_date:
              this.creditCard.creditCardFormGroup.controls['cardmonth'].value
                .toString()
                .padStart(2, '0') + year2d.toString().padStart(2, '0'),
            ssl_cvv2cvc2:
              this.creditCard.creditCardFormGroup.controls['cardcvv'].value,
            ssl_merchant_txn_id: this.sequenceNumber,
            ssl_partial_auth_indicator: 0,
            ssl_verify: 'Y',
            ssl_merchant_initiated_unscheduled: 'Y',
            ssl_email: account.Email,
            custom_reference_number: this.invoiceNumber,
            custom_userloginid: account.UserLogonId,
            ssl_avs_address: account?.SameBillingAsMailingAddress
              ? account?.MailingAddress
              : account.BillingAddress,
            ssl_avs_zip: account?.SameBillingAsMailingAddress
              ? account?.MailingPostalCode?.replace('-', '')
              : account.BillingPostalCode?.replace('-', ''),
          };

          // if (this.configService.config.Name === 'iba') {
          //   paymentDataDual['ssl_transaction_currency'] =
          //     account.CurrencyType === 'D' ? 'USD' : 'CAD';
          // }

          if (account.PreAuthStatus == 1) {
            paymentDataDual['ssl_add_token'] = 'Y';
          }

          ConvergeEmbeddedPayment.pay(paymentDataDual, this.callback);

          // Evalon library JavaScript call
          // https://demo.convergepay.com/hosted-payments/Checkout.js
          // continue on callbacks: PaymentCallbackDeclined, PaymentCallbackApproval, PaymentCallbackError
        },
        (error) => {
          this.processing = false;
          this.logService.logMessage(
            'payment process failed at getInvoiceNumber' + JSON.stringify(error)
          );
          // this.alertService.error(
          //   'Unable to process your request at this time please try again later!',
          //   true
          // );
          this.subject.error(error);
        }
      );

    return this.subject.asObservable();
  }

  processAuthOnly(account: Account) {
    this.subject = new Subject<any>();
    let paymentToken = '';
    this.processing = true;
    this.account = account;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          if (!data) {
            throwError;
          }
          return this.configService.getInitialInvoiceNumber();
        }),
        switchMap((resp) => {
          this.invoiceNumber = resp.InvoiceNumber;
          this.sequenceNumber = resp.SequenceNumber;
          return this.evalonPaymentService.getPaymentToken(
            account.TotalInitialAmount,
            5,
            account.CurrencyType
          );
        })
      )
      .subscribe(
        (resp) => {
          paymentToken = resp;
          let year2d =
            this.creditCard.creditCardFormGroup.controls['cardyear'].value;
          if (year2d > 2000) {
            year2d = year2d - 2000;
          }

          var paymentDataDual = {
            ssl_first_name: account.AccountType === 'P' ? account.FirstName : null,
            ssl_last_name: account.LastName,
            ssl_txn_auth_token: paymentToken,
            ssl_card_number:
              this.creditCard.creditCardFormGroup.controls['cardNumber'].value,
            ssl_exp_date:
              this.creditCard.creditCardFormGroup.controls['cardmonth'].value
                .toString()
                .padStart(2, '0') + year2d.toString().padStart(2, '0'),
            ssl_cvv2cvc2:
              this.creditCard.creditCardFormGroup.controls['cardcvv'].value,
            ssl_merchant_txn_id: this.sequenceNumber,
            ssl_partial_auth_indicator: 0,
            ssl_verify: 'Y',
            ssl_merchant_initiated_unscheduled: 'Y',
            ssl_email: account.Email,
            custom_reference_number: this.invoiceNumber,
            custom_userloginid: account.UserLogonId,
            ssl_avs_address: account?.SameBillingAsMailingAddress
              ? account?.MailingAddress
              : account.BillingAddress,
            ssl_avs_zip: account?.SameBillingAsMailingAddress
              ? account?.MailingPostalCode?.replace('-', '')
              : account.BillingPostalCode?.replace('-', ''),
          };

          // if (this.configService.config.Name === 'iba') {
          //   paymentDataDual['ssl_transaction_currency'] =
          //     account.CurrencyType === 'D' ? 'USD' : 'CAD';
          // }

          if (account.PreAuthStatus == 1) {
            paymentDataDual['ssl_add_token'] = 'Y';
          }

          ConvergeEmbeddedPayment.pay(paymentDataDual, this.callback);

          // Evalon library JavaScript call
          // https://demo.convergepay.com/hosted-payments/Checkout.js
          // continue on callbacks: PaymentCallbackDeclined, PaymentCallbackApproval, PaymentCallbackError
        },
        (error) => {
          this.processing = false;
          this.logService.logMessage(
            'payment process failed at getInvoiceNumber' + JSON.stringify(error)
          );
          // this.alertService.error(
          //   'Unable to process your request at this time please try again later!',
          //   true
          // );
          this.subject.error(error);
        }
      );

    return this.subject.asObservable();
  }

  hideCCAmt() {
    this.creditCard.hideAmt();
  }

  PaymentCallbackError(error2: any): void {
    this.logService.logMessage(
      'payment process failed Error:' + JSON.stringify(error2)
    );
    this.processing = false;

    this.displayError(error2);
    this.subject.error(error2);
    this.subject.complete();
  }

  PaymentCallbackDeclined(response: any): void {

    this.logService.logMessage('payment process failed Declined:' + JSON.stringify(response));

    if (this.componentMode == ComponentMode.Emit) {
      this.displayError(response);
      this.subject.error(response);
    } else {
      if (response.ssl_card_number) {
        this.ContinuePaymentRoute(response);
      } else {
        this.displayError(response);
        this.subject.error(response);
      }
    }
  }

  PaymentCallbackApproval(response: any): void {
    this.logService.logMessage('payment process approved:' + JSON.stringify(response));
    if (this.componentMode == ComponentMode.Emit) {
      response.ssl_merchant_txn_id = this.sequenceNumber;
      response.ssl_transaction_currency = this.account.CurrencyType === 'D' ? 'USD' : 'CAD';
      this.subject.next(response);
    } else {
      response.ssl_email = this.account.Email;
      response.ssl_transaction_currency = this.account.CurrencyType === 'D' ? 'USD' : 'CAD';
      this.ContinuePaymentRoute(response);
    }
  }

  ContinuePaymentRoute(response: any) {
    const user = this.userService.getCurrentUser();
    response.ssl_card_number = response?.ssl_card_number.replace('*', '0');
    response.PaymentMethod = 'DC';
    response.ssl_transaction_currency = this.account.CurrencyType === 'D' ? 'USD' : 'CAD';
    response.ssl_merchant_txn_id = this.sequenceNumber;
    this.PostEvalonPayment(
      response,
      user.AccountId,
      Number(user.UserId),
      user.UserName
    );
  }

  public PostEvalonPayment(
    response: any,
    accountID: number,
    userID: number,
    userLogonId: string
  ): void {
    var res = clone(response);
    response.AccountID = accountID;
    response.UserID = userID;
    response.UserLogonId = userLogonId ? userLogonId : this.newUserLogonId;

    this.evalonPaymentService
      .PostEvalonPayment_backend(response)
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            concatMap((e, i) =>
              iif(() => i > 1, throwError(e), of(e).pipe(delay(1000)))
            )
          )
        )
      )
      .subscribe(
        (resp: TransactionResponse) => {
          this.accountService
            .loadActiveAccount(this.account.AccountID)
            .subscribe(() => {
              console.log('Success Receipt Respose: ' + JSON.stringify(resp));
              this.processing = false;
              response.ssl_result_message = 'POSTED';
              if (resp.BankAuthorizationStatusDB !== 'D') {
                this.DisplayReceipt(resp);
              } else {
                this.displayError(res);
              }
            });
        },
        (error) => {
          this.logService.logMessage(
            `payment process failed at PostEvalonPaymentbackend ${JSON.stringify(
              response
            )} Error: ${JSON.stringify(error)}`
          );
          this.processing = false;
          // this.alertService.error(
          //   `Post payment failed. Please contact customer service for support.`,
          //   false
          // );
        }
      );
  }

  DisplayReceipt(txnResp: TransactionResponse): Observable<boolean> {
    var subject = new Subject<boolean>();
    if (txnResp.ResponseOrderID) {
      const softHyphen = '-'; // &shy; does not work here for some reasons
      let a = txnResp.ResponseOrderID;
      let count = Math.floor(a.length / 12);
      while (count > 0) {
        const position = count * 12;
        if (a.length > position) {
          a = [a.slice(0, position), softHyphen, a.slice(position)].join('');
        }
        count--;
      }
      txnResp.ResponseOrderID = a;
    }

    this.creditCard?.creditCardFormGroup.reset();
    this.paymentForm?.reset();

    const dialogRef = this.matDialog.open(EvalonReceiptComponent, {
      data: {
        transactionResponse: txnResp,
        currentDate: new Date(),
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      subject.next(true);
      subject.complete();
    });
    return subject.asObservable();
  }

  processPreauthTokenSigup(account: Account) {
    this.processing = true;
    this.alertService.clear();

    const query: CardQueryWithExpiry = new CardQueryWithExpiry();

    query.NameOnCard =
      this.creditCard.creditCardFormGroup.get('nameOnCard').value;
    query.CreditCardNumber =
      this.creditCard.creditCardFormGroup.get('cardNumber').value;
    query.ExpiryMonth = Number(
      this.creditCard.creditCardFormGroup.get('cardmonth').value
    );
    query.ExpiryYear = Number(
      this.creditCard.creditCardFormGroup.get('cardyear').value
    );
    query.Cvv = this.creditCard.creditCardFormGroup.get('cardcvv').value;

    query.FirstName = account.FirstName;
    query.LastName = account.LastName;
    query.Email = account.Email;

    return this.evalonPaymentService.getPreauthToken(query).pipe(
      catchError((error) => {
        this.processing = false;
        this.alertService.error(
          'Cannot aquire preauth token. Please make sure you provided valid credit card information.',
          false
        );
        console.log('error in processPreauthToken: ' + error);
        return of('error', error);
      })
    );
  }

  displayError(msg: any) {
    if (msg) {
      this.requestProcessed = false; // hide form and show error
      this.errorObj = {
        title: '',
        message: '',
      };

      this.errorObj.title = 'Payment Incomplete!';
      this.errorObj.message = `The payment information provided in the authorization request appears to be invalid.
      Please check account and transit/routing number details and retry.`;

      if (msg.errorMessage) {
        this.errorObj.title = msg.errorName;
        this.errorObj.message = msg.errorMessage;
      }

      if (msg.ErrorCode || msg.errorMessage || msg.ssl_result_message) {
        this.errorObj.title = 'Payment Incomplete!';

        switch (msg.ErrorCode || msg.errorCode) {
          case '5106' || '5105':
            this.errorObj.message = `The bank information provided in the authorization request appears to be invalid.
             Please check account and transit/routing number details and retry.`;
            break;

          case '5001':
            this.errorObj.message =
              'The credit card expiration date entered is invalid';
            break;

          case '5000':
            this.errorObj.message = 'The credit card number entered is invalid';
            break;

          case '5021':
            this.errorObj.message =
              'The CVV2 number should be 3 or 4 digits in length';
            break;

          case '5021':
            this.errorObj.message =
              'The CVV2 number should be 3 or 4 digits in length';
            break;

          case '5087':
            this.errorObj.message =
              'Transaction currency is not allowed for this terminal.  Your terminal must be setup with Multi currency';
            break;

          case 'DECLINED CVV2':
            this.errorObj.message =
              'Invalid CVV';
            break;

          default:
            this.errorObj.message = `Payment incomplete. The payment information provided in the authorization
             request appears to be invalid.
            Please check payment details and try again.`;
            break;
        }
      }

      if (msg.ssl_result_message === 'DECLINED') {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = `The payment declined. Please check payment details and try again.`;
      }

      if (msg.ssl_result_message === 'DECLINED: NSF') {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = `The payment declined. Please check payment details and try again.`;
      }

      if (msg.ssl_result_message === 'SERV NOT ALLOWED') {
        this.errorObj.title = 'Invalid Payment';
        this.errorObj.message = `The payment information provided appears to be invalid.
       Please check payment details and try again.`;
      }

      setTimeout(() => {
        this.modalService.open(this.errorModal, { size: 'lg' });
      });
    }

    this.processing = false;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
