import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Transponder } from '@ibitoll/toll-core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mdot-supend-alert-modal',
  templateUrl: './supend-alert-modal.component.html',
  styleUrls: ['./supend-alert-modal.component.scss']
})
export class SupendAlertModalComponent implements OnInit {

  @Input() transponder: Transponder;

  @Output() onTransponderSuspended: EventEmitter<Transponder> = new EventEmitter();

  isRequesting: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  confirmSuspension() {
    this.isRequesting = true;
    this.onTransponderSuspended.emit(this.transponder);
  }

}
