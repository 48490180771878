<div class="card mt-4 mb-2 shadow"
     [ngClass]="{ 'mx-3': isSubComponent }">
  <div class="card-header"
       *ngIf="
      account && !isEmployee && configurationService.config.RequestTransponder
    ">
    <div class="row">
      <div class="col-4 align-middle"
           *ngIf="!isLoading">
        <h1 class="mb-0 mt-1 align-middle h3 title-text">
          {{ 'TRANSPONDERS_SMARTCARDS' | translate }}
        </h1>
      </div>
      <div class="col-8"
           *ngIf="!isLoading">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button class="btn btn-app-secondary float-end"
                  (click)="downloadTags()"
                  InActiveAccount
                  *ngIf="!isSubComponent && transponders.length > 0"
                  role="button"
                  [attr.aria-label]="'DOWNLOAD_TRANSPONDER_LIST' | translate"><!-- DOWNLOAD_CSV -->
            <i class="fa fa-cloud-download pe-1"></i>{{ 'DOWNLOAD_TRANSPONDER_LIST' | translate }}
            <i class="fa fa-spinner fa-pulse"
               *ngIf="isDocLoading"></i>
          </button>
          <button class="btn btn-app-primary"
                  (click)="openModalForActivate()"
                  InActiveAccount
                  *ngIf="!isSubComponent && transponders.length > 0"
                  role="button"
                  [ngClass]="{ disabled: !hasInactiveTags }"
                  btnDisabled
                  [attr.aria-label]="'ACTIVATE_ALL_TRANSPONDERS' | translate"><!-- Activate All -->
            <i class="fa fa-key pe-1"></i>{{ 'ACTIVATE_ALL_TRANSPONDERS' | translate }}
            <i class="fa fa-spinner fa-pulse"
               *ngIf="isDocLoading"></i>
          </button>
          <button class="btn btn-app-primary"
                  (click)="openModal()"
                  InActiveAccount
                  id="requestTransponder"
                  role="button"
                  [attr.aria-label]="'REQUEST_TRANSPONDERS_SMARTCARDS' | translate">
            <i class="fa fa-plus-square"></i>
            {{ 'REQUEST_TRANSPONDERS_SMARTCARDS' | translate }}
            <i class="fa fa-spinner fa-pulse"
               *ngIf="loadingTag"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body pt-0 row justify-content-center">
    <div class="text-center d-flex justify-content-center align-self-center mt-4"
         *ngIf="isLoading">
      <i class="fa fa-4x fa-spinner fa-pulse"></i>
    </div>
    <!-- web view (wide) -->
    <div class="
        table-responsive
        conditionalScroll
        w-100
        d-none d-md-block d-lg-block d-xl-block px-0
      "
         id="scroll-bar"
         *ngIf="!isLoading">
      <table aria-label="Tag List Table"
             role="table"
             class="table  table-hover tag-table ">
        <caption class="tag-table-caption sr-only sr-only-focusable">Tag List Table</caption>
        <thead>
          <tr class="tr-header">
            <th scope="col">
              {{ 'TRANSPONDERS_SMARTCARD' | translate }}
            </th>
            <th scope="col">
              {{ 'VEHICLE_MAKE' | translate }}
            </th>
            <th scope="col">
              {{ 'VEHICLE_MODEL' | translate }}
            </th>
            <th scope="col">
              {{ 'COLOR' | translate }}
            </th>
            <th scope="col">
              {{ 'VEHICLE_PLATE' | translate }}
            </th>
            <th scope="col">
              {{ 'VEHICLE_CATEGORY' | translate }}
            </th>
            <th scope="col">
              {{ 'STATUS' | translate }}
            </th>
            <th *ngIf="isEmployee"
                scope="col">
              {{ 'TRIP_COUNT' | translate }}
            </th>
            <th scope="col">
              {{ 'ACTION' | translate }}
            </th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <!-- Registered (existing) transponders -->
          <tr *ngFor="
              let transponder of transponders
                | sortBy: 'asc':'TransponderDisplayId'
            ">
            <td>
              {{
              transponder.TransponderDisplayId
              ? transponder.TransponderDisplayId
              : '-'
              }}
            </td>
            <td>
              {{
              transponder.VehicleMake ? transponder.VehicleMake : '-'
              }}
            </td>
            <td>
              {{
              transponder.VehicleModel ? transponder.VehicleModel : '-'
              }}
            </td>
            <td>
              {{
              transponder.VehicleColor ? transponder.VehicleColor : '-'
              }}
            </td>
            <!--plate -->
            <td>{{ checkPlate(transponder) }}</td>
            <td>
              {{ getCategoryName(transponder.TollCategoryId) }}
            </td>
            <td align="center"><!--Status-->
              <span>
                <i class="fa"
                   [ngClass]="
                    transponder?.RestrictionStatus !== 2
                      ? transponder?.RestrictionStatusUserId === 0
                          ?'fa-pause-circle hov-warning'
                          :'fa-ban hov-alert'
                      : 'fa-check-circle-o hov-ok'
                  "></i>
                {{ GetTranspondeRestrictionStatus(transponder) }}
                <!-- {{
                transponder.RestrictionStatus
                | mapType: 'eTransponderRestrictionMap'
                | translate
                }} -->
              </span>
            </td>
            <td *ngIf="isEmployee">
              {{ transponder.TripCount }}
            </td>
            <td align="center"
                InActiveAccount>
              <button *ngIf="transponder.RestrictionStatus === 2"
                      type="button"
                      class="btn btn-app-primary edit-btn"
                      id="editBtn-{{transponder.TransponderDisplayId}}"
                      (click)="openEditModal(transponder)">
                <i class="fa far fa-edit"></i>
                {{ 'EDIT' | translate }}
                <i class="fa fa-spinner fa-pulse"
                   *ngIf="selectedEditTransponder === transponder.TransponderDisplayId && editLoading"></i>
              </button>
              <div class="btn-group"
                   *ngIf="transponder.RestrictionStatus !== 2">
                <button cancelbtn
                        class="btn btn-warning rounded me-1"
                        (click)="unSupendTransponder(transponder)"
                        id="unSupendTransponder"
                        type="button"
                        role="button"
                        [ngClass]="{'disabled ': transponder?.RestrictionStatusUserId !== 0}"
                        aria-label="Unsuspend Tag">
                  <i class="fa far fa-check-circle"></i>
                  {{ 'ACTIVATE' | translate }}
                </button>
                <button cancelbtn
                        class=" btn btn-danger rounded ms-1"
                        (click)="removeTransponder(transponder)"
                        id="removeTransponder"
                        type="button"
                        role="button"
                        aria-label="Remove Tag">
                  <i class="fa far fa-times-circle"></i>
                  {{ 'REMOVE' | translate }}
                </button>
              </div>
            </td>
          </tr>
          <!-- Pending (requested) transponders -->
          <tr *ngFor="let transponder of pendingTransponders | sortBy: 'desc':'CreatedDateTime'">
            <td>
              {{transponder.TransponderSerialNo ? transponder.TransponderSerialNo : 'Unassigned'}}
            </td>
            <td>
              {{
              transponder.VehicleMake ? transponder.VehicleMake : '-'
              }}
            </td>
            <td>
              {{
              transponder.VehicleModel ? transponder.VehicleModel : '-'
              }}
            </td>
            <td>
              {{
              transponder.VehicleColor ? transponder.VehicleColor : '-'
              }}
            </td>
            <td>{{ checkPlate(transponder) }}</td>
            <td>{{ getCategoryName(transponder.TollCategoryId) }}</td><!--Vehicle Group-->
            <!--Status-->
            <td
                *ngIf="transponder.VehicleStatus === 'R' && (transponder.WebRequestStatus === 'R' || transponder.WebRequestStatus === 'V')">
              <i class="fa" [ngClass]="'fa-question-circle hov-warning'"></i>
              {{'TRANSPONDER_REQUESTED' | translate}}
              <span *ngIf="transponder.TransponderRequesrQty && transponder.TransponderRequesrQty > 1">
                 ({{transponder.TransponderRequesrQty}})
              </span>
            </td>

            <td *ngIf="transponder.VehicleStatus === 'R' && transponder.WebRequestStatus === 'J'">
              <span *ngIf="transponder.TransponderRequesrQty">
                {{transponder.TransponderRequesrQty}} -
              </span>
              <i class="fa" [ngClass]="'fa-minus-circle hov-alert'"></i>
              {{'REJECTED' | translate}}
            </td>
            <td *ngIf="isEmployee">{{ transponder.TripCount }}</td>
            <td InActiveAccount>-</td>
          </tr>
        </tbody>
      </table>
    </div><!-- /web view (wide) -->

    <!-- mobile view (narrow) -->
    <div class="d-md-none">
      <ul class="list-group list-group-flush mt-2">
        <li class="list-group-item mb-1"
            *ngFor="let transponder of transponders">
          <div class="row">
            <div class="col">
              <div class="h5">
                {{ transponder.TransponderDisplayId }}
              </div>
            </div>
            <div class="col">
              <span class="float-end">
                <i class="fa"
                   [ngClass]="
                    transponder.RestrictionStatus !== 2
                      ? 'fa-exclamation-circle hov-alert'
                      : 'fa-check-circle-o hov-ok'
                  "></i>
                {{
                transponder.RestrictionStatus
                | mapType: 'eTransponderRestrictionMap'
                | translate
                }}
              </span>
            </div>
          </div>
          <div>
            <!-- <button type="button"
                    class="btn btn-app-primary edit-btn"
                    *ngIf="transponder.RestrictionStatus === 2"
                    (click)="(transponder)"
                    InActiveAccount>
              <i class="fa far fa-pause-circle"></i>
              {{ 'SUSPEND' | translate }}
            </button> -->

            <button *ngIf="transponder.RestrictionStatus === 2"
              type="button"
              class="btn btn-warning btn-sm float-end"
              id="editBtn-{{transponder.TransponderDisplayId}}"
              (click)="openEditModal(transponder)"
              InActiveAccount>
                <i class="fa far fa-edit"></i>
                {{ 'EDIT' | translate }}
                <i class="fa fa-spinner fa-pulse"
                  *ngIf="selectedEditTransponder === transponder.TransponderDisplayId && editLoading"></i>
            </button>
            <div class="btn-group float-end"
                  *ngIf="transponder.RestrictionStatus !== 2">
              <button cancelbtn
                      class="btn btn-warning btn-sm me-1"
                      (click)="unSupendTransponder(transponder)"
                      id="unSupendTransponder"
                      type="button"
                      role="button"
                      [ngClass]="{'disabled ': transponder?.RestrictionStatusUserId !== 0}"
                      aria-label="Unsuspend Tag">
                <i class="fa far fa-check-circle"></i>
                {{ 'ACTIVATE' | translate }}
              </button>
              <button cancelbtn
                      class=" btn btn-danger btn-sm ms-1"
                      (click)="removeTransponder(transponder)"
                      id="removeTransponder"
                      type="button"
                      role="button"
                      aria-label="Remove Tag">
                <i class="fa far fa-times-circle"></i>
                {{ 'REMOVE' | translate }}
              </button>
            </div>

            <div>
              <b> {{ 'PLATE' | translate }}:</b>
              {{ checkPlate(transponder) }}
            </div>
            <div>
              <b> {{ 'CATEGORY' | translate }}:</b>
              {{ getCategoryName(transponder.TollCategoryId) }}
            </div>
          </div>
        </li>
        <li class="list-group-item mb-1"
            *ngFor="let transponder of pendingTransponders">
          <div class="row">
            <div class="col">
              <div>
                <b> Ordered</b>
              </div>
              <div>
                <b> {{ 'PLATE' | translate }}:</b>
                {{ checkPlate(transponder) }}
              </div>
              <div>
                <b> {{ 'CATEGORY' | translate }}:</b>
                {{ getCategoryName(transponder.TollCategoryId) }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- /mobile view (narrow) -->
  </div>
</div>
