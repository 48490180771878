import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalstorageService } from '@ibitoll/toll-core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'hhb-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss'],
})
export class SurveysComponent {
  @Output() confirmNoThanks: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private localstorageService: LocalstorageService,
    public modal: NgbActiveModal
  ) {}

  noThanks() {
    this.confirmNoThanks.emit(false);
    this.modal.dismiss();
  }

  giveFeedBack() {
    this.confirmNoThanks.emit(true);
    this.modal.dismiss();
  }
}
