<div
  class="modal fade"
  id="newTransponderModal"
  tabindex="-1"
  role="dialog"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3>{{ 'REQUEST_TRANSPONDERS_SMARTCARDS' | translate }}</h3>
      </div>
      <div class="modal-body">
        <transponder-info
          (onTransponderRequested)="onTransponderRequested($event)"
          (onTransponderRequestCancelled)="
            onTransponderRequestCancelled($event)
          "
        >
        </transponder-info>
      </div>
    </div>
  </div>
</div>
