import { AccountInfo } from './accountInfo';

export class User {
  UserId: string;
  UserName: string;
  UserLogonId: string;
  UserGroupName: string;
  UserSchema: string;
  userGroupId: string;
  EmailAddress: string;
  AccountId: number;
  LastLoginDateTime: Date;
  LoginTokenExpireDateTime: Date;
  Password: string;
  Accounts: AccountInfo[];
  QrTransponder: string;
}
