import { Pipe, PipeTransform } from '@angular/core';
import { TransactionRecord } from '../models/transactionRecord';
//import { cloneDeep } from 'lodash';

@Pipe({ name: 'transactionTypeFilter' })
export class TransactionTypeFilter implements PipeTransform {
  result: TransactionRecord[] = [];

  transform(
    transactionList: TransactionRecord[],
    payment: boolean,
    transaction: boolean,
    fees: boolean,
    adjustment: boolean,
    refund: boolean,
    curbside: boolean,
    parkside: boolean,
    roadside:boolean,
    transponder: string
  ): TransactionRecord[] {
    if (!transactionList) {
      return transactionList;
    }

    this.result = [];

    if (payment) {
      this.result = this.result.concat(this.getPayment(transactionList));
    }
    if (transaction) {
      this.result = this.result.concat(this.getTransaction(transactionList));
    }
    if (fees) {
      this.result = this.result.concat(this.getFees(transactionList));
    }
    if (adjustment) {
      this.result = this.result.concat(this.getAdjustment(transactionList));
    }
    if (refund) {
      this.result = this.result.concat(this.getRefund(transactionList));
    }
    //var tmpTransactionList = this.result;
    //this.result = [];
    if (curbside) {
      this.result = this.result.concat(this.getCurbside(transactionList));
    }
    if (parkside) {
      this.result = this.result.concat(this.getParkside(transactionList));
    }
    if (roadside) {
      this.result = this.result.concat(this.getRoadside(transactionList));
    }

    if (transponder !== '') {
      return this.result
        .filter((trans) => trans.TransponderText.indexOf(transponder) !== -1)
        .sort((x, y) =>
          new Date(x.TransactionNumber) > new Date(y.TransactionNumber)
            ? -1
            : 1
        );
    }

    return this.result.sort(
      (x, y) =>
        new Date(y.TransactionNumber).getTime() -
        new Date(x.TransactionNumber).getTime()
    );
  }

  getPayment(transactionList: TransactionRecord[]): TransactionRecord[] {
    return transactionList.filter(
      (transaction) =>
        //transaction.TransactionType.toLowerCase().indexOf('01') !== -1
        transaction.TransactionType === '01'
    );
  }
  getTransaction(transactionList: TransactionRecord[]): TransactionRecord[] {
    return transactionList.filter(
      (transaction) =>
        transaction.TransactionType.toLowerCase().indexOf('02') !== -1
    );
  }
  getFees(transactionList: TransactionRecord[]): TransactionRecord[] {
    return transactionList.filter(
      (transaction) =>
        transaction.TransactionType.toLowerCase().indexOf('03') !== -1 ||
        transaction.TransactionType.toLowerCase().indexOf('04') !== -1
    );
  }
  getAdjustment(transactionList: TransactionRecord[]): TransactionRecord[] {
    return transactionList.filter(
      (transaction) =>
        transaction.TransactionType.toLowerCase().indexOf('05') !== -1 ||
        transaction.TransactionType.toLowerCase().indexOf('08') !== -1 ||
        transaction.TransactionType.toLowerCase().indexOf('09') !== -1
    );
  }
  getRefund(transactionList: TransactionRecord[]): TransactionRecord[] {
    return transactionList.filter(
      (transaction) =>
        transaction.TransactionType.toLowerCase().indexOf('06') !== -1
    );
  }
  getCurbside(transactionList: TransactionRecord[]): TransactionRecord[] {
    transactionList = this.getTransaction(transactionList);
    return transactionList.filter(
      (transaction) =>
      transaction.PlazaSectionType == 3
    );
    // return transactionList.filter(
    //   (transaction) =>
    //     transaction.PlazaText.toLowerCase().indexOf('curbside') !== -1
    // );
  }
  getParkside(transactionList: TransactionRecord[]): TransactionRecord[] {
    transactionList = this.getTransaction(transactionList);
    return transactionList.filter(
      (transaction) =>
      transaction.PlazaSectionType == 2
    );
    // return transactionList.filter(
    //   (transaction) =>
    //     transaction.PlazaText.toLowerCase().indexOf('parkade') !== -1
    // );
  }
  getRoadside(transactionList: TransactionRecord[]): TransactionRecord[] {
    transactionList = this.getTransaction(transactionList);
    return transactionList.filter(
      (transaction) =>
      transaction.PlazaSectionType == 1
    );
    // return  transactionList.filter(
    //   (transaction) =>
    //     transaction.PlazaText.toLowerCase().indexOf('central') !== -1 ||
    //     transaction.PlazaText.toLowerCase().indexOf('macdonald - d') !== -1
    // );
  }
}
