export class TransactionRecord {
  AccountId: number;
  AccountBalance: number;
  AccountBalanceActual: number;
  AdjustmentSubType: string;
  BankAuthorizationStatusDB: string;
  DescriptionText: string;
  FeeId: number;
  FeeDescription: string;
  FeeTrigger: string;
  Lane: number;
  LaneText: string;
  NetAmount: number;
  NodeId: number;
  PaymentMethod: string;
  PlazaExternal: string;
  PlazaId: number;
  PlazaSectionType: number;
  PlazaText: string;
  SuppressDescriptionOnStmnt: string;
  SysPostLDateTime: string;
  SysPostLDateTimeStd: string;
  TaxAmount: number;
  TollCategoryName: string;
  TollCathegoryNameCharged:string;
  TransactionAmount: number;
  TransactionDateTimeStd: string;
  TransactionLDateTime: string;
  TransactionNumber: number;
  TransactionSubType: string;
  TransactionSource?: number;
  TransactionText: string;
  TransactionType: string;
  TransponderId: number;
  TransponderDisplayId: string;
  TransponderManufacturerId: string;
  TransponderText: string;
  UserComment: string;
  DownloadingReceipt: boolean;
}
