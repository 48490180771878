<nav class="navbar navbar-expand-lg pt-0 pb-0">
  <div class="container-fluid">
    <a href="#" class="navbar-brand ps-ms-4 mt-2" routerLink="/login" aria-label="home link navigation logo"
      tabindex="-1" aria-hidden="false">
      <img src="{{ configService.config.LogoImageUrl | translate }}" class="d-inline-block align-middle header-logo img-fluid"
        alt="{{ configService.config.ClientName}} Logo" aria-hidden="false" />
      <!-- <div class="h2 mb-0 app-name">Application Name</div>
      <small class="h3 mb-0 department-name">Department of Technology, Management, and Budget</small> -->
    </a>

    <button class="navbar-toggler navbar-light me-3" type="button" data-bs-toggle="collapse"
      data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>


    <div class="collapse navbar-collapse ps-md-4 nav-bar-theme nav-bar-cruve" id="navbarScroll"
      *ngIf="authService.loggedIn()">
      <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
        <li class="nav-item" *ngFor="let aLink of NavLinks">

          <a *ngIf="isUrl(aLink.Url | translate); else url" class="nav-link" routerLinkActive="active"
            id="{{aLink.Name}}" [attr.aria-label]="aLink.Name | translate"
            [attr.aria-current]="getCurrentPage(aLink.Url)" [routerLink]="aLink.Url">
            {{ aLink.Name | translate }}
          </a>

          <ng-template #url>
            <a class="nav-link nav-text nav-hover nav-background" routerLinkActive="active"
              id="{{ aLink.Name | translate }}" [attr.aria-current]="getCurrentPage(aLink.Url)"
              [attr.aria-label]="aLink.Name | translate" [attr.href]="aLink.Url | translate ">
              {{ aLink.Name | translate }}
            </a>
          </ng-template>
        </li>

        <li class="nav-item d-none d-sm-block d-md-block d-md-block d-lg-none" *ngIf="isLoggedIn">
          <a class="nav-link dropdown-item" [routerLink]="['/userpassword']" aria-label="change password" tabindex="0"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            [attr.aria-current]="getCurrentPage('userpassword')" role="link">
            {{ 'CHANGE_PASSWORD' | translate }}
          </a>
        </li>

        <li class="nav-item d-none d-sm-block d-md-block d-md-block d-lg-none" *ngIf="isLoggedIn">
          <a class="nav-link dropdown-item" (click)="logOut()" aria-label="logout" tabindex="0" role="link">
            {{ 'LOGOUT' | translate }}
          </a>
        </li>
      </ul>

      <ul class="d-flex navbar-nav me-2 mb-0 d-none d-lg-block d-xl-block " *ngIf="authService.loggedIn()">
        <li class="nav-item dropdown ">
          <a class="nav-link dropdown-toggle btn btn-primary" href="#" id="subMenunavbarDropdown" role="button"
            [attr.aria-label]="getCurrentUser()?.UserName " data-bs-toggle="dropdown" aria-expanded="false"
            (focus)="toggleSubmenu($event)" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
            <i aria-hidden="true" class="fa fa-user-circle pe-1"></i>
            {{ getCurrentUser()?.UserName }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="subMenunavbarDropdown" #submenu>
            <li>
              <a class="dropdown-item" [routerLink]="['/userpassword']" aria-label="change password" tabindex="0"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                [attr.aria-current]="getCurrentPage('userpassword')" role="link">
                <i aria-hidden="true" class="fa fa-pencil-square-o pe-1"></i>{{ 'CHANGE_PASSWORD' | translate }}
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" (click)="logOut()" aria-label="logout" tabindex="0" role="link">
                <i aria-hidden="true" class="fa fa-power-off pe-1"></i>{{ 'LOGOUT' | translate }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <ul class="navbar-nav collapse navbar-collapse nav-bar-cruve-non-login" *ngIf="!authService.loggedIn()">
      <li class="nav-item nav-item  ms-5">
        <span class="h2 mb-0 bold brandname-color" role="heading" aria-level="1">
          {{'TAG_BRAND' | translate}}
        </span>
      </li>
      <li class="nav-item ms-auto">
        <a class="nav-link-btn btn btn-primary py-2 mx-1" routerLink="/login" aria-label="home link navigation">
          <span class="px-2">{{ 'HOME' | translate }}</span>
        </a>
      </li>
    </ul>

  </div>
</nav>

<div class="section-header mb-3 pb-2">
  <span class="float-end mx-3" *ngIf="!authService.loggedIn() && configService.config.HasLanguage">
    <language-selector tabindex="0" [swipMode]="true"></language-selector>
  </span>
  <div class="pb-1 h4">
  </div>
</div>
