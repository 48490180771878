import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '@ibitoll/toll-core/src';


@Component({
  selector: 'pad-agreement',
  templateUrl: './pad-agreement.component.html',
  styleUrls: ['./pad-agreement.component.scss'],
})
export class PadAgreementComponent implements OnInit {

  public agreementConfirmControl: FormControl;
  public pdfSrc: string;
  public loading: boolean = true;
  public scrolledToBottom = false;

  constructor(
    public dialogRef: MatDialogRef<PadAgreementComponent>,
    private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public data,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.agreementConfirmControl = new FormControl('');
    this.accountService.GetPadAgreement({
      accountId: this.data.accountId,
      name: this.data.name,
      padDayOfMonth: this.data.padDayOfMonth
    })
      .subscribe((data: any) => {
        const file = new Blob([data], { type: 'application/pdf' });
        this.pdfSrc = URL.createObjectURL(file);
        this.loading = false;
      },
        (error: any) => {
          debugger
          console.error(`Error: ${error}`)
        }

      );
  }

  close(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  onScroll(): void {
    if (this.scrolledToBottom) {
      return;
    }
    const scrollableContent = this.elementRef.nativeElement.querySelector('#scrollable-content');
    const isScrolledToBottom = scrollableContent.scrollHeight - scrollableContent.scrollTop - scrollableContent.clientHeight < 300;
    this.scrolledToBottom = isScrolledToBottom;
  }

}
