import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AccountInfo } from '../models/accountInfo';
import { CURRENT_USER } from '../models/keyConstand';
import { UpdateUserEmailQuery } from '../models/updateUserEmailQuery';
import { User } from '../models/user';
import { ChangePasswordQuery } from './../models/changePasswordQuery';
import { AccountService } from './account.service';
import { LocalstorageService } from './localstorage.service';
import { LogService } from './log.service';
import { ProxyService } from './proxy.service';
import { ReCaptchaService } from './re-captcha.service';
import { UserMessageQuery } from '../models/userMessageQuery';
import { UserDeviceQuery } from '../models/userDeviceQuery';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private endpointUrl: string;
  private userEndpoint = 'api/User';
  private webAccessEndpoint = 'api/WebAccess';
  private passwordEndpoint = 'api/Password';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  private subs = new SubSink();

  constructor(
    private accountService: AccountService,
    private http: HttpClient,
    private logService: LogService,
    private proxy: ProxyService,
    private reCaptchaService: ReCaptchaService,
    private localstorageService: LocalstorageService
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  getCurrentUser(): User {
    try {
      return JSON.parse(this.localstorageService.getItem(CURRENT_USER));
    } catch (e) {
      return null;
    }
  }

  setCurrentUser(user: User) {
    this.localstorageService.setItem(CURRENT_USER, JSON.stringify(user));
    this.accountService.setLoadTime(new Date());
  }

  refreshUserAccounts(accounts: AccountInfo[]) {
    const curUser: User = this.getCurrentUser();
    curUser.Accounts = accounts;
    this.localstorageService.setItem(CURRENT_USER, JSON.stringify(curUser));
  }

  updateEmailAddress(query: UpdateUserEmailQuery) {
    console.log('updateEmailAddress()->' + JSON.stringify(query));
    return this.http
      .post<boolean>(
        this.endpointUrl + this.userEndpoint + '/UpdateUserEmail',
        JSON.stringify(query.Email),
        { headers: this.headers }
      )
      .pipe(
        map((r) => {
          return r;
        })
      );
  }

  changePassword(changePasswordQuery: ChangePasswordQuery): Observable<any> {
    return this.reCaptchaService.refresh('ChangePassword').pipe(
      switchMap(() => {
        return this.http
          .post<ChangePasswordQuery>(
            this.endpointUrl + this.userEndpoint + '/ChangePassword',
            JSON.stringify(changePasswordQuery),
            { headers: this.headers }
          )
          .pipe(
            map((r) => {
              return r;
            })
          );
      })
    );
  }

  changePasswordRecovery(
    changePasswordQuery: ChangePasswordQuery
  ): Observable<boolean> {
    return this.reCaptchaService.refresh('ChangePasswordRecovery').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl + this.passwordEndpoint + '/ChangePasswordRecovery',
          JSON.stringify(changePasswordQuery),
          { headers: this.headers }
        );
      })
    );
  }

  sendChangePasswordEmail(
    changePasswordQuery: ChangePasswordQuery
  ): Observable<string> {
    return this.reCaptchaService.refresh('SendChangePasswordEmail').pipe(
      switchMap(() => {
        return this.http.post<string>(
          this.endpointUrl + this.passwordEndpoint + '/SendChangePasswordEmail',
          JSON.stringify(changePasswordQuery),
          { headers: this.headers }
        );
      })
    );
  }

  logout(): Observable<any> {
    return this.http
      .post<any>(
        this.endpointUrl + this.passwordEndpoint + '/Logout',
        {},
        { headers: this.headers }
      )
      .pipe(
        tap(() => {
          try {
          } catch (error) {
            this.logService.logMessage('UserService.logout.Error: ' + JSON.stringify(error));
          }
        }),
        map((r) => {
          return r;
        })
      );
  }

  sendUserMessage(userMessageQuery: UserMessageQuery): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.userEndpoint + '/SendWebUserMessage',
      JSON.stringify(userMessageQuery),
      { headers: this.headers }
    );
  }

  resetPassword(changePasswordQuery: ChangePasswordQuery): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.passwordEndpoint + '/ResetPassword',
      JSON.stringify(changePasswordQuery),
      { headers: this.headers }
    );
  }

  sendPasswordVerificationCodeEmail(
    changePasswordQuery: ChangePasswordQuery
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl +
        this.passwordEndpoint +
        '/SendPasswordVerificationCodeEmail',
      JSON.stringify(changePasswordQuery),
      { headers: this.headers }
    );
  }

  RegisterUserDevice(userDeviceQuery: UserDeviceQuery): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.userEndpoint + '/RegisterUserDevice',
      JSON.stringify(userDeviceQuery),
      { headers: this.headers }
    );
  }

  RegisterBridgeDevice(userDeviceQuery: UserDeviceQuery): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.webAccessEndpoint + '/RegisterBridgeDevice',
      JSON.stringify(userDeviceQuery),
      { headers: this.headers }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
