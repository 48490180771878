<section class="container-12 px-0 px-md-2">
  <!-- The navbar for the wizard -->
  <header class="header col-12" *ngIf="!hideHeader">
    <ol class="px-0">
      <ng-container *ngFor="let step of steps; let i = index;">
        <li [ngClass]="(selectedIndex === i) ? activeClass : ''">
          <a>
            <ng-container *ngIf="step.stepLabel; else showLabelText" [ngTemplateOutlet]="step.stepLabel.template">
            </ng-container>
            <ng-template #showLabelText>
              {{ step.label }}
            </ng-template>
          </a>
        </li>
      </ng-container>
    </ol>

  </header>

  <div class="stepper-body px-0">
    <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
  </div>

  <footer class="step-navigation-bar">
    <ng-content select="[footer]"></ng-content>
  </footer>
  <ng-content select="[subfooter]"></ng-content>

</section>
