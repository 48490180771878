<div class="container-12">
  <div class="no-gutters">
    <div [hidden]="editMode" [ngClass]="{ 'card mb-3': cardMode }">
      <div class="card-header" *ngIf="cardMode">
        <span class="h4">{{ 'CARD_INFO' | translate }}</span>

      </div>
      <div class="text-danger ps-4 pt-3" *ngIf="!account?.PaymentNumber" autoreptxt>
        <strong>
          <span class="h4">
            Select Default Credit Card to enable Auto Replenishment
          </span>
        </strong>
      </div>
      <div class="" [ngClass]="{ 'card-body': cardMode }">
        <div class="card mb-1">
          <div class="row cc-header mx-0">
            <div class="col-5">
              <div class="label px-3 py-2">
                {{ 'CARD_NUMBER_TITLE' | translate }}
              </div>
            </div>
            <div class="col-3 text-center">
              <div class="label px-3 py-2">
                {{ 'MONTH_YEAR' | translate }}
              </div>
            </div>
            <div class="col-4 text-center">
              <div class="label px-3 py-2">
                {{ 'STATUS' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-1" *ngIf="account?.PaymentNumber">
          <div class="row">
            <div class="col-5 align-middle">
              <div class="label px-2 py-3 fs-6 fw-bolder">
                <img src="./assets/images/cc-logo-visa.png" alt="visa" class="pe-1"
                  *ngIf="account?.PreAuthCreditCardType == '1'" />
                <img src="./assets/images/cc-logo-mastercard.png" alt="visa" class="pe-1"
                  *ngIf="account?.PreAuthCreditCardType == '2'" />
                <img src="./assets/images/cc-logo-maestro.png" alt="visa" class="pe-1"
                  *ngIf="account?.PreAuthCreditCardType == '3'" />
                <img src="./assets/images/cc-logo-maestro.png" alt="visa" class="pe-1"
                  *ngIf="account?.PreAuthCreditCardType == '4'" />
                <img src="./assets/images/cc-logo-dinners.png" alt="visa" class="pe-1"
                  *ngIf="account?.PreAuthCreditCardType == '5'" />{{ account?.PaymentNumber }}
              </div>
            </div>
            <div class="col-3 text-center align-middle">
              <div class="label p-3 pt-4 fs-6 fw-bolder">
                {{ account?.PreAuthCardExpiryMonth | number: '2.0-0' }}/{{
                    account?.PreAuthCardExpiryYear
                  }}
              </div>
            </div>
            <div class="col-4 text-center">
              <div class="label p-3 fs-6 default-txt">
                {{ 'DEFAULT' | translate }}
                <button class="btn btn-labeled btn-danger btn-circle ms-1" role="button" aria-label="remove-active-card"
                  id="remove-active-card" (click)="removeActiveCardConfirmation()">
                  <span class="btn-label">
                    <i aria-hidden="true" class="fa fa-times-circle"></i>
                    <i aria-hidden="true" class="fa fa-spinner fa-pulse ml-3" *ngIf="isRemoveActiveCardProcessing"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center d-flex justify-content-center align-self-center" *ngIf="isSavedProcessing">
          <i class="fa fa-3x fa-spinner fa-pulse"></i>
        </div>
        <div class="card mb-1" *ngFor="let card of savedCards">
          <div class="row">
            <div class="col-5 align-middle">
              <div class="label p-2 fs-6 fw-bolder">
                <img src="./assets/images/cc-logo-visa.png" alt="visa" class="pe-1" *ngIf="card.CardType == 1">
                <img src="./assets/images/cc-logo-mastercard.png" alt="visa" class="pe-1" *ngIf="card.CardType == 2">
                <img src="./assets/images/cc-logo-maestro.png" alt="visa" class="pe-1" *ngIf="card.CardType == 3">
                <img src="./assets/images/cc-logo-maestro.png" alt="visa" class="pe-1" *ngIf="card.CardType == 4">
                <img src="./assets/images/cc-logo-dinners.png" alt="visa" class="pe-1" *ngIf="card.CardType == 5">
                {{ getMaskedCardNumber(card?.PaymentNum) }}
              </div>
            </div>
            <div class="col-3 text-center align-middle">
              <div class="label p-3 pt-4 fs-6 fw-bolder">
                {{ card?.BankCardTokenExpiryDate | date: 'MM/yy' }}
              </div>
            </div>
            <div class="col-4 text-center">
              <div class="btn-group mx-auto">
                <button class="btn btn-labeled btn-success btn-circle me-1" role="button" id="set-saved-card-active"
                  aria-label="set-saved-card-active" (click)="setActiveCardConfirmation(card)">
                  <span class="btn-label">
                    <i aria-hidden="true" class="fa fa-check"></i>
                    <i aria-hidden="true" class="fa fa-spinner fa-pulse ml-3" *ngIf="
                          isDefaultProcessing &&
                          selectedCard?.CardId == card?.CardId
                        "></i>
                  </span>
                </button>
                <button id="remove-saved-card" class="btn btn-labeled btn-danger btn-circle ms-2" role="button"
                  aria-label="remove-saved-card" (click)="removeSavedCardConfirmation(card)">
                  <span class="btn-label">
                    <i class="fa fa-times-circle"></i>
                    <i class="fa fa-spinner fa-pulse ml-3" *ngIf="
                          isRemoveProcessing &&
                          selectedCard?.CardId == card?.CardId
                        "></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 p-0">
          <div class="form-group">
            <div class="col-12 pl-0 pt-2">
              <button class="btn btn-app-primary topbutton float-left" (click)="editModeEnabled()" role="button"
                [attr.aria-label]="'ADD_CARD' | translate">
                {{'ADD_CARD' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 p-0" [hidden]="!this.editMode">
      <credit-card [cardMode]="cardMode">
        <div class="text-danger" *ngIf="!account?.PaymentNumber" autoreptxt>
          <strong>
            <span class="h4">Enter payment information to enable Auto Replenishment</span>
          </strong>
        </div>
        <div submit class="col-12 pr-5" *ngIf="!hideButtons">

          <button class="btn btn-app-secondary submit-btn" (click)="editModeEnabled()" type="button" role="button"
            aria-label="CANCEL">
            {{ 'CANCEL' | translate }}
          </button>

          <button class="btn btn-app-primary  submit-btn float-end me-3" (click)="processPreauthLocalToken()"
            type="button" role="button" aria-label="SUBMIT" [ngClass]="{'disabled': !enableCCSubmit && processing}"
            [attr.aria-disabled]="!enableCCSubmit && processing" btnDisabled>
            <span *ngIf="!processing">
              {{ 'SUBMIT' | translate }}
            </span>

            <span *ngIf="processing"> Processing... </span>
            <i aria-hidden="true" class="fa fa-spinner fa-pulse" *ngIf="processing"></i>
          </button>
        </div>
      </credit-card>
    </div>
  </div>
</div>

<!-- ERROR MODAL -->

<ng-template #errorModal let-modal let-c="close" let-d="dismiss" class="errorModal">
  <div *ngIf="errorObj">
    <h6 class="
        modal-header
        text-center text-danger
        align-middle
        px-2
        d-flex
        justify-content-center
      ">
      <div class="d-flex align-items-center">
        <em class="d-inline align-middle px-2 fa fa-times-circle fa-2x"> </em>
        <h3 class="m-0">{{ errorObj.title }}</h3>
      </div>
    </h6>
    <p class="modal-body text-center">{{ errorObj.message }}</p>
    <div class="modal-footer p-0">
      <button class="m-2 btn btn-danger float-end" data-dismiss="errorModal" aria-label="Close" (click)="modal.close()">
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<!-- ERROR MODAL -->



<!-- REMOVE ACTIVE CARD MODAL -->
<ng-template #removeCardModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title mb-0">{{'REMOVE-ACTIVE-CARD' | translate}}</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body" [attr.aria-label]="'REMOVE-ACTIVE-CARD-MSG' | translate">
    <p>{{'REMOVE-ACTIVE-CARD-MSG' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button class="m-2 btn btn-app-secondary float-start me-auto" data-dismiss="errorModal" aria-label="Close"
      (click)="modal.close()">
      {{ 'CANCEL' | translate }}
    </button>
    <button cancelbtn class="btn btn-app-primary px-4 m-1 float-end" (click)="removeActiveCard()">
      {{ 'CONFIRM' | translate }}
      <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isRemoveActiveCardProcessing"></i>
    </button>
  </div>
</ng-template>
<!-- REMOVE ACTIVE CARD MODAL -->

<!-- REMOVE SAVED ACTIVE CARD MODAL -->
<ng-template #removeSavedCardModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title mb-0">{{'REMOVE-ACTIVE-CARD' | translate}}</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body" [attr.aria-label]="'REMOVE-ACTIVE-CARD-MSG' | translate">
    <p>{{'REMOVE-ACTIVE-CARD-MSG' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button class="m-2 btn btn-app-secondary float-start me-auto" data-dismiss="errorModal" aria-label="Close"
      (click)="modal.close()">
      {{ 'CANCEL' | translate }}
    </button>
    <button cancelbtn class="btn btn-app-primary px-4 m-1 float-end" (click)="removeSavedCardActive(selectedCard)">
      {{ 'CONFIRM' | translate }}
      <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isRemoveProcessing"></i>
    </button>
  </div>
</ng-template>

<!-- REMOVE ACTIVE CARD MODAL -->

<!-- SET ACTIVE CARD MODAL -->
<ng-template #setSavedCardModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title mb-0">
      {{'SET-ACTIVE-CARD' | translate}}
    </h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body" [attr.aria-label]="'SET-ACTIVE-CARD-MSG' | translate">
    <p>{{'SET-ACTIVE-CARD-MSG' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button class="m-2 btn btn-app-secondary float-start me-auto" data-dismiss="errorModal" aria-label="Close"
      (click)="modal.close()">
      {{ 'CANCEL' | translate }}
    </button>
    <button cancelbtn class="btn btn-app-primary px-4 m-1 float-end" (click)="setCardActive(selectedCard)">
      {{ 'CONFIRM' | translate }}
      <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isRemoveActiveCardProcessing"></i>
    </button>
  </div>
</ng-template>
<!-- SET ACTIVE CARD MODAL -->
