import { switchMap } from 'rxjs/operators';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { MessageBoard } from '../models/messageboard';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SubSink } from 'subsink';
import { Cacheable } from 'ts-cacheable';
import { ProxyService } from './proxy.service';
import { ReCaptchaService } from './re-captcha.service';
import { Closure } from '../models/closure';

@Injectable({
  providedIn: 'root',
})
export class ClosureService implements OnDestroy {
  private endpointUrl: string;

  private closureEndpoint = 'api/closure';

  private subs = new SubSink();

  public closuresEvent = new EventEmitter<Array<Closure>>();

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient,
    private proxy: ProxyService,
    private reCaptchaService: ReCaptchaService
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  refreshClosures() {
    this.http.get<Closure[]>(
      this.endpointUrl + this.closureEndpoint + '/getclosure',
      { headers: this.headers }
    ).subscribe(closures => {
      this.closuresEvent.emit(closures);
    });
  }

  getClosure(): Observable<Closure[]> {
    return this.http.get<Closure[]>(
      this.endpointUrl + this.closureEndpoint + '/getclosure',
      { headers: this.headers }
    );
  }

  getClosureAll(): Observable<Closure[]> {
    return this.http.get<Closure[]>(
      this.endpointUrl + this.closureEndpoint + '/getClosureAll',
      { headers: this.headers }
    );
  }

  getCacheClosure(): Observable<Closure[]> {
    return this.http.get<Closure[]>(
      this.endpointUrl + this.closureEndpoint + '/getclosure',
      { headers: this.headers }
    );
  }


  setClosure(msgb: Closure): Observable<boolean> {
    return this.reCaptchaService.refresh('setclosure').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl + this.closureEndpoint + '/setclosure',
          JSON.stringify(msgb),
          { headers: this.headers }
        );
      })
    );
  }

  updateClosure(msgb: Closure): Observable<boolean> {
    return this.reCaptchaService.refresh('updateclosure').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl + this.closureEndpoint + '/updateclosure',
          JSON.stringify(msgb),
          { headers: this.headers }
        );
      })
    );
  }

  removeClosure(msgb: Closure): Observable<boolean> {
    return this.reCaptchaService.refresh('removeclosure').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl + this.closureEndpoint + '/removeClosure',
          JSON.stringify(msgb),
          { headers: this.headers }
        );
      })
    );
  }

  pusjClosure(msgb: Closure): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.closureEndpoint + '/PushClosure',
      JSON.stringify(msgb),
      { headers: this.headers }
    );
  }

  pushTopicClosure(msgb: Closure): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.closureEndpoint + '/PushTopicClosure',
      JSON.stringify(msgb),
      { headers: this.headers }
    );
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
