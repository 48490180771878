import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ibitoll-payment-confirm',
  templateUrl: './payment-confirm.component.html',
  styleUrls: ['./payment-confirm.component.scss']
})
export class PaymentConfirmComponent implements OnInit {

  @Output() confirmStaus: EventEmitter<any> = new EventEmitter();

  isRequesting = false;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  confirmStausTrigger() {
    this.isRequesting = true;
    this.confirmStaus.emit(true);
  }

}
