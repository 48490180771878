export class CreditCard {
  UserId: string;
  UserLogonId: string;
  AccountId: number;
  PaymentType: string;
  PaymentCardType: number;
  PaymentBankBranchId: number;
  PaymentBankType: number;
  PaymentBankTransitNumber: string;
  PaymentNum: string; // masked with *
  PaymentLastName: string;
  PaymentFirstName: string;
  PaymentCardHolder: string;
  PaymentCardExpiryYear: number;
  PaymentCardExpiryMonth: number;
  PaymentCardCvv: string;
  BalanceReplenishAmount: number;
  BalanceReplenishThreshold: number;
  BalanceWarningThreshold: number;
  PadDayOfMonth: number;
  CardBankTokenId: string;
  PreauthStatus: number;
  Amount: number;
  //AgreementConfirm: boolean;
  ActionCode: string;
}
