<div class="modal-header">
  <div class="text-center rounded-top">
    <h1 class="mb-0">
      <i class="fa"
         [ngClass]="isApproved() ? 'fa-check-circle-o' : 'fa-exclamation-circle'"></i>
      {{ (isApproved() ? 'TR_STATUS_A' : 'TR_STATUS_D') | translate }}
      {{ 'INTERNET_TRANSACTION' | translate }}
    </h1>
  </div>
</div>

<div class="modal-body" id="paymentRecieptModalBody">
  <div class="container p-1">
    <div class="row">
      <div class="col-sm-3">
        <b>{{ 'AMOUNT' | translate }}: </b>
      </div>
      <div class="col-sm-9">
        {{ formatMoneyValue(parseAmount(transactionResponse?.ChargeTotal)) | currency }}
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <b>{{ 'CARD_NUMBER' | translate }}: </b>
      </div>
      <div class="col-sm-9">
        {{ transactionResponse.PaymentNumber }}
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <b>{{ 'PROCESSED_AS' | translate }}: </b>
      </div>
      <div class="col-sm-9">
        {{ getCardType(transactionResponse?.PaymentCardType) | translate }}
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <b>{{ 'REFERENCE_NO' | translate }}: </b>
      </div>
      <div class="col-sm-9">
        {{ transactionResponse?.TraceNumber }}
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <b>{{ 'INVOICE_NO' | translate }}: </b>
      </div>
      <div class="col-sm-9">
        {{ transactionResponse?.ResponseOrderID }}
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <b>{{ 'PROCESSED_ON' | translate }}: </b>
      </div>
      <div class="col-sm-9">
        {{
        transactionResponse?.BankTransactionUDateTime
        ? (transactionResponse?.BankTransactionUDateTime
        | dateTimeFormatter)
        : (currentDate | dateTimeFormatter)
        }}
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="me-1 btn btn-app-primary me-2" [disabled]="downloading"
          (click)="downloadReceipt()">
    {{ 'DOWNLOAD' | translate }}
  </button>
  <button class="ms-1 btn btn-app-secondary"
          (click)="afterModalClose()">
    {{ 'OK' | translate }}
  </button>
</div>
