import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from '../providers/date-time.service';
import { UtilsService } from '../providers/utils.service';

@Pipe({ name: 'dateTimeFormatter' })
export class DateTimeFormatter implements PipeTransform {
  constructor(
    private dateTimeService: DateTimeService,
    private utilsService: UtilsService
  ) { }

  transform(date: Date): string {
    if (this.utilsService.isAttiki()) {
      return this.dateTimeService.getGreekDateTimeString(date);
    } else if (this.utilsService.isMackinac()) {
      return this.dateTimeService.getMackinacShortDateTimeString(date);
    } else if (this.utilsService.isMdot()) {
      return this.dateTimeService.getMdotDateTimeString(date);
    } else {
      return this.dateTimeService.getShortDateTimeString(date);
    }
  }
}
