import { cloneDeep } from 'lodash-es';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountCreationFee } from '../models/accountCreationFee';
import { CURRENT_USER, REFRESH_TOKEN } from '../models/keyConstand';
import { TollCategoryInfo } from '../models/tollCategoryInfo';
import { AuthServiceProvider } from './auth.service';
import { ConfigurationService } from './configuration.service';
import { DateTimeService } from './date-time.service';
import { Globals } from './globals.service';
import { LocalstorageService } from './localstorage.service';
import { ThemeService } from './theme.service';
import { JwtHelperService } from '@auth0/angular-jwt';

declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  fees: AccountCreationFee;
  accountId4personal = -1;
  accountId4business: number;
  helper = new JwtHelperService();

  constructor(
    public globals: Globals,
    private configurationService: ConfigurationService,
    private themeService: ThemeService,
    private idle: Idle,
    private dateTimeService: DateTimeService,
    public router: Router,
    private authService: AuthServiceProvider,
    public localstorageService: LocalstorageService
  ) {
    this.loadGlobalData();
  }

  getCompanyLogo(): string {
    const companyLogo = this.themeService.active.LogoImageUrl;
    return companyLogo;
  }

  getCompanyLogoHeader(): string {
    return this.getCompanyLogo();
  }

  getBackgroungPic(): string {
    let backgrImg = '';
    if (this.isMackinac()) {
      backgrImg = this.themeService.active.BackgroundImageUrl; // 'assets/images/background.jpg';
    } else {
      backgrImg = this.themeService.active.BackgroundImageUrl; // 'assets/bridge-authority-bridge.jpg';
    }
    return backgrImg;
  }

  isBahamas(): boolean {
    return this.globals.appName === 'tba';
  }

  isMackinac(): boolean {
    return this.configurationService.config.Name === 'mba';
  }

  isAttiki(): boolean {
    return this.configurationService.config.Name === 'aodos';
  }

  isMdot(): boolean {
    return this.configurationService.config.Name === 'fbcl' || this.configurationService.config.Name === 'bwb' || this.configurationService.config.Name === 'iba';
  }

  isFbcl(): boolean {
    return this.configurationService.config.Name === 'fbcl';
  }

  isBwb(): boolean {
    return this.configurationService.config.Name === 'bwb';
  }

  isIba(): boolean {
    return this.configurationService.config.Name === 'iba';
  }

  isEgnatia(): boolean {
    return this.configurationService.config.Name === 'eodos';
  }
  isHHB(): boolean {
    return this.configurationService.config.Name === 'hhb';
  }

  getSingleTransponderFeeAmount(
    TollCategoryId: number,
    TransponderTypeId: number
  ): number {
    this.fees = JSON.parse(localStorage.getItem('accFees'));
    return this.fees.TransponderAssignFee + this.fees.StartTransponderFeeAmount;
  }

  isLoggedIn(): boolean {
    const currentUserString = this.localstorageService.getItem(CURRENT_USER);
    if (currentUserString) {
      return true;
    } else {
      return false;
    }
  }

  getInitialBalanceAmount(TollCategoryId: number): number {
    // For all accounts, the customer is required to make a deposit to cover all
    // initial account and transponder fees and have an initial balance
    let initialBalance = 0;
    if (TollCategoryId === this.accountId4personal) {
      // Personal
      initialBalance = 80;
    } else {
      initialBalance = 200;
    }
    return initialBalance;
  }

  getAccountProfileIdPersonal() {
    this.configurationService
      .getConfigParameter('AccountProfileIdPersonal')
      .subscribe(
        (resp) => {
          this.accountId4personal = Number(resp);
          console.log('Loaded: accountId4personal=' + this.accountId4personal);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getAccountProfileIdBusiness() {
    this.configurationService
      .getConfigParameter('AccountProfileIdBusiness')
      .subscribe(
        (resp) => {
          this.accountId4business = Number(resp);
          console.log('Loaded: accountId4business=' + this.accountId4business);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  initialiseValues() {
    this.getAccountProfileIdPersonal();
    this.getAccountProfileIdBusiness();
  }

  getDefaultAccountProfileId(AccountType: string): number {
    let value = 0;
    if (AccountType === 'P') {
      // Personal
      value = this.accountId4personal; // should correspond to ACCOUNT_PROFILE.ACCOUNTPROFILE_ID
    } else if (AccountType === 'B') {
      // Business account
      value = this.accountId4business; // should correspond to ACCOUNT_PROFILE.ACCOUNTPROFILE_ID
    }
    return value;
  }

  getInitialAccountFees(AccountType: string): Observable<any> {
    // console.log('getInitialAccountFees starts');
    // if (this.fees === null || this.fees.CreateAdminFee === 0) {
    const accProfileId = this.getDefaultAccountProfileId(AccountType);
    console.log('getInitialAccountFees accProfileId = ' + accProfileId);
    return this.configurationService.getAccountCreateFeeType(accProfileId).pipe(
      map(
        (resp) => {
          this.fees = resp;
          console.log('getInitialAccountFees->' + JSON.stringify(this.fees));
        },
        (error) => {
          console.log('error in getInitialAccountFees: ' + error);
        }
      )
    );
  }

  getCardType(ccnumber: string): number {

    // visa
    var re = new RegExp('^4');
    if (ccnumber.match(re) != null) return 1;

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        ccnumber
      )
    )
      return 2;

    // Maestrocard
    re = new RegExp('^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}');
    if (ccnumber.match(re) != null) return 3;

    // AMEX
    re = new RegExp('^3[47]');
    if (ccnumber.match(re) != null) return 4;

    // Diners
    re = new RegExp('^36');
    if (ccnumber.match(re) != null) return 5;

    // Diners - Carte Blanche
    re = new RegExp('^30[0-5]');
    if (ccnumber.match(re) != null) return 5;

    // Discover
    re = new RegExp(
      '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'
    );
    if (ccnumber.match(re) != null) return 7;

    // JCB
    re = new RegExp('^35(2[89]|[3-8][0-9])');
    if (ccnumber.match(re) != null) return 9;

    return 0;
  }

  isDebug(): boolean {
    let debug = false;
    const location = 'host=' + window.location.host;
    // console.log('isDebug.host=' + location);
    if (location.indexOf('localhost:4200') > 0) {
      debug = true;
    }
    return debug;
  }

  getMaskedCardNumber(protectedCardNumber: string) {
    // protectedCardNumber is 16 digits
    const maskedCardNumber =
      '0000' + '0000' + '"0000' + protectedCardNumber.slice(12);
    return maskedCardNumber;
  }

  filterTollCategories(tollCategories: TollCategoryInfo[]): TollCategoryInfo[] {
    if (this.isMackinac()) {
      const distinctCat = new Array<TollCategoryInfo>();
      const flags = new Array<any>(),
        len = tollCategories.length;
      let i: number;
      for (i = 0; i < len; i++) {
        if (flags[tollCategories[i].GroupName]) {
          continue;
        }
        flags[tollCategories[i].GroupName] = true;
        distinctCat.push(tollCategories[i]);
      }
      return distinctCat;
    } else {
      return tollCategories;
    }
  }

  parseAmount(value: number): string {
    // usage: <span  [innerHTML]="parseAmount(Amount)"></span>
    let sBalance = '';
    if (value) {
      let sCredit = '';
      if (this.isMackinac()) {
        sBalance += '$';
      } else if (this.isAttiki()) {
        sCredit = 'CR';
        // console.log('Current lang: ' + this.themeService.GetClientLanguage());
        if (this.themeService.GetClientLanguage() === 'gr') {
          sCredit = 'Π';
        }
        sBalance += '&euro;';
      }
      if (value < 0) {
        sBalance += (-value).toString() + sCredit; // 'CR'
      } else {
        sBalance += value.toString();
      }
    }
    return sBalance; // Math.abs(value);
  }

  stopIdleService() {
    console.log(
      'stopIdleService Now: ' + this.dateTimeService.getTimeString(new Date())
    );
    this.idle.stop();
    this.idle.onTimeout.observers.length = 0;
    this.idle.onIdleStart.observers.length = 0;
    this.idle.onIdleEnd.observers.length = 0;
    this.idle.onInterrupt.observers.length = 0;
    this.idle.onTimeout.observers.length = 0;
    this.idle.onTimeoutWarning.observers.length = 0;
  }

  initIdleService() {
    // make sure this.themeService.active.SessionTimeOut has numerical value in configuration "SessionTimeOut": 15.
    // This will not work: "SessionTimeOut": "15",
    var sessionTimeOut = this.themeService.active.SessionTimeOut;

    // if Refresh Token used (stay logged in) then set idle timeout to maximum (refresh token expiration)
    if (this.localstorageService.check(REFRESH_TOKEN)) {
      try {
        var refreshToken = this.localstorageService.getItem(REFRESH_TOKEN);

        var expirationTime: any =
          this.helper.getTokenExpirationDate(refreshToken);
        var now: any = new Date();

        // check if expiration is larger
        if (expirationTime - now > 0)
          sessionTimeOut = Math.ceil((expirationTime - now) / 1000);
      } catch (error) {
        console.log(error);
      }
    }

    // setIdle(A); setTimeout(B)
    // activity stop -> A sec -> Idle State -> B sec -> LogOff. So real logoff timeout is A+B
    console.log(
      'Idle Timeout set to ' +
        sessionTimeOut +
        ' sec; Now: ' +
        this.dateTimeService.getTimeString(new Date())
    );

    this.idle.setIdle(sessionTimeOut); // set time in seconds until Idle event starts
    this.idle.setTimeout(10); // set time in seconds of how long Idle event lasts
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onTimeout.subscribe(() => {
      console.log(
        'Idle Tiemout logout: URL' +
          this.router.url +
          '; Now: ' +
          this.dateTimeService.getTimeString(new Date())
      );
      if (
        !this.router.url.includes('/Signup') &&
        !this.router.url.includes('/login') &&
        !this.router.url.includes('/message/msg') &&
        !this.router.url.includes('/ActivateWebAccount') &&
        !this.router.url.includes('/ForgotPassword')
      ) {
        // logoff
        this.authService.logout();
        this.router.navigate(['/login']);
        this.stopIdleService();
      } else {
        this.idle.watch();
      }
    });
    this.idle.onInterrupt.subscribe(() => {
      // why we do this here?
      this.authService.updateCurrentUserTokenExpiry(
        this.themeService.active.RestoreLoginTokenPeriod
      );
    });
    this.idle.watch();
  }

  loadGlobalData() {
    this.configurationService.getCountries().subscribe((countries) => {
      this.globals.countries = countries;
    });

    this.configurationService.getProvinces().subscribe((provinces) => {
      this.globals.provinces = cloneDeep(provinces);
    });

    this.configurationService.getTollCategories().subscribe((categories) => {
      this.globals.tollCategories = categories;
    });

    this.configurationService.getAccountProfiles().subscribe((profiles) => {
      this.globals.accountProfiles = profiles;
    });
  }

  isUrl(url: string): boolean {
    if (url.charAt(0) === '/') {
      return true;
    } else {
      return false;
    }
  }

  isLink(navItem: any) {
    if (navItem.SubLinks) {
      return false;
    } else {
      return true;
    }
  }
}
