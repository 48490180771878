import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MessageboardService } from '../services/messageboard.service';
import { MessageBoard } from '../models/messageboard';

@Component({
  selector: 'cob-messageboard',
  templateUrl: './messageboard.component.html',
  styleUrls: ['./messageboard.component.scss'],
})
export class MessageboardComponent implements OnInit {
  msgb: MessageBoard = new MessageBoard();

  @ViewChild('messageBody', { static: true }) messageBody: ElementRef;

  constructor(
    private _renderer: Renderer2,
    private messageboardService: MessageboardService
  ) {}

  ngOnInit(): void {
    this.messageboardService.getMessageBoardMsg().subscribe((msgb) => {
      this.msgb = msgb;
      this._renderer.setStyle(
        this.messageBody.nativeElement,
        'border',
        `2px solid ${this.msgb.BoardBorder}`
      );
    });
  }
}
