import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AccountService,
  Account,
  ConfigurationService,
} from '@ibitoll/toll-core';
import { SubSink } from 'subsink';

@Component({
  selector: 'account-balance',
  templateUrl: './account-balance.component.html',
  styleUrls: ['./account-balance.component.scss'],
})
export class AccountBalanceComponent implements OnInit, OnDestroy {
  public account: Account;
  isLoading = false;
  private subs = new SubSink();

  constructor(
    private accountService: AccountService,
    public configurationService: ConfigurationService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.accountService.activeAccount.subscribe((account: Account) => {
        this.account = account;
        this.isLoading = false;
      })
    );
  }

  getDate(date: string): Date {
    return new Date(date);
  }

  parseAmount(value: number) {
    return -value;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
