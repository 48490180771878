import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class FormValidation {


  static InvalidValidator(control: AbstractControl): { [key: string]: boolean } {
    const nameRegexp: RegExp = /[%&<>\/]/;
    if (control.value && nameRegexp.test(control.value)) {
      return { invalidValidaChar: true };
    } else {
      return null;
    }
  }

  static MatchPassword(abstractControl: AbstractControl) {
    const password = abstractControl.get('password').value;

    const confirmPassword = abstractControl.get('confirmPassword').value;
    if (password !== confirmPassword) {
      abstractControl.get('confirmPassword').setErrors({ MatchPassword: true });
    } else {
      abstractControl.get('confirmPassword').setErrors(null);
      return null;
    }
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static invalidInputValidator(error: ValidationErrors): ValidatorFn {
    const nameRegexp: RegExp = /[!#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value && nameRegexp.test(control.value)) {
        return error
      } else {
        return null
      }
    };
  }

  static forbidAllZerosValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isAllZeros = control.value === ''.padEnd(control.value?.length, '0'); //Number(control.value) === 0; //control.value === ''.padEnd(len, '0');
      return isAllZeros ? {'allZerosNotAllowed': true} : null;
    };
  }
}
