export class EvalonTxnResponse {
  ssl_issuer_response: string;
  ssl_transaction_type: string;
  ssl_card_number: string;
  ssl_oar_data: string;
  ssl_result: string;
  ssl_txn_id: string;
  ssl_avs_response: string;
  ssl_approval_code: string;
  ssl_amount: string;
  ssl_txn_time: string;
  ssl_account_balance: string;
  ssl_ps2000_data: string;
  ssl_exp_date: string;
  ssl_result_message: string;
  ssl_card_short_description: string;
  ssl_card_type: string;
  ssl_cvv2_response: string;
  custom_reference_number: string;
  custom_customer_number: string;
  errorMessage: string;
  errorCode: string;
  errorName: string;
  PaymentType: string;
  PaymentMethod: string;
  ssl_aba_number: string;
}
