import { Directive, ElementRef, AfterViewInit } from "@angular/core";

@Directive({
  selector: "[appFocus]"
})
export class FocusDirective implements AfterViewInit {
  constructor(private element: ElementRef) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.element.nativeElement.focus();
    }, 500);
  }
}
