import { Directive, Input } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

@Directive({
  selector: '[validInputTransponder][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: TransponderInputFormDirective,
      multi: true,
    },
  ],
})
export class TransponderInputFormDirective implements Validator {
  @Input('validInputTransponder') appInputRestriction = 'accountNumber';
  validate(control: AbstractControl): ValidationErrors {

    if (this.appInputRestriction === 'accountNumber') {
      control.setErrors(null);
      return null;
    }

    if (control?.value?.length <= 4) {
      //Show Error
      control.setErrors({ invalidTransponder: true });
      return { invalidTransponder: true };
    } else {
      if (control?.value?.startsWith('104')) {
        if (control?.value?.length < 14) {
          //Show Error
          control.setErrors({ invalidTransponder: true });
          return { invalidTransponder: true };
        } else {
          //Remove Error
          control.setErrors(null);
          return null;
        }
      } else if (control?.value[2].toUpperCase() === 'E') {
        if (control?.value?.length == 12) {
          //Remove Error
          control.setErrors(null);
          return null;
        } else {
          //Show Error
          control.setErrors({ invalidTransponder: true });
          return { invalidTransponder: true };
        }
      } else if (control?.value?.startsWith('124')) {
        if (control?.value?.length == 14) {
          //Remove Error
          control.setErrors(null);
          return null;
        } else {
          //Show Error
          control.setErrors({ invalidTransponder: true });
          return { invalidTransponder: true };
        }
      } else if (control?.value?.startsWith('2529') || control?.value?.startsWith('2531') || control?.value?.startsWith('2530')) {
        if (control?.value?.length == 15) {
          //Remove Error
          control.setErrors(null);
          return null;
        } else {
          //Show Error
          control.setErrors({ invalidTransponder: true });
          return { invalidTransponder: true };
        }
      }
      else {
        if (control?.value?.length < 12) {
          //Show Error
          control.setErrors({ invalidTransponder: true });
          return { invalidTransponder: true };
        } else {
          //Remove Error
          control.setErrors(null);
          return null;
        }
      }
    }
  }
}
