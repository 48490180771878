 <ng-template #content let-modal>
   <div class="modal-header">
     <h1 aria-label="session-timeout" id="dialog-child-name" class="modal-title pull-left mb-0 h4">
       Session Timeout
     </h1>
   </div>
   <div class="modal-body">
     <div>
       <i class="far fa-clock"></i> Your online session will expire in {{ count }} secs
     </div>
     <div>
       Please click "Continue" to keep working or click "Log Off" to end your
       session now
     </div>
     <div class="mt-3">
       <button type="button" class="btn btn-app-primary btn-primary pull-left" (click)="restart()"
         aria-label="Continue">
         Continue
       </button>
       <button type="button" class="btn btn-primary pull-right" (click)="logOut()" aria-label="Log Off">
         Log Off
       </button>
     </div>
   </div>
 </ng-template>
