<form
  class="form"
  (ngSubmit)="newMode ? requestTransponder() : updateTransponder()"
  #transponderForm="ngForm"
>
  <div *ngIf="newAccountMode && isEmployer" class="row">
    <div class="col-md-6 px-4 pt-2">
      <div class="form-check form-check-inline">
        <label class="form-check-label form-check-label-sm">
          <input
            type="checkbox"
            class="form-check-input"
            [(ngModel)]="transponderRequest.Enabled"
            name="billing"
          />
          Request a Transponder
        </label>
      </div>
    </div>
  </div>
  <div *ngIf="transponderRequest.Enabled" class="row py-3">
    <div
      class="col-lg-4 px-4"
      [class.col-lg-4]="newAccountMode"
      [class.col-12]="!newAccountMode"
    >
      <div *ngIf="false">
        <label class="col-form-label col-form-label-sm account-label"
          ><b>{{ 'TRANSPONDER' | translate }}</b></label
        >
        <div class="form-group">
          <label for="type" class="col-form-label col-form-label-sm">{{
            'TYPE' | translate
          }}</label>
          <select
            class="form-control form-control-sm form-select"
            id="type"
            name="type"
            [(ngModel)]="transponderRequest.TransponderTypeId"
            required
            #TransponderTypeId="ngModel"
          >
            <option
              *ngFor="let transponderType of transponderTypes"
              [ngValue]="transponderType.Id"
            >
              {{ transponderType.Name }}
            </option>
          </select>
        </div>
      </div>

      <label class="col-form-label col-form-label-sm account-label"
        ><b>{{ 'VEHICLE' | translate }}</b></label
      >
      <div class="form-group">
        <label for="category" class="col-form-label col-form-label-sm">{{
          vehicleCategoryText
        }}</label>
        <select
          class="form-control form-control-sm form-select"
          id="category"
          name="category"
          [(ngModel)]="transponderRequest.TollCategoryId"
          required
          #category="ngModel"
        >
          <option
            *ngFor="let tollCategory of tollCategories"
            [ngValue]="tollCategory.Id"
            class="auto-dropdown"
          >
            {{ tollCategory.GroupName }}
            <div #subDescription class="subDescription">
              - {{ getSubNotes(tollCategory.GroupName) }}
            </div>
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="prefecture" class="col-form-label col-form-label-sm">{{
          'STATE_PROVINCE' | translate
        }}</label>
        <select
          class="form-control form-control-sm form-select"
          id="prefecture"
          name="prefecture"
          [(ngModel)]="transponderRequest.PlateProvinceId"
          required
          #prefecture="ngModel"
        >
          <optgroup *ngIf="preferedProvinces.length > 0">
            <option
              *ngFor="let province of preferedProvinces"
              [ngValue]="province.Id"
            >
              {{ province.Name }}
            </option>
          </optgroup>

          <optgroup label="__________________" *ngIf="provinces.length > 0">
            <option *ngFor="let province of provinces" [ngValue]="province.Id">
              {{ province.Name }}
            </option>
          </optgroup>
        </select>
      </div>

      <div class="form-group">
        <label for="plate" class="col-form-label col-form-label-sm">{{
          'PLATE' | translate
        }}</label>
        <input
          class="form-control form-control-sm"
          name="plate"
          type="text"
          [(ngModel)]="transponderRequest.PlateNum"
          required
          #plate="ngModel"
          onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
          onDrag="return false"
          onDrop="return false"
          onPaste="return false"
        />
      </div>

      <div class="form-group">
        <label for="make" class="col-form-label col-form-label-sm">{{
          'MAKE' | translate
        }}</label>
        <input
          class="form-control form-control-sm"
          name="make"
          type="text"
          [(ngModel)]="transponderRequest.VehicleMake"
          required
          #make="ngModel"
          onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
          onDrag="return false"
          onDrop="return false"
          onPaste="return false"
        />
      </div>

      <div class="form-group">
        <label for="model" class="col-form-label col-form-label-sm">{{
          'MODEL' | translate
        }}</label>
        <input
          class="form-control form-control-sm"
          name="model"
          type="text"
          [(ngModel)]="transponderRequest.VehicleModel"
          required
          #model="ngModel"
          onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
          onDrag="return false"
          onDrop="return false"
          onPaste="return false"
        />
      </div>

      <div class="row">
        <div class="form-group col-6">
          <label for="color" class="col-form-label col-form-label-sm">{{
            'COLOR' | translate
          }}</label>
          <input
            class="form-control form-control-sm"
            name="color"
            type="text"
            [(ngModel)]="transponderRequest.VehicleColor"
            required
            #model="ngModel"
            onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
            onDrag="return false"
            onDrop="return false"
            onPaste="return false"
          />
        </div>

        <div class="form-group col-6">
          <label for="year" class="col-form-label col-form-label-sm">{{
            'YEAR' | translate
          }}</label>
          <input
            class="form-control form-control-sm"
            name="year"
            type="text"
            mask="0000"
            [showMaskTyped]="true"
            onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
            onDrag="return false"
            onDrop="return false"
            onPaste="return false"
            [(ngModel)]="transponderRequest.VehicleYear"
            required
            #year="ngModel"
          />
        </div>
      </div>

      <div *ngIf="newAccountMode" class="form-group">
        <button
          [disabled]="!transponderForm.valid"
          class="btn btn-app-primary"
          (click)="addVehicle()"
          type="button"
        >
          {{ 'ADD_VEHICLE' | translate }}
        </button>
      </div>
    </div>

    <div *ngIf="newAccountMode" class="col-lg-8 px-4 v-lst">
      <label class="col-form-label col-form-label-sm account-label"
        ><b>{{ 'VEHICLE_LIST' | translate }}</b></label
      >
      <div class="form-group">
        <label
          *ngIf="addedTranspCount == 0"
          class="col-form-label col-form-label-sm"
          >{{ 'PLEASE_ADD_VEHICLE' | translate }}</label
        >
        <div *ngIf="addedTranspCount > 0" class="row row-head">
          <div class="col-sm-1 col-lg-1">{{ 'CAT' | translate }}</div>
          <div class="col-sm-2 col-lg-2">
            {{ 'STATE_PROVINCE' | translate }}
          </div>
          <div class="col-sm-2 col-lg-2">{{ 'PLATE' | translate }}</div>
          <div class="col-sm-3 col-lg-3">{{ 'MAKE_MODEL' | translate }}</div>
          <div class="col-sm-1 col-lg-1">{{ 'COLOR' | translate }}</div>
          <div class="col-sm-1 col-lg-1">{{ 'YEAR' | translate }}</div>
          <div class="col-sm-1 col-lg-1"></div>
        </div>
        <div
          *ngFor="let transponder of transponderRequestList; let ind = index"
          class="row row-striped"
        >
          <!-- <div class="col-lg-2">{{ getTransponderTypeText(transponder.TransponderTypeId) }}</div> -->
          <div class="col-sm-1 col-lg-1">
            {{ getCategoryText(transponder.TollCategoryId) }}
          </div>
          <div class="col-sm-2 col-lg-2">
            {{ getProvince(transponder.PlateProvinceId) }}
          </div>
          <div class="col-sm-2 col-lg-2">{{ transponder.PlateNum }}</div>
          <div class="col-sm-3 col-lg-3">
            {{ transponder.VehicleMake }}, {{ transponder.VehicleModel }}
          </div>
          <div class="col-sm-1 col-lg-1">{{ transponder.VehicleColor }}</div>
          <div class="col-sm-1 col-lg-1">{{ transponder.VehicleYear }}</div>
          <div class="col-sm-2 col-lg-2" tooltip="Delete" placement="right">
            <a
              role="button"
              style="float: right; cursor: pointer"
              (click)="removeVehicle(ind)"
              >[-]</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!newAccountMode">
    <div class="form-group row pull-right">
      <button class="btn mx-3" (click)="cancelRequest()" type="button">
        {{ 'CANCEL' | translate }}
      </button>
      <button
        class="btn btn-app-primary"
        [disabled]="!transponderForm.form.valid || isRequesting"
        type="submit"
      >
        {{ (newMode ? 'REQUEST' : 'UPDATE') | translate }}
        <i
          class="fa fa-lg fa-spinner fa-pulse align-middle"

          *ngIf="isRequesting"
        ></i>
      </button>
    </div>
  </div>
</form>

<div
  class="modal fade"
  id="autoInfoModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="autoInfoModalLabel"

>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body auto-popup">
        <div class="container-fluid">
          <div class="row">
            <p>
              <strong>Note: Auto Group </strong> includes: passenger car, van,
              motorcycle, station wagon, SUV, pick-up truck, and school bus.<br />
              <strong>Truck Group</strong> includes: tractor trailers, buses and
              step or cube vans. <br /><strong>RV Group</strong> includes motor
              homes. Please select group based on vehicle with MacPass (i.e.
              lead vehicle).
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template
  #macPassModal
  let-modal
  let-c="close"
  let-d="dismiss"
  class="macPassModal p-3"
>
  <div *ngIf="errorObj">
    <h6
      class="
        modal-header
        text-center
        align-middle
        px-2
        d-flex
        justify-content-center
      "
      [ngClass]="{
        'text-danger': !errorObj.status,
        ' text-success': errorObj.status
      }"
    >
      <div class="d-inline align-middle">
        <i
          class="d-inline align-middle px-2 fa fa-times-circle fa-2x"
          *ngIf="!errorObj.status"
        ></i>
        <i
          class="d-inline align-middle px-2 fa fa-check-circle fa-2x"
          *ngIf="errorObj.status"
        ></i>
        <h5>{{ errorObj.title }}</h5>
      </div>
    </h6>

    <p class="modal-body text-center">{{ errorObj.message }}</p>

    <button
      class="m-2 btn float-right"
      data-dismiss="macPassModal"
      aria-label="Close"
      (click)="modal.close()"
      [ngClass]="{
        'btn-danger': !errorObj.status,
        'btn-success': errorObj.status
      }"
    >
      Close
    </button>
  </div>
</ng-template>
