import { Transponder } from "./transponder";

export class TransponderExport extends Transponder {
  TransponderProfileID: number;
  Transponder: string;
  TransponderActive: string;
  TollCategoryName: string;
  PlateHolderFirstName: string;
  PlateHolderLastName: string;
  FulFilled: string;
  FulFilledLDatetime: Date;
}
