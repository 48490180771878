export class WebUserCardInfo {
  AccountId: string;
  CardId: number;
  PaymentNum: string;
  BankCardTokenId: string;
  BankCardTokenExpiryDate: Date;
  CardAlias: string;
  CardType: number;
  CardStatus: boolean;
}
