/* eslint-disable prefer-const */
import { cloneDeep } from 'lodash-es';
import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  NgZone,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Account,
  AccountService,
  Address,
  AddressComponentC,
  ConfigurationService,
  CountryInfo,
  Options,
  ProvinceInfo,
} from '@ibitoll/toll-core';
import { combineLatest } from 'rxjs';
import { SubSink } from 'subsink';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddressConfirmationComponent } from '../modals/address-confirmation/address-confirmation.component';
import { TranslateService } from '@ngx-translate/core';

declare let google: any;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoading = false;
  sameBillingAsMailingChecked = false;

  editAccount = false;

  countryOptions: Array<CountryInfo>;
  provinceOptions: Array<ProvinceInfo>;

  bilingProvinces: ProvinceInfo[] = [];
  mailingProvinces: ProvinceInfo[] = [];

  public addressInfoFormGroup: FormGroup;
  public account: Account;

  private subs = new SubSink();
  setReadOnly = true;
  public options: Options = new Options();

  addressConfirmationPopeModal: NgbModalRef;
  mailingAddressComponents: Address;

  private autocomplete: unknown;
  private eventListener: unknown;

  public appartmentNote; //' - Please include apartment/unit number in the address field below.';

  constructor(
    private modalService: NgbModal,
    private ngZone: NgZone,
    private accountService: AccountService,
    public configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    public translationService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.options.types = [];
    this.options.componentRestrictions = { country: 'CA' };
  }

  ngOnInit(): void {
    this.appartmentNote = this.translationService.instant(
      'ADDRESS_APPARTMENT_NOTE'
    );

    this.addressInfoFormGroup = this.formBuilder.group({
      mailCountry: ['2', Validators.required],
      mailAddress: ['', Validators.required],
      mailCity: ['', Validators.required],
      mailProvince: ['', Validators.required],
      mailPostalCode: ['', Validators.required],
      sameBillingAsMailing: [''],
      billCountry: ['', Validators.required],
      billAddress: ['', Validators.required],
      billCity: ['', Validators.required],
      billProvince: ['', Validators.required],
      billPostalCode: ['', Validators.required],
    });

    this.subs.add(
      this.addressInfoFormGroup.controls['mailCountry'].valueChanges.subscribe(
        (data) => {
          if (this.provinceOptions) {
            this.mailingProvinces = this.provinceOptions.filter(
              (x) => x.CountryId === data
            );

            // Place ONTARIO on first 
            // let index;
            // this.mailingProvinces.forEach((v, i) => {
            //   if (v.Code === 'ON') {
            //     index = i;
            //   }
            // });

            // if (index) {
            //   this.mailingProvinces.unshift(
            //     this.mailingProvinces.splice(index, 1)[0]
            //   );
            // }
          }

          const pattern = this.getPostalCodePattern(data);

          if (pattern) {
            this.addressInfoFormGroup.controls['mailPostalCode'].setValidators([
              Validators.required,
              Validators.pattern(pattern),
            ]);
          }
          this.addressInfoFormGroup.controls[
            'mailPostalCode'
          ].updateValueAndValidity();
        }
      )
    );

    this.subs.add(
      this.addressInfoFormGroup.controls['billCountry'].valueChanges.subscribe(
        (data) => {
          if (this.provinceOptions) {
            this.bilingProvinces = this.provinceOptions.filter(
              (x) => x.CountryId === data
            );

            // let index;
            // this.bilingProvinces.forEach((v, i) => {
            //   if (v.Code === 'ON') {
            //     index = i;
            //   }
            // });

            // if (index) {
            //   this.bilingProvinces.unshift(
            //     this.bilingProvinces.splice(index, 1)[0]
            //   );
            // }
          }

          const pattern = this.getPostalCodePattern(data);
          if (pattern) {
            this.addressInfoFormGroup.controls['billPostalCode'].setValidators([
              Validators.required,
              Validators.pattern(pattern),
            ]);
          }
          this.addressInfoFormGroup.controls[
            'billPostalCode'
          ].updateValueAndValidity();
        }
      )
    );

    this.subs.add(
      combineLatest(
        this.addressInfoFormGroup.controls['mailCountry'].valueChanges,
        this.addressInfoFormGroup.controls['mailAddress'].valueChanges,
        this.addressInfoFormGroup.controls['mailCity'].valueChanges,
        this.addressInfoFormGroup.controls['mailProvince'].valueChanges,
        this.addressInfoFormGroup.controls['mailPostalCode'].valueChanges
      ).subscribe((data) => {
        if (
          this.addressInfoFormGroup.get('mailCountry').valid &&
          this.addressInfoFormGroup.get('mailAddress').valid &&
          this.addressInfoFormGroup.get('mailCity').valid &&
          this.addressInfoFormGroup.get('mailProvince').valid &&
          this.addressInfoFormGroup.get('mailPostalCode').valid
        ) {
          this.addressInfoFormGroup.controls['sameBillingAsMailing'].enable();
          this.addressInfoFormGroup.controls[
            'sameBillingAsMailing'
          ].updateValueAndValidity();
        } else {
          this.addressInfoFormGroup.controls['sameBillingAsMailing'].disable();
          this.addressInfoFormGroup.controls[
            'sameBillingAsMailing'
          ].updateValueAndValidity();
        }
      })
    );

    this.subs.add(
      this.addressInfoFormGroup.controls[
        'sameBillingAsMailing'
      ].valueChanges.subscribe((data) => {
        if (data) {
          this.addressInfoFormGroup
            .get('billCountry')
            .setValue(this.addressInfoFormGroup.controls['mailCountry'].value);
          this.addressInfoFormGroup.get('billCountry').disable();

          this.addressInfoFormGroup
            .get('billAddress')
            .setValue(this.addressInfoFormGroup.controls['mailAddress'].value);
          this.addressInfoFormGroup.get('billAddress').disable();

          this.addressInfoFormGroup
            .get('billCity')
            .setValue(this.addressInfoFormGroup.controls['mailCity'].value);
          this.addressInfoFormGroup.get('billCity').disable();

          this.addressInfoFormGroup
            .get('billProvince')
            .setValue(this.addressInfoFormGroup.controls['mailProvince'].value);
          this.addressInfoFormGroup.get('billProvince').disable();

          this.addressInfoFormGroup
            .get('billPostalCode')
            .setValue(
              this.addressInfoFormGroup.controls['mailPostalCode'].value
            );
          this.addressInfoFormGroup.get('billPostalCode').disable();
        } else {
          if (this.canEditAccount()) {
            this.addressInfoFormGroup.get('billCountry').enable();
            this.addressInfoFormGroup.get('billAddress').enable();
            this.addressInfoFormGroup.get('billCity').enable();
            this.addressInfoFormGroup.get('billProvince').enable();
            this.addressInfoFormGroup.get('billPostalCode').enable();
          }
        }
      })
    );

    this.subs.add(
      this.configurationService.getCountries().subscribe((countries) => {
        this.countryOptions = countries;
        this.refreshCountries();

        this.configurationService.getProvinces().subscribe((provinces) => {
          this.addressInfoFormGroup
            .get('mailCountry')
            .setValue(this.countryOptions[0].Id);
          //this.provinceOptions = cloneDeep(provinces);
          const province = cloneDeep(provinces);
          this.provinceOptions = province.sort((a, b) => {
            return a.ProvinceOrderId - b.ProvinceOrderId; 
          });
          //console.log('sortProv='+JSON.stringify(this.provinceOptions));
          this.refreshProvinces();
        });
      })
    );
  }

  ngAfterViewInit() {
    this.subs.add(
      this.accountService.activeAccount.subscribe((account: Account) => {
        this.account = account;
        setTimeout(() => {
          this.ngZone.run(() => {
            this.updateFormvalue();
          });
        });

        if (account) {
          // this.setReadOnly = false;
          this.editForm(false);
        }

        this.isLoading = false;
        this.cdr.detectChanges();
      })
    );
  }

  public onChange(address: Address) {
    const currentTypedAddress =
      this.addressInfoFormGroup.controls['mailAddress'].value;
    this.mailingAddressComponents = address;
    const unitNo = this.getComponentByType(address, 'subpremise');
    const streetNumber = this.getComponentByType(address, 'street_number');
    const streetNumberSN = streetNumber ? streetNumber.short_name : '';
    const street = this.getComponentByType(address, 'route');
    const streetSN = street ? street.short_name : '';
    const city = this.getComponentByType(address, 'locality');
    const citySN = city ? city.short_name : '';
    const state = this.getComponentByType(
      address,
      'administrative_area_level_1'
    );
    const stateSN = state ? state.short_name : '';
    const zip = this.getComponentByType(address, 'postal_code');
    const zipSN = zip ? zip.short_name : '';

    const country = this.getComponentByType(address, 'country').long_name;

    this.addressConfirmationPopeModal = this.modalService.open(
      AddressConfirmationComponent
    );

    this.addressConfirmationPopeModal.componentInstance.mailingAddressComponents =
      address;
    this.addressConfirmationPopeModal.componentInstance.confirmStatus.subscribe(
      (data) => {
        this.addressConfirmationPopeModal.dismiss();

        if (data) {
          const address =
            this.mailingAddressComponents.formatted_address.split(',');
          //const country = address[3].trim();
          const mP = this.provinceOptions.find((o) => o.Code === stateSN);
          const mC = this.countryOptions.find((o) => o.Name === country);

          this.addressInfoFormGroup.controls['mailCountry'].setValue(mC.Id);

          this.addressInfoFormGroup.controls['mailAddress'].setValue(address[0].trim());

          this.addressInfoFormGroup.controls['mailCity'].setValue(citySN);

          this.addressInfoFormGroup.controls['mailProvince'].setValue(mP.Id);

          this.addressInfoFormGroup.controls['mailPostalCode'].setValue(zipSN);

        } else {
          this.addressInfoFormGroup.controls['mailAddress'].setValue(
            currentTypedAddress
          );
        }
      }
    );
  }

  public onBillingChange(address: Address) {
    const currentTypedAddress =
      this.addressInfoFormGroup.controls['billAddress'].value;

    this.mailingAddressComponents = address;
    const unitNo = this.getComponentByType(address, 'subpremise');
    const streetNumber = this.getComponentByType(address, 'street_number');
    const streetNumberSN = streetNumber ? streetNumber.short_name : '';
    const street = this.getComponentByType(address, 'route');
    const streetSN = street ? street.short_name : '';
    const city = this.getComponentByType(address, 'locality');
    const citySN = city ? city.short_name : '';
    const state = this.getComponentByType(
      address,
      'administrative_area_level_1'
    );
    const stateSN = state ? state.short_name : '';
    const zip = this.getComponentByType(address, 'postal_code');
    const zipSN = zip ? zip.short_name : '';
    const country = this.getComponentByType(address, 'country').long_name;


    this.addressConfirmationPopeModal = this.modalService.open(
      AddressConfirmationComponent
    );
    this.addressConfirmationPopeModal.componentInstance.mailingAddressComponents =
      address;
    this.addressConfirmationPopeModal.componentInstance.confirmStatus.subscribe(
      (data) => {
        this.addressConfirmationPopeModal.dismiss();

        if (data) {
          const address =
            this.mailingAddressComponents.formatted_address.split(',');
          //const country = address[3].trim();
          const mP = this.provinceOptions.find((o) => o.Code === stateSN);
          const mC = this.countryOptions.find((o) => o.Name === country);

          this.addressInfoFormGroup.controls['billCountry'].setValue(mC.Id);

          this.addressInfoFormGroup.controls['billAddress'].setValue(address[0].trim());

          this.addressInfoFormGroup.controls['billCity'].setValue(citySN);

          this.addressInfoFormGroup.controls['billProvince'].setValue(mP.Id);

          this.addressInfoFormGroup.controls['billPostalCode'].setValue(zipSN);

        } else {
          this.addressInfoFormGroup.controls['billAddress'].setValue(
            currentTypedAddress
          );
        }
      }
    );
  }

  public getComponentByType(address: Address, type: string): AddressComponentC {
    if (!type) return null;

    if (
      !address ||
      !address.address_components ||
      address.address_components.length == 0
    )
      return null;

    type = type.toLowerCase();

    for (let comp of address.address_components) {
      if (!comp.types || comp.types.length == 0) continue;

      if (comp.types.findIndex((x) => x.toLowerCase() == type) > -1)
        return comp;
    }

    return null;
  }

  get addressInfo() {
    return this.addressInfoFormGroup.controls;
  }

  updateFormvalue() {
    this.ngZone.run(() => {
      this.addressInfoFormGroup
        .get('mailAddress')
        .setValue(this.account.MailingAddress);
      this.addressInfoFormGroup
        .get('mailCity')
        .setValue(this.account.MailingCity);
      this.addressInfoFormGroup
        .get('mailPostalCode')
        .setValue(this.account.MailingPostalCode);

      this.addressInfoFormGroup
        .get('sameBillingAsMailing')
        .setValue(this.account.SameBillingAsMailingAddress);

      if (this.account.SameBillingAsMailingAddress) {
        this.addressInfoFormGroup
          .get('billAddress')
          .setValue(this.account.MailingAddress);
        this.addressInfoFormGroup
          .get('billCity')
          .setValue(this.account.MailingCity);
        this.addressInfoFormGroup
          .get('billPostalCode')
          .setValue(this.account.MailingPostalCode);
      } else {
        this.addressInfoFormGroup
          .get('billAddress')
          .setValue(this.account.BillingAddress);
        this.addressInfoFormGroup
          .get('billCity')
          .setValue(this.account.BillingCity);
        this.addressInfoFormGroup
          .get('billPostalCode')
          .setValue(this.account.BillingPostalCode);
      }

      setTimeout(() => {
        this.refreshCountries();
        setTimeout(() => {
          this.refreshProvinces();
        }, 100);
      }, 100);
    });
  }

  editForm(enableEdit: boolean) {
    this.setReadOnly = !enableEdit;
    this.editAccount = enableEdit;
    if (this.setReadOnly) {
      this.addressInfoFormGroup.get('mailCountry').disable();
      this.addressInfoFormGroup.get('mailAddress').disable();
      this.addressInfoFormGroup.get('mailCity').disable();
      this.addressInfoFormGroup.get('mailProvince').disable();
      this.addressInfoFormGroup.get('mailPostalCode').disable();

      this.addressInfoFormGroup.get('sameBillingAsMailing').disable();

      this.addressInfoFormGroup.get('billCountry').disable();
      this.addressInfoFormGroup.get('billAddress').disable();
      this.addressInfoFormGroup.get('billCity').disable();
      this.addressInfoFormGroup.get('billProvince').disable();
      this.addressInfoFormGroup.get('billPostalCode').disable();
    } else {
      this.addressInfoFormGroup.get('mailCountry').enable();
      this.addressInfoFormGroup.get('mailAddress').enable();
      this.addressInfoFormGroup.get('mailCity').enable();
      this.addressInfoFormGroup.get('mailProvince').enable();
      this.addressInfoFormGroup.get('mailPostalCode').enable();

      this.addressInfoFormGroup.get('sameBillingAsMailing').enable();

      if (!this.account?.SameBillingAsMailingAddress) {
        this.addressInfoFormGroup.get('billCountry').enable();
        this.addressInfoFormGroup.get('billAddress').enable();
        this.addressInfoFormGroup.get('billCity').enable();
        this.addressInfoFormGroup.get('billProvince').enable();
        this.addressInfoFormGroup.get('billPostalCode').enable();
      }
    }
    this.addressInfoFormGroup.updateValueAndValidity();
  }

  refreshCountries() {
    if (this.account != null && this.countryOptions != null) {
      const mailingCountry = this.countryOptions.find(
        (x) => x.Id === this.account.MailingCountryID
      );

      if (mailingCountry) {
        this.addressInfoFormGroup
          .get('mailCountry')
          .setValue(mailingCountry.Id);
        this.addressInfoFormGroup.get('mailCountry').updateValueAndValidity();
      }

      if (this.account.SameBillingAsMailingAddress) {
        this.addressInfoFormGroup
          .get('billCountry')
          .setValue(mailingCountry.Id);
        this.addressInfoFormGroup.get('billCountry').updateValueAndValidity();
      } else {
        const billingCountry = this.countryOptions.find(
          (x) => x.Id === this.account.BillingCountryID
        );

        if (billingCountry) {
          this.addressInfoFormGroup
            .get('billCountry')
            .setValue(billingCountry.Id);
          this.addressInfoFormGroup.get('billCountry').updateValueAndValidity();
        }
      }
    }
  }

  refreshProvinces() {
    if (this.account != null && this.provinceOptions != null) {
      const mailingProvince = this.provinceOptions.find(
        (x) => x.Id === this.account.MailingProvinceID
      );

      if (mailingProvince) {
        this.addressInfoFormGroup
          .get('mailProvince')
          .setValue(mailingProvince.Id);
        this.addressInfoFormGroup.get('mailProvince').updateValueAndValidity();
      }

      if (this.account.SameBillingAsMailingAddress) {
        this.addressInfoFormGroup
          .get('billProvince')
          .setValue(mailingProvince.Id);
        this.addressInfoFormGroup.get('billProvince').updateValueAndValidity();
      } else {
        const billingProvince = this.provinceOptions.find(
          (x) => x.Id === this.account.BillingProvinceID
        );

        if (billingProvince) {
          this.addressInfoFormGroup
            .get('billProvince')
            .setValue(billingProvince.Id);
          this.addressInfoFormGroup
            .get('billProvince')
            .updateValueAndValidity();
        }
      }
    }
  }

  getPostalCodePattern(countryId: number) {
    if (this.countryOptions && countryId) {
      const country = this.countryOptions.find((x) => x.Id === countryId);

      if (country !== undefined) {
        if (country.Name === 'USA') {
          return '(\\d{5}([\\-]\\d{4})?)';
        } else if (country.Name === 'Canada') {
          return '[A-Za-z][0-9][A-Za-z]* ?[0-9][A-Za-z][0-9]';
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  checkStatus(fromConName: string) {
    return this.addressInfoFormGroup.controls[fromConName].disabled;
  }

  filterProvinces(countryId: number) {
    this.bilingProvinces = this.provinceOptions.filter(
      (x) => x.CountryId === countryId
    );
  }

  canEditAccount(): boolean {
    return this.editAccount;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
