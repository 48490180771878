<div class="container-12">
  <form [formGroup]="blukTransponderInfoFormGroup">
    <div class="col-12 p-0">
      <div class="card card-own-transponder">
        <div class="card-body">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 pl-3">

              <div class="form-group mb-3"
                   *ngIf="!hideTransponderType">
                <label for="transponderType"
                       class="form-label float-start">{{
                  'TAG_TYPE' | translate
                  }}</label>
                <select class="form-control form-select"
                        id="transponderType"
                        name="transponderType"
                        formControlName="transponderType">
                  <option *ngFor="let transponderFee of transponderFees | orderBy: 'id' : true "
                          [ngValue]="transponderFee.TransponderTypeId"
                          class="auto-dropdown">
                    {{ transponderFee.TransponderTypeName | translate }}
                  </option>

                </select>
              </div>

              <div class="mb-3"
                   *ngIf="!hideCategory">
                <label id="category-bulktransponder-label"
                       for="category-{{unique}}sss"
                       class="form-label  float-start">{{
                  'CATEGORY' | translate
                  }}</label>
                <select class="form-control form-select"
                        id="category-{{unique}}sss"
                        name="category"
                        formControlName="category"
                        required>
                  <option *ngFor="let tollCategory of tollCategories | orderBy: 'id'"
                          [ngValue]="tollCategory.Id"
                          class="auto-dropdown">
                    {{ tollCategory.GroupName }}
                  </option>
                </select>
              </div>

              <div class="form-group pt-2 text-start">
                <label for="quantity"
                       class="col-form-label col-form-label-sm">
                  {{ 'QUANTITY_OF_TAGS' | translate }}
                </label><!-- Quantity of Transponders Required -->
                <div class="row">
                  <div class="col-12">
                    <div class="input-group mb-3">
                      <button class="btn btn-outline-secondary"
                              type="button"
                              (click)="incrementQ()"
                              aria-label="increment QUANTITY">
                        <i class="fa fa-plus"></i>
                      </button>
                      <input OnlyNumber="true"
                             type="number"
                             class="form-control"
                             id="quantity"
                             formControlName="quantity"
                             name="quantity"
                             onkeypress="return event.charCode != 32 || event.target.selectionStart !=0" />
                      <button class="btn btn-outline-secondary"
                              type="button"
                              (click)="decremenQ()"
                              aria-label="decremen QUANTITY">
                        <i class="fa fa-minus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group pt-3">
                <ng-content select="[total]"></ng-content>
              </div>

              <div class="form-group pt-2 text-center">
                <ng-content select="[insufficientNote]"></ng-content>

              </div>
              <div class="form-group p-0">
                <ng-content select="[cancelbtn]"></ng-content>
                <ng-content select="[submitbtn]"></ng-content>
              </div>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
