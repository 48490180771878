//transponder provider
export const TRAN_HD = 'CAHD';
export const TRAN_SJ = 'CASJ';
export const TRAN_AH = 'CAAH';
export const TRAN_61 = '0061';
export const TRAN_102 = '0102';
export const TRAN_104 = '0104';
export const TRAN_CON = '19E1';

export const TRANSPONDERPROVIDERMAP = {
  CAAH: 1,
  CASJ: 2,
  CAHD: 3,
  '0061': 6,
  '19E1': 7,
  '0102': 5, //102, //5,
  '0104': 4, //104, //4,
};

export const TRANSPONDERTYPE = {
  New: 1,
  Own: 2,
  Bulk: 3,
  Other: 4,
};
