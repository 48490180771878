import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, timeout, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AddTollAccountQuery } from '../models/addTollAccountQuery';
import { ChangePasswordQuery } from '../models/changePasswordQuery';
import { EInvoiceStatusUpdateQuery } from '../models/EInvoiceStatusUpdateQuery';
import { EnableWebUserAccountMobileQuery } from '../models/enableWebUserAccountMobileQuery';
import { EnableWebUserAccountQuery } from '../models/enableWebUserAccountQuery';
import {
  CURRENT_USER,
  TOKEN,
  USER_LOGON_ID,
  ACTIVE_ACCOUNT,
} from '../models/keyConstand';
import { NewAccountRequest } from '../models/newAccountRequest';
import { ValidateAccountQuery } from '../models/validateAccountQuery';
import { VerifyUsername } from '../models/verifyUsername';
import { WebAccessQuery } from './../models/webAccessQuery';
import { AccountService } from './account.service';
import { LocalstorageService } from './localstorage.service';
import { ProxyService } from './proxy.service';
import { ReCaptchaService } from './re-captcha.service';
import { TransponderValidationResult } from '../models/transponder';

@Injectable({
  providedIn: 'root',
})
export class WebAccessService implements OnDestroy {
  private endpointUrl: string;
  private webAccessEndpoint = 'api/WebAccess';
  helper = new JwtHelperService();

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private subs = new SubSink();

  constructor(
    private http: HttpClient,
    private reCaptchaService: ReCaptchaService,
    private proxy: ProxyService,
    private localstorageService: LocalstorageService,
    private accountService: AccountService
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  requestNewAccount(
    newAccountRequest: NewAccountRequest
  ): Observable<WebAccessQuery> {
    return this.reCaptchaService.refresh('NewAccount').pipe(
      switchMap(() => {
        const url =
          this.endpointUrl + this.webAccessEndpoint + '/NewAccountRequest';
          console.log('Calling: ' + url);
        return this.http
          .post(url, newAccountRequest, { headers: this.headers })
          .pipe(
            timeout(300000),
            map((resp: WebAccessQuery) => {
              return resp;
            })
          );
      })
    );
  }

  validateTollAccount(
    validateAccountQuery: ValidateAccountQuery
  ): Observable<string> {
    return this.reCaptchaService.refresh('ValidateTollAccount').pipe(
      switchMap(() => {
        return this.http.post<string>(
          this.endpointUrl + this.webAccessEndpoint + '/ValidateTollAccount',
          JSON.stringify(validateAccountQuery),
          { headers: this.headers }
        );
      })
    );
  }

  resetUsernameByEmail(email: string): Observable<boolean> {
    return this.reCaptchaService.refresh('ResetUsernameByEmail').pipe(
      switchMap(() => {
        const params = new HttpParams().set('email', email);
        return this.http.get<boolean>(
          this.endpointUrl + this.webAccessEndpoint + '/ResetUsernameByEmail',
          { headers: this.headers, params }
        );
      })
    );
  }

  enableWebUserAccount(
    enableWebUserAccountQuery: EnableWebUserAccountQuery
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.webAccessEndpoint + '/EnableWebUserAccount',
      JSON.stringify(enableWebUserAccountQuery),
      { headers: this.headers }
    );
  }

  enableWebUserAccountMobile(
    enableWebUserAccountQuery: EnableWebUserAccountMobileQuery
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.webAccessEndpoint + '/EnableWebUserAccountMobile',
      JSON.stringify(enableWebUserAccountQuery),
      { headers: this.headers }
    );
  }

  requestLinkAccount(
    requestLinkAccountQuery: ValidateAccountQuery
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.webAccessEndpoint + '/RequestLinkAccount',
      JSON.stringify(requestLinkAccountQuery),
      { headers: this.headers }
    );
  }

  linkTollAccount(
    addTollAccountQuery: AddTollAccountQuery
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.webAccessEndpoint + '/LinkTollAccount',
      JSON.stringify(addTollAccountQuery),
      { headers: this.headers }
    );
  }

  sendActivationEmail(webAccessQuery: WebAccessQuery): Observable<string> {
    return this.reCaptchaService.refresh('SendActivationEmail').pipe(
      switchMap(() => {
        return this.http.post<string>(
          this.endpointUrl + this.webAccessEndpoint + '/SendActivationEmail',
          JSON.stringify(webAccessQuery),
          { headers: this.headers }
        );
      })
    );
  }

  sendVerificationCodeEmail(
    webAccessQuery: WebAccessQuery
  ): Observable<WebAccessQuery> {
    return this.http.post<WebAccessQuery>(
      this.endpointUrl + this.webAccessEndpoint + '/SendVerificationCodeEmail',
      JSON.stringify(webAccessQuery),
      { headers: this.headers }
    );
  }

  //COB Only
  isValidOwnTransponderID(transponderID: any): Observable<boolean> {
    return this.reCaptchaService.refresh('IsValidOwnTransponderID').pipe(
      switchMap(() => {
        return this.http.get<boolean>(
          this.endpointUrl +
            this.webAccessEndpoint +
            `/IsValidOwnTransponderID/${transponderID.toString()}`,
          { headers: this.headers }
        );
      })
    );
  }

  // HHB +
  validateOwnTransponderID(transponderID: any): Observable<TransponderValidationResult> {
    return this.reCaptchaService.refresh('ValidateOwnTransponder').pipe(
      switchMap(() => {
        return this.http.get<TransponderValidationResult>(
          this.endpointUrl +
            this.webAccessEndpoint +
            `/ValidateOwnTransponder/${transponderID.toString()}`,
          { headers: this.headers }
        );
      })
    );
  }

  // EPT Only
  sentRegisterAccountEmail(webAccessQuery: WebAccessQuery): Observable<string> {
    return this.reCaptchaService.refresh('sentregisteraccountemail').pipe(
      switchMap(() => {
        return this.http.post<string>(
          this.endpointUrl +
            this.webAccessEndpoint +
            '/sentregisteraccountemail',
          webAccessQuery,
          { headers: this.headers }
        );
      })
    );
  }

  // EPT Only
  forgotAccountInfo(webAccessQuery: WebAccessQuery): Observable<boolean> {
    return this.reCaptchaService.refresh('forgotaccountinfo').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl + this.webAccessEndpoint + '/forgotaccountinfo',
          webAccessQuery,
          { headers: this.headers }
        );
      })
    );
  }

  // EPT Only
  forgotPassword(webAccessQuery: WebAccessQuery): Observable<boolean> {
    return this.reCaptchaService.refresh('forgotpassword').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl + this.webAccessEndpoint + '/forgotpassword',
          webAccessQuery,
          { headers: this.headers }
        );
      })
    );
  }

  // EPT Only
  public GetUserConfiguration(key: string) {
    return this.http
      .get<string>(
        this.endpointUrl + this.webAccessEndpoint + `/getUserToken/${key}`,
        { headers: this.headers }
      )
      .pipe(
        switchMap((tokenResponse) => {
          const subject = new Subject<boolean>();
          const UserToken = tokenResponse.slice(7);
          if (UserToken !== 'undefined' && UserToken !== 'null' && UserToken) {
            this.localstorageService.setItem(TOKEN, UserToken);
            const token = this.helper.decodeToken(UserToken);
            this.localstorageService.setItem(USER_LOGON_ID, token.unique_name);
            this.localstorageService.setItem(ACTIVE_ACCOUNT, token.AccountId);
            this.setCurrentUser(token.UserProfile);
            setTimeout(() => {
              subject.next(true);
              subject.complete();
            });
            return subject;
          } else {
            subject.error('Unable to verify');
            subject.complete();
            return subject;
          }
        })
      );
  }

  public setTokenData(UserToken: any) {
    const subject = new Subject<boolean>();

    if (UserToken !== 'undefined' && UserToken !== 'null' && UserToken) {
      this.localstorageService.setItem(TOKEN, UserToken);
      const token = this.helper.decodeToken(UserToken);
      this.localstorageService.setItem(USER_LOGON_ID, token.unique_name);
      this.localstorageService.setItem(ACTIVE_ACCOUNT, token.AccountId);
      this.setCurrentUser(token.UserProfile);
      setTimeout(() => {
        subject.next(true);
        subject.complete();
      }, 100);
    }

    return subject;
  }

  getDecryptedCode(webAccessCode: string): any {
    console.log('getDecryptedCode '+ webAccessCode);
    return this.reCaptchaService.refresh('GetDecryptedCode').pipe(
      switchMap(() => {
        return this.http.get<WebAccessQuery>(
          this.endpointUrl +
            this.webAccessEndpoint +
            `/GetDecryptedCode/${webAccessCode}`,
          { headers: this.headers }
        );
      })
    );
  }

  postDecryptedCode(webAccessCode: string): any {
    const parm = new ChangePasswordQuery();
    parm.Uid = webAccessCode;
    return this.reCaptchaService.refresh('GetDecryptedCode').pipe(
      switchMap(() => {
        return this.http.post<WebAccessQuery>(
          this.endpointUrl + this.webAccessEndpoint + `/GetDecryptedCode`,
          JSON.stringify(parm),
          { headers: this.headers }
        );
      })
    );
  }

  checkUsername(userName: string): Observable<boolean> {
    const parm = new VerifyUsername();
    parm.username = userName;

    return this.reCaptchaService.refresh('checkPassword').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl + this.webAccessEndpoint + `/VerifyUsername`,
          JSON.stringify(parm),
          { headers: this.headers }
        );
      })
    );
  }

  setCurrentUser(userString: string) {
    this.localstorageService.setItem(CURRENT_USER, userString);
    this.accountService.setLoadTime(new Date());
  }

  updateEInvoiceStatus(updateQuery: EInvoiceStatusUpdateQuery) {
    return this.http.post(
      this.endpointUrl + this.webAccessEndpoint + '/UpdateEInvoiceStatus',
      updateQuery,
      { headers: this.headers }
    );
  }

  getTerms(): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    return this.http.get<Blob>(
      this.endpointUrl + this.webAccessEndpoint + '/getTerms',
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
