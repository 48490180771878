import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { ErrorDto } from '../models/errorDto';
import { IdNameConfig } from '../models/idNameConfig';
import { ProxyService } from './proxy.service';
import { ReCaptchaService } from './re-captcha.service';
import { switchMap } from 'rxjs/operators';
//import { UtilsService } from '@ibitoll/toll-core/src';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class LogService implements OnDestroy {
  private endpointUrl: string;
  private logEndpoint = 'api/Log';
  private localLogEndpoint = 'AO-web-log/Log';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private subs = new SubSink();

  constructor(
    private http: HttpClient,
    private proxy: ProxyService,
    //private utilsService: UtilsService,
    private reCaptchaService: ReCaptchaService
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  logMessage(logMessage: string): void {
    this.hashLogLine(logMessage).then(hash => {
      const logQuery: IdNameConfig = new IdNameConfig();
      logQuery.Name = logMessage;
      logQuery.Description = hash;
      this.reCaptchaService.refresh('logMessage').pipe(
        switchMap(() => {
          const nHeaders = this.headers.append('X-Client-Ref', this.encodeDateToken());
          return this.http 
            .post<string>(
              this.endpointUrl + this.logEndpoint + '/LogMessage',
              JSON.stringify(logQuery),
              { headers: nHeaders }
            )
        })).subscribe(() => {
  
        }, error =>{
  
        })
    });
  }

  logMsg(logMessage: string): void {
    const logQuery: IdNameConfig = new IdNameConfig();
    logQuery.Name = logMessage;
    this.reCaptchaService
      .refresh('LogMsg')
      .pipe(
        switchMap(() => {
          return this.http.post<string>(
            this.endpointUrl + this.logEndpoint + '/LogMsg',
            JSON.stringify(logQuery),
            { headers: this.headers }
          );
        })
      )
      .subscribe(
        () => {},
        (error) => {}
      );
  }

  hashLogLine(text: string): Promise<string> {
    // Encode the text as a Uint8Array
    const encoder = new TextEncoder();
    const data = encoder.encode(text);

    // Hash the data with SHA-256
    if (window.crypto && window.crypto.subtle) {
      return crypto.subtle.digest('SHA-256', data).then(hashBuffer => {
          // Convert the ArrayBuffer to a hex string
          const hashArray = Array.from(new Uint8Array(hashBuffer));
          const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
          return this.getHash4(hashHex);
      });
    } else {
      return Promise.resolve('');
    }
  }

  calculateSHA256Hash(fields): string {
    const concatenatedString = Object.values(fields).join('');
    const hash = CryptoJS.SHA256(concatenatedString);
    return hash.toString(CryptoJS.enc.Hex);
  }

  getHash4(hashHex: string): string {
    // Pick characters at positions 1st, 5th, 11th, etc., and reverse the string
    let result = '';
    for (let i = 0; i * 4 < hashHex.length; i++) {
        result += hashHex.charAt(i * 4); // 4n gives positions 0, 4, 8, 12, which are effectively 1st, 5th, 9th, 13th if you count from 1
    }
    return result.split('').reverse().join(''); // Reverse the result string
  }
  
  getShortHash(text: string): string {
    return this.getHash4(this.calculateSHA256Hash(text));
  }


  encodeDateToken(): string { // check DecodeData in C# \IBI.Framework.CustomerWebsite\Filters\WebsiteAuthFilter.cs
    const dateString = (new Date()).toISOString() + '#SecureToken';
    const firstEncode = btoa(dateString);  // First base64 encoding

    // Swap each pair of characters
    let swapped = '';
    for (let i = 0; i < firstEncode.length; i += 2) {
        if (i + 1 < firstEncode.length) {
            swapped += firstEncode[i + 1] + firstEncode[i];
        } else {
            // Handle odd length by adding the last character as is
            swapped += firstEncode[i];
        }
    }

    // Encode again
    return btoa(swapped);
  }  

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
