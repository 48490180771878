import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { PaymentProviderInfo } from '../models/paymentProviderInfo';
import { PrepPaymentQuery } from '../models/prepPaymentQuery';
import { TokenizedStoredCard } from '../models/TokenizedStoredCard';
import { TransactionResponse } from '../models/transactionResponse';
import { ProxyService } from './proxy.service';

@Injectable({
  providedIn: 'root',
})
export class AlphaPaymentService implements OnDestroy {
  private endpointUrl: string;

  private configurationEndpoint = 'api/alphaPaym';

  private subs = new SubSink();

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient, private proxy: ProxyService) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  getTxnReceipt(orderId: string): Observable<TransactionResponse> {
    return this.http
      .post<TransactionResponse>(
        this.endpointUrl + this.configurationEndpoint + '/GetTxnReceipt',
        JSON.stringify(orderId),
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getPaymProviderInfo(): Observable<PaymentProviderInfo> {
    return this.http
      .get<PaymentProviderInfo>(
        this.endpointUrl + this.configurationEndpoint + '/GetPaymProviderInfo',
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  prepareAlphaBankPayment(
    query: PrepPaymentQuery
  ): Observable<PrepPaymentQuery> {
    return this.http
      .post<PrepPaymentQuery>(
        this.endpointUrl + this.configurationEndpoint + '/PrepAlphaPayment',
        query,
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getStoredCards(): Observable<TokenizedStoredCard[]> {
    return this.http
      .get<TokenizedStoredCard[]>(
        this.endpointUrl + this.configurationEndpoint + '/GetStoredCards',
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  deleteCard(id: string) {
    //console.log(id);
    return this.http
      .get(
        this.endpointUrl +
          this.configurationEndpoint +
          '/DeleteStoredCard/' +
          id,
        { headers: this.headers }
      )
      .pipe(
        map((resp: boolean) => {
          return resp;
        })
      );
  }

  saveCard(card: TokenizedStoredCard) {
    //console.log(id);
    return this.http
      .post(
        this.endpointUrl + this.configurationEndpoint + '/SaveStoredCard',
        card,
        { headers: this.headers }
      )
      .pipe(
        map((resp: boolean) => {
          return resp;
        })
      );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
