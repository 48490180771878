<nav class="navbar navbar-expand-custom pt-2">

  <div class="container nav nav-bx" ><!-- nav-bx - 5 px line  style="overflow: auto;"-->

    <!-- <div class="mx-md-4 pt-4"> -->
      <!-- <div class="row">
        <div class="col-md-3"> -->
  
    
          <a class="navbar-brand ps-2 py-3"
            (click)="logoClick()"
            (dblclick)="logoDoubleClick()"
            href="#"
            routerLink="/login">
            <img class="header-logo rounded"
                src="{{ configService.config.LogoImageUrl | translate }}"
                alt="{{ configService.config.ClientName}} Logo" />
          </a>
          <!-- </div>
          <div class="col-lg-9"> -->
            
          <!-- sandwitch button -->
          <button class="navbar-toggler me-3"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarScroll"
                  aria-controls="navbarScroll"
                  aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <!-- /sandwitch button -->

          <div class="collapse navbar-collapse p-1"
              id="navbarScroll">
            <ul class="navbar-nav mx-auto py-1">
              <li *ngFor="let navItem of NavLinks"
                  class="nav-item mx-1 text-center"
                  [ngClass]="{ dropdown: !utilsService.isLink(navItem) }">

                <div *ngIf="utilsService.isLink(navItem); then link; else sublink"></div>

                <ng-template #link>
                  <a *ngIf="utilsService.isUrl(navItem.Url | translate); else url"
                    class="nav-link nav-text nav-hover nav-background"
                    routerLinkActive="active"
                    [routerLink]="navItem.Url">
                    {{ navItem.Name | translate }}
                  </a>

                  <ng-template #url>
                    <a class="nav-link nav-text nav-hover nav-background"
                      routerLinkActive="active"
                      target="_blank"
                      href="{{ navItem.Url | translate }}">{{ navItem.Name | translate }}
                    </a>
                  </ng-template>
                </ng-template>

                <ng-template #sublink>
                  <a *ngIf="utilsService.isUrl(navItem.Url | translate); else url"
                    class="nav-link dropdown-toggle nav-text nav-hover nav-background"
                    routerLinkActive="active"
                    [routerLink]="navItem.Url"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown">
                    {{ navItem.Name | translate }}
                  </a>

                  <div class="dropdown-menu"
                      aria-labelledby="navbarDropdown">
                    <div *ngFor="let subLinks of navItem.SubLinks">
                      <a class="dropdown-item"
                        href="{{ subLinks.Url | translate }}">
                        {{ subLinks.Name | translate }}</a>
                    </div>
                  </div>

                  <ng-template #url>
                    <a class="nav-link nav-text nav-hover nav-background"
                      routerLinkActive="active"
                      target="_blank"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown">
                      {{ navItem.Name | translate }}
                    </a>

                    <div class="dropdown-menu"
                        aria-labelledby="navbarDropdown">
                      <div *ngFor="let subLinks of navItem.SubLinks">
                        <a class="dropdown-item"
                          href="{{ subLinks.Url | translate }}">
                          {{ subLinks.Name | translate }}</a>
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </li>
              <!--This is for sandwitch only -->
              <li class="nav-item d-sm-block d-lg-none text-center"
                  *ngIf="isLoggedIn">
                <span class="nav-link"
                      [routerLink]="['/userpassword']"
                      aria-label="Toggle navigation">
                  {{ 'CHANGE_PASSWORD' | translate }}
                </span>
              </li>

              <li class="nav-item d-sm-block d-lg-none text-center"
                  *ngIf="isLoggedIn">
                <a class="nav-link"
                  (click)="logOut()"
                  aria-label="Toggle navigation">
                  {{ 'LOGOUT' | translate }}
                </a>
              </li>
              <!--/This is for sandwitch only -->
            </ul>
            <form class="d-flex"
                  *ngIf="!isLoggedIn">
              <button class="btn btn-app-primary me-3"
                      routerLinkActive="active"
                      routerLink="/login">
                <i aria-hidden="true"
                  class="fa fa-user-circle pe-1"></i>
                <span class="ps-1 btn-login--text">
                  {{ 'LOGIN' | translate }}
                </span>
              </button>
            </form>

            <!--This is for wide screeny -->
            <form class="d-flex me-3 d-none d-lg-block"
                  *ngIf="isLoggedIn">
              <ul class=" navbar-nav text-center rounded">
                <li class="nav-item dropdown">
                  <a class="dropdown-toggle btn btn-primary user-control"
                    href="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    [attr.aria-label]="getCurrentUser()?.UserName "
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    (focus)="toggleSubmenu($event)"
                    (mouseenter)="mouseenter()"
                    (mouseleave)="mouseleave()">
                    <i aria-hidden="true"
                      class="fa fa-user-circle pe-1"></i>
                    {{ getCurrentUser()?.UserName }}
                  </a>

                  <ul class="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink">
                    <li>
                      <span class="dropdown-item"
                            [routerLink]="['/userpassword']">
                        <i class="fa fa fa-pencil-square-o pe-1"></i>
                        {{ 'CHANGE_PASSWORD' | translate }}
                      </span>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <a class="dropdown-item"
                        routerLink="/login"
                        (click)="logOut()">
                        <i class="fa fa-power-off pe-1"></i>{{ 'LOGOUT' | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </form>
          </div>
        <!-- </div> -->
      <!-- </div> -->
    <!-- </div>  -->
</div>  

</nav>
