import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Alert, AlertType } from './../models/alert';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private keepAfterRouteChange = false;
  private subject = new Subject<Alert>();
  messageCode: string;

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  getAlert(): Observable<Alert> {
    return this.subject.asObservable();
  }

  successTranslate(
    messageCode: string,
    autoClose: boolean,
    keepAfterRouteChange = false
  ) {
    this.translateService
      .get(messageCode)
      .subscribe((text) => this.success(text, autoClose, keepAfterRouteChange));
  }

  errorTranslate(
    messageCode: string,
    autoClose = false,
    keepAfterRouteChange = false,
    postion: string = 'toast-bottom-center'
  ) {
    if (this.messageCode !== messageCode) {
      this.messageCode = messageCode;
      this.translateService
        .get(messageCode)
        .subscribe((text) =>
          this.alert(
            AlertType.Error,
            text,
            autoClose,
            keepAfterRouteChange,
            postion
          )
        );
    } else {
      setTimeout(() => {
        this.messageCode = '';
      }, 500);
    }
  }

  warnTranslate(
    messageCode: string,
    autoClose: boolean,
    keepAfterRouteChange = false
  ) {
    this.translateService
      .get(messageCode)
      .subscribe((text) =>
        this.alert(AlertType.Warning, text, autoClose, keepAfterRouteChange)
      );
  }
  success(message: string, autoClose: boolean, keepAfterRouteChange = false) {
    this.alert(AlertType.Success, message, autoClose, keepAfterRouteChange);
  }

  error(message: string, autoClose: boolean, keepAfterRouteChange = false) {
    this.alert(AlertType.Error, message, autoClose, keepAfterRouteChange);
  }

  info(message: string, autoClose: boolean, keepAfterRouteChange = false) {
    this.alert(AlertType.Info, message, autoClose, keepAfterRouteChange);
  }

  warn(message: string, autoClose: boolean, keepAfterRouteChange = false) {
    this.alert(AlertType.Warning, message, autoClose, keepAfterRouteChange);
  }

  alert(
    type: AlertType,
    message: string,
    autoClose: boolean,
    keepAfterRouteChange = false,
    postion: string = 'toast-bottom-center'
  ) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({
      type,
      message,
      autoClose,
      position: postion,
    });
  }

  clear() {
    this.messageCode = '';
    this.subject.next(null);
  }
}
