import { Injectable, OnDestroy } from '@angular/core';

import { LocalstorageService } from './localstorage.service';
import { Globals } from './globals.service';
import { Storage } from '@ionic/storage-angular';
import { SecureStorageService } from './securestroage.service';
import { SETTINGS_KEY } from '../models/keyConstand';
import { SecureSessionStorageService } from './securesessionstroage.service';

@Injectable({
  providedIn: 'root',
})
export class MobileLocalstorageService
  implements LocalstorageService, OnDestroy
{
  public toggleStorage: boolean = false;

  private _storage: Storage | null = null;

  constructor(
    public secureStorageService: SecureStorageService,
    public secureSessionStorageService: SecureSessionStorageService,
    public storage: Storage,
    public globals: Globals
  ) {
    this.init();
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  load() {
    return new Promise((resolve) => {
      if (this._storage == null) {
        this.init().then(() => {
          this._storage
            .forEach((value, key, index) => {
              this.globals.settings[key] = value;
            })
            .then((value) => {
              resolve(true);
            });
        });
      } else {
        this._storage
          .forEach((value, key, index) => {
            this.globals.settings[key] = value;
          })
          .then((value) => {
            resolve(true);
          });
      }
    });
  }

  setItem(key: string, value: string) {
    this.globals.settings[key] = value;
    return this._storage.set(key, value).then((data) => {});
  }

  getItem(key: string): string {
    if (this.globals.settings && this.globals.settings[key]) {
      return this.globals.settings[key];
    } else {
      return null;
    }
  }

  removeItem(key: string): void {
    delete this.globals.settings[key];
    this._storage.remove(key).then((data) => {});
  }

  check(key: string): boolean {
    return this.globals.settings[key] !== undefined;
  }

  clear(): void {
    this.globals.settings = [];
    this._storage.clear().then((data) => {});
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    this._storage
      .set(SETTINGS_KEY, JSON.stringify(this.globals.settings))
      .then((data) => {});
  }
}
