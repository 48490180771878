import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransponderRequest } from '@ibitoll/toll-core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tag-add-confirmation-modal',
  templateUrl: './tag-add-confirmation.component.html',
  styleUrls: ['./tag-add-confirmation.component.scss']
})
export class TagAddConfirmationComponent implements OnInit {

  @Input() transponderReq: TransponderRequest;

  @Output() onTransponderUnSuspendedOut: EventEmitter<TransponderRequest> = new EventEmitter();

  isRequesting: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onTransponderUnSuspended(transponder) {
    this.isRequesting = true;
    this.onTransponderUnSuspendedOut.emit(this.transponderReq);
  }


}
