export class AccountInfo {
  AccountID: number;
  AccountProfile: string;
  FirstName: string;
  LastName: string;
  AccountBalance: number;
  AccountStatus: number;
  BalanceStatus: string;
  EInvoiceStatus: string;
  Email: string;
}
