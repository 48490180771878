<div class="row gx-0 sub-container">
  <div class="d-none d-lg-block col-lg-6">
    <img src="{{ configService.config.BackgroundImageUrl | translate }}" class="w-100 h-100 logo-img "
      alt="{{ configService.config.ClientName}}" />
  </div>
  <div class="col-xs-12 col-md-12 col-lg-6 gx-xs-2">
    <div class="container-fluid top-nav float-end">
      <div class="row">
        <div class="col min-top">
          <language-selector class="float-end"></language-selector>
        </div>
      </div>
    </div>
    <ng-content class="wa-form"></ng-content>
  </div>
</div>
