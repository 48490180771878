import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationError, NavigationCancel } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService,ThemeService } from '@ibitoll/toll-core';

import jsonEn from '../assets/i18n/en.json';
import jsonFr from '../assets/i18n/fr.json';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { OutdateBrowserCheckService } from './core/services/outdate-browser-check.service';

declare var gtag: Function;

@Component({
  selector: 'ibitoll-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public hideNavBar = false;
  public MaintenanceFlag = false;
  currenSelectedLanguage = 'en';
  isFocus = false;
  @ViewChild('content')
  mainContent!: ElementRef;
  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;


  constructor(
    public outdateBrowserCheckService: OutdateBrowserCheckService,
    public configurationService: ConfigurationService,
    private translateService: TranslateService,
    public themeService: ThemeService,
    private titleService: Title,
    private _renderer2: Renderer2,
    private router: Router,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(DOCUMENT) adoc
  ) {
    this.router.events.subscribe((evn) => {
      if (evn instanceof NavigationEnd) {
        if (
          evn.urlAfterRedirects === '/login' ||
          evn.urlAfterRedirects === '/webaccess' ||
          evn.urlAfterRedirects === '/signup' ||
          evn.urlAfterRedirects === '/forgotpassword/request' ||
          evn.urlAfterRedirects.includes('/ActivateWebAccount') ||
          evn.urlAfterRedirects.includes('/ChangePassword') ||
          evn.urlAfterRedirects.includes('/paymentviolation') ||
          evn.urlAfterRedirects.includes('/accountagreement') ||
          evn.urlAfterRedirects.includes('/forgotusername/request') ||
          evn.urlAfterRedirects.includes('/message/msg') ||
          evn.urlAfterRedirects.includes('/closure/closure') ||
          evn.urlAfterRedirects.includes('returnUrl') ||
          evn.urlAfterRedirects.includes('/payment/chase-wrapper')
        ) {
          this.hideNavBar = false;
        } else {
          this.hideNavBar = true;
        }

        if (evn.urlAfterRedirects === '/maintenance'){
          this.MaintenanceFlag = true;
        }
        else{
          this.MaintenanceFlag = false;
        }
      }

      if (evn instanceof NavigationStart) {
        this.loading = true;
      }


      if (evn instanceof NavigationEnd || evn instanceof NavigationCancel || evn instanceof NavigationError) {
        this.loading = false;

      }

    });

    const jsonEnData = jsonEn as any;
    this.translateService.setTranslation('en', jsonEnData, true);

    const jsonElData = jsonFr as any;
    this.translateService.setTranslation('fr', jsonElData, true);

    this.currenSelectedLanguage = this.configurationService.GetClientLanguage();
    if (this.currenSelectedLanguage) {
      this.translateService.setDefaultLang(this.currenSelectedLanguage);
      this.translateService.addLangs(['en', 'fr']);
      this.translateService.use(this.currenSelectedLanguage);
    } else {
      this.translateService.setDefaultLang('en');
      this.translateService.addLangs(['en', 'fr']);
      this.translateService.use('en');
    }

    adoc.documentElement.lang = this.currenSelectedLanguage == 'fr' ? 'en' : this.currenSelectedLanguage;
    document.documentElement.setAttribute('xml:lang', this.currenSelectedLanguage == 'fr' ? 'en' : this.currenSelectedLanguage);

    this.outdateBrowserCheckService.ngOnInit();
  }
  ngOnInit(): void {

    this.appendGaTrackingCode();

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.titleService.setTitle(this.configurationService.config.ClientName + ' - ' + this.getNestedRouteTitles().join(' | '));
    });
  }

  getNestedRouteTitles(): string[] {
    let currentRoute = this.router.routerState.root.firstChild;
    const titles: string[] = [];

    while (currentRoute) {
      if (currentRoute.snapshot.routeConfig.data?.title) {
        titles.push(currentRoute.snapshot.routeConfig.data.title);
      }

      currentRoute = currentRoute.firstChild;
    }

    return titles;
  }

  getLastRouteTitle(): string {
    let currentRoute = this.router.routerState.root.firstChild;

    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    return currentRoute.snapshot.data?.title;
  }

  skipToMain() {
    this.mainContent.nativeElement.focus();
  }

  onActivate(event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

 // Google Analytics
 appendGaTrackingCode() {
  if (!this.themeService.active.GoogleAnalyticsKey) {
    console.log('Google andalytics disabled');
    return;
  }
  try {
    const scriptlib = this._renderer2.createElement('script');
    scriptlib.setAttribute('data-cookieconsent', 'statistics');
    scriptlib.setAttribute('type', 'text/plain');
    scriptlib.async = true;
    scriptlib.src =
      'https://www.googletagmanager.com/gtag/js?id=' +
      this.themeService.active.GoogleAnalyticsKey;
    this._renderer2.appendChild(this._document.head, scriptlib);

    const script = this._renderer2.createElement('script');
    script.setAttribute('data-cookieconsent', 'statistics');
    script.setAttribute('type', 'text/plain');
    script.innerHTML =
      `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '` +
      this.themeService.active.GoogleAnalyticsKey +
      `');
          `;
    this._renderer2.appendChild(this._document.head, script);
  } catch (ex) {
    console.error('Error appending google analytics');
    console.error(ex);
  }

  // subscribe to router events and send page views to Google Analytics
  this.router.events.subscribe((event) => {
    try {
      if (event instanceof NavigationEnd && gtag) {
        gtag('config', this.themeService.active.GoogleAnalyticsKey, {
          page_path: event.urlAfterRedirects,
        });
      }
    } catch (error) {}
  });
}

}
