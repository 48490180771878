import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { CanComponentDeactivate } from '../interface/cancomponentdeactivate.interface';

@Injectable({
  providedIn: 'root',
})
export class WebAccessGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor() {}

  canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate && component.canDeactivate();
  }
}
