import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '@ibitoll/toll-core';
import { environment } from '../../../environments/environment';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'mdot-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year: number;
  seconds: number;
  envAppVersion = environment.config.appVersion;

  constructor(public configService: ConfigurationService) {}

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.seconds = (new Date()).getTime(); // to prevent link caching
  }

  isUrl(url: string): boolean {
    if (url.charAt(0) === '/' && url !== '/home') {
      return true;
    } else {
      return false;
    }
  }

  isLink(navItem: any) {
    if (navItem.SubLinks) {
      return false;
    } else {
      return true;
    }
  }
}
