import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthServiceProvider } from '@ibitoll/toll-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CsrguardGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthServiceProvider
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.authService.isCsr()) {
      this.router.navigate(['/home']);
      return false;
    } else {
      return true;
    }
  }
}
