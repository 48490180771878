import { Injectable } from '@angular/core';
import { LANGUAGE_KEY } from '../models/keyConstand';

import { LocalstorageService } from './localstorage.service';
import { SecureSessionStorageService } from './securesessionstroage.service';
import { SecureStorageService } from './securestroage.service';

@Injectable({
  providedIn: 'root',
})
export class SessionstorageService implements LocalstorageService {
  public toggleStorage: boolean = false;

  constructor(public secureStorageService: SecureStorageService, public secureSessionStorageService: SecureSessionStorageService) {

  }

  load() {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  setItem(key: string, value: string): void {
    this.secureSessionStorageService.setData(key, value);
  }

  getItem(key: string): string {
    return this.secureSessionStorageService.getData(key);
  }

  removeItem(key: string): void {
    this.secureSessionStorageService.removeItems(key);
  }

  check(key: string): boolean {
    return this.secureSessionStorageService.checkIsExist(key);
  }

  clear(): void {
    if (this.secureSessionStorageService.checkIsExist(LANGUAGE_KEY)) {
      const value = this.getItem(LANGUAGE_KEY);
      if (!this.toggleStorage) {
        sessionStorage.clear();
      } else {
        this.secureSessionStorageService.destroy();
      }
      this.setItem(LANGUAGE_KEY, value);
    } else {
      if (!this.toggleStorage) {
        sessionStorage.clear();
      } else {
        this.secureSessionStorageService.destroy();
      }
    }

  }
}
