import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cob-account-agreement',
  templateUrl: './account-agreement.component.html',
  styleUrls: ['./account-agreement.component.scss'],
})
export class AccountAgreementComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
