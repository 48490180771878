import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ibiCurrency' })
export class IbiCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(
    val: number,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string {
    if (val !== undefined && val !== null) {
      let formatted = this.currencyPipe.transform(
        val,
        currencyCode,
        display,
        digitsInfo,
        locale
      );
      const euro = String.fromCharCode(8364);

      if (formatted.indexOf(euro) > 0) {
        // AO removing space as thousand separator

        const nbsp = String.fromCharCode(160);
        const nbspRX = new RegExp(String.fromCharCode(160), 'g');

        formatted = formatted.replace(' ', '').replace(nbspRX, '');
        if (currencyCode) {
          formatted = formatted.replace(euro, nbsp + euro);
        }
      }
      return formatted;
    } else {
      return '';
    }
  }
}
