<div class="container-12">
  <div class="row">
    <div class="col-12">
      <form #myForm="ngForm"
            [formGroup]="contactInfoFormGroup">
        <fieldset>
          <legend class="form-label account-label mb-1 h3 fw-bold">
            {{ 'CONTACT' | translate }}
          </legend>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-6 mb-sm-3">
              <label for="primaryPhone"
                     class="form-label mb-1"
                     id="primaryPhoneNumber">{{ 'PRIMARY_NUMBER' | translate }}
                <span class="non-ng-invalid-mark"
                      *ngIf="getControllerStatus('primaryPhone')">*</span>
              </label>
              <input type="text"
                     onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                     class="form-control"
                     id="primaryPhone"
                     placeholder="(000) 000-0000"
                     mask="(000) 000-0000"
                     [showMaskTyped]="false"
                     formControlName="primaryPhone"
                     onDrag="return false"
                     onDrop="return false"
                     onPaste="return false"
                     required
                     aria-required="true"
                     aria-describedby="primaryPhoneNumberInvalidCheck"
                     [attr.aria-label]="'PRIMARY_NUMBER' | translate"
                     [ngClass]="{ 'is-invalid': canEditAccount() && contactInfo.primaryPhone.touched && contactInfo.primaryPhone.invalid}" />

              <div id="primaryPhoneNumberInvalidCheck"
                   class="invalid-feedback"
                   *ngIf="canEditAccount() && contactInfo.primaryPhone?.errors?.required">
                {{ 'PRIMARY_NUMBER_REQUIRED' | translate }}
              </div>


              <div id="primaryPhoneNumberInvalidCheck"
                   class="invalid-feedback"
                   *ngIf="  canEditAccount() && contactInfo.primaryPhone?.errors?.maxlength">
                {{'PRIMARY_NUMBER_REQUIRED_19_CHARACTERS' | translate}}
              </div>

              <div id="primaryPhoneNumberInvalidCheck"
                   class="invalid-feedback"
                   *ngIf="   canEditAccount() && contactInfo.primaryPhone?.errors?.mask">
                {{ 'PRIMARY_NUMBER_REQUIRED_10_CHARACTERS' | translate }}
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <label for="primaryPhoneExt"
                     class="form-label mb-1 mt-3 mt-md-0">
                {{ 'EXT' | translate }}</label>
              <input type="text"
                     onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                     [readonly]="!canEditAccount()"
                     class="form-control inline-block"
                     id="primaryPhoneExt"
                     [attr.aria-label]="'EXT' | translate"
                     formControlName="primaryPhoneExt"
                     name="primaryPhoneExt"
                     maxlength="5"
                     onDrag="return false"
                     onDrop="return false"
                     onPaste="return false" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-12 col-md-6">
              <label for="altPhone"
                     class="form-label mb-1">{{
                'SECONDARY_PHONE_NUMBER' | translate
                }}</label>
              <input type="text"
                     onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                     [readonly]="!canEditAccount()"
                     class="form-control"
                     id="altPhone"
                     placeholder="(000) 000-0000"
                     mask="(000) 000-0000"
                     [showMaskTyped]="false"
                     formControlName="altPhone"
                     name="altPhone"
                     maxlength="19"
                     onDrag="return false"
                     onDrop="return false"
                     onPaste="return false"
                     [ngClass]="{ 'is-invalid': canEditAccount() && contactInfo.altPhone.touched && contactInfo.altPhone.invalid}"  />
              
              <div class="invalid-feedback"
                   *ngIf="canEditAccount() && contactInfo.altPhone?.errors?.mask">
                {{ 'SECONDARY_NUMBER_REQUIRED_10_CHARACTERS' | translate }}
              </div>

            </div>
            <div class="col-sm-12 col-md-6">
              <label for="altPhoneExt"
                     class="form-label mb-1 mt-3 mt-md-0">{{
                'EXT' | translate
                }}</label>
              <input type="text"
                     onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                     [readonly]="!canEditAccount()"
                     class="form-control inline-block"
                     id="altPhoneExt"
                     [attr.aria-label]="'EXT' | translate"
                     formControlName="altPhoneExt"
                     name="altPhoneExt"
                     maxlength="5"
                     onDrag="return false"
                     onDrop="return false"
                     onPaste="return false" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-12 col-md-6">
              <label for="mobile"
                     class="form-label mb-1">{{
                'MOBILE' | translate
                }}</label>
              <input type="text"
                     onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                     [readonly]="!canEditAccount()"
                     class="form-control"
                     id="mobile"
                     placeholder="(000) 000-0000"
                     mask="(000) 000-0000"
                     [showMaskTyped]="false"
                     formControlName="mobile"
                     name="mobile"
                     maxlength="19"
                     onDrag="return false"
                     onDrop="return false"
                     onPaste="return false"
                     [ngClass]="{ 'is-invalid': canEditAccount() && contactInfo.mobile.touched && contactInfo.mobile.invalid}" />

              <div class="invalid-feedback"
                   *ngIf="canEditAccount() && contactInfo.mobile?.errors?.mask">
                {{ 'MOBILE_NUMBER_REQUIRED_10_CHARACTERS' | translate }}
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <label for="fax"
                     class="form-label mb-1 mt-3 mt-md-0">{{
                'FAX' | translate
                }}</label>
              <input type="text"
                     onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                     [readonly]="!canEditAccount()"
                     class="form-control"
                     id="fax"
                     placeholder="(000) 000-0000"
                     mask="(000) 000-0000"
                     [showMaskTyped]="false"
                     formControlName="fax"
                     name="fax"
                     maxlength="19"
                     onDrag="return false"
                     onDrop="return false"
                     onPaste="return false" 
                     [ngClass]="{ 'is-invalid': canEditAccount() && contactInfo.fax.touched && contactInfo.fax.invalid}"/>

              <div class="invalid-feedback"
                     *ngIf="canEditAccount() && contactInfo.fax?.errors?.mask">
                  {{ 'FAX_NUMBER_REQUIRED_10_CHARACTERS' | translate }}
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-12 col-md-6">
              <label for="sms"
                     class="form-label mb-1">{{
                'SMS' | translate
                }}
                <!-- <i class="fa fa-info-circle fa-lg infomation-icon"
                   tabindex="0"
                   placement="right"
                   [ngbTooltip]="smsMsg"
                   attr.aria-label="{{ 'ACCOUNT_STATUS_TOOL_TIP' | translate }}"></i> -->
              </label>
              <input type="text"
                     onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                     [readonly]="!canEditAccount()"
                     class="form-control"
                     id="mobile"
                     placeholder="(000) 000-0000"
                     mask="(000) 000-0000"
                     [showMaskTyped]="false"
                     formControlName="sms"
                     name="sms"
                     maxlength="19"
                     onDrag="return false"
                     onDrop="return false"
                     onPaste="return false"
                     [ngClass]="{ 'is-invalid': canEditAccount() && contactInfo.sms.touched && contactInfo.sms.invalid}" />

              <div class="invalid-feedback"
                     *ngIf="canEditAccount() && contactInfo.sms?.errors?.mask">
                  {{ 'SMS_NUMBER_REQUIRED_10_CHARACTERS' | translate }}
              </div>

              <span 
                     class="form-label m-0 h5">
                     This number will be used to send out text messages
               </span>
            </div>


          </div>

          <div class="row mb-3">
            <div class="col-sm-12 col-md-6">
              <label for="emailMain"
                     class="form-label mb-1">{{ 'EMAIL_ADDRESS' | translate }}
                <span class="non-ng-invalid-mark">*</span>
              </label>
              <input 
                     [readonly]="!canEditAccount()"
                     class="form-control"
                     
                     formControlName="emailMain"
                     
                     maxlength="80"
                     (keydown.space)="$event.preventDefault()"
                     required
                     aria-required="true"
                     aria-describedby="invalidCheckEmail"
                     [ngClass]="{ 'is-invalid': canEditAccount()
                     && contactInfo.emailMain.invalid
                     && (contactInfo.emailMain.dirty || contactInfo.emailMain.touched)}" /><!-- id="email" name="email"  type="email"   -->

              <div id="invalidCheckEmail"
                   class="invalid-feedback"
                   *ngIf="contactInfo.emailMain?.errors?.required">
                {{ 'EMAIL_ADDRESS_REQUIRED' | translate }}
              </div>

              <div id="invalidCheckEmail"
                   class="invalid-feedback"
                   *ngIf="contactInfo.emailMain?.errors?.maxlength">
                {{ 'EMAIL_ADDRESS_REQUIRED_80_CHARACTERS' | translate }}
              </div>


              <div id="invalidCheckEmail"
                   class="invalid-feedback"
                   *ngIf="contactInfo.emailMain?.errors?.pattern">
                {{ 'EMAIL_ADDRESS_INVALID' | translate }}
              </div>

              <div id="invalidCheckEmail"
                   class="invalid-feedback"
                   *ngIf="contactInfo.emailMain?.errors?.email">
                {{ 'EMAIL_ADDRESS_INVALID' | translate }}
              </div>

              <div id="invalidCheckEmail"
                   class="invalid-feedback"
                   *ngIf="contactInfo.emailMain?.errors?.inValidSeparator">
                {{ 'TWO_EMAIL_INVALID_SEPARATOR' | translate }}
              </div>


              <div id="invalidCheckEmail"
                   class="invalid-feedback"
                   *ngIf="contactInfo.emailMain?.errors?.inValidSecondEmail">
                {{ 'TWO_EMAIL_ADDRESS_INVALID' | translate }}
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <label for="altemail"
                     class="form-label mb-1 mt-3 mt-md-0">
                {{ 'SECONDARY_EMAIL_ADDRESS' | translate }}
              </label>
              <input type="email"
                     [readonly]="!canEditAccount()"
                     class="form-control"
                     id="altemail"
                     [attr.aria-label]="'SECONDARY_EMAIL_ADDRESS' | translate"
                     formControlName="altemail"
                     name="altemail"
                     maxlength="80"
                     (keydown.space)="$event.preventDefault()"
                     [ngClass]="{ 'is-invalid': canEditAccount()
                     && contactInfo.altemail.invalid
                     && (contactInfo.altemail.dirty || contactInfo.altemail.touched)}" />

              <div *ngIf="
                  canEditAccount() &&
                  contactInfo.altemail.invalid &&
                  (contactInfo.altemail.dirty || contactInfo.altemail.touched)
                "
                   class="fade show"
                   role="alert">
                <div *ngIf="contactInfo.altemail?.errors?.maxlength">
                  <small>{{
                    'SECONDARY_EMAIL_ADDRESS_REQUIRED_80_CHARACTERS' | translate
                    }}</small>
                </div>
                <div *ngIf="contactInfo.altemail?.errors?.pattern" style="color: red;" class="mt-1">
                  <small>{{
                    'SECONDARY_EMAIL_ADDRESS_INVALID' | translate
                    }}</small>
                </div>

                <div id="invalidCheckEmail"
                     class="invalid-feedback"
                     *ngIf="contactInfo.altemail?.errors?.email">
                  {{ 'SECONDARY_EMAIL_ADDRESS_INVALID' | translate }}
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</div>

<ng-template #smsMsg>
  <p>
    This number will be used to send out text messages
  </p>
</ng-template>
