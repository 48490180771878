import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthServiceProvider } from '@ibitoll/toll-core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'user-inactivity',
  templateUrl: './user-inactivity.component.html',
  styleUrls: ['./user-inactivity.component.scss'],
})
export class UserInactivityComponent {
  count = 0;

  @ViewChild('content') contentModal: ElementRef;

  currentRoute: string;
  activeModal: NgbModalRef;
  isShown = false;

  constructor(
    private authService: AuthServiceProvider,
    private router: Router,
    private userIdle: UserIdleService,
    @Inject(DOCUMENT) private _document,
    private modalService: NgbModal
  ) {
    this.router.events.subscribe((evn) => {
      if (evn instanceof NavigationEnd) {
        this.currentRoute = evn.urlAfterRedirects;
        if (evn.urlAfterRedirects == '/home') {
          this.watchUserActivity();
        }

        if (evn.urlAfterRedirects == '/login') {
          this.userIdle.stopWatching();
        }
      }
    });
  }

  watchUserActivity() {
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
      if (!this.isShown && count) {
        this.showChildModal();
      }
      this.count = 60 - count;
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      // this.authService.logout().subscribe(() => {
      //   this.count = 0;
      //   this.hideChildModal();
      //   this.router.navigate(['/login']);
      // });
      this.logOut();
    });
  }

  restart() {
    this.hideChildModal();
    this.count = 0;
    this.userIdle.resetTimer();
  }

  showChildModal(): void {
    this.isShown = true;
    this.activeModal = this.modalService.open(this.contentModal);
    this.activeModal.shown.subscribe(() => {
      this.isShown = true;
    });

    this.activeModal.hidden.subscribe(() => {
      setTimeout(() => {
        this.isShown = false;
      }, 2000);
    });
  }

  hideChildModal(): void {
    if (this.isShown) {
      this.activeModal.dismiss();
    }
  }

  logOut() {
    this.restart();
    this.modalService.dismissAll();
    this.hideChildModal();
    this.count = 0;
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }
}
