<div *ngIf="!integrated"
     class="section-header mb-4">
  <h1 class="h4">{{ 'ACCOUNT' | translate }}</h1>
</div>

<div [class.container]="!integrated"
     class="h-100 mx-4">
  <div class="row mt-2 h-100 border-line-right">
    <div class="col-lg-8 col-md-12"
         style="border-right: 1px solid #eeeeee">
      <div class="pb-1"
           style="border-bottom: 1px solid #eeeeee"
           aria-hidden="true">
        <h1 class="ibi-bold h2 mb-0 fw-bold" >
          <span *ngIf="account?.AccountType == 'P'">{{ account?.FirstName }} </span>
          {{ account?.LastName }}
        </h1>
        <span class="pl-1">
          {{'ACCOUNT_NO' | translate}}{{ account?.AccountID }} 
        </span>
        <!-- Inactive (suspended) account status -->
        <span *ngIf="!showRestrictionStatusInSummary && account?.RestrictionStatus==='S' && account?.AccountStatus != 5" class="ibi-bold fw-bold">
          {{ 'SUSPENDED_ACC_STATUS' | translate }}
          <!-- {{account?.RestrictionStatus | mapType: 'eAccountRestrictionStatusMap' | translate}}  -->
        </span>
        <!-- Closed account status -->
        <span *ngIf="!showAccStatusInSummary && account?.AccountStatus == 5" class="ibi-bold fw-bold text-danger">
          {{ 'CLOSED_ACC_STATUS' | translate }}
            <!-- {{account?.AccountStatus | mapType: 'eAccountStatusMap' | translate}}  -->
        </span>

      </div>

      <form *ngIf="account"
            class="mt-2">
        <div class="row">
          <div *ngIf="showBalanceInSummary" class="col-6">
            <div class="my-3"
                 tabindex="-1">
              <div class="ibi-bold fw-bold">
                <currency-type></currency-type>{{ 'BALANCE' | translate }}
              </div>
              <div
                   [ngClass]="{'text-danger' :  account?.BalanceStatus == 3, 'text-warning': account?.BalanceStatus == 2, 'text-success' :  account?.BalanceStatus == 1}">
                <span>{{
                  parseAmount(account?.AccountBalance)
                  | currency: themeService.active.DefaultCurrency
                  }}</span>
                <span>
                  /
                  {{
                  account?.BalanceStatus
                  | mapType: 'eBalanceStatusMap'
                  | translate
                  }}</span>
              </div>
            </div>

          </div>
          <div *ngIf="showAccStatusInSummary" class="col-6">
            <div class="my-3"
                 tabindex="-1">
              <div class="ibi-bold fw-bold">
                {{ 'ACCOUNT_STATUS' | translate }}
                <i class="fa fa-info-circle fa-lg"
                   tabindex="0"
                   placement="top"
                   [ngbTooltip]="tolTemplate"
                   attr.aria-label="{{ 'ACCOUNT_STATUS_TOOL_TIP' | translate }}"></i>
              </div>
              <div>
                {{account?.AccountStatus | mapType: 'eAccountStatusMap' | translate}}
              </div>
            </div>
          </div>

          <div *ngIf="showRestrictionStatusInSummary" class="col-6">
            <div class="my-3"
                 tabindex="-1">
              <div class="ibi-bold fw-bold">
                {{ 'ACCOUNT_RESTRICTIONSTATUS' | translate }}
              </div>
              <div class=""
                   [ngClass]="{'res-danger': account?.RestrictionStatus==='S'}">
                <span>
                  {{account?.RestrictionStatus | mapType: 'eAccountRestrictionStatusMap' | translate}}
                </span>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="my-3"
                 tabindex="-1">
              <div class="ibi-bold fw-bold">
                {{ 'LAST_PAYMENT' | translate }}
              </div>
              <div>
                <currency-type></currency-type>
                {{parseAmount(account?.LastPaymentAmount) | currency: themeService.active.DefaultCurrency}}
                <span *ngIf="account?.LastPaymentAmount">on
                  {{
                  getDate(account?.LastPaymentDateTime) | dateTimeFormatter
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="my-3"
                 tabindex="-1">
              <div class="ibi-bold fw-bold">
                {{ 'TOTAL_TAGS' | translate }}
              </div>
              <div>{{ account?.TranspondersDistributed }}</div>
            </div>
          </div>
          <div class="col-6">
            <div class="my-3"
                 tabindex="-1">
              <div class="ibi-bold fw-bold">
                {{ 'REBILL_STATUS' | translate }}
              </div>
              <div>
                {{ isPreAuth() | translate }}
              </div>
            </div>
          </div>
          <div *ngIf="isHHB" class="col-6">
            <div class="my-3"
                 tabindex="-1">
              <div class="ibi-bold fw-bold">
                <!-- {{'MACPASSPLUSE_STATUS' |translate}} -->
                MACPASS<i>plus</i>
              </div>
              <div>
                {{ macPassPlusStatus() | translate }}
              </div>
            </div>
          </div>
          <!-- <div class="col-6" tabindex="-1">
            <div class="my-3">
              <div class="ibi-bold fw-bold">
                {{ 'TOTAL_REQUESTED' | translate }}
              </div>
              <div>
                {{ account?.TranspondersRequested }}
              </div>
            </div>
          </div> -->
        </div>
      </form>

      <div *ngIf="!account"
           class="text-center mb-3 loader">
        <i class="fa fa-4x fa-spinner fa-pulse align-middle"></i>
      </div>

      <div class="pull-right"
           *ngIf="!integrated">
        <a href="#"
           class="text-primary"
           routerLink="/AccountDetail">{{ 'ACCOUNT_DETAILS' | translate }}
          <i class="fas fa fa-caret-right"></i>
        </a>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 pb-2 text-center d-flex align-items-center">
      <div class="text-center w-100">
        <div *ngIf="account"
             class="card">
          <div class="card-body">
            <div>{{ 'CURRENT_BALANCE' | translate }}</div>
            <div [ngClass]="{'text-danger' :  account?.BalanceStatus == 3, 'text-warning': account?.BalanceStatus == 2, 'text-success' :  account?.BalanceStatus == 1}"
                 class="h1"
                 placement="top"
                 [ngbTooltip]="tipContent">
              <currency-type></currency-type>
              {{
              parseAmount(account?.AccountBalance)
              | currency: themeService.active.DefaultCurrency
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #tolTemplate>
  {{'ACCOUNT_STATUS_TOOL_TIP' | translate}}
</ng-template>

<ng-template #tipContent>
  <p>Green - Account balance is in good standing</p>
  <p>Yellow - Account balance is in good standing, but getting low. Transponders can still be used in the lanes.</p>
  <p>Red - Account balance is not in good standing and requires funds. Transponders cannot be used in the lanes.</p>
</ng-template>
