import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  EventEmitter,
  Injectable,
  OnDestroy,
  Output,
  OnInit,
  Injector,
  NgZone,
} from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Keepalive } from '@ng-idle/keepalive';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AccountInfo } from '../models/accountInfo';
import {
  CURRENT_USER,
  REFRESH_TOKEN,
  TOKEN,
  USER_LOGON_ID,
  ACTIVE_ACCOUNT,
} from '../models/keyConstand';
import { User } from '../models/user';
import { AccountService } from './account.service';
import { ConfigurationService } from './configuration.service';
import { LocalstorageService } from './localstorage.service';
import { LogService } from './log.service';
import { ProxyService } from './proxy.service';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { LogoutInfo } from '../models/logoutInfo';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TermsViewComponent } from '../components/terms-view/terms-view.component';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceProvider implements OnDestroy {
  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onLoginChanged: EventEmitter<boolean> = new EventEmitter();

  public isloggedIn = new BehaviorSubject<boolean>(false);
  public isKeepMeSignedIn: boolean = true;
  private endpointUrl: string;

  isloggedInProcess = false;

  helper = new JwtHelperService();

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private subs = new SubSink();

  modalReference: NgbModalRef;
  private modalService: NgbModal;

  constructor(
    private http: HttpClient,
    private proxy: ProxyService,
    private logService: LogService,
    private accountService: AccountService,
    public router: Router,
    private configurationService: ConfigurationService,
    private keepalive: Keepalive,
    private injector: Injector,
    private localstorageService: LocalstorageService,
    private zone: NgZone
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );

    this.keepalive.interval(5);
    this.keepalive.onPing.subscribe(() => this.checkloggedIn());

    this.loggedIn();
  }

  login(userName: string, password: string): any {
    this.localstorageService.removeItem(TOKEN);
    this.localstorageService.removeItem(REFRESH_TOKEN);
    this.localstorageService.removeItem(USER_LOGON_ID);

    this.isloggedInProcess = true;
    const loginHeaders = new HttpHeaders({
      'Content-Type': 'x-www-form-urlencoded',
      Audience: 'Any',
    });

    var clientId = this.configurationService?.config?.ClientId
      ? this.configurationService?.config?.ClientId
      : '123';

    const data = new URLSearchParams();
    data.append('username', encodeURIComponent(userName));
    data.append('password', encodeURIComponent(password));
    data.append('grant_type', 'password');
    data.append('client_id', clientId);
    data.append('client_secret', 'secret');
    data.append('stay_signed_in', this.isKeepMeSignedIn.toString());

    return this.http
      .post(this.endpointUrl + 'oauth2/token', data.toString(), {
        headers: loginHeaders,
      })
      .pipe(
        map((response) => {
          const loginResponse: any = response;

          this.localstorageService.setItem(TOKEN, loginResponse.access_token);

          this.localstorageService.setItem(USER_LOGON_ID, userName);

          if (loginResponse.refresh_token)
            this.localstorageService.setItem(
              REFRESH_TOKEN,
              loginResponse.refresh_token
            );

          const token = this.helper.decodeToken(loginResponse.access_token);

          this.setCurrentUser(token.UserProfile);

          this.isloggedIn.next(true);

          return JSON.parse(token.UserProfile);
        })
      );
  }

  loginWithOtp(
    userName: string,
    password: string,
    otp: string,
    isEmail: boolean
  ): any {
    this.isloggedInProcess = true;
    const loginHeaders = new HttpHeaders({
      'Content-Type': 'x-www-form-urlencoded',
      Audience: 'Any',
    });

    var clientId = this.configurationService?.config?.ClientId
      ? this.configurationService?.config?.ClientId
      : '123';

    const data = new URLSearchParams();
    data.append('username', encodeURIComponent(userName));
    data.append('password', encodeURIComponent(password));
    data.append('otp', encodeURIComponent(otp));
    data.append('isemail', encodeURIComponent(isEmail));
    data.append('grant_type', 'password');
    data.append('client_id', clientId);
    data.append('client_secret', 'secret');
    data.append('stay_signed_in', this.isKeepMeSignedIn.toString());

    return this.http
      .post(this.endpointUrl + 'oauth2/token', data.toString(), {
        headers: loginHeaders,
      })
      .pipe(
        map((response) => {
          const loginResponse: any = response;

          this.localstorageService.setItem(TOKEN, loginResponse.access_token);

          this.localstorageService.setItem(USER_LOGON_ID, userName);

          if (loginResponse.refresh_token)
            this.localstorageService.setItem(
              REFRESH_TOKEN,
              loginResponse.refresh_token
            );

          const token = this.helper.decodeToken(loginResponse.access_token);

          this.setCurrentUser(token.UserProfile);

          this.isloggedIn.next(true);

          return JSON.parse(token.UserProfile);
        })
      );
  }

  /**
   * It's a function that takes in 4 parameters, and returns an observable.
   * @param {string} userName - string,
   * @param {string} password - string,
   * @param {string} otp - string,
   * @param {boolean} isEmail - boolean
   * @returns The loginWithtaStatus.asObservable() is being returned.
   */
  loginWithta(userName: string, password: string): any {
    const loginWithtaStatus = new Subject<any>();
    this.isloggedInProcess = true;
    const loginHeaders = new HttpHeaders({
      'Content-Type': 'x-www-form-urlencoded',
      Audience: 'Any',
    });

    var clientId = this.configurationService?.config?.ClientId
      ? this.configurationService?.config?.ClientId
      : '123';

    const data = new URLSearchParams();
    data.append('username', encodeURIComponent(userName));
    data.append('password', encodeURIComponent(password));
    data.append('grant_type', 'password');
    data.append('client_id', clientId);
    data.append('client_secret', 'secret');
    data.append('stay_signed_in', this.isKeepMeSignedIn.toString());

    this.http
      .post(this.endpointUrl + 'oauth2/token', data.toString(), {
        headers: loginHeaders,
      })
      .subscribe({
        next: (response) => {
          const loginResponse: any = response;
          const token = this.helper.decodeToken(loginResponse.access_token);

          /* Checking if the user has accepted the terms and conditions. If not, it will open a modal to accept
          the terms and conditions. */
          if (!JSON.parse(token.ta)) {
            this.modalService = this.injector.get(NgbModal);

            this.modalReference = this.modalService.open(TermsViewComponent, {
              scrollable: true,
              size: 'xl',
            });
            this.modalReference.componentInstance.closeEditModalOutput.subscribe(
              (data) => {
                if (data) {
                  this.localstorageService.setItem(
                    TOKEN,
                    loginResponse.access_token
                  );
                  this.localstorageService.setItem(USER_LOGON_ID, userName);
                  if (loginResponse.refresh_token)
                    this.localstorageService.setItem(
                      REFRESH_TOKEN,
                      loginResponse.refresh_token
                    );

                  const token = this.helper.decodeToken(
                    loginResponse.access_token
                  );

                  this.setCurrentUser(token.UserProfile);

                  this.accountService.confirmTerms(token.AccountId).subscribe(
                    (data) => {
                      this.modalReference.close();
                      this.isloggedIn.next(true);
                      loginWithtaStatus.next(JSON.parse(token.UserProfile));
                      loginWithtaStatus.complete();
                    },
                    (error) => {
                      loginWithtaStatus.error({ error: 'Unable to Login' });
                    }
                  );
                } else {
                  loginWithtaStatus.error({ error: 'Unable to Login' });
                  loginWithtaStatus.complete();
                  this.logOutClearCache();
                  this.modalReference.close();
                }
              }
            );

            this.modalReference.dismissed.subscribe((data) => {
              loginWithtaStatus.error({ error: 'Unable to Login' });
            });
          } else {
            this.localstorageService.setItem(TOKEN, loginResponse.access_token);
            this.localstorageService.setItem(USER_LOGON_ID, userName);
            if (loginResponse.refresh_token)
              this.localstorageService.setItem(
                REFRESH_TOKEN,
                loginResponse.refresh_token
              );

            const token = this.helper.decodeToken(loginResponse.access_token);

            this.setCurrentUser(token.UserProfile);

            this.isloggedIn.next(true);
            loginWithtaStatus.next(JSON.parse(token.UserProfile));
            loginWithtaStatus.complete();
          }
        },
        error: (error) => {
          loginWithtaStatus.error(error);
          throw error;
        },
        complete: () => {},
      });

    return loginWithtaStatus.asObservable();
  }

  loginWithMobile(userName: string, password: string): any {
    this.isloggedInProcess = true;
    const loginHeaders = new HttpHeaders({
      'Content-Type': 'x-www-form-urlencoded',
      Audience: 'Any',
    });

    var clientId = this.configurationService?.config?.ClientId
      ? this.configurationService?.config?.ClientId
      : '123';

    const data = new URLSearchParams();
    data.append('username', encodeURIComponent(userName));
    data.append('password', encodeURIComponent(password));
    data.append('grant_type', 'password');
    data.append('client_id', clientId);
    data.append('client_secret', 'secret');
    data.append('stay_signed_in', this.isKeepMeSignedIn.toString());

    return this.http.post(this.endpointUrl + 'oauth2/token', data.toString(), {
      headers: loginHeaders,
    });
  }

  GetUserAccountsInfo(user: User): Observable<AccountInfo[]> {
    return this.http
      .get<AccountInfo[]>(
        this.endpointUrl + 'api/Account/GetUserAccountsInfo',
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          user.Accounts = resp;
          const now = new Date();
          user.LoginTokenExpireDateTime = new Date(
            now.getTime() +
              this.configurationService.config.RestoreLoginTokenPeriod * 1000
          );

          this.setCurrentUser(JSON.stringify(user));
          this.accountService
            .loadActiveAccount(user.Accounts[0].AccountID)
            .subscribe();
          this.onLoginChanged.emit(true);

          return resp;
        })
      );
  }

  serverLogout() {
    const logoutHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var logInf = new LogoutInfo();

    if (this.localstorageService.check(REFRESH_TOKEN))
      logInf.RefreshToken = this.localstorageService.getItem(REFRESH_TOKEN);

    if (this.localstorageService.check(TOKEN))
      logInf.AccessToken = this.localstorageService.getItem(TOKEN);

    if (logInf.AccessToken || logInf.RefreshToken) {
      return this.http.post(this.endpointUrl + 'api/WebAccess/Logout', logInf, {
        headers: logoutHeaders,
      });
    }
  }

  refreshToken() {
    const loginHeaders = new HttpHeaders({
      'Content-Type': 'x-www-form-urlencoded',
      Audience: 'Any',
    });

    const data = new URLSearchParams();

    var clientId = this.configurationService?.config?.ClientId
      ? this.configurationService?.config?.ClientId
      : '123';

    if (!this.localstorageService.getItem(REFRESH_TOKEN)) {
      return this.logout();
    }

    data.append('grant_type', REFRESH_TOKEN);
    data.append(REFRESH_TOKEN, this.localstorageService.getItem(REFRESH_TOKEN));
    data.append('client_id', clientId);
    data.append('client_secret', 'secret');

    return this.http
      .post(this.endpointUrl + 'oauth2/token', data.toString(), {
        headers: loginHeaders,
      })
      .pipe(
        tap((response) => {
          const loginResponse: any = response;

          this.localstorageService.setItem(TOKEN, loginResponse.access_token);

          // save refresh token if valid
          if (loginResponse.refresh_token)
            this.localstorageService.setItem(
              REFRESH_TOKEN,
              loginResponse.refresh_token
            );

          const token = this.helper.decodeToken(loginResponse.access_token);

          console.log(
            'Logon: UserProfile: ' + JSON.stringify(token.UserProfile)
          );

          this.setCurrentUser(token.UserProfile);
        })
      );
  }

  logout() {
    const subject = new Subject<boolean>();
    var accId = '';
    if (this.getCurrentUser()) {
      accId = JSON.parse(this.getCurrentUser()).AccountId;
      this.logService.logMessage(
        `Account Id ${accId} Logged out`
      );
    }
    console.log('logout Acc: ' + accId);
    // only for AO, EO, MBA, COB
    if (
      this.configurationService.config.Name === 'aodos' ||
      this.configurationService.config.Name === 'eodos' ||
      this.configurationService.config.Name === 'cob' ||
      this.configurationService.config.Name === 'mba' ||
      this.configurationService.config.Name === 'fbcl' ||
      this.configurationService.config.Name === 'iba' ||
      this.configurationService.config.Name === 'bwb'
    ) {
      var sLogout = this.serverLogout();
      if (sLogout) {
        sLogout.subscribe((res) => {
          setTimeout(() => {
            this.logOutClearCache();
            subject.next(true);
          });
        });
      } else {
        setTimeout(() => {
          this.logOutClearCache();
          subject.next(true);
        });
      }
    } else {
      this.logOutClearCache();
      setTimeout(() => {
        subject.next(true);
      });
    }
    return subject.asObservable();
  }

  private logOutClearCache() {
    this.localstorageService.clear();
    this.softLogout();
    this.accountService.clearActiveAccount();
    this.onLoginChanged.emit(false);
    this.isloggedIn.next(false);
    globalCacheBusterNotifier.next();
    this.zone.run(() => {
      this.router.navigateByUrl('/login');
    });
  }

  public logOutClearCacheNotRedirect() {
    this.localstorageService.clear();
    this.softLogout();
    this.accountService.clearActiveAccount();
    this.onLoginChanged.emit(false);
    this.isloggedIn.next(false);
    globalCacheBusterNotifier.next();
  }

  clearCache() {
    this.localstorageService.clear();
    this.softLogout();
    globalCacheBusterNotifier.next();
    this.accountService.clearActiveAccount();
  }

  loggedIn(): boolean {
    // if refresh token exists
    if (this.localstorageService.check(REFRESH_TOKEN)) {
      try {
        var refreshtoken = this.localstorageService.getItem(REFRESH_TOKEN);

        // get access token expiration
        var token = this.localstorageService.getItem(TOKEN);
        var tokenExpired = true;
        if (token) var tokenExpired = this.helper.isTokenExpired(token);

        // this is actual checking for logout - It is logout when refrerh token is expired
        var refreshTokenExpired = this.helper.isTokenExpired(refreshtoken);

        if (refreshTokenExpired) {
          if (!tokenExpired) {
            // for some STRANGE reason there is some refresh token but is not valid and we have an access token that is valid
            return false;
          }

          // log first time
          if (this.isloggedIn.getValue()) console.log('refresh token expired');
        }

        this.isloggedIn.next(!refreshTokenExpired);
        return !refreshTokenExpired;
      } catch (err) {
        // if corrupted or something went wrong just logout
        console.log(err);

        // force cleanup caches
        this.logout();

        // TODO : alert that
        return false;
      }
    } else {
      // if access token exists
      if (this.localstorageService.check(TOKEN)) {
        try {
          var token = this.localstorageService.getItem(TOKEN);
          var tokenExpired = this.helper.isTokenExpired(token);

          if (tokenExpired && this.isloggedIn.getValue())
            console.log('token expired');

          this.isloggedIn.next(!tokenExpired);
          return !tokenExpired;
        } catch (err) {
          // if corrupted or something went wrong just logout
          console.log(err);

          // force cleanup caches
          this.logout();

          // TODO : alert that
          return false;
        }
      } else {
        this.isloggedIn.next(false);
        return false;
      }
    }
  }

  initializeAccount() {
    return new Promise((resolve) => {
      if (this.localstorageService.check(CURRENT_USER)) {
        if (this.localstorageService.check(ACTIVE_ACCOUNT)) {
          this.subs.add(
            this.accountService
              .loadActiveAccount(
                this.accountService.getLocalSelectedAccountID()
              )
              .subscribe(() => {
                this.onLoggedIn();
              })
          );

          resolve(true);
        } else {
          if (this.getCurrentActiveUser().Accounts) {
            this.subs.add(
              this.accountService
                .loadActiveAccount(
                  this.getCurrentActiveUser().Accounts[0].AccountID
                )
                .subscribe(() => {
                  this.onLoggedIn();
                })
            );
            resolve(true);
          } else {
            this.subs.add(
              this.accountService
                .loadActiveAccount(this.getCurrentActiveUser().AccountId)
                .subscribe(() => {
                  this.onLoggedIn();
                })
            );
            resolve(true);
          }
        }
      } else {
        resolve(true);
      }
    });
  }

  getLoginToken(): string {
    return this.localstorageService.getItem(TOKEN);
  }

  setCurrentUser(userString: string) {
    this.localstorageService.setItem(CURRENT_USER, userString);
    this.accountService.setLoadTime(new Date());
  }

  softLogout() {
    this.accountService.resetAllAccountServiceCache();
    this.configurationService.resetAllConfigurationServiceCache();
  }

  getUserLogonId() {
    return this.localstorageService.getItem(USER_LOGON_ID);
  }

  setUserLogonId(logonId: string): void {
    if (logonId) {
      this.localstorageService.setItem(USER_LOGON_ID, logonId);
      this.accountService.setLoadTime(new Date());
    }
  }

  checkloggedIn() {
    if (
      !this.loggedIn() &&
      !this.router.url.includes('/login') &&
      !this.router.url.includes('/Signup') &&
      !this.router.url.includes('/Help') &&
      !this.router.url.includes('/ChangePassword') &&
      !this.router.url.includes('/ActivateWebAccount') &&
      !this.router.url.includes('/ForgotUserName') &&
      !this.router.url.includes('/ForgotPassword') &&
      !this.router.url.includes('/WebRequest') &&
      !this.router.url.includes('/message/msg')
    ) {
      this.logout();
      this.router.navigate(['/login']);
    }
  }

  onLoggedIn() {
    const msg = `Account Id ${
      JSON.parse(this.getCurrentUser()).AccountId
    } Logged in version ${this.configurationService.config.Version} UserAgent ${window.navigator.userAgent}`;
    this.logService.logMessage(msg);
    this.onLoginChanged.emit(true);
  }

  tokenExpirationDate(): Date {
    return this.helper.getTokenExpirationDate(
      this.localstorageService.getItem(TOKEN)
    );
  }

  // this is when the browser is closed and reopened
  updateCurrentUserTokenExpiry(userTokenExiryTimeSeconds: number) {
    // on login we set for a User LoginTokenExpireDateTime. Then every request to getCurrentActiveUser we check that
    // this time is not expired. If expired we logoff the user. This method allows to prolong the expry if user is active.
    if (this.localstorageService.check(CURRENT_USER)) {
      const usr: User = JSON.parse(
        this.localstorageService.getItem(CURRENT_USER)
      );
      const now = new Date();
      usr.LoginTokenExpireDateTime = new Date(
        now.getTime() + userTokenExiryTimeSeconds * 1000
      );
      this.localstorageService.removeItem(CURRENT_USER);
      this.setCurrentUser(JSON.stringify(usr));
    }
  }

  getCurrentUser(): string {
    const currentUserString = this.localstorageService.getItem(CURRENT_USER);
    return currentUserString;
  }

  getCurrentActiveUser(): User {
    if (this.localstorageService.check(CURRENT_USER)) {
      if (this.isLoginExpired()) {
        if (this.loggedIn()) {
          this.logout();
        }
        return new User();
      }
      return JSON.parse(this.localstorageService.getItem(CURRENT_USER));
    } else {
      return new User();
    }
  }

  isLoginExpired(): boolean {
    let expired = true;
    if (this.localstorageService.check(CURRENT_USER)) {
      const usr: User = JSON.parse(
        this.localstorageService.getItem(CURRENT_USER)
      );
      const now = new Date();
      const exp: Date = new Date(usr.LoginTokenExpireDateTime);

      if (now.getTime() > exp.getTime()) {
        expired = true;
      } else {
        expired = false;
      }
    }
    return expired;
  }

  updateCurrentUserEmail(email: string) {
    const activeUser = this.getCurrentActiveUser();
    activeUser.EmailAddress = email;
    this.localstorageService.setItem(CURRENT_USER, JSON.stringify(activeUser));
  }

  isCsr() {
    var user = JSON.parse(this.localstorageService.getItem(CURRENT_USER));
    if (user?.userGroupId && user?.userGroupId === '2') {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
