import { HttpParams } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ConfigurationService,
  DatabaseMapService,
  PaymentService,
  PaymentViolationInfo,
  ThemeService,
  TransactionResponse,
  UtilsService,
} from '@ibitoll/toll-core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'hhb-chase-receipt',
  templateUrl: './chase-receipt.component.html',
  styleUrls: ['./chase-receipt.component.scss'],
})
export class ChaseReceiptComponent implements OnInit {
  @Input()
  transactionResponse: TransactionResponse;

  @Input()
  currentDate: Date;

  @Input()
  merchantId: string;

  @Output() ModalClose = new EventEmitter<any>(); // happens on receipt close

  pvInfo: PaymentViolationInfo[] = null;
  downloading: boolean = false

  constructor(
    private databaseMapService: DatabaseMapService,
    private configurationService: ConfigurationService,
    public utilsService: UtilsService,
    public themeService: ThemeService,
    public activeModal: NgbActiveModal,
    public paymentService: PaymentService
  ) {}

  ngOnInit() {
    this.configurationService.getCenposMerchantId().subscribe((resp) => {
      this.merchantId = resp;
    });
  }

  public updatePvList(list: PaymentViolationInfo[]) {
    this.pvInfo = list;
  }

  isApproved() {
    return (
      this.transactionResponse &&
      this.transactionResponse.BankAuthorizationStatusDB === 'A' // this.databaseMapService.eAuthorizationStatusTypeMap.approved
    );
  }

  getCardType(value: number): string {
    return this.databaseMapService.getCardTypeString(value);
  }

  parseAmount(value: number) {
    return Math.abs(value);
  }

  downloadReceipt() {
    let params = new HttpParams();
    params = params.set('amount', this.parseAmount(this.transactionResponse?.ChargeTotal)); 
    params = params.set('cardNumber', this.transactionResponse.PaymentNumber);
    params = params.set('cardType', this.getCardType(this.transactionResponse?.PaymentCardType));
    params = params.set('referenceNo', this.transactionResponse?.TraceNumber);
    params = params.set('orderID', this.transactionResponse?.ResponseOrderID);
    params = params.set('processedOn', this.transactionResponse.BankTransactionUDateTime.toString());
    this.downloading = true
    this.paymentService.getPaymentReceipt(params).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'payment_receipt.pdf';
      link.click();
      window.URL.revokeObjectURL(url);
      this.downloading = false
    });

    // const doc = new jsPDF();

    // // Add a title to your PDF
    // // doc.setFontSize(20);
    // // doc.text(12, 10, 'Bridge Authority');

    // // https://rawgit.com/MrRio/jsPDF/master/
    // // Create your table here (The dynamic table needs to be converted to canvas).
    // /* tslint:disable */
    // const element = <HTMLScriptElement>(
    //   document.getElementById('paymentRecieptModalBody')
    // );
    // html2canvas(element).then((canvas: any) => {
    //   doc.addImage(
    //     canvas.toDataURL('image/jpeg'),
    //     'JPEG',
    //     50,
    //     50,
    //     element.offsetWidth / 5,
    //     element.offsetHeight / 5
    //   );
    //   doc.save(`Transaction-${Date.now()}.pdf`);
    // });
  }

  formatMoneyValue(val: number):string {
    var formatted = val.toFixed(2);
    return formatted;
  }

  afterModalClose() {
    this.ModalClose.emit(true);
    this.activeModal.dismiss();
  }
}
