import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  activePageChanged: EventEmitter<string> = new EventEmitter<string>(true);
  activateEInvoiceRequested: EventEmitter<string> = new EventEmitter(true);

  constructor(private router: Router) {}

  setActivePage(page: string) {
    this.activePageChanged.emit(page);
  }

  requestActivateEInvoice() {
    this.activateEInvoiceRequested.emit('');
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([currentUrl]));
  }
}
