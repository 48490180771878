export const STORAGE_KEY = 'IBI_GROUPS';
export const KMS = 'kms';
export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refresh_token';
export const CURRENT_USER = 'current_user';
export const ACTIVE_ACCOUNT = 'acctive_account';
export const USER_LOGON_ID = 'user_logon_id';
export const REMEMBER_ME = 'MeLang';
export const LANGUAGE_KEY = 'lang';
export const EURO_CURRENCY_CODE = 'fr-FR';
export const SETTINGS_KEY = 'user_settings';
export const LOGIN_COUNT = 'login_count';

export const ELAVON = 'elavon';
export const MONERIS = 'moneris';
