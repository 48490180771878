import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import { last, orderBy } from 'lodash';
import { Subscription, from } from 'rxjs';
import { AccountProfileConfig, IdNameConfig } from '../../models/idNameConfig';
import { User } from '../../models/user';
import { TransactionRecord } from '../../models/transactionRecord';
import { Transponder } from '../../models/transponder';
import { AccountQuery } from '../../models/accountQuery';
import { AccountService } from '../../providers/account.service';
import { ThemeService } from '../../providers/theme.service';
import { UtilsService } from '../../providers/utils.service';
import { AuthServiceProvider } from '../../providers/auth.service';
import { ConfigurationService } from '../../providers/configuration.service';
import { ActivityQuery } from '../../models/activityQuery';
import { Account } from '../../models/account';
import { SubSink } from 'subsink';
import { UserService } from '../../providers/user.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'account-summary',
  templateUrl: 'account-summary.component.html',
  styleUrls: ['./account-summary.component.scss'],
})
export class AccountSummaryComponent implements OnInit, OnDestroy {
  @Input()
  integrated: boolean;

  account: Account;
  transpondersCount: number;
  accountProfileOptions: AccountProfileConfig[];
  webUser: User;

  transactions: TransactionRecord[] = [];
  tollCategories: IdNameConfig[];
  isLoading = true;

  searchDateRange: any;
  bdate: Date;
  edate: Date;
  ePassList = [];
  GRITList = [];
  LastTransactionDateTime: any = null;
  noLastTransactionDateTime: boolean;
  uniqEPassList: any[] = [];
  transactionCountCollection = Object.create(null);

  accountSub: Subscription;
  transponderList: Transponder[] = [];
  selectedTransponder: Transponder;
  isAO: boolean = this.utilsService.isAttiki();
  isMba: boolean = this.utilsService.isMackinac();
  isHHB: boolean = this.utilsService.isHHB();
  showBalanceInSummary: boolean = !this.isHHB;
  showAccStatusInSummary: boolean = !this.isHHB;
  showRestrictionStatusInSummary: boolean = !this.isHHB;
  private subs = new SubSink();

  accountQuery: AccountQuery;

  constructor(
    private accountService: AccountService,
    public themeService: ThemeService,
    private utilsService: UtilsService,
    private authService: AuthServiceProvider,
    private configurationService: ConfigurationService,
    private userService: UserService,

  ) {
    this.bdate = new Date();
    this.bdate.setHours(0, 0, 0, 0);

    this.edate = new Date();
    this.bdate.setHours(0, 0, 0, 0);
  }

  ngOnInit() {
    this.accountSub = this.accountService
      .getActiveAccount()
      .subscribe((account: Account) => {
        if (this.authService.loggedIn()) {
          this.webUser = this.accountService.getCurrentUser();
          this.account = account;
          this.loadData();
        }
      });
  }

  private loadData() {
    this.getTransponderVehicleInfo();
    this.getAccountProfileInfo();
    this.getCurrentUser();
  }

  getTransponderVehicleInfo() {
    if (this.account !== undefined && this.isAO) {
      this.accountService
        .getTransponderVehicleInfo(this.account.AccountID)
        .subscribe(
          (resp) => {
            this.transponderList = resp;
            if (resp.length > 0) {
              this.selectedTransponder = resp[0];
            }
            this.transpondersCount = resp.length;

            if (this.isAO) {
              this.getAccountRecentActivities();
            }
          },
          (error) => {
            console.log('error in GetTransponderVehicleInfo: ' + error);
          }
        );
    }
  }

  getAccountRecentActivities() {
    this.isLoading = true;
    const activityQuery: ActivityQuery = {
      BeginLDateTime: dayjs().utc().startOf('month').toDate(),
      EndLDateTime: dayjs().utc().endOf('day').toDate(),
      BeginLDateTimeStr: dayjs().utc().startOf('month').toDate().toString(),
      EndLDateTimeStr: dayjs().utc().endOf('day').toDate().toString(),
      AccountId: this.accountService.getActiveAccountInfo().AccountID,
    };
    this.accountService.getAccountRecentActivities(activityQuery).subscribe(
      (resp) => {
        this.isLoading = false;
        this.transactions = resp;

        this.transponderList.forEach((o) => {
          let count = 0;
          count = this.transactions.filter((p) => {
            return p.TransponderId === o.TransponderId;
          }).length;
          this.transactionCountCollection[o.TransponderId] = count;
        });

        this.UpdateSelectedTransponder();
      },
      (error) => {
        console.log('error in GetAccount: ' + error);
      }
    );
  }

  private UpdateSelectedTransponder() {
    const sortedTranscations = orderBy(
      this.transactions,
      (o) => {
        return dayjs(o.TransactionLDateTime).toDate();
      },
      'desc'
    ).filter((o) => {
      return o.TransponderId === this.selectedTransponder.TransponderId;
    });

    this.ePassList = this.transactions.filter((x) => {
      return (
        x.PlazaExternal !== 'Y' &&
        x.TransponderId === this.selectedTransponder.TransponderId
      );
    });

    this.GRITList = this.transactions.filter((x) => {
      return (
        x.PlazaExternal === 'Y' &&
        x.TransponderId === this.selectedTransponder.TransponderId
      );
    });

    if (sortedTranscations.length > 0) {
      this.LastTransactionDateTime = dayjs(
        last(sortedTranscations).TransactionLDateTime
      ).toDate();
      this.noLastTransactionDateTime = true;
    } else {
      this.noLastTransactionDateTime = false;
    }
  }

  parseAmount(value: number) {
    return -value;
  }

  getAccountCount(transponderId: number) {
    return this.transactions.filter((o) => {
      return o.TransponderId === transponderId;
    }).length;
  }

  getAccountProfileInfo() {
    const isCreateMode = false;
    this.configurationService
      .getAccountProfiles(isCreateMode)
      .subscribe((accountProfiles) => {
        this.accountProfileOptions = accountProfiles;
      });
  }

  isBalanceGood() {
    // 1 - Good, 2 - Warning, 3 - Violation TOLL_STATUS in DB
    // const isGood: boolean = this.account && (this.account.BalanceStatus === '1');
    // console.log('this.account = ' + this.account + '; this.account.BalanceStatus = ' +
    //   this.account.BalanceStatus + '; isGood=' + isGood);
    // return isGood; // eListTransponderStatusMap.Good
  }

  getCurrentUser() {
    this.webUser = this.accountService.getCurrentUser();
  }

  getDate(date: string): Date {
    return new Date(date);
  }

  isPreAuth() {
    var text4status = "";
    if (this.isHHB) {
      if (this.account.PreAuthStatus === 1) {
        text4status =  'Enabled';
      } else {
        text4status = 'Disabled';
      }
    } else {
      if (this.account.PreAuthStatus === 1) {
        text4status =  'ON';
      } else {
        text4status = 'OFF';
      }
    }
    return text4status;
  }

  macPassPlusStatus() {
    if (this.account.MacPassPluse) {
      return "Active";
    } else {
      return "Inactive";
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
