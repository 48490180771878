import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import {
  Account,
  AccountService,
  AlertService,
  ConfigurationService,
  TollCategoryInfo,
  TransponderValidationResult,
  TransponderRequest,
  WebAccessService,
} from '@ibitoll/toll-core';
import { find } from 'lodash';
import { SubSink } from 'subsink';
import {
  switchMap,
  delay,
  debounceTime,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import {
  TRAN_AH,
  TRAN_HD,
  TRAN_SJ,
  TRANSPONDERPROVIDERMAP,
  TRAN_CON,
  TRAN_61,
  TRAN_102,
  TRAN_104,
  TRANSPONDERTYPE,
} from '../constants/key.constants';
import { of } from 'rxjs';


@Component({
  selector: 'owntransponder',
  templateUrl: './owntransponder.component.html',
  styleUrls: ['./owntransponder.component.scss'],
})
export class OwntransponderComponent implements OnInit, OnDestroy {
  account: Account;
  transponderRequestList: Array<TransponderRequest> = [];
  transponderRequest = new TransponderRequest();
  ownTransponderInfoFormGroup: FormGroup;
  tollCategories: Array<TollCategoryInfo> = [];

  private subs = new SubSink();
  checkingOwnTransponderID = false;
  public ownTransponderValid: boolean;
  public prefixValue = TRAN_HD;
  public maskValue = '00000000';
  public backgroundOpacity = 0.85;
  public hideThumbnail = true;
  public tagValidationResult: TransponderValidationResult;

  @Output() OwnTransponderStatus = new EventEmitter<any>();
  unique = this.constructor['ɵcmp'].id;
  public tagLength = 12;
  oldTagValue: any;

  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public configurationService: ConfigurationService,
    private webAccessService: WebAccessService
  ) {}

  get ownTransponderInfo() {
    return this.ownTransponderInfoFormGroup.controls;
  }

  ngOnInit(): void {
    this.subs.add(
      this.accountService.activeAccount
        .pipe(
          switchMap((account) => {
            this.account = account;
            if (this.account?.AccountProfileID) {
              return this.configurationService.GetTollCategoryInfoByAccountProfileID(
                this.account.AccountProfileID.toString()
              );
            } else {
              return this.configurationService.getTollCategories();
            }
          })
        )
        .subscribe((tollCategories) => {
          const distinctCat = new Array<TollCategoryInfo>();
          const flags = new Array<any>();
          const len = tollCategories.length;
          let i: number;
          for (i = 0; i < len; i++) {
            if (flags[tollCategories[i].GroupName]) {
              continue;
            }
            flags[tollCategories[i].GroupName] = true;
            distinctCat.push(tollCategories[i]);
          }
          this.tollCategories = distinctCat;

          if (!this.account?.AccountProfileID) {
            this.tollCategories = distinctCat
              .sort((a, b) => a.Id - b.Id)
              .filter((o) => {
                return o.GroupId == this.account.AccountProfileID;
              });
          }

          if (this.tollCategories.length === 1) {
            this.transponderRequest.TollCategoryId = this.tollCategories[0].Id;
          }
        })
    );

    this.ownTransponderInfoFormGroup = this.formBuilder.group({
      tagAgencyType: ['2', Validators.required],
      serialNumber: [
        '',
        [Validators.required],
        this.checkIsValidOwnTransponderID.bind(this),
      ],
    });

    this.ownTransponderInfoFormGroup.controls[
      'tagAgencyType'
    ].valueChanges.subscribe((data) => {
      console.log('updated to: ' + data);
      this.oldTagValue = null;
      if (data == 1) {
        this.prefixValue = TRAN_AH; // 'CAAH'
        this.tagLength = 12;
        this.maskValue = '00000000';
      }

      if (data == 2) {
        this.prefixValue = TRAN_HD; // 'CAHD'
        this.tagLength = 12;
        this.maskValue = '00000000';
      }

      if (data == 3) {
        this.prefixValue = TRAN_SJ; // 'CASJ'
        this.tagLength = 12;
        this.maskValue = '00000000';
      }

      if (data == 4) {
        this.prefixValue = TRAN_61; // '0061'
        this.tagLength = 15;
        this.maskValue = '00000000000';
      }

      if (data == 5) {
        this.prefixValue = TRAN_102; // '0102'
        this.tagLength = 15;
        this.maskValue = '00000000000';
      }

      if (data == 6) {
        this.prefixValue = TRAN_104; // '0104'
        this.tagLength = 15;
        this.maskValue = '00000000000';
      }

      this.ownTransponderInfoFormGroup.controls['serialNumber'].setValidators([
        Validators.maxLength(this.tagLength),
      ]);
      this.ownTransponderInfoFormGroup.controls['serialNumber'].reset();
    });

    this.OwnTransponderStatus.emit(this);
  }

  addOwnTransponder()
  {
    console.log('addOwnTransp List before:' + JSON.stringify(this.transponderRequestList));
    if (
      !find(this.transponderRequestList, {
        TransponderSerialNumber:
          this.transponderRequest.TransponderSerialNumber,
      })
    ) {
      const transponder = new TransponderRequest();

      transponder.TransponderSerialNumber =
        this.getTranspoderSerial(this.ownTransponderInfoFormGroup.controls['serialNumber'].value);
      transponder.TollCategoryId = this.getTollCategoryId();
      transponder.ManufacturerId = this.getManufacturerId(
        transponder.TransponderSerialNumber
      );
      transponder.TransponderId = this.getTransponderId(
        transponder.TransponderSerialNumber
      );

      this.transponderRequestList.push(transponder);
      this.ownTransponderInfoFormGroup.controls['serialNumber'].reset();

      console.log('addOwnTransp List after:' + JSON.stringify(this.transponderRequestList));

    } else {
      this.alertService.error(
        `Vehicle with TransponderSerialNumber ${this.transponderRequest.TransponderSerialNumber} is already exists.`,
        true
      );
    }
  }

  getTranspoderSerial(barCode: string): string {
    var serial = barCode;
    // if (barCode.startsWith(TRAN_102) || barCode.startsWith(TRAN_104) ) {
    //   serial = barCode.substring(0, barCode.length - 1);
    // }
    return serial;
  }

  getTollCategoryId(): any {
    if (this.tollCategories) {
      if (this.tollCategories.length === 1) {
        this.transponderRequest.TollCategoryId = this.tollCategories[0].Id;
        return this.tollCategories[0].Id;
      } else {
        const tollCat = this.tollCategories.filter((o) => {
          return o.GroupId === this.account.AccountProfileID;
        });
        return tollCat[0].Id;
      }
    }

    return null;
  }

  public IsOwnTranspValid(): boolean {
    return this.ownTransponderValid;
  }

  getTransponderId(serialNumber: string): any {
    console.log('Own.getTransponderId sn=' + serialNumber);
    //debugger;
    if (serialNumber) {
      if (serialNumber[2].toUpperCase() === 'E') {
        const transponderIDTimed = serialNumber.substring(
          1,
          serialNumber.length - 2
        );
        const tranIDHex = transponderIDTimed.substring(
          transponderIDTimed.length - 4
        );
        return parseInt(tranIDHex, 16);
      } else if (serialNumber.startsWith('124')) {
        return parseInt(serialNumber.substring(3, serialNumber.length - 1));
      } else if (serialNumber.startsWith('2530')) {
        return parseInt(serialNumber.substring(4, serialNumber.length - 1));
      } else if (serialNumber.startsWith('2531')) {
        return parseInt(serialNumber.substring(4, serialNumber.length - 1));
      } else if (serialNumber.startsWith('2529')) {
        return parseInt(serialNumber.substring(4, serialNumber.length - 1));
      } else if (serialNumber.startsWith(TRAN_102)) {
        return parseInt(serialNumber.substring(4, serialNumber.length - 1));
      } else if (serialNumber.startsWith(TRAN_104)) {
        return parseInt(serialNumber.substring(4, serialNumber.length - 1));
      } else if (serialNumber.startsWith(TRAN_HD)) {
        return parseInt(serialNumber.substring(4, serialNumber.length));
      } else if (serialNumber.startsWith(TRAN_SJ)) {
        return parseInt(serialNumber.substring(4, serialNumber.length));
      } else if (serialNumber.startsWith(TRAN_AH)) {
        return parseInt(serialNumber.substring(4, serialNumber.length));
      } else if (serialNumber.startsWith(TRAN_61)) {
        return parseInt(serialNumber.substring(4, serialNumber.length - 1));
      } else {
        return serialNumber.substring(serialNumber.length - 4);
      }
    }
  }

  getManufacturerId(serialNumber: string): number {
    //debugger;
    if (serialNumber) {
      if (serialNumber[2].toUpperCase() === 'E') {
        const transponderIDTimed = serialNumber.substring(
          1,
          serialNumber.length - 2
        );

        const manuIDHex = transponderIDTimed.substring(0, 3);

        return parseInt(manuIDHex, 16);
      } else if (serialNumber.startsWith('124')) {
        return parseInt(serialNumber.substring(0, 3));
      } else if (serialNumber.startsWith('2530')) {
        return parseInt(serialNumber.substring(0, 4));
      } else if (serialNumber.startsWith('2531')) {
        return parseInt(serialNumber.substring(0, 4));
      } else if (serialNumber.startsWith('2529')) {
        return parseInt(serialNumber.substring(0, 4));
      } else {
        if (serialNumber.startsWith(TRAN_AH)) {
          return TRANSPONDERPROVIDERMAP[TRAN_AH];
        } else if (serialNumber.startsWith(TRAN_HD)) {
          return TRANSPONDERPROVIDERMAP[TRAN_HD];
        } else if (serialNumber.startsWith(TRAN_SJ)) {
          return TRANSPONDERPROVIDERMAP[TRAN_SJ];
        } else if (serialNumber.startsWith(TRAN_102)) {
          return TRANSPONDERPROVIDERMAP[TRAN_102];
        } else if (serialNumber.startsWith(TRAN_104)) {
          return TRANSPONDERPROVIDERMAP[TRAN_104];
        } else if (serialNumber.startsWith(TRAN_CON)) {
          return TRANSPONDERPROVIDERMAP[TRAN_CON];
        } else if (serialNumber.startsWith(TRAN_61)) {
          return TRANSPONDERPROVIDERMAP[TRAN_61];
        }
      }
    }
  }

  getTransponderTypeId(): number {
    var typeId = TRANSPONDERTYPE.Own; // default value
    if (this.tagValidationResult?.Tag) { // update if tag exists in inventory
      typeId = this.tagValidationResult.Tag.TransponderTypeId;
    }
    return typeId; 
  }

  IsValidOwnTransponderID(control: FormControl) {
    console.log('IsValidOwnTransponderID called -> '+ control.value);
    if (control.value !== '' && control.value) {
      let tranSerial = control.value;
      tranSerial = tranSerial.trim().toUpperCase();
      let serialNoPart = 0;

      const validSerial =
        tranSerial.startsWith(TRAN_AH) ||
        tranSerial.startsWith(TRAN_HD) ||
        tranSerial.startsWith(TRAN_SJ);

      if (tranSerial.length > 4) {
        serialNoPart = tranSerial.substr(tranSerial.length - 4);
      }

      if (
        !!tranSerial &&
        tranSerial.length >= 12 &&
        validSerial &&
        serialNoPart !== 0
      ) {
        return null;
      }
    }

    return { validOwnTransponderID: true };
  }

  checkIsValidOwnTransponderID(control: AbstractControl) {
    console.log('checkIsValidOwnTransponderID() called: '+control.value + '; oldTagValue=' + this.oldTagValue);
    if (
      control.value !== '' &&
      control.value &&
      control.value.length === this.maskValue.length 
      //&& this.oldTagValue !== control.value
    ) {
      this.ownTransponderValid = false;
      this.checkingOwnTransponderID = true;
      return control.valueChanges.pipe(
        delay(800),
        debounceTime(800),
        distinctUntilChanged(),
        map((value) => {
          console.log('Validating ' + value);
          this.webAccessService
            .validateOwnTransponderID(this.prefixValue + '' + value.trim())
            .subscribe(
              (res) => {
                console.log('checkIsValidOwnTransponderID res: '+JSON.stringify(res));
                this.oldTagValue = control.value;
                this.tagValidationResult = res;
                this.ownTransponderValid = this.tagValidationResult.CanBeAdded;
                this.checkingOwnTransponderID = false;
                if (res.CanBeAdded) {
                  control.setErrors(null);
                } else {
                  control.setErrors({
                    transponderExist: true,
                  });
                }
              },
              () => {
                this.checkingOwnTransponderID = false;
                control.setErrors({
                  unverfied: true,
                });
              }
            );
        })
      );
    } else {
      return of({
        unverfied: true,
      });
    }
  }


  // checkIsValidOwnTransponderID(control: AbstractControl) {
  //   console.log('checkIsValidOwnTransponderID called: '+control.value);
  //   if (
  //     control.value !== '' &&
  //     control.value &&
  //     control.value.length === this.maskValue.length &&
  //     this.oldTagValue !== control.value
  //   ) {
  //     this.ownTransponderValid = false;
  //     this.checkingOwnTransponderID = true;
  //     return control.valueChanges.pipe(
  //       delay(800),
  //       debounceTime(800),
  //       distinctUntilChanged(),
  //       map((value) => {
  //         this.webAccessService
  //           .isValidOwnTransponderID(this.prefixValue + '' + value.trim())
  //           .subscribe(
  //             (res) => {
  //               this.oldTagValue = control.value;
  //               this.ownTransponderValid = res;
  //               this.checkingOwnTransponderID = false;
  //               if (res) {
  //                 control.setErrors(null);
  //               } else {
  //                 control.setErrors({
  //                   transponderExist: true,
  //                 });
  //               }
  //             },
  //             () => {
  //               this.checkingOwnTransponderID = false;
  //               control.setErrors({
  //                 unverfied: true,
  //               });
  //             }
  //           );
  //       })
  //     );
  //   } else {
  //     return of({
  //       unverfied: true,
  //     });
  //   }
  // }

  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Backspace' && this.ownTransponderInfoFormGroup.controls.serialNumber.value.length < 12) {
      this.ownTransponderValid = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openTagType(event: KeyboardEvent) {
    const tagAgencyTypeBtn = document.getElementById('tagAgencyTypeBtn');
    tagAgencyTypeBtn.click();
  }

  resetActiveForm() {
    this.ownTransponderInfoFormGroup.controls['tagAgencyType'].setValue('1');
    this.ownTransponderInfoFormGroup.reset();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
