export class ChaseResponse {
  txn_guid: string;
  token_id: string;
  tds_auth_status: string;
  gateway: Gateway;
  status: string;
  status_code: string;
  status_message: string;
  gateway_code: string;
  gateway_message: string;
  uid: string;
  order_id: string;
  card_brand: string;
  billing_name: string;
  country: string;
  mpan: string;
  exp_month: string;
  exp_year: string;
  email: string;
  merchant_data1: string;
  merchant_data2: string;
  merchant_data3: string;
  merchant_data4: string;
}

export class Gateway {
  procStatus: string;
  procStatusMessage: string;
  profileProcStatus: string;
  profileProcStatusMsg: string;
  approvalStatus: string;
  respCode: string;
  respCodeMessage: string;
  avsRespCode: string;
  cvvRespCode: string;
  authorizationCode: string;
  txRefNum: string;
  txRefIdx: string;
  mitReceivedTransactionID: string;
  visaVbVRespCode: string;
  cardBrand: string;
}
