<div class="card mb-3">
  <div class="card-header">
    <h1 class="h4 float-start mb-0 mt-2">{{ 'ENABLE_AUTO_REPLENISHMENT_SUBHEADER' | translate }}</h1>
    <button *ngIf="!rebillEdit && preAuthStaus && getDropDownPaymentType() == account.PaymentType"
            class="btn btn-sm btn-app-primary float-end"
            [ngClass]="{'disabled': !isPreAuthEnabled()}"
            [attr.aria-disabled]="!isPreAuthEnabled()"
            btnDisabled
            (click)="editAutoRebill()">
      {{ 'EDIT' | translate }}
    </button>
  </div>
  <div class="card-body">
    <form [formGroup]="autoRebillInfoFormGroup">
      <div class="row ms-0 mb-2">
        <div class="form-group form-check form-check-inline form-check ps-0">
          <div class="form-check form-switch form-switch-sm float-start"
               ngbTooltip="{{'ENABLE_AUTO_REPLENISHMENT_TOOLTIP_MSG' | translate}}"
               placement="top">
            <input class="form-check-input rebillStatus"
                   type="checkbox"
                   id="rebillStatusCheck"
                   formControlName="rebillStatus">
            <label class="form-check-label"
                   type="checkbox"
                   for="rebillStatusCheck">
              {{ 'ENABLE_AUTO_REPLENISHMENT' | translate }} 
            </label>
          </div>
          <i class="fa fa-lg fa-spinner fa-spin align-middle ms-3 float-end"
             *ngIf="isProcessing"></i>
        </div>
      </div>

      <div class="row ms-0 mb-2">
        <label class="ps-0"> <!--  *ngIf="getDropDownPaymentType() != 'B'" -->
          {{'AUTO_REBILL_MSG' | translate  | translateCut:0 }}
          <b>{{'AUTO_REBILL_MSG' | translate  | translateCut:1 }}</b>
          {{'AUTO_REBILL_MSG' | translate  | translateCut:2 }}
        </label>
        <label class="ps-0"> <!--  *ngIf="getDropDownPaymentType() == 'B'"-->
          {{'AUTO_REBILL_MSG_PAD' | translate | translateCut:0 }}
          <b>{{'AUTO_REBILL_MSG_PAD' | translate | translateCut:1 }}</b>
          {{'AUTO_REBILL_MSG_PAD' | translate | translateCut:2 }}
        </label> 
      </div>

      <div>

        <div class="row"
             id="preAuthAmount">
          <div class="col-12">
            <label for="rebillAmount"
                   class="form-label">{{'AUTO_REPLENISHMENT_AMOUNT' | translate}} </label>

            <div class="input-group">
              <span class="input-group-text"
                    for="rebillAmount">
                {{ configurationService.GetCurrencyCode() }}</span>
              <input type="text"
                     class="form-control"
                     [readonly]="!rebillEdit"
                     id="rebillAmount"
                     name="rebillAmount"
                     formControlName="rebillAmount"
                     name="rebillAmount" /><!--mask="separator.2"
                      [allowNegativeNumbers]="false"-->
            </div>
          </div>
          <div class="pt-1 ps-4">
            <p class="text-danger"
               *ngIf="autoRebillInfo.rebillAmount?.errors?.min">
               {{ replaceWithValue('AMOUNT_SHOULD_BE_GRATHER_THAN_TEXT', topupAmount) }}
            </p>
          </div>
        </div>

        <div class="row mt-3" *ngIf="getDropDownPaymentType() != 'B'">
          <div class="col-12">
            <label class="form-label">{{'AUTO_REPLENISHMENT_THRESHOLD' | translate}}</label>
            <div class="input-group">
              <span class="input-group-text"
                    for="rebillThreshold">
                {{ configurationService.GetCurrencyCode() }}</span>
              <input
                     class="form-control"
                     type="text"
                     [readonly]="!rebillEdit"
                     id="rebillThreshold"
                     formControlName="rebillThreshold"
                     name="rebillThreshold"
                     aria-label="rebillThreshold"
                     aria-describedby="rebillThreshold" /><!-- mask="separator.2"
                      [allowNegativeNumbers]="false"-->
            </div>
          </div>
          <div class="pt-1 ps-4">
            <p class="text-danger"
               *ngIf="autoRebillInfo.rebillThreshold?.errors?.min">
               {{ replaceWithValue('AMOUNT_SHOULD_BE_GRATHER_THAN_TEXT', rebillThresholdLimit) }}
            </p>
          </div>
        </div>

        <div class="row pt-2">
          <div class="col-12">
            <button *ngIf="rebillEdit"
                    (click)="canclePreAuthorizationAmout()"
                    [disabled]="isProcessing"
                    aria-label="CANCEL"
                    [ngClass]="{'disabled': isProcessing}"
                    [attr.aria-disabled]="isProcessing"
                    btnDisabled
                    class="btn btn-sm btn-app-secondary float-start"
                    role="button"
                    (click)="cancel()">
              <span *ngIf="!isCancelProcessing"> {{ 'CANCEL' | translate }} </span>
              <span *ngIf="isCancelProcessing"> Canceling... </span>
              <i class="fa fa-spinner fa-pulse"
                 *ngIf="isCancelProcessing"></i>
            </button>

            <button *ngIf="rebillEdit"
                    [disabled]="isProcessing"
                    class="btn btn-sm btn-app-primary float-end"
                    role="button"
                    aria-label="save"
                    [ngClass]="{'disabled': !autoRebillInfoFormGroup.valid}"
                    [attr.aria-disabled]="!autoRebillInfoFormGroup.valid"
                    btnDisabled
                    (click)="setCardPreAuthorizationAmount()">
              {{ 'SAVE' | translate }}
              <i class="fa fa-lg fa-spinner fa-pulse align-middle"
                 *ngIf="isProcessing"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
