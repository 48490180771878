import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { TollFee } from '../models/tollFee';
import { ProxyService } from './proxy.service';

@Injectable({
  providedIn: 'root',
})
export class FaqService implements OnDestroy {
  private endpointUrl: string;
  private faqEndpoint = 'api/Faq';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private subs = new SubSink();

  constructor(private http: HttpClient, private proxy: ProxyService) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  getTollFees(): Observable<TollFee[]> {
    return this.http
      .post<TollFee[]>(this.endpointUrl + this.faqEndpoint + '/GetTollFees', {
        headers: this.headers,
      })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
