/* eslint-disable @angular-eslint/component-selector */
import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  NgZone,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Account,
  AccountProfileConfig,
  AccountService,
  ConfigurationService,
  AccountCreationFee,
  NameIdNameConfig,
  DatabaseMapService,
  AuthServiceProvider,
  FormValidation,
} from '@ibitoll/toll-core';
import { SubSink } from 'subsink';
import { clone } from 'lodash';

@Component({
  selector: 'account-type-info',
  templateUrl: './account-type-info.component.html',
  styleUrls: ['./account-type-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountTypeInfoComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  editAccount = true;
  account: Account = new Account();
  accountProfileOptions: Array<AccountProfileConfig> = [];
  accountInfoFormGroup: FormGroup;
  fees: AccountCreationFee;

  public AccountType = 'P';
  public AccountProfile: number;
  public showLowBalance = false;

  private subs = new SubSink();

  accountTypeOptions: NameIdNameConfig[];
  accountCurrencyOptions: NameIdNameConfig[];
  OgaccountProfileOptions: AccountProfileConfig[];
  showDualCurrency = false;
  showLanguage = false;
  myTimeout: NodeJS.Timeout;
  isProfiledLoading = false;
  languageOptions: NameIdNameConfig[];

  constructor(
    private databaseMapService: DatabaseMapService,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private authService: AuthServiceProvider,
    public configurationService: ConfigurationService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  get accInfo() {
    return this.accountInfoFormGroup.controls;
  }

  ngOnInit(): void {
    this.accountInfoFormGroup = this.formBuilder.group({
      accountLanguage: ['1'],
      accountType: ['1', Validators.required],
      accountCurrencyType: ['D'],
      accountProfile: ['', Validators.required],
      firstName: [
        '',
        [Validators.required, FormValidation.InvalidValidator.bind(this)],
      ],
      lastName: [
        '',
        [Validators.required, FormValidation.InvalidValidator.bind(this)],
      ],
      contactFirstName: ['', [FormValidation.InvalidValidator.bind(this)]],
      contactLastName: ['', [FormValidation.InvalidValidator.bind(this)]],
      contactTitle: ['', [FormValidation.InvalidValidator.bind(this)]],
      contactPhone: ['', [FormValidation.InvalidValidator.bind(this)]],
      contactPhoneExt: ['', [FormValidation.InvalidValidator.bind(this)]],
      lowBalance: ['', Validators.min(0)],
      macPassPluse: [false, Validators.required],
    });

    if (this.configurationService.config.DualCurrency) {
      if (this.configurationService.config.DefaultCurrencyType === 'USD') {
        this.accountInfoFormGroup.controls['accountCurrencyType'].setValue('F');
      } else {
        this.accountInfoFormGroup.controls['accountCurrencyType'].setValue('D');
      }
      this.accountInfoFormGroup.controls['accountCurrencyType'].setValidators([
        Validators.required,
      ]);
      this.showDualCurrency = true;
    } else {
      this.accountInfoFormGroup.controls['accountCurrencyType'].setValue('D');
      this.accountInfoFormGroup.controls['accountCurrencyType'].setValidators(
        []
      );
      this.showDualCurrency = false;
    }

    this.accountTypeOptions = new Array<NameIdNameConfig>();
    const accountTypeKeys = Object.keys(
      this.databaseMapService.eAccountTypeMap
    );

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < accountTypeKeys.length; i++) {
      if (accountTypeKeys[i] !== 'O') {
        this.accountTypeOptions.push({
          NameId: accountTypeKeys[i],
          Name: this.databaseMapService.eAccountTypeMap[accountTypeKeys[i]],
        });
      }
    }

    this.languageOptions = new Array<NameIdNameConfig>();
    const languageKeys = Object.keys(this.databaseMapService.eLanguageMap);

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < languageKeys.length; i++) {
      if (languageKeys[i] !== 'O') {
        this.languageOptions.push({
          NameId: languageKeys[i],
          Name: this.databaseMapService.eLanguageMap[languageKeys[i]],
        });
      }
    }

    this.accountCurrencyOptions = new Array<NameIdNameConfig>();
    const accountCurrentTypeKeys = Object.keys(
      this.databaseMapService.eCurrencyTypeMap
    );

    // only type employer and standard as an option
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < accountCurrentTypeKeys.length; i++) {
      if (
        accountCurrentTypeKeys[i] !==
        accountCurrentTypeKeys[
          this.databaseMapService.eAccountSubTypeMap.Employee
        ]
      ) {
        this.accountCurrencyOptions.push({
          NameId: accountCurrentTypeKeys[i],
          Name: this.databaseMapService.eCurrencyTypeMap[
            accountCurrentTypeKeys[i]
          ],
        });
      }
    }

    this.subs.add(
      this.accountInfoFormGroup.controls[
        'accountCurrencyType'
      ].valueChanges.subscribe((data) => {
        if (this.configurationService.config.Name === 'iba') {
          if (this.OgaccountProfileOptions) {
            if (data === 'D') {
              this.accountProfileOptions = this.OgaccountProfileOptions.filter(
                (o) => o.CurrencyType === 'D'
              );
            } else {
              this.accountProfileOptions = this.OgaccountProfileOptions.filter(
                (o) => o.CurrencyType === 'F'
              );
            }

            setTimeout(() => {
              if (
                this.accountProfileOptions[0]?.AccountProfileID &&
                !this.account?.AccountID
              ) {
                const com = this.accountProfileOptions.find(
                  (o) => o.AccountType == 'P'
                );
                if (com) {
                  this.accountInfoFormGroup.controls['accountProfile'].setValue(
                    com.AccountProfileID
                  );
                  this.accountInfoFormGroup.controls[
                    'accountProfile'
                  ].setValidators([Validators.required]);
                  this.accountInfoFormGroup.controls[
                    'accountProfile'
                  ].updateValueAndValidity();
                } else {
                  this.accountInfoFormGroup.controls['accountProfile'].setValue(
                    this.accountProfileOptions[0].AccountProfileID
                  );
                  this.accountInfoFormGroup.controls[
                    'accountProfile'
                  ].setValidators([Validators.required]);
                  this.accountInfoFormGroup.controls[
                    'accountProfile'
                  ].updateValueAndValidity();
                }
              }
            }, 500);
          }
        }
      })
    );

    this.subs.add(
      this.accountInfoFormGroup.controls['accountType'].valueChanges.subscribe(
        (data) => {
          if (data === 'P') {
            this.accountInfoFormGroup.controls['firstName'].setValidators([
              Validators.required,
              FormValidation.InvalidValidator.bind(this),
            ]);

            this.accountInfoFormGroup.controls[
              'contactFirstName'
            ].setValidators([]);
            this.accountInfoFormGroup.controls[
              'contactFirstName'
            ].updateValueAndValidity();
            this.accountInfoFormGroup.controls['contactLastName'].setValidators(
              []
            );
            this.accountInfoFormGroup.controls[
              'contactLastName'
            ].updateValueAndValidity();

            this.accountInfoFormGroup.controls['contactTitle'].setValidators(
              []
            );
            this.accountInfoFormGroup.controls[
              'contactTitle'
            ].updateValueAndValidity();

            this.accountInfoFormGroup.controls['contactPhone'].setValidators(
              []
            );
            this.accountInfoFormGroup.controls[
              'contactPhone'
            ].updateValueAndValidity();

            this.AccountType = 'P';
          } else {
            this.accountInfoFormGroup.controls['firstName'].setValidators([]);
            this.accountInfoFormGroup.controls[
              'firstName'
            ].updateValueAndValidity();

            this.accountInfoFormGroup.controls[
              'contactFirstName'
            ].setValidators([
              Validators.required,
              FormValidation.InvalidValidator.bind(this),
            ]);
            this.accountInfoFormGroup.controls[
              'contactFirstName'
            ].updateValueAndValidity();

            this.accountInfoFormGroup.controls['contactLastName'].setValidators(
              [Validators.required, FormValidation.InvalidValidator.bind(this)]
            );
            this.accountInfoFormGroup.controls[
              'contactLastName'
            ].updateValueAndValidity();

            this.accountInfoFormGroup.controls['contactTitle'].setValidators([
              
              FormValidation.InvalidValidator.bind(this),
            ]);
            this.accountInfoFormGroup.controls[
              'contactTitle'
            ].updateValueAndValidity();

            this.accountInfoFormGroup.controls['contactPhone'].setValidators([
              Validators.required,
              Validators.minLength(10),
              FormValidation.InvalidValidator.bind(this),
            ]);
            this.accountInfoFormGroup.controls[
              'contactPhone'
            ].updateValueAndValidity();

            this.AccountType = 'B';
          }
        }
      )
    );

    this.showLanguage = this.configurationService.config.HasLanguage;
    this.accountInfoFormGroup.controls['accountLanguage'].setValue('1');

    this.accountInfoFormGroup.valueChanges.subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.isProfiledLoading = false;
    this.ngZone.run(() => {
      this.subs.add(
        this.configurationService.getAccountProfiles().subscribe(
          (data) => {
            this.accountProfileOptions = data;
            this.isProfiledLoading = false;
            this.OgaccountProfileOptions = clone(data);

            if (
              this.configurationService.config.Name === 'iba' &&
              !this.authService.loggedIn()
            ) {
              this.accountInfoFormGroup.controls[
                'accountCurrencyType'
              ].setValue('D');
            }

            this.subs.add(
              this.accountService.activeAccount.subscribe(
                (account: Account) => {
                  this.account = account;
                  if (account) {
                    this.AccountProfile = this.account.AccountProfileID;
                    this.AccountType = this.account.AccountType;
                    this.editAccount = false;
                    this.accountInfoFormGroup.controls[
                      'accountCurrencyType'
                    ].setValue(this.account.CurrencyType);
                    this.accountInfoFormGroup.controls['accountType'].setValue(
                      this.account.AccountType
                    );

                    this.updateForm();
                    this.cdr.markForCheck();
                  }
                }
              )
            );
          },
          () => {
            this.isProfiledLoading = false;
          }
        )
      );
    });
  }

  updateForm() {
    this.ngZone.run(() => {
      if (this.account.Language) {
        this.accountInfoFormGroup.controls['accountLanguage'].setValue(
          this.account.Language.toString()
        );
      }
      this.accountInfoFormGroup
        .get('lowBalance')
        .setValue(this.account.BalanceWarning * -1);

      if (this.account.AccountType === 'P') {
        this.accountInfoFormGroup.controls['accountProfile'].setValue(
          this.account.AccountProfileID
        );
        this.accountInfoFormGroup.controls['accountProfile'].setValidators([]);

        this.accountInfoFormGroup.controls['accountType'].setValue(
          this.account.AccountType
        );
        this.accountInfoFormGroup.controls['accountType'].setValidators([]);

        this.accountInfoFormGroup.controls['accountCurrencyType'].setValue(
          this.account.CurrencyType
        );
        this.accountInfoFormGroup.controls['accountCurrencyType'].setValidators(
          []
        );

        this.accountInfoFormGroup.controls['firstName'].setValue(
          this.account.FirstName
        );
        this.accountInfoFormGroup.controls['firstName'].setValidators([]);
        this.accountInfoFormGroup.controls['firstName']?.disable();

        this.accountInfoFormGroup.controls['lastName'].setValue(
          this.account.LastName
        );
        this.accountInfoFormGroup.controls['lastName'].setValidators([]);
        this.accountInfoFormGroup.controls['lastName']?.disable();

        this.AccountType = 'P';
      } else {
        this.accountInfoFormGroup.controls['accountProfile'].setValue(
          this.account.AccountProfileID
        );
        this.accountInfoFormGroup.controls['accountProfile'].setValidators([]);

        this.accountInfoFormGroup.controls['accountType'].setValue(
          this.account.AccountType
        );
        this.accountInfoFormGroup.controls['accountType'].setValidators([]);

        this.accountInfoFormGroup.controls['accountCurrencyType'].setValue(
          this.account.CurrencyType
        );
        this.accountInfoFormGroup.controls['accountCurrencyType'].setValidators(
          []
        );

        this.accountInfoFormGroup.controls['lastName'].setValue(
          this.account.LastName
        );
        this.accountInfoFormGroup.controls['lastName'].disable();

        this.accountInfoFormGroup.controls['contactFirstName'].setValue(
          this.account.ContactPersonFirstName
        );
        this.accountInfoFormGroup.controls['contactFirstName'].disable();

        this.accountInfoFormGroup.controls['contactLastName'].setValue(
          this.account.ContactPersonLastName
        );
        this.accountInfoFormGroup.controls['contactLastName'].disable();

        this.accountInfoFormGroup.controls['contactTitle'].setValue(
          this.account.ContactPersonTitle
        );
        this.accountInfoFormGroup.controls['contactTitle'].disable();

        this.accountInfoFormGroup.controls['contactPhone'].setValue(
          this.account.PhoneBusinessContact
        );
        this.accountInfoFormGroup.controls['contactPhone'].disable();

        this.accountInfoFormGroup.controls['contactPhoneExt'].setValue(
          this.account.PhoneBusinessContactExt
        );
        this.accountInfoFormGroup.controls['contactPhoneExt'].disable();

        this.AccountType = 'B';
      }

      this.accountInfoFormGroup.controls['macPassPluse'].setValue(
        this.account.MacPassPluse
      );
      this.accountInfoFormGroup.controls['macPassPluse'].setValidators([]);
      this.accountInfoFormGroup.controls['macPassPluse']?.disable();

      this.updateControl('accountLanguage', false);
      this.updateControl('lowBalance', false);
      this.updateControl('accountProfile', false);
      this.updateControl('accountCurrencyType', false);
      this.updateControl('accountType', false);
      this.editForm(false);
      this.accountInfoFormGroup.updateValueAndValidity();
    });
  }

  editForm(enableEdit: boolean) {
    // called to enable/disable editing mode
    this.editAccount = enableEdit;

    this.updateControl('lowBalance', this.editAccount);
    this.updateControl('accountLanguage', this.editAccount);

    if (this.account.AccountType === 'P') {
      this.updateControl('accountProfile', false);
      this.updateControl('accountType', false);

      this.updateControl('firstName', false);
      this.updateControl('lastName', false);
      this.updateControl('macPassPluse', this.editAccount);
    }

    if (this.account.AccountType === 'B') {
      this.updateControl('accountProfile', false);
      this.updateControl('accountType', false);

      this.updateControl('lastName', false);
      this.updateControl('contactFirstName', false);
      this.updateControl('contactLastName', false);

      this.updateControl('contactTitle', this.editAccount);
      this.updateControl('contactPhone', this.editAccount);
      this.updateControl('contactPhoneExt', this.editAccount);
      this.updateControl('macPassPluse', this.editAccount);
    }

    this.accountInfoFormGroup.updateValueAndValidity();
  }

  updateControl(name: string, editable: boolean) {
    if (editable) {
      this.accountInfoFormGroup.controls[name].enable();
      this.accountInfoFormGroup.controls[name].updateValueAndValidity();
    } else {
      this.accountInfoFormGroup.controls[name].disable();
      this.accountInfoFormGroup.controls[name].updateValueAndValidity();
    }
  }

  checkStatus(fromConName: string) {
    return this.accountInfoFormGroup.controls[fromConName].disabled;
  }

  getAxleInfo(profileID: number) {
    if (profileID === 1) {
      return '(2 axle)';
    } else {
      return '(3+ axles)';
    }
  }

  getControllerStatus(controllerName: string) {
    if (this.accountInfoFormGroup.controls[controllerName].enabled) {
      return true;
    } else {
      return false;
    }
  }

  canEditAccount(): boolean {
    return this.editAccount;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
