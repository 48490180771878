import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebAccessService } from '@ibitoll/toll-core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ibitoll-terms-view',
  templateUrl: './terms-view.component.html',
  styleUrls: ['./terms-view.component.scss'],
})
export class TermsViewComponent implements OnInit {
  @Input() docPath;

  @Output() closeEditModalOutput: EventEmitter<any> = new EventEmitter();

  pdfSrc: string;

  constructor(
    public activeModal: NgbActiveModal,
    private webAccess: WebAccessService
  ) {}

  ngOnInit(): void {
    this.webAccess.getTerms().subscribe((data) => {
      this.pdfSrc = URL.createObjectURL(data);
    });
  }

  confirm() {
    this.closeEditModalOutput.emit(true);
  }

  reject() {
    this.closeEditModalOutput.emit(false);
  }

  notConfirm() {
    this.closeEditModalOutput.emit(false);
    this.activeModal.dismiss();
  }
}
