import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Account, AccountProfileConfig, AccountService, AlertService, ConfigurationService, CreditCard, DatabaseMapService, FormValidation, LogService, UserService } from '@ibitoll/toll-core';
import { MatDialog } from '@angular/material/dialog';
import { PadAgreementComponent } from '../../../core/pad-agreement/pad-agreement.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export enum BankAccountType {
  Personal = 90,
  Business = 91
}

@Component({
  selector: 'hhb-banking-information',
  templateUrl: './banking-information.component.html',
  styleUrls: ['./banking-information.component.scss']
})
export class BankingInformationComponent implements OnInit, OnChanges {

  BankAccountTypeEnum = BankAccountType;

  @Input()
  editMode: boolean = false;
  
  @Output()
  bankInfoChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('removeBankInfoModal') activeBankInfoRemoveModal: ElementRef;

  bankAccountOptions = [
    { id: BankAccountType.Personal, name: 'Personal' },
    { id: BankAccountType.Business, name: 'Business' },
  ];

  account: Account;
  profile: AccountProfileConfig;
  bankingInfoForm: FormGroup;

  submitted: boolean = false;
  updating: boolean = false;
  monthDays = Array.from({ length: 28 }, (_, i) => i + 1);

  isRemoveActiveBankInfoProcessing = false;

  agreementConfirmed: boolean = false;


  constructor(
    private accountService: AccountService,
    private alertService: AlertService,
    private userService: UserService,
    private databaseMapService: DatabaseMapService,
    private formBuilder: FormBuilder,
    public logService: LogService,
    private modalService: NgbModal,
    public dialog: MatDialog,
    public configurationService: ConfigurationService,) { }

  ngOnInit(): void {

    this.bankingInfoForm = this.formBuilder.group({
      accountType: [this.bankAccountOptions[0].id],
      companyName: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required, Validators.maxLength(120)]],
      accountNumber: ['', [Validators.required, FormValidation.forbidAllZerosValidator()]],
      transitNumber: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern(/^[0-9]+$/), FormValidation.forbidAllZerosValidator()]],
      institutionNumber: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern(/^[0-9]+$/), FormValidation.forbidAllZerosValidator()]],
      padDayOfMonth: ['', [Validators.required]],
    });

    this.refreshInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.bankingInfoForm != undefined) {
      if (changes['editMode'] && changes['editMode']?.previousValue != changes['editMode']?.currentValue) {
        this.refreshInfo();
      }
    }
  }
  
  refreshInfo(): void {
    this.accountService.getActiveAccount().subscribe((account: Account) => {
      this.account = account;
      
      this.configurationService.getAccountProfiles().subscribe((profiles) => {
        this.profile = profiles.find(
          (p) => p.AccountProfileID === this.account.AccountProfileID
        );
        
        if(this.account.BankAccountType > 0) {
          this.bankingInfoForm.controls.accountType.setValue(this.account.BankAccountType);
        }

        this.resetValidatorsOnAccountTypeChange()
        this.bankingInfoForm.controls.companyName.setValue(this.account.BankAccountName);
        this.bankingInfoForm.controls.firstName.setValue(this.account.BankAccountFirstName);
        this.bankingInfoForm.controls.lastName.setValue(this.account.BankAccountLastName);
        this.bankingInfoForm.controls.companyName.setValue(this.account.BankAccountLastName);
        this.bankingInfoForm.controls.accountNumber.setValue(this.account.BankAccountNumber);
        
        const temp = this.account?.BankTransitNumber?.toString();
        const bankTransitNumber = temp?.slice(-5)
        const bankInstitutionNumber = temp?.slice(1, 4)
        
        this.bankingInfoForm.controls.transitNumber.setValue(bankTransitNumber);
        this.bankingInfoForm.controls.institutionNumber.setValue(bankInstitutionNumber);
        this.bankingInfoForm.controls.padDayOfMonth.setValue(this.account.PadDayOfMonth);

      })
    });
  }

  getBankAccountTypeName(bankAccountTypeId: number) {
    return this.bankAccountOptions.find(x => x.id === bankAccountTypeId)?.name;
  }

  onAccountTypeChange(): void {
    this.resetValidatorsOnAccountTypeChange()
  }

  resetValidatorsOnAccountTypeChange(): void {
    if(this.bankingInfoForm.controls.accountType.value == BankAccountType.Personal) {
      this.bankingInfoForm.controls.companyName.clearValidators();

      this.bankingInfoForm.controls.firstName.addValidators([Validators.required]);
      this.bankingInfoForm.controls.lastName.addValidators([Validators.required]);
    } else if(this.bankingInfoForm.controls.accountType.value == BankAccountType.Business) {
      this.bankingInfoForm.controls.firstName.clearValidators();
      this.bankingInfoForm.controls.lastName.clearValidators();
      
      this.bankingInfoForm.controls.companyName.addValidators([Validators.required]); 
    }
    this.bankingInfoForm.controls.companyName.updateValueAndValidity();
    this.bankingInfoForm.controls.firstName.updateValueAndValidity();
    this.bankingInfoForm.controls.lastName.updateValueAndValidity();
  }

  onSubmit(): void {
    this.submitted = true;

    if(this.bankingInfoForm.valid && this.agreementConfirmed) {
      const user = this.userService.getCurrentUser();

      const paymentInfo = new CreditCard();
      paymentInfo.UserId = user.UserId;
      paymentInfo.UserLogonId = user.UserName;
      paymentInfo.AccountId = user.AccountId;
      paymentInfo.PaymentType = this.databaseMapService.ePaymentTypeMap.BankAccount;
      paymentInfo.PaymentNum = this.bankingInfoForm.controls.accountNumber.value;
      paymentInfo.PaymentBankTransitNumber = '0' + this.bankingInfoForm.controls.institutionNumber.value + this.bankingInfoForm.controls.transitNumber.value;
      paymentInfo.PaymentBankType = this.bankingInfoForm.controls.accountType.value;

      if(paymentInfo.PaymentBankType == BankAccountType.Personal) {
        paymentInfo.PaymentFirstName = this.bankingInfoForm.controls.firstName.value;
        paymentInfo.PaymentLastName = this.bankingInfoForm.controls.lastName.value;
      } else {
        paymentInfo.PaymentLastName = this.bankingInfoForm.controls.companyName.value;
      }
      if (this.account.PaymentType){
        paymentInfo.BalanceReplenishAmount = -this.account.TopUpAmount;
        paymentInfo.BalanceReplenishThreshold = -this.account.TopUpAmount;
      }
      else{
        paymentInfo.BalanceReplenishAmount = -this.profile.BalanceReplenishAmount;
        paymentInfo.BalanceReplenishThreshold = - this.profile.BalanceReplenishAmount;
      }

      paymentInfo.BalanceWarningThreshold = Math.abs(this.account.BalanceWarning);
      paymentInfo.PadDayOfMonth = this.bankingInfoForm.controls.padDayOfMonth.value;
      paymentInfo.PreauthStatus = this.account.PreAuthStatus == 0 ? 2 : this.account.PreAuthStatus;
      paymentInfo.ActionCode = "ADDBI";

      this.updating = true;
      this.logService.logMessage(
        'AttemptWeb HHB.addBankInfo(' + user.AccountId + ')'
      ); 
      this.accountService.updatePaymentDetails(paymentInfo).subscribe(x => {

        this.accountService.loadActiveAccount(this.account.AccountID).subscribe(x => {
          this.refreshInfo();
          this.bankInfoChanged.emit(true);

          this.updating = false;
          this.submitted = false;
          this.agreementConfirmed = false;
        });
      });
    }
  }


  onDeleteBankInfoConfirm():void {
    const activeModal = this.modalService.open(this.activeBankInfoRemoveModal, {
      ariaLabelledBy: 'remove-bank-info-modal',
    });
    activeModal.shown.subscribe(() => {
      console.log('removeBankInfoModal modal shown');
    });
  }

  removeActiveBankInfo() {
    console.log('removeActiveBankInfo start');
    this.isRemoveActiveBankInfoProcessing = true;
    const user = this.userService.getCurrentUser();
    const paymentInfo = new CreditCard();
    paymentInfo.UserId = user.UserId;
    paymentInfo.UserLogonId = user.UserName;
    paymentInfo.AccountId = user.AccountId;
    paymentInfo.PaymentType = this.databaseMapService.ePaymentTypeMap.BankAccount;
    paymentInfo.PaymentNum = '';
    paymentInfo.PaymentBankTransitNumber = '';
    paymentInfo.PaymentBankType = this.bankingInfoForm.controls.accountType.value;

    if (this.account.PaymentType){
      paymentInfo.BalanceReplenishAmount = -this.account.TopUpAmount;
      paymentInfo.BalanceReplenishThreshold = -this.account.TopUpAmount;
    }
    else{
      paymentInfo.BalanceReplenishAmount = -this.profile.BalanceReplenishAmount;
      paymentInfo.BalanceReplenishThreshold = - this.profile.BalanceReplenishAmount;
    }

    paymentInfo.BalanceWarningThreshold = Math.abs(this.account.BalanceWarning);
    paymentInfo.PadDayOfMonth = this.bankingInfoForm.controls.padDayOfMonth.value;
    paymentInfo.PreauthStatus = this.account.PreAuthStatus == 0 ? 2 : this.account.PreAuthStatus;

    this.logService.logMessage(
      'AttemptWeb HHB.DeleteBankInfo(' + user.AccountId + ')'
    ); 
    this.accountService.updatePaymentDetails(paymentInfo).subscribe(
      () => {
        this.accountService
          .loadActiveAccount(this.account.AccountID)
          .subscribe(() => {
            if (this.editMode) {
              this.editMode = false;
            }
            //this.processing = false;
            this.alertService.success(
              'Bank Information has been successfully removed.',
              true
            );
            this.isRemoveActiveBankInfoProcessing = false;
            this.modalService.dismissAll();
            //this.cd.detectChanges();
          });
      },
      () => {
        this.modalService.dismissAll();
        this.isRemoveActiveBankInfoProcessing = false;
      }
    );
  }
  openAgreementModal(){
    //debugger
    let accountOwner: string;
    if(this.bankingInfoForm.controls.accountType.value == BankAccountType.Personal) {
      accountOwner = `${this.bankingInfoForm.controls.firstName.value} ${this.bankingInfoForm.controls.lastName.value}`
    }
    else{
      accountOwner = this.bankingInfoForm.controls.companyName.value
    }

    const dialogRef = this.dialog.open(PadAgreementComponent, {
      width: '850px',
      height: '700px',
      data: { 
        accountId: this.account.AccountID,
        name : accountOwner, 
        accountNumber: this.bankingInfoForm.controls.accountNumber.value, 
        padDayOfMonth: this.bankingInfoForm.controls.padDayOfMonth.value, 
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed){
        this.agreementConfirmed = true
      }
    });
    
  }

  getFormattedDayOfMonth(day){
    if(day == 0){
      return 'Last'
    }
    else if(day == 1 || day == 21){
      return day + 'st'
    }
    else if(day == 2 || day == 22){
      return day + 'nd'
    }
    else{
      return day + 'th'
    }
  }


}
