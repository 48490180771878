import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ibitoll-saved-card',
  templateUrl: './saved-card.component.html',
  styleUrls: ['./saved-card.component.scss'],
})
export class SavedCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
