import { User } from './user';

export enum DocumentType {
  eInvoice,
  PdfAnalysis,
  ExcelAnalysis,
}

export class StatementInfo {
  AccountId: string;
  Date: Date;
  RefNumber: string;
  IssueNumber: string;
  EInvoiceAvailable: boolean;
  PdfAnalysisAvailable: boolean;
}
export class StatementsInfoRequest {
  AccountId: string;
  User: User;
  Year: number;
  EInvoiceStatus: string;
}
export class DocumentContentRequest {
  DocumentType: DocumentType;
  DocumentInfo: StatementInfo;
}
