import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ToastController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ConfigurationService } from './configuration.service';



@Injectable({
  providedIn: 'root',
})
export class AlertMobileService {
  alert: HTMLIonAlertElement;
  messageCode: string = '';

  constructor(
    private translateService: TranslateService,
    public alertController: AlertController,
    public toastController: ToastController,
    private inAppBrowser: InAppBrowser,
    private configurationService: ConfigurationService
  ) {}

  async errorTranslate(
    messageCode: string,
    autoClose = false,
    keepAfterRouteChange = false,
    postion: string = 'toast-bottom-center'
  ) {
    if (this.messageCode !== messageCode) {
      this.messageCode = messageCode;
      if (this.alert) {
        this.alert.dismiss();
      }
      this.alert = await this.alertController.create({
        header: this.translateService.instant('ERROR'),
        message: this.translateService.instant(messageCode),
        buttons: [this.translateService.instant('OK')],
      });
      setTimeout(() => {
        this.messageCode = null;
      }, 800);
      await this.alert.present();
    }
  }

  confirmationTranslate(
    messageCode: string,
    autoClose = false,
    keepAfterRouteChange = false,
    postion: string = 'toast-bottom-center'
  ) {
    if (this.messageCode !== messageCode) {
      this.messageCode = messageCode;
      if (this.alert) {
        this.alert.dismiss();
      }

      this.translateService.get(messageCode).subscribe(async (text) => {
        this.alert = await this.alertController.create({
          message: text,
          buttons: [this.translateService.instant('CANCEL')],
        });

        setTimeout(() => {
          this.messageCode = null;
        }, 800);

        await this.alert.present();
      });
    }
  }

  showAlert(messageCode: string, header: string) {
    if (this.messageCode !== messageCode) {
      this.messageCode = messageCode;
      if (this.alert) {
        this.alert.dismiss();
      }

      this.translateService.get(messageCode).subscribe(async (text) => {
        this.alert = await this.alertController.create({
          header: this.translateService.instant(header),
          message: text,
          buttons: [this.translateService.instant('OK')],
        });

        setTimeout(() => {
          this.messageCode = null;
        }, 800);

        await this.alert.present();
      });
    }
  }

  error(messageCode: string, header: string) {
    if (this.messageCode !== messageCode) {
      this.messageCode = messageCode;
      if (this.alert) {
        this.alert.dismiss();
      }
      this.translateService.get(messageCode).subscribe(async (text) => {
        this.alert = await this.alertController.create({
          header: this.translateService.instant(header),
          message: text,
          buttons: [this.translateService.instant('OK')]
        });

        setTimeout(() => {
          this.messageCode = null;
        }, 800);

        await this.alert.present();
      });
    }
  }

  showMaintenance(messageCode: string, header: string) {
    if (this.messageCode !== messageCode) {
      this.messageCode = messageCode;
      if (this.alert) {
        this.alert.dismiss();
      }
      this.translateService.get(messageCode).subscribe(async (text) => {
        this.alert = await this.alertController.create({
          header: this.translateService.instant(header),
          message: text,
          buttons: [this.translateService.instant('OK')],
          cssClass: 'maintenance-alert'
        });

        setTimeout(() => {
          this.messageCode = null;
        }, 800);

        await this.alert.present();
      });
    }
  }



  showUpdateVersion(messageCode: string, header: string) {
    if (this.messageCode !== messageCode) {
      this.messageCode = messageCode;
      if (this.alert) {
        this.alert.dismiss();
      }
      this.translateService.get(messageCode).subscribe(async (text) => {
        this.alert = await this.alertController.create({
          header: this.translateService.instant(header),
          message: text,
          buttons: [
            {
              text: 'Update',
              handler: () => {
                this.configurationService.getMobileVersionUpdateLink().subscribe(
                  (storeUrl) => {
                    const browser = this.inAppBrowser.create(storeUrl, '_system');
                    browser.show();
                  }
                );
                return false;
              },
            }
    
          ],

          backdropDismiss: false
        });

        setTimeout(() => {
          this.messageCode = null;
        }, 100);

        await this.alert.present();
      });
    }
  }




}
