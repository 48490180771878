export class IdNameConfig {
  Id: number;
  GroupId: number;
  Name: string;
  Selectable: boolean;
  Description: string;
}

export class NameIdNameConfig {
  NameId: string;
  Name: string;
}

export class AccountProfileConfig extends IdNameConfig {
  AccountProfileID: number;
  AccountProfileName: string;
  AccountProfileText: number;
  AccountType: string;
  BalanceReplenishThreshold: number;
  BalanceReplenishAmount: number;
  BalanceWarning: number;
  BalanceViolation: number;
  CurrencyType: string;
}
