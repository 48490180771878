<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div class="modal-header">
    <div class="modal-title mb-0 h3" id="preTagRequestModalLabel">
      {{ 'PRE_TAG_MODAL_TITLE' | translate}}
    </div>
    <button type="button" role="button" id="model-close-btn" class="btn-close btn-close-white pull-right"
      (click)="modal.dismiss()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <span>{{ 'PRE_TAG_MODAL_TITLE_BODY' | translate | translateCut:0 }}</span>
    {{' '}}
    <span>{{pricePerTag?.AccountFeeRate | currency}}</span>
    {{' '}}
    <span class="">{{ 'PRE_TAG_MODAL_TITLE_BODY' | translate | translateCut:2 }}</span>
  </div>
  <div class="modal-footer">
    <button data-dismiss="modal" class="btn btn-app-secondary px-4 m-1 me-auto" (click)="modal.dismiss()">
      {{ 'CANCEL' | translate }}
    </button>
    <button cancelbtn class="btn btn-app-primary px-4 m-1" (click)="addNewVehicle()" aria-disabled="false">
      {{ 'CONFIRM' | translate }}
      <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isRequesting"></i>
    </button>
  </div>
</div>
