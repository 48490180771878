import { AccountQuery } from './accountQuery';
export class EInvoiceStatusUpdateQuery extends AccountQuery {
  InvoiceStatus: string;
  AccountEmailAddress: string;

  constructor(accountId: number, email: string, status: string) {
    super();

    this.AccountId = accountId;
    this.InvoiceStatus = status;
    this.AccountEmailAddress = email;
  }
}
