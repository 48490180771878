
<div class="pt-0">
  <div class="row">
    <div class="col-12 px-2">
      <div class="card mb-1">
        <div class="card-header">
          <h1 class="p-0 m-0 d-inline h3">
            <b>Pre-Authorized Debits Agreement Form</b>
          </h1>

          <button class="float-end d-inline btn btn-app-primary" color="primary" role="button" type="button"
            (click)="cancel()">
            <!-- printSectionId="print-section" ngxPrint  -->
            Cancel
          </button>
        </div>
        <div  *ngIf="loading" class="pdf-viewer-height">
          <mat-spinner class="m-auto mt-5"></mat-spinner>
        </div>
        <div *ngIf="!loading" class="card-body px-4 overflow-auto pdf-viewer-height" id="print-section"
          (scroll)="onScroll()" id="scrollable-content">
          <div *ngIf="pdfSrc">
            <pdf-viewer 
              [src]="pdfSrc" 
              [show-all]="true" 
              [fit-to-page]="true" 
              [zoom]="0.9" 
              [zoom-scale]="'page-width'"
              [stick-to-page]="true" 
              [render-text]="true" 
              [external-link-target]="'blank'" 
              [autoresize]="true"
              [show-borders]="false" 
              style="width: 100%; height: 2100px">
            </pdf-viewer>
          </div>
        </div>
        <div class="card-footer">
          <mat-checkbox class="my-auto" [formControl]="agreementConfirmControl" [disabled]="!this.scrolledToBottom">
            </mat-checkbox> I have authority under the terms of my account agreement with my financial institution to debit the account.
          <button (click)="close()" [ngClass]="{'disabled': !this.agreementConfirmControl.value}"
            class="btn btn-app-primary float-end">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</div>

