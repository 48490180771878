import { Component, OnInit } from '@angular/core';
import { Account } from '../../models/account';
import { AccountService } from '../../providers/account.service';
import { ConfigurationService } from '../../providers/configuration.service';

@Component({
  selector: 'currency-type',
  templateUrl: './currency-type.component.html',
  styleUrls: ['./currency-type.component.scss'],
})
export class CurrencyTypeComponent implements OnInit {
  currencyType = 'CAD';
  account: Account;

  constructor(
    private configService: ConfigurationService,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.accountService.getActiveAccount().subscribe((acc) => {
      this.account = acc;
      if (this.configService.config.Name === 'fbcl') {
        this.currencyType = 'CAD';
      }
      else if (this.configService.config.Name === 'bwb') {
        this.currencyType = 'USD';
      } else if (this.configService.config.Name === 'iba') {
        if (acc.CurrencyType == 'D') {
          this.currencyType = 'USD';
        }

        if (acc.CurrencyType == 'F') {
          this.currencyType = 'CAD';
        }
      } else if (this.configService.config.Name === 'hhb') {
        this.currencyType = 'CAD';
      }
      else if (this.configService.config.DefaultCurrencyType === 'CAD') {
        this.currencyType = 'CAD';
      } else if (this.configService.config.DefaultCurrencyType === 'USD') {
        this.currencyType = 'USD';
      }
    });
  }
}
