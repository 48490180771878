<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="container-12">
  <form [formGroup]="ownTransponderInfoFormGroup">
    <div class="col-12 p-0">
      <div class="card card-own-transponder">
        <div class="card-body">
          <h1 class="text-start m-0 mb-3 h4">
            {{ 'I_HAVE_TRANSPONDER_STR' | translate }}
            <div class="req-text text-muted float-end fw-light">
              <small><span class="non-ng-invalid-mark">*</span>{{'DEF_REQUIRED' | translate}}</small>
            </div>
          </h1>
          <div class="row collapse"
               id="collapseImage">
            <div class="container">
              <div class="row">
                <img *ngIf="ownTransponderInfo.tagAgencyType.value == '1'"
                     src="../../../assets/images/CAAH.jpg"
                     backgroundColor="white"
                     lightbox
                     [backgroundOpacity]="0.8"
                     animationTimingFunction="cubic-bezier(0.77, 0, 0.175, 1)"
                     [hideThumbnail]="false"
                     class=" col-6 p-1 img-fluid logo-img rounded "
                     alt="Responsive image" />

                <img *ngIf="ownTransponderInfo.tagAgencyType.value == '2'"
                     src="../../../assets/images/CAHD.jpg"
                     backgroundColor="white"
                     lightbox
                     [backgroundOpacity]="0.8"
                     animationTimingFunction="cubic-bezier(0.77, 0, 0.175, 1)"
                     [hideThumbnail]="false"
                     class=" col-6 p-1 img-fluid logo-img rounded "
                     alt="Responsive image" />

                <img *ngIf="ownTransponderInfo.tagAgencyType.value == '2'"
                     src="../../../assets/images/CAHD_2.jpg"
                     backgroundColor="white"
                     lightbox
                     [backgroundOpacity]="0.8"
                     animationTimingFunction="cubic-bezier(0.77, 0, 0.175, 1)"
                     [hideThumbnail]="false"
                     class=" col-6 p-1 img-fluid logo-img rounded "
                     alt="Responsive image" />

                <img *ngIf="ownTransponderInfo.tagAgencyType.value == '2'"
                     src="../../../assets/images/CAHD_1.jpg"
                     backgroundColor="white"
                     lightbox
                     [backgroundOpacity]="0.8"
                     animationTimingFunction="cubic-bezier(0.77, 0, 0.175, 1)"
                     [hideThumbnail]="false"
                     class=" col-6 p-1 img-fluid logo-img rounded "
                     alt="Responsive image" />

                <img *ngIf="ownTransponderInfo.tagAgencyType.value == '4'"
                     src="../../../assets/images/61_1.jpg"
                     backgroundColor="white"
                     [fullImage]="{path: '../../../assets/images/61_1.png'}"
                     lightbox
                     [backgroundOpacity]="0.8"
                     animationTimingFunction="cubic-bezier(0.77, 0, 0.175, 1)"
                     [hideThumbnail]="false"
                     class=" col-6 p-1 img-fluid logo-img rounded "
                     alt="Responsive image" />

                <img *ngIf="ownTransponderInfo.tagAgencyType.value == '4'"
                     src="../../../assets/images/61_2.jpg"
                     lightbox
                     [fullImage]="{path: '../../../assets/images/61_2.png'}"
                     backgroundColor="white"
                     [backgroundOpacity]="0.8"
                     animationTimingFunction="cubic-bezier(0.77, 0, 0.175, 1)"
                     [hideThumbnail]="false"
                     class=" col-6 p-1 img-fluid logo-img rounded "
                     alt="Responsive image" />

                <img *ngIf="ownTransponderInfo.tagAgencyType.value == '5'"
                     src="../../../assets/images/61_2.jpg"
                     lightbox
                     [fullImage]="{path: '../../../assets/images/61_2.png'}"
                     backgroundColor="white"
                     [backgroundOpacity]="0.8"
                     animationTimingFunction="cubic-bezier(0.77, 0, 0.175, 1)"
                     [hideThumbnail]="false"
                     class=" col-6 p-1 img-fluid logo-img rounded "
                     alt="Responsive image" />

                <img *ngIf="ownTransponderInfo.tagAgencyType.value == '6'"
                     src="../../../assets/images/61_2.jpg"
                     lightbox
                     [fullImage]="{path: '../../../assets/images/61_2.png'}"
                     backgroundColor="white"
                     [backgroundOpacity]="0.8"
                     animationTimingFunction="cubic-bezier(0.77, 0, 0.175, 1)"
                     [hideThumbnail]="false"
                     class=" col-6 p-1 img-fluid logo-img rounded "
                     alt="Responsive image" />                     

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 mt-1">
              <label for="tagAgencyType"
                     class="form-label float-start mt-1 mt-md-0">
                {{ 'TAG_TYPE' | translate }}
                <span class="non-ng-invalid-mark">*</span>
                <a href=""
                   role="button"
                   data-bs-toggle="collapse"
                   tabindex="0"
                   data-bs-target="#collapseImage"
                   id="tagAgencyTypeBtn"
                   (keypress)="openTagType($event)"
                   aria-controls="collapseImage">
                  <span class="fa fa-info-circle fa-lg ps-2"
                        title="click to show tag example:"
                        aria-hidden="true"></span>
                  <span class="sr-only">click to show tag example:</span>
                </a>
              </label>
              <select class="form-select"
                      id="tagAgencyType"
                      formControlName="tagAgencyType">
                <option value="2">CAHD</option>
                <option value="1">CAAH</option>
                <!--<option value="3">CASJ</option>-->
                <option value="4">61</option>
                <option value="5">0102</option>
                <option value="6">0104</option>
              </select>
            </div>
            <div class="col-8">
              <div class="row">
                <div class="col-10">
                  <label for="serialNumber"
                         class="col-form-label col-form-label-sm float-start">
                    {{ 'TRANSPONDER_SERIAL_NUMBER' | translate }}
                    <span class="non-ng-invalid-mark">*</span>
                  </label>
                  <div class="input-group">
                    <input type="text"
                           class="form-control mb-1 me-1 rounded-end"
                           id="serialNumber"
                           [prefix]="prefixValue"
                           mask="{{maskValue}}"
                           formControlName="serialNumber"
                           name="serialNumber"
                           maxlength="100"
                           minlength="4"
                           onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                           autocomplete="off"
                           appInputRestriction="noSpecialChars"
                           [transponderInput]="tagLength"
                           (keyup)="onKeyUp($event)"
                           validInputTransponder="transponderNumber" />

                    <span *ngIf="
                      ownTransponderInfo.serialNumber.dirty &&
                      ownTransponderInfo.serialNumber?.errors
                        ?.transponderExist
                    ">
                      <i class="material-icons text-danger ms-2  mt-1">cancel</i>
                    </span>
                    <span *ngIf="
                      !ownTransponderInfo.serialNumber.invalid &&
                      ownTransponderInfo.serialNumber.dirty &&
                      !ownTransponderInfo.serialNumber?.errors
                        ?.transponderExist &&
                      !checkingOwnTransponderID
                    ">
                      <i class="material-icons text-success ms-2 mt-1">
                        check_circle
                      </i>
                    </span>

                    <span class="spinner-border ms-2 text-success"
                          *ngIf="checkingOwnTransponderID"></span>

                  </div>

                  <div *ngIf="
                        ownTransponderInfo.serialNumber.invalid &&
                        (ownTransponderInfo.serialNumber.dirty ||
                          ownTransponderInfo.serialNumber.touched)
                      "
                       class="text-danger fade show pl-2 float-start"
                       role="alert">
                    <div *ngIf="
                          ownTransponderInfo.serialNumber?.errors
                            ?.transponderExist
                        ">
                      <small>{{ 'OWN_TRANSPONDER_EXIST' | translate }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-1">
              <ng-content select="[cancelbtn]"></ng-content>
              <ng-content select="[submitbtn]"></ng-content>
            </div>
            <div class="col-12 mt-1">
              <ng-content select="[subnote]"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
