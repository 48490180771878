<div class="container-12">
  <form [formGroup]="accountInfoFormGroup">
    <fieldset>
      <legend class="form-label account-label mb-1 h3 fw-bold">
        {{ 'ACCOUNT_INFORMATION' | translate }}
        <span class="req-text text-muted float-end fw-light">
          <small>
            <span class="non-ng-invalid-mark ">*</span>
            {{ 'DEF_REQUIRED' | translate }}
          </small>
        </span>
      </legend>

      <div class="row">
        <!-- ACCOUNT_LANGUAGE -->
        <div class="col-sm-12 col-md-6 mb-3 mt-3"
             *ngIf="showLanguage">
          <label for="accountLanguage"
                 class="form-label mb-1">
            {{ 'ACCOUNT_LANGUAGE' | translate }}
            <span appMarkAsterisk
                  [formGroup]="accountInfoFormGroup"
                  [controlName]="'accountLanguage'"></span>
          </label>
          <select class="form-control form-select dd"
                  id="accountLanguage"
                  formControlName="accountLanguage"
                  [ngClass]="{
              'remove-form-select-dropdown': checkStatus('accountLanguage')
            }"
                  name="accountLanguage"
                  aria-describedby="accountLanguage"
                  required
                  aria-required="true">
            <option *ngFor="let option of languageOptions"
                    [ngValue]="option.NameId | orderBy: 'Name'"
                    [attr.aria-label]="option.Name | translate">
              {{ option.Name | translate }}
            </option>
          </select>
        </div>

        <!-- ACCOUNT_CURRENCY_TYPE -->
        <div class="col-sm-12 col-md-6 mb-3"
             *ngIf="showDualCurrency">
          <label for="accountCurrencyType"
                 class="form-label mb-1">
            {{ 'ACCOUNT_CURRENCY_TYPE' | translate }}
            <span appMarkAsterisk
                  [formGroup]="accountInfoFormGroup"
                  [controlName]="'accountCurrencyType'"></span>
          </label>

          <select class="form-control form-select dd"
                  id="accountCurrencyType"
                  formControlName="accountCurrencyType"
                  [ngClass]="{
              'remove-form-select-dropdown': checkStatus('accountCurrencyType')
            }"
                  name="accountCurrencyType"
                  aria-describedby="accountCurrencyType"
                  required
                  aria-required="true">
            <option *ngFor="let option of accountCurrencyOptions"
                    [ngValue]="option.NameId | orderBy: 'Name'"
                    [attr.aria-label]="option.Name | translate">
              {{ option.Name | translate }}
            </option>
          </select>
        </div>


      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6 mb-3"
             *ngIf="!isProfiledLoading">
          <label for="accountProfile"
                 class="form-label mb-1">
            {{ 'ACCOUNT_PROFILE' | translate }} <!---->
            <span appMarkAsterisk
                  [formGroup]="accountInfoFormGroup"
                  [controlName]="'accountProfile'"></span>
          </label>
          <ng-content select="[helpPop]"></ng-content>
          <select class="form-control form-select"
                  id="accountProfile"
                  formControlName="accountProfile"
                  [ngClass]="{
              'remove-form-select-dropdown': checkStatus('accountProfile')
            }"
                  name="accountProfile"
                  required
                  aria-required="true">
            <option *ngFor="
                let option of accountProfileOptions
                  | orderBy: 'AccountProfileName':true
              "
                    [ngValue]="option.AccountProfileID"
                    [attr.aria-label]="option.AccountProfileName | translate">
              {{ option.AccountProfileName | translate }}
            </option>
          </select>
          <ng-content select="[accountsubtext]"></ng-content>
        </div>

        <div class="col-sm-12 col-md-6 mb-3"
             *ngIf="!isProfiledLoading">
          <label for="accountType"
                 class="form-label mb-1">
            {{ 'ACCOUNT_TYPE' | translate }}
            <span appMarkAsterisk
                  [formGroup]="accountInfoFormGroup"
                  [controlName]="'accountType'"></span>
          </label>

          <select class="form-control form-select"
                  id="accountType"
                  formControlName="accountType"
                  name="accountType"
                  [ngClass]="{
              'remove-form-select-dropdown': checkStatus('accountType')
            }"
                  aria-required="true">
            <option *ngFor="let option of accountTypeOptions"
                    [ngValue]="option.NameId"
                    [attr.aria-label]="option.Name | translate">
              {{ option.Name | translate }}
            </option>
          </select>
        </div>
      </div>

      <!-- personal  -->
      <div class="row"
           *ngIf="AccountType === 'P'">
        <!-- FIRST_NAME -->
        <div class="col-sm-12 col-md-6 mb-3"
             *ngIf="!isProfiledLoading">
          <div class="form-group">
            <label for="firstName"
                   class="form-label mb-1">
              {{ 'FIRST_NAME' | translate }}
              <span class="non-ng-invalid-mark"
                    *ngIf="getControllerStatus('firstName')">*</span>
            </label>
            <input type="text"
                   class="form-control"
                   id="firstName"
                   formControlName="firstName"
                   maxlength="50"
                   onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                   onDrag="return false"
                   onDrop="return false"
                   onPaste="return false"
                   [attr.aria-required]="canEditAccount()"
                   [attr.aria-required]="canEditAccount()"
                   [attr.aria-readonly]="canEditAccount()"
                   [attr.aria-label]="'FIRST_NAME' | translate"
                   aria-describedby="invalidCheckFirstName"
                   [ngClass]="{ 'is-invalid': canEditAccount() &&
                   accInfo.firstName.invalid &&
                   (accInfo.firstName.dirty || accInfo.firstName.touched)}" />

            <div id="invalidCheckFirstName"
                 class="invalid-feedback"
                 *ngIf="accInfo.firstName?.errors?.required">
              {{ 'FIRST_NAME_REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <!-- FIRST_NAME -->
        <!-- LAST_NAME -->
        <div class="col-sm-12 col-md-6 mb-3"
             *ngIf="!isProfiledLoading">
          <div class="form-group">
            <label for="lastName"
                   class="form-label mb-1">{{ 'LAST_NAME' | translate }}
              <span class="non-ng-invalid-mark"
                    *ngIf="getControllerStatus('lastName')">*</span>
            </label>
            <input type="text"
                   class="form-control"
                   id="lastName"
                   formControlName="lastName"
                   maxlength="60"
                   onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                   onDrag="return false"
                   onDrop="return false"
                   onPaste="return false"
                   aria-required="true"
                   aria-describedby="invalidCheckLastName"
                   [ngClass]="{ 'is-invalid': canEditAccount() &&
                   accInfo.lastName.invalid &&
                   (accInfo.lastName.dirty || accInfo.lastName.touched)}" />

            <div id="invalidCheckLastName"
                 class="invalid-feedback"
                 *ngIf="accInfo.lastName?.errors?.required">
              {{ 'LAST_NAME_REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <!-- LAST_NAME -->
      </div>
      <!-- /personal  -->

      <div *ngIf="AccountType === 'B'">
        <div class="row">
          <div class="col-sm-12 col-md-6 mb-3">
            <label for="lastName"
                   class="form-label mb-1">{{ 'BUSINESS_NAME' | translate }}
              <span class="non-ng-invalid-mark"
                    *ngIf="getControllerStatus('lastName')">*</span>
            </label>
            <input type="text"
                   [readonly]="!canEditAccount()"
                   class="form-control"
                   id="lastName"
                   formControlName="lastName"
                   maxlength="60"
                   onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                   onDrag="return false"
                   onDrop="return false"
                   onPaste="return false"
                   aria-describedby="invalidCheckBussinessName"
                   [ngClass]="{ 'is-invalid': canEditAccount() &&
                   accInfo.lastName.invalid &&
                   (accInfo.lastName.dirty || accInfo.lastName.touched)}" />

            <div id="invalidCheckBussinessName"
                 class="invalid-feedback"
                 *ngIf="accInfo.lastName?.errors?.required">
              {{ 'BUSINESS_NAME_REQUIRED' | translate }}
            </div>
          </div>

          <div class="col-sm-12 col-md-6 mb-3">
            <label for="contactTitle"
                   class="form-label mb-1">{{ 'CONTACT_TITLE' | translate }}
              <!-- <span class="non-ng-invalid-mark"
                    *ngIf="getControllerStatus('contactTitle')">*</span> -->
            </label>
            <input type="text"
                   [readonly]="!canEditAccount()"
                   class="form-control"
                   id="contactTitle"
                   formControlName="contactTitle"
                   maxlength="40"
                   onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                   onDrag="return false"
                   onDrop="return false"
                   onPaste="return false"
                   aria-required="true"
                   aria-describedby="invalidCheckContactTitle"
                   [ngClass]="{ 'is-invalid': canEditAccount() &&
                   accInfo.contactTitle.invalid &&
                   (accInfo.contactTitle.dirty || accInfo.contactTitle.touched)}" />

            <!-- <div id="invalidCheckContactTitle"
                 class="invalid-feedback"
                 *ngIf="accInfo.contactTitle?.errors?.required">
              {{ 'CONTACT_TITLE_REQUIED' | translate }}
            </div> -->

          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 mb-3">
            <label for="contactFirstName"
                   class="form-label mb-1">
              {{ 'CONTACT_FIRST_NAME' | translate }}
              <span class="non-ng-invalid-mark"
                    *ngIf="getControllerStatus('contactFirstName')">*</span>
            </label>
            <input type="text"
                   [readonly]="!canEditAccount()"
                   class="form-control"
                   id="contactFirstName"
                   formControlName="contactFirstName"
                   maxlength="60"
                   onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                   onDrag="return false"
                   onDrop="return false"
                   onPaste="return false"
                   aria-required="true"
                   aria-describedby="invalidCheckcontactFirstName"
                   [ngClass]="{ 'is-invalid': canEditAccount() &&
                   accInfo.contactFirstName.invalid &&
                   (accInfo.contactFirstName.dirty || accInfo.contactFirstName.touched)}" />

            <div id="invalidCheckcontactFirstName"
                 class="invalid-feedback"
                 *ngIf="accInfo.contactFirstName?.errors?.required">
              {{ 'CONTACT_FIRST_NAME_REQUIED' | translate }}
            </div>


          </div>

          <div class="col-sm-12 col-md-6 mb-3">
            <label for="contactLastName"
                   class="form-label mb-1">
              {{ 'CONTACT_LAST_NAME' | translate }}
              <span class="non-ng-invalid-mark"
                    *ngIf="getControllerStatus('contactLastName')">*</span>
            </label>
            <input type="text"
                   [readonly]="!canEditAccount()"
                   class="form-control"
                   id="contactLastName"
                   formControlName="contactLastName"
                   maxlength="60"
                   onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                   onDrag="return false"
                   onDrop="return false"
                   onPaste="return false"
                   aria-required="true"
                   aria-describedby="invalidCheckcontactLastName"
                   [ngClass]="{ 'is-invalid': canEditAccount() &&
                   accInfo.contactLastName.invalid &&
                   (accInfo.contactLastName.dirty || accInfo.contactLastName.touched)}" />

            <div id="invalidCheckcontactLastName"
                 class="invalid-feedback"
                 *ngIf="accInfo.contactLastName?.errors?.required">
              {{ 'CONTACT_LAST_NAME_REQUIED' | translate }}
            </div>

          </div>
        </div>

        <div class="form-group mb-3">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <label for="contactPhone"
                     class="form-label mb-1">
                {{ 'CONTACT_NUMBER' | translate }}
                <span class="non-ng-invalid-mark"
                      *ngIf="getControllerStatus('contactPhone')">*</span>
              </label>
              <input type="text"
                     onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                     [readonly]="!canEditAccount()"
                     class="form-control"
                     id="contactPhone"
                     formControlName="contactPhone"
                     placeholder="(000) 000-0000"
                     mask="(000) 000-0000"
                     onDrag="return false"
                     onDrop="return false"
                     onPaste="return false"
                     aria-required="true"
                     aria-describedby="invalidCheckcontactPhone"
                     [ngClass]="{ 'is-invalid': canEditAccount() &&
                     accInfo.contactPhone.invalid &&
                     (accInfo.contactPhone.dirty || accInfo.contactPhone.touched)}" />

              <div id="invalidCheckcontactPhone"
                   class="invalid-feedback"
                   *ngIf="accInfo.contactPhone?.errors?.required">
                {{ 'CONTACT_NUMBER_REQUIRED' | translate }}
              </div>


              <div id="invalidCheckcontactPhone"
                   class="invalid-feedback"
                   *ngIf="accInfo.contactPhone?.errors?.mask">
                {{ 'CONTACT_NUMBER_REQUIRED_10_CHARACTERS' | translate }}
              </div>


            </div>
            <div class="col-sm-12 col-md-6">
              <label for="contactPhoneExt"
                     class="form-label mb-1 mt-3 mt-md-0">{{ 'EXT' | translate }}</label>
              <input type="text"
                     onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                     [readonly]="!canEditAccount()"
                     class="form-control inline-block"
                     id="contactPhoneExt"
                     name="contactPhoneExt"
                     maxlength="5"
                     formControlName="contactPhoneExt"
                     onDrag="return false"
                     onDrop="return false"
                     onPaste="return false" />
            </div>
          </div>
        </div>
      </div>
      <!-- /business  -->

      <div class="row">
        <div class="form-group col-sm-12 col-md-6"
             *ngIf="false">
          <label for="lowBalance"
                 class="form-label mb-1">
            {{ 'LOW_BALANCE_THRESHOLD' | translate }}
            <span class="non-ng-invalid-mark"
                  *ngIf="getControllerStatus('lowBalance')">*</span>
          </label>
          <div class="input-group">
            <span class="input-group-text">
              {{ configurationService.GetCurrencyCode() }}</span>
            <input id="lowBalance"
                   aria-label="lowBalance"
                   name="lowBalance"
                   type="number"
                   class="form-control"
                   OnlyNumber
                   aria-label="lowBalance"
                   aria-describedby="lowBalance"
                   formControlName="lowBalance"
                   name="lowBalance"
                   required />
          </div>
        </div>

        <!-- ACCOUNT_MACPASS_PLUSE -->
        <div class="col-sm-12 col-md-6 mb-3">
          <mat-checkbox formControlName="macPassPluse">
            <span class="h3">
              MACPASS<i>plus</i> Opt-in
            </span>
            <!-- <span class="non-ng-invalid-mark"
                  *ngIf="getControllerStatus('macPassPluse')">*</span> -->
          </mat-checkbox>
          <br>
          <span 
                class="form-label m-0 h5">
                The MACPASS<i>plus</i> service allows you to use your MACPASS to pay for short and long-term parking in the parkade at the airport.
          </span>
          <!-- <i class="fa fa-info-circle fa-lg infomation-icon"
             tabindex="0"
             placement="right"
             [ngbTooltip]="macPassPlus"
             attr.aria-label="{{ 'ACCOUNT_STATUS_TOOL_TIP' | translate }}"></i> -->
        </div>
        <!-- ACCOUNT_MACPASS_PLUSE -->
      </div>
    </fieldset>
  </form>
</div>


<ng-template #macPassPlus>
  <p>
    The MACPASS
    plus
    service allows you to use your MACPASS to pay for short and long-term parking in the parkade at the airport.
  </p>
</ng-template>
