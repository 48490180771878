<div class="modal fade" id="paymentReceiptModal" tabindex="-1" role="dialog" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div id="paymentRecieptModalBody" class="modal-body">
        <div style="text-align: right">
          <!--*ngIf="isAO" -->
          <img src="{{ themeService.active.LogoImageUrl | translate }}" height="40" style="margin: 25px" alt="" />
        </div>
        <div class="modal-title text-center rounded-top">
          <div class="h3">
            <i class="fa" [ngClass]="
                isApproved() ? 'fa-check-circle-o' : 'fa-exclamation-circle'
              "></i>
            {{ (isApproved() ? 'TR_STATUS_A' : 'TR_STATUS_D') | translate }}
            {{ 'INTERNET_TRANSACTION' | translate }}
          </div>
        </div>

        <div class="col border border-secondary">
          <div class="ps-2 my-1">
            <span>
              <b>{{ 'AMOUNT' | translate }}: </b>
            </span>
            <span>{{ parseAmount(transactionResponse?.Amount) | currency }}
            </span>
          </div>
          <div class="ps-2 my-1">
            <!-- *ngIf="!isAO" -->
            <span>
              <b>{{ 'CARD_NUMBER' | translate }}: </b>
            </span>
            <span>{{ displayCard() }} </span>
          </div>

          <div class="ps-2 my-1">
            <span>
              <b>{{ 'AUTH_CODE' | translate }}: </b>
            </span>
            <span>{{
              transactionResponse?.Status
                ? transactionResponse?.Status
                : ('NOT_APPLICABLE' | translate)
            }}</span>
          </div>
          <div class="ps-2 my-1">
            <span>
              <b>{{ 'PROCESSED_AS' | translate }}: </b>
            </span>
            <span>{{
              getCardType(transactionResponse?.CardType) | translate
            }}</span>
          </div>
          <div class="ps-2 my-1">
            <span>
              <b>{{ 'REFERENCE_NO' | translate }}: </b>
            </span>
            <span>{{ transactionResponse?.TransactionId }}</span>
          </div>

          <div class="ps-2 my-1">
            <span>
              <b>{{ 'DEVICE_ID' | translate }}: </b>
            </span>
            <span>{{ transactionResponse?.NodeId }}</span>
          </div>
          <div class="ps-2 my-1">
            <span>
              <b>{{ 'INVOICE_NO' | translate }}: </b>
            </span>
            <span>{{ transactionResponse?.OrderId }}</span>
          </div>
          <div class="ps-2 my-1" *ngIf="pvInfo?.length > 0">
            <span><b>Processed IOU:</b></span>
            <table class="pvItem">
              <tr>
                <td><b>Date</b></td>
                <td><b>Amount</b></td>
                <td><b>NodeId</b></td>
                <td><b>Status</b></td>
              </tr>
              <tr *ngFor="let pv of pvInfo; let index = index">
                <td>{{ pv.LDateTime | dateTimeFormatter }}</td>
                <td>{{ pv.Amount }}</td>
                <td>{{ pv.NodeId }}</td>
                <td>PAID</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="text-center">
          <span>{{ 'PROCESSED_ON' | translate }}:
            {{
              transactionResponse?.DateTime
                ? (transactionResponse?.DateTime | dateTimeFormatter)
                : (currentDate | dateTimeFormatter)
            }}
          </span>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-app-primary col-sm-4" (click)="downloadReceipt()">
          <i class="fa fa-cloud-download" ></i>
          {{ 'DOWNLOAD' | translate }}
        </button>
        <button class="btn btn-app-primary col-sm-3 offset-5" data-dismiss="modal" type="button"
          (click)="afterModalClose()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
