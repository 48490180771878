import { switchMap } from 'rxjs/operators';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  Account,
  AccountProfileConfig,
  AccountService,
  AlertService,
  ConfigurationService,
  CreditCard,
  DatabaseMapService,
  LogService,
  PaymentRequest,
} from '@ibitoll/toll-core';
import { SubSink } from 'subsink';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { BankAccountType } from '../../modules/user-payment/banking-information/banking-information.component';
import {MatDialog} from '@angular/material/dialog';
import { debug } from 'console';

@Component({
  selector: 'auto-rebill',
  templateUrl: './auto-rebill.component.html',
  styleUrls: ['./auto-rebill.component.scss'],
})
export class AutoRebillComponent implements OnInit, OnDestroy, OnChanges {
  public autoRebillInfoFormGroup: FormGroup;
  public account: Account;
  public creditCard: CreditCard;
  public topupAmount = 4;
  public rebillThresholdLimit = 20;
  public isProcessing = false;
  public rebillEdit = false;
  
  private pmtReq: PaymentRequest;
  private subs = new SubSink();

  BalanceReplenishAmount: number;
  BalanceReplenishThreshold: number;
  preAuthStaus = false;
  oldBalanceReplenishAmount: number;
  oldBalanceReplenishThreshold: number;
  isCancelProcessing = false;

  accountProfileOptions: Array<AccountProfileConfig> = [];

  @ViewChild(NgbTooltip, { static: false })
  public autoRebillTooltip: NgbTooltip;

  @Input() dropDownPaymentType: string
  @Input() paymentEditMode: boolean = false;

  constructor(
    private accountService: AccountService,
    public configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    public databaseMapService: DatabaseMapService,
    private alertService: AlertService,
    public translateService: TranslateService,
    public logService: LogService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}

  get autoRebillInfo() {
    return this.autoRebillInfoFormGroup.controls;
  }

  ngOnInit(): void {
    this.pmtReq = PaymentRequest.createBlank();

    this.autoRebillInfoFormGroup = this.formBuilder.group({
      rebillStatus: [''],
      rebillAmount: ['', Validators.required],
      rebillThreshold: ['', Validators.required],
    });
    this.subs.add(
      this.accountService.activeAccount
        .pipe(
          switchMap((account: Account) => {
            //debugger
            this.account = account;
            return this.configurationService.getAccountProfiles();
          })
        )
        .subscribe(
          (accProfile: AccountProfileConfig[]) => {
            this.accountProfileOptions = accProfile;
            const profile = this.accountProfileOptions.find(
              (o) => o.AccountProfileID === this.account.AccountProfileID
            );

            if (profile) {
              this.topupAmount = Math.abs(profile.BalanceReplenishAmount);
              this.rebillThresholdLimit = Math.abs(
                profile.BalanceReplenishThreshold
              );
            }

            this.getCreditCard();

            if (this.autoRebillInfoFormGroup) {
              this.autoRebillInfoFormGroup.controls['rebillStatus'].setValue(
                this.getPreAuthStatus(this.account)
              );
              this.autoRebillInfoFormGroup.controls[
                'rebillStatus'
              ].updateValueAndValidity();
              if (this.account.PaymentType){
                this.autoRebillInfoFormGroup.controls['rebillAmount'].setValue(
                  this.formatMoneyValue(this.account.TopUpAmount * -1) 
                );
                this.autoRebillInfoFormGroup.controls['rebillThreshold'].setValue(
                  this.formatMoneyValue(this.account.LowBalanceAmount * -1)
                );
              }
              else{
                this.autoRebillInfoFormGroup.controls['rebillAmount'].setValue(
                  this.formatMoneyValue(this.topupAmount)
                );
                this.autoRebillInfoFormGroup.controls['rebillThreshold'].setValue(
                  this.formatMoneyValue(this.rebillThresholdLimit)
                );
              }
    
              this.autoRebillInfoFormGroup.controls[
                'rebillAmount'
              ].setValidators([
                Validators.min(this.topupAmount),
                Validators.required,
              ]);
              this.autoRebillInfoFormGroup.controls[
                'rebillAmount'
              ].updateValueAndValidity();


              // this.autoRebillInfoFormGroup.controls[
              //   'dayOfMonth'
              // ].setValidators([
              //   Validators.required,
              // ]);
              // this.autoRebillInfoFormGroup.controls[
              //   'dayOfMonth'
              // ].updateValueAndValidity();
              

              this.autoRebillInfoFormGroup.controls[
                'rebillThreshold'
              ].setValidators([
                Validators.min(this.rebillThresholdLimit),
                Validators.required,
              ]);
              this.autoRebillInfoFormGroup.controls[
                'rebillThreshold'
              ].updateValueAndValidity();
            }
            if (!this.account.PaymentNumber || this.dropDownPaymentType != this.account.PaymentType) {
              this.autoRebillInfoFormGroup.disable();
            } else {
              this.autoRebillInfoFormGroup.enable();
            }

            this.cd.markForCheck();
          },
          (error) => {
            console.error('error in onChangeAccType: ' + error);
          }
        )
    );

    this.subs.add(
      this.autoRebillInfoFormGroup.controls[
        'rebillStatus'
      ].valueChanges.subscribe((data) => {
        if (this.getPreAuthStatus(this.account) !== data) {
          this.enablePreAuth(data);
        }
      })
    );
  }

  formatMoneyValue(val: number):string {
    var formatted = val.toFixed(2);
    //console.log('formatMoneyValue2(' + val + ')=' + formatted);
    return formatted;
  }

  ngOnChanges(changes: SimpleChanges) {
    //debugger;
    if (!this.autoRebillInfoFormGroup)
      return;
    if (this.paymentEditMode == false || this.dropDownPaymentType == this.account.PaymentType){
      this.autoRebillInfoFormGroup.enable();
    }
    else{
      this.autoRebillInfoFormGroup.disable();
    }

    if (this.paymentEditMode == true && this.dropDownPaymentType == 'C' && this.account.PaymentType == 'B'){
      this.autoRebillInfoFormGroup.controls['rebillThreshold'].setValue(
        this.formatMoneyValue(this.rebillThresholdLimit)
      );
    }
    else {
      this.autoRebillInfoFormGroup.controls['rebillThreshold'].setValue(
        this.formatMoneyValue(this.account.LowBalanceAmount * -1)
      );
    }
    //changes
    // Do something when the value of `inputValue` changes
  }


  replaceWithValue(text: any, textValue: any): string {
    //console.log('text='+text+'; textValue=' + textValue);
    var outp = this.translateService.instant(text)
    var regEx = /==AMOUNT==/gi;
    outp = outp.replace(regEx, '$' + this.formatMoneyValue(textValue));
    //console.log('outp='+outp);
    return outp;
  }

  getCreditCard() {
    this.accountService.getCreditCard(this.account.AccountID).subscribe(
      (resp) => {
        this.pmtReq.paymentType = 2;
        this.pmtReq.card_num = resp.PaymentNum;
        this.pmtReq.exp_month = resp.PaymentCardExpiryMonth;
        this.pmtReq.exp_year =
          resp.PaymentCardExpiryYear.toString().length <= 2
            ? resp.PaymentCardExpiryYear + 2000
            : resp.PaymentCardExpiryYear;
        this.pmtReq.nameOnCard = resp.PaymentLastName;
        this.creditCard = resp;
        this.oldBalanceReplenishAmount = this.convertDebitCredit(
          this.creditCard.BalanceReplenishAmount
        );
        this.oldBalanceReplenishThreshold = this.convertDebitCredit(
          this.creditCard.BalanceReplenishThreshold
        );

        this.BalanceReplenishAmount = this.convertDebitCredit(
          this.creditCard.BalanceReplenishAmount
        );

        this.BalanceReplenishThreshold = this.convertDebitCredit(
          this.creditCard.BalanceReplenishThreshold
        );
        this.creditCard.BalanceReplenishAmount = this.convertDebitCredit(
          this.creditCard.BalanceReplenishAmount
        );

        this.creditCard.BalanceReplenishThreshold = this.convertDebitCredit(
          this.creditCard.BalanceReplenishThreshold
        );
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }

  getPreAuthStatus(account: any): boolean {
    if (account.PreAuthStatus === 1) {
      this.preAuthStaus = true;
      return this.preAuthStaus;
    }
    if (account.PreAuthStatus === 2 || account.PreAuthStatus === 0) {
      this.preAuthStaus = false;
      return this.preAuthStaus;
    }
  }

  isAccountActive(): boolean {
    try {
      const val: boolean =
        this.account.AccountStatus ===
          this.databaseMapService.eAccountStatusDMap['Active'] ||
        this.account.AccountStatus ===
          this.databaseMapService.eAccountStatusDMap['Active Partial'];
      return val;
    } catch {
      return false;
    }
  }

  isPreAuthEnabled(): boolean {
    const val: boolean =
      this.account !== undefined &&
      this.account.PreAuthStatus ===
        this.databaseMapService.ePreAuthStatus.active;
    return val;
  }

  setCardPreAuthorizationAmount(): void {
    this.isProcessing = true;
    const enabled: boolean = this.isPreAuthEnabled();

    if(this.account.PaymentType == 'C') {
      this.creditCard.PaymentCardType = this.databaseMapService.getCardType(
        this.getCardType(this.pmtReq.card_num)
      );
      
      this.creditCard.PaymentCardExpiryMonth = this.pmtReq.exp_month;
      this.creditCard.PaymentCardExpiryYear = this.pmtReq.exp_year - 2000;
      this.creditCard.PaymentLastName = this.pmtReq.nameOnCard;
      this.creditCard.PaymentCardType = this.account.PaymentCardType;
      this.creditCard.BalanceReplenishThreshold = this.autoRebillInfoFormGroup.controls['rebillThreshold'].value;

    } else if(this.account.PaymentType == 'B') {
      this.creditCard.PaymentNum = this.account.PaymentNumber;
      this.creditCard.PaymentBankTransitNumber = this.account.BankTransitNumber?.toString();
      this.creditCard.PaymentBankType = this.account.BankAccountType;
      this.creditCard.BalanceReplenishThreshold = this.autoRebillInfoFormGroup.controls['rebillAmount'].value;
  
      if(this.creditCard.PaymentBankType == BankAccountType.Personal) {
        this.creditCard.PaymentFirstName = this.account.BankAccountFirstName;
        this.creditCard.PaymentLastName = this.account.BankAccountLastName;
      } else {
        this.creditCard.PaymentLastName = this.account.BankAccountLastName;
      }
    }


    this.creditCard.BalanceReplenishAmount =
      this.autoRebillInfoFormGroup.controls['rebillAmount'].value;
    this.creditCard.BalanceWarningThreshold = Math.abs(
      this.account.BalanceWarning
    );
    this.creditCard.ActionCode = "URPA";

    this.logService.logMessage(
      'Attempt HHB.updateReplenish('+this.account.AccountID+') for ' + this.account.PaymentType + 
      ' to Amnt: ' + this.creditCard.BalanceReplenishAmount + ', thshd: ' + this.creditCard.BalanceReplenishThreshold
    ); 
    this.accountService
      .enableCardPreAuthorization(this.creditCard, enabled)
      .subscribe(
        () => {
          this.accountService
            .loadActiveAccount(this.account.AccountID)
            .subscribe(
              () => {
                this.autoRebillInfoFormGroup.controls['rebillStatus'].enable();
                this.autoRebillInfoFormGroup.controls[
                  'rebillStatus'
                ].updateValueAndValidity();
                this.isProcessing = false;
                this.rebillEdit = false;
                this.alertService.success(
                  'Rebill amount was successfully updated',
                  true
                );
              },
              (error) => {
                console.log('PA-LoadAcc-Update1 failed: ' + JSON.stringify(error));
                this.isProcessing = false;
                this.autoRebillInfoFormGroup.controls['rebillStatus'].enable();
                this.autoRebillInfoFormGroup.controls[
                  'rebillStatus'
                ].updateValueAndValidity();
              }
            );
        },
        (error) => {
          console.log('setCardPreAuthorizationAmount-Update failed: ' + JSON.stringify(error));
          this.autoRebillInfoFormGroup.controls['rebillStatus'].enable();
          this.autoRebillInfoFormGroup.controls[
            'rebillStatus'
          ].updateValueAndValidity();
          this.isProcessing = false;
          // this.alertService.error(
          //   'There was an error in editing rebill Amount! ' + error,
          //   true
          // );
        }
      );
  }

  enablePreAuth(enabled: boolean): void {
    //debugger;
    if (this.pmtReq.card_num) {

      this.isProcessing = true;
      
      if(this.account.PaymentType == 'C') {
        if (this.pmtReq.card_num[0] == '*') {
          this.creditCard.PaymentCardType = this.account.PaymentCardType;
        } else {
          this.creditCard.PaymentCardType = this.databaseMapService.getCardType(
            this.getCardType(this.pmtReq.card_num)
          );
        }

        this.creditCard.PaymentCardExpiryMonth = this.pmtReq.exp_month;
        this.creditCard.PaymentCardExpiryYear = this.pmtReq.exp_year;
        this.creditCard.PaymentLastName = this.pmtReq.nameOnCard;

        this.creditCard.BalanceReplenishThreshold = this.autoRebillInfoFormGroup.controls['rebillThreshold'].value;

      } else if(this.account.PaymentType == 'B') {
        this.creditCard.PaymentNum = this.account.PaymentNumber;
        this.creditCard.PaymentBankTransitNumber = this.account.BankTransitNumber?.toString();
        this.creditCard.PaymentBankType = this.account.BankAccountType;
        this.creditCard.BalanceReplenishThreshold = this.autoRebillInfoFormGroup.controls['rebillAmount'].value;
        this.creditCard.PadDayOfMonth = this.account.PadDayOfMonth;
    
        if(this.creditCard.PaymentBankType == BankAccountType.Personal) {
          this.creditCard.PaymentFirstName = this.account.BankAccountFirstName;
          this.creditCard.PaymentLastName = this.account.BankAccountLastName;
        } else {
          this.creditCard.PaymentLastName = this.account.BankAccountLastName;
        }
      }

      this.creditCard.BalanceReplenishAmount = this.autoRebillInfoFormGroup.controls['rebillAmount'].value;
      this.creditCard.BalanceWarningThreshold = Math.abs(this.account.BalanceWarning);
      this.creditCard.ActionCode = enabled?"ENPA":"DSPA";

      this.logService.logMessage(
        'Attempt HHB.enablePreAuth('+this.account.AccountID+') for ' +this.account.PaymentType + ' to ' + enabled
      );
      //this.alertService.clearAll();
      this.accountService
        .enableCardPreAuthorization(this.creditCard, enabled)
        .subscribe(
          () => {
            // preauth enabled
            this.accountService
              .loadActiveAccount(this.account.AccountID)
              .subscribe(() => {
                // 1 second delay so if clicked again quickly db has different datetime
                setTimeout(() => {
                  this.isProcessing = false;
                }, 1000);
                if (enabled) {
                  this.alertService.success(
                    'Auto Replenishment has been enabled.',
                    true
                  );
                } else {
                  this.alertService.success(
                    'Auto Replenishment has been disabled.',
                    true
                  );
                }
              },
              (err2) => {
                console.log('enablePreAuth-LoadAcc failed: ' + JSON.stringify(err2));
              }
            );
          },
          (error) => {
            console.log('enablePreAuth-Update failed: ' + JSON.stringify(error));
            this.logService.logMessage(
              'payment process failed at enablePreAuth' +
                JSON.stringify(this.creditCard)
            );
            var errMsg = this.translateService.instant(error?.error?.Message);
            this.alertService.error(
              'There was an error in setting up auto-replenishment [' + errMsg + ']',
              false
            );
            this.isProcessing = false;
          }
        );
    }
  }

  convertDebitCredit(value: number) {
    return Math.abs(value);
  }

  cancel() {
    this.isCancelProcessing = true;
    this.accountService
      .loadActiveAccount(this.account.AccountID)
      .subscribe(() => {
        this.autoRebillInfoFormGroup.controls['rebillStatus'].enable();
        this.autoRebillInfoFormGroup.controls[
          'rebillStatus'
        ].updateValueAndValidity();
        this.rebillEdit = false;
        this.isCancelProcessing = false;
      });
  }

  canclePreAuthorizationAmout() {
    this.creditCard.BalanceReplenishAmount = this.oldBalanceReplenishAmount;
    this.creditCard.BalanceReplenishThreshold =
      this.oldBalanceReplenishThreshold;
  }

  editAutoRebill() {
    this.autoRebillInfoFormGroup.controls['rebillStatus'].disable();
    this.autoRebillInfoFormGroup.controls[
      'rebillStatus'
    ].updateValueAndValidity();

    this.rebillEdit = true;
  }

  getCardType(card: string): string {
    // "visa","visaElectron","mastercard","maestro";"auto:MasterPass"
    let cardType = 'undefined';
    if (card.startsWith('4')) {
      cardType = 'visa';
    } else if (
      card.startsWith('54') ||
      card.startsWith('55') ||
      card.startsWith('36')
    ) {
      cardType = 'dinners';
    } else if (card.startsWith('5')) {
      cardType = 'mastercard';
    } else if (card.startsWith('3')) {
      cardType = 'amex';
    }
    return cardType;
  }

  getDropDownPaymentType(): string{
    if (this.paymentEditMode == false){
      return this.account.PaymentType
    }
    else{
      return this.dropDownPaymentType
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
