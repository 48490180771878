/* eslint-disable @angular-eslint/component-selector */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AccountService,
  AlertService,
  AuthServiceProvider,
  ChasePaymentService,
  ComponentMode,
  ConfigurationService,
  LocalstorageService,
  LogService,
  ThemeService,
  TransactionResponse,
} from '@ibitoll/toll-core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs';
import { SubSink } from 'subsink';
import { ChaseReceiptComponent } from '../chase-receipt/chase-receipt.component';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';


@Component({
  selector: 'hhb-chase-wrapper',
  templateUrl: './chase-wrapper.component.html',
  styleUrls: ['./chase-wrapper.component.scss'],
})
export class ChaseWrapperComponent implements OnInit, OnDestroy {
  @ViewChild('errorModal') errorModal: ElementRef;

  @Input() componentMode: ComponentMode = ComponentMode.Self;
  public mode = ComponentMode.Emit;

  ticketNo: string;
  processing = false;
  requestProcessed: boolean;
  errorObj: { title: string; message: string };
  showConfirmPayment = false;

  amount: number;
  chaseLink = this.themeService.active.ChaseHPFLink; // 'https://chase-var.hostedpaymentservice.net/hpf/?uid=';
  url: string;
  tokenInfo = {}
  private subs = new SubSink();

  constructor(
    public logService: LogService,
    public authService: AuthServiceProvider,
    public formBuilder: FormBuilder,
    public configService: ConfigurationService,
    public sanitizer: DomSanitizer,
    public chasePaymentService: ChasePaymentService,
    private modalService: NgbModal,
    public themeService: ThemeService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    public router: Router,
    private alertService: AlertService
  ) {}
  helper = new JwtHelperService();

  ngOnInit(): void {
    debugger
    this.ticketNo = this.route.snapshot.queryParamMap.get('ticketNo');
    this.url =  this.chaseLink + this.ticketNo;
    this.amount = Number(this.route.snapshot.queryParamMap.get('amount'));
    const token = this.route.snapshot.queryParamMap.get('token');

    if (!token || this.helper.isTokenExpired(token) ){
      this.router.navigateByUrl('/login');
    }

    //this.localstorageService.setItem(TOKEN, token);

    this.tokenInfo = this.helper.decodeToken(token);

  }

  @HostListener('window:message', ['$event'])
  init(paymentResult: any): void {
    console.log('[CW]one-time pay Chase resp', paymentResult);

    if (paymentResult.data['subject'] == 'init') {
      console.log('init', paymentResult);
    }

    if (paymentResult.data['subject'] == 'startPayment') {
      console.log('startPayment', paymentResult);
    }

    if (paymentResult.data['subject'] == 'completePayment') {
      this.postResponse();
    }

    if (paymentResult.data['subject'] == 'cancelPayment') {
      //this.isTicketAvailable = false;
      console.log('cancelPayment', paymentResult.data.content);
      this.logService.logMessage('[CW]cancel: amount=' + this.amount + ' data: ' + JSON.stringify(paymentResult.data));
      window.close() 
      this.alertService.info('Please press “Done” at the top left to return back to your account', true);

      // setTimeout(() => {
      //   window.alert('Please close this window and press “Done” at the top left to return back to your account');
      // }, 400);
      
    }

    if (paymentResult.data['subject'] == 'handlePaymentErrors') {
      //this.isTicketAvailable = false;
      console.log('handlePaymentErrors', paymentResult.data.content);
      this.logService.logMessage('[CW]handlePaymentErrors: amount=' + this.amount + ' data: ' + JSON.stringify(paymentResult.data));
      //this.postResponse();
      this.alertService.error(
        this.displayError(paymentResult),
        true
      );
    }

    if (paymentResult.data['subject'] == 'handleTransactionErrors') {
      //this.isTicketAvailable = false;
      console.log('handleTransactionErrors', paymentResult.data.content);
      this.logService.logMessage('[CW]handleTransactionErrors: amount=' + this.amount + ' data: ' + JSON.stringify(paymentResult.data));
      //this.postResponse();
      this.alertService.error(
        this.displayError(paymentResult),
        true
      );
    }
  }

  displayError(msg: any) {
    try {
      let error_message;
      if (msg.data.content && Array.isArray(msg.data.content) && msg.data.content.length > 0)
      {
        if (msg.data.content[0].status_code == '2300'){
          error_message = msg.data.content[0].gateway_message
        }
        else{
          error_message = msg.data.content[0].status_message
        }
        return `[CW]Transaction declined: ${error_message}. Please try again.`
      }
      else if (msg.data.content && msg.data.content.status_code) {

        if (msg.data.content.status_code == '2300'){
          error_message = msg.data.content.gateway_message
        }
        else{
          error_message = msg.data.content.status_message
        }
        return `[CW]Transaction declined: ${error_message}. Please try again.`
      }
      else{
        return `[CW]Transaction declined, Please try again.`
      }
    } catch (error) {
      return `[CW]Transaction declined, Please try again.`
    }
  }

  postResponse() {
    debugger
    this.processing = true;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap(() => {
          debugger
          return this.chasePaymentService.postResponse(
            this.amount,
            this.tokenInfo['AccountId'],
            1,
            1,
            this.ticketNo
          );
        })
      )
      .subscribe({
        next: (receiptResponse) => {
          // this.accountService
          //   .loadActiveAccount(this.token_value['AccountId'])
          //   .subscribe({
          //     next: () => {
                //this.isTicketAvailable = false;
                this.processing = false;
                this.displayReceipt(receiptResponse);
                //debugger
                this.hideChase();

                //this.paymentForm.reset();
                //this.chaseFormGroup.reset();
             // },
            //});
        },
        error: () => {
          debugger
          //this.isTicketAvailable = false;
          this.processing = false;
          this.hideChase();
          window.alert('An error occurred, please close the window and try again.');
          //this.paymentForm.reset();
          //this.chaseFormGroup.reset();
        },
      });
  }


  confirmPayment() {
    this.showConfirmPayment = true;
    console.log("Confirm ?");
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hideChase() {}

  showChase() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  }

  displayReceipt(txnResp: TransactionResponse): any {
    this.showConfirmPayment = false;
    if (txnResp.ResponseOrderID) {
      const softHyphen = '-'; // &shy; does not work here for some reasons
      let a = txnResp.ResponseOrderID;
      let count = Math.floor(a.length / 12);
      while (count > 0) {
        const position = count * 12;
        if (a.length > position) {
          a = [a.slice(0, position), softHyphen, a.slice(position)].join('');
        }
        count--;
      }
      txnResp.ResponseOrderID = a;
    }

    const modalRef = this.modalService.open(ChaseReceiptComponent, {
      animation: true,
      centered: true,
    });
    modalRef.componentInstance.transactionResponse = txnResp;
    modalRef.componentInstance.currentDate = new Date();

    modalRef.result.then((result) => {
      console.log(`Modal closed with result: ${result}`);
      window.close();
      this.alertService.info('Please press “Done” at the top left to return back to your account', true);


    }, (reason) => {
      debugger
      console.log(`Modal dismissed with reason: ${reason}`);
      window.close();
      this.alertService.info('Please press “Done” at the top left to return back to your account', true);

    });
  }

  displayError2(msg: any) {
    if (msg) {
      this.requestProcessed = false; // hide form and show error
      this.errorObj = {
        title: '',
        message: '',
      };

      this.errorObj.title = 'Payment Incomplete!';
      this.errorObj.message = `The payment information provided in the authorization request appears to be invalid.
      Please check is Card is valid and retry.`;

      if (msg.errorMessage) {
        this.errorObj.title = msg.errorName;
        this.errorObj.message = msg.errorMessage;
      }

      if (msg.ErrorCode || msg.errorMessage) {
        this.errorObj.title = 'Payment Incomplete!';

        switch (msg.ErrorCode || msg.errorCode) {
          case '5106' || '5105':
            this.errorObj.message = `The bank information provided in the authorization request appears to be invalid.
             Please check account and transit/routing number details and retry.`;
            break;

          case '5001':
            this.errorObj.message =
              'The credit card expiration date entered is invalid';
            break;

          case '5000':
            this.errorObj.message = 'The credit card number entered is invalid';
            break;

          case '5021':
            this.errorObj.message =
              'The CVV2 number should be 3 or 4 digits in length';
            break;

          case '5022':
            this.errorObj.message =
              'The CVV2 number should be numeric character only.';
            break;

          default:
            this.errorObj.message = `Payment incomplete. The payment information provided in the authorization
             request appears to be invalid.
            Please check payment details and try again.`;
            break;
        }
      }

      if (msg?.error?.Message) {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = this.translateService.instant(
          msg?.error?.Message
        );
      }

      if (msg === 'Payment Failed') {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = `The payment declined. Please check payment details and try again.`;
      }

      if (msg.ssl_result_message === 'DECLINED') {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = `The payment declined. Please check payment details and try again.`;
      }

      if (msg.ssl_result_message === 'DECLINED: NSF') {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = `The payment declined. Please check payment details and try again.`;
      }

      if (msg.ssl_result_message === 'SERV NOT ALLOWED') {
        this.errorObj.title = 'Invalid Payment';
        this.errorObj.message = `The payment information provided appears to be invalid.
       Please check payment details and try again.`;
      }

      setTimeout(() => {
        this.modalService.open(this.errorModal, { size: 'lg' });
      });
    }

    this.processing = false;
    this.showConfirmPayment = false;
  }

  formatMoneyValue(val: number):string {
    var formatted = val.toFixed(2);
    return formatted;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
