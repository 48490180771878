<div *ngIf="!integrated" class="section-header mb-4">
  <h4>{{ 'ACCOUNT_ACTIVITY' | translate }}</h4>
</div>

<div *ngIf="integrated" class="mt-5 mx-4">
  <div class="h5">
    <b>{{ 'ACCOUNT_ACTIVITY' | translate }}</b>
  </div>
</div>

<div class="mx-4">
  <form *ngIf="!integrated">
    <div class="form-group mt-4">
      <div class="row">
        <div class="col-md-6">
          <div class="col-md-12 col-sm-12 pl-0 pt-1">
            <label for="searchDateRange" class="mb-4">{{
              'TRANSACTION_DATE_RANGE' | translate
            }}</label>
            <div class="input-group">
              <input
                class="form-control"
                angular-mydatepicker
                name="reactiveFormsDate"
                (click)="dp.toggleCalendar()"
                [options]="dateRangePickerOptions"
                (dateChanged)="onSearchDateRangeChanged($event)"
                [locale]="translate.defaultLang"
                [(ngModel)]="dModel"
                #dp="angular-mydatepicker"
                autocomplete="one"
              />
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="dp.clearDate()"
                >
                  <i class="fa fa-times"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="dp.toggleCalendar()"
                >
                  <i class="fa fa-calendar"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 pt-2">
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [(ngModel)]="paymentChecked"
                    name="payment"
                  />{{ 'PAYMENTS' | translate }}
                </label>
              </div>

              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [(ngModel)]="transactionChecked"
                    name="transaction"
                  />{{ 'TRANSACTIONS' | translate }}
                </label>
              </div>

              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [(ngModel)]="feeChecked"
                    name="fee"
                  />{{ 'FEES' | translate }}
                </label>
              </div>

              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [(ngModel)]="adjustmentChecked"
                    name="adjustment"
                  />{{ 'ADJUSTMENT' | translate }}
                </label>
              </div>

              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [(ngModel)]="refundChecked"
                    name="refund"
                  />{{ 'REFUND' | translate }}
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8 col-sm-8">
              <select
                class="form-control form-select"
                id="selectTransponder"
                [(ngModel)]="selectedTransponder"
                name="selectedTransponder"
              >
                <option value="{{ allTransponderOption.value }}">
                  {{ allTransponderOption.TransponderText | translate }}
                </option>
                <option
                  *ngFor="let transponder of transponderOptions"
                  [ngValue]="transponder.TransponderText"
                >
                  {{ transponder.TransponderText }}
                </option>
              </select>
            </div>
            <div class="col-md-4 col-sm-4 d-none d-sm-none d-md-block">
              <button
                class="btn btn-md btn-app-primary float-md-right"
                (click)="downloadXlsx()"
              >
                <i class="fa fa-cloud-download pr-1" ></i
                >{{ 'DOWNLOAD_CSV' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="mx-4">
  <div class="table-responsive d-none d-md-block">
    <!--d-none d-md-block">-->
    <div class="table-responsive">
      <table class="table" style="width: 100%" id="data-table">
        <thead>
          <tr class="">
            <th scope="col">{{ 'TRANSACTION_TIME' | translate }}</th>
            <th scope="col">{{ 'TRANSACTION' | translate }}</th>
            <th scope="col">{{ 'TRANSPONDERS_SMARTCARD' | translate }}</th>
            <th scope="col">{{ 'LOCATION' | translate }}</th>
            <th scope="col">{{ 'CLASS' | translate }}</th>
            <th scope="col">{{ 'AMOUNT' | translate }}</th>
            <th scope="col">{{ 'ACCOUNT_BALANCE' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="loadingData">
            <td colspan="8">
              <div class="text-center my-4 mx-auto loader">
                <i
                  class="fa fa-4x fa-spinner fa-pulse align-middle"

                ></i>
              </div>
            </td>
          </tr>
          <tr
            class="activity-row"
            *ngFor="
              let transaction of transactions
                | transactionTypeFilter
                  : paymentChecked
                  : transactionChecked
                  : feeChecked
                  : adjustmentChecked
                  : refundChecked
                  : true
                  : true
                  : true 
                  : selectedTransponder
            "
          >
            <td>
              {{ getDate(transaction.SysPostLDateTimeStd) | dateTimeFormatter }}
            </td>
            <td>{{ getTransactionType(transaction) | translate }}</td>
            <td>{{ transaction.TransponderText | translate }}</td>
            <td>{{ getLocation(transaction) }}</td>
            <td>{{ displayCategory(transaction.TollCategoryName) }}</td>
            <td>
              {{
                parseAmount(transaction.TransactionAmount)
                  | currency
                    : themeService.active.DefaultCurrency
                    : 'symbol'
                    : '1.2-2'
              }}
              {{ isCredit(transaction.TransactionAmount) }}
            </td>
            <td>
              {{
                parseAmount(transaction.AccountBalanceActual)
                  | currency
                    : themeService.active.DefaultCurrency
                    : 'symbol'
                    : '1.2-2'
              }}
              {{ isCredit(transaction.AccountBalanceActual) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="d-md-none">
  <ul class="list-group list-group-flush">
    <li class="list-group-item" *ngFor="let transaction of transactions">
      <div class="row">
        <div class="col-8">
          <div class="h5">
            {{
              getDate(transaction.TransactionDateTimeStd) | dateTimeFormatter
            }}
          </div>
        </div>
        <div class="col-4">
          <span class="float-right">
            {{
              parseAmount(transaction.TransactionAmount)
                | currency: themeService.active.DefaultCurrency:'symbol':'1.2-2'
            }}
            {{ isCredit(transaction.TransactionAmount) }}
          </span>
        </div>
      </div>
      <div>
        <div>
          <b>Posted:</b>
          {{ getDate(transaction.SysPostLDateTimeStd) | dateTimeFormatter }}
        </div>
        <div>
          <b>{{ 'TRANSACTION' | translate }}:</b>
          {{ getTransactionType(transaction) }}
        </div>
        <div *ngIf="getDescription(transaction)">
          <b>Details:</b> {{ getDescription(transaction) }}
        </div>
        <div *ngIf="transaction.TransponderDisplayId">
          <b>{{ 'TRANSPONDERS_SMARTCARD' | translate }}:</b>
          {{ transaction.TransponderDisplayId }}
        </div>
        <div *ngIf="getLocation(transaction)">
          <b>{{ 'LOCATION' | translate }}:</b> {{ getLocation(transaction) }}
        </div>
        <div *ngIf="transaction.TollCategoryName">
          <b>{{ 'CLASS' | translate }}:</b> {{ transaction.TollCategoryName }}
        </div>
        <div class="float-right mt-2">
          <b>{{ 'ACCOUNT_BALANCE' | translate }}:</b>
          {{
            parseAmount(transaction.AccountBalanceActual)
              | currency: themeService.active.DefaultCurrency:'symbol':'1.2-2'
          }}
          {{ isCredit(transaction.AccountBalanceActual) }}
        </div>
      </div>
    </li>
  </ul>
</div>
