export class ErrorDto {
  Message: string;
  Url: string;
  Stack: string;

  /**
   *
   */
  constructor(message: string, url: string, stack: string) {
    this.Message = message;
    this.Url = url;
    this.Stack = stack;
  }
}
