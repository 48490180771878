<div class="container-12">
  <div class="row>">
    <div class="card mb-3">
      <div class="card-header">
        {{ 'BALANCE' | translate }}
      </div>
      <div class="card-body text-center">
        <div class="card-title text-center">
          <div class="text-muted h4">{{ 'BALANCE_ON_ACCOUNT' | translate }}</div>
        </div>

        <div class="card-title h4"
          [ngClass]="{'text-danger' :  account?.BalanceStatus == 3, 'text-warning': account?.BalanceStatus == 2, 'text-success' :  account?.BalanceStatus == 1}">
          <currency-type></currency-type>
          {{
            parseAmount(account?.AccountBalance)
              | currency: configurationService.config.DefaultCurrency
          }}
        </div>
      </div>
      <small class="card-footer text-muted text-center" *ngIf="account?.LastPaymentDateTime">
        {{ 'UPDATED_ON' | translate }}:
        {{ getDate(account?.LastPaymentDateTime) | dateTimeFormatter }}</small>
    </div>
  </div>
</div>
