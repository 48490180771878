import { TranslateService } from '@ngx-translate/core';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  isDevMode,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ComponentMode } from '../../enum/component-mode.enum';
import { Account } from '../../models/account';
import { CardQueryWithExpiry } from '../../models/cardQuery';
import { CreditCard } from '../../models/creditCard';
import { AccountProfileConfig } from '../../models/idNameConfig';
import { TransactionResponse } from '../../models/transactionResponse';
import { User } from '../../models/user';
import { WebUserCardInfo } from '../../models/webUserCardInfo';
import { AccountService } from '../../providers/account.service';
import { AlertService } from '../../providers/alert.service';
import { ConfigurationService } from '../../providers/configuration.service';
import { DatabaseMapService } from '../../providers/databasemap.service';
import { LogService } from '../../providers/log.service';
import { MonerisService } from '../../providers/moneris.service';
import { PaymentService } from '../../providers/payment.service';
import { UserService } from '../../providers/user.service';
import { MonerisReceiptComponent } from '../moneris-receipt/moneris-receipt.component';
import { ScriptService } from 'ngx-script-loader';
import { MonerisPreLoadQuery } from '../../models/monerisPreLoadQuery';


declare const monerisCheckout: any;

@Component({
  selector: 'moneris-auto-rebill',
  templateUrl: './moneris-auto-rebill.component.html',
  styleUrls: ['./moneris-auto-rebill.component.scss'],
})
export class MonerisAutoRebillComponent
  implements OnInit, AfterViewInit, DoCheck {
  @Input() componentMode: ComponentMode = ComponentMode.Self;

  @Input() cardMode: boolean = true;

  @Input() language: string = 'EN';

  @Output() paymentatus = new EventEmitter<any>();

  @Output() cardChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() onTransactionCancel = new EventEmitter<any>();

  @Output() onTransactionComplete = new EventEmitter<any>();


  user: User;
  account: Account;
  subs = new SubSink();
  creditCardQuery: CreditCard;
  accountProfileOptions: AccountProfileConfig[];

  BalanceReplenishAmount = 0;
  BalanceReplenishThreshold = 0;

  oldBalanceReplenishAmount = 0;
  oldBalanceReplenishThreshold = 0;

  balanceReplenishAmountLimit = 50;
  balanceReplenishThresholdLimit = 20;

  isLoading = false;
  public editMode = false;
  isTicketAvailable: boolean = false;
  paymentToken: string;

  processing: boolean;
  ticketNo: any;

  myCheckout: any;

  monerisUrl = `https://www3.moneris.com/HPPtoken/index.php?id=htXY2OUEAI5RTRZ&pmmsg=true&css_body=background:white;
  &css_textbox=border-width:2px;
  &display_labels=2&css_textbox_exp=width:40px;

  &css_label_pan=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_pan=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;

  &css_label_exp=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_exp=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;

  &css_label_cvd=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_cvd=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;
  &enable_cvd=1&enable_exp=1&exp_label=Card Expiry Date (MMYY)&cvd_label=CVV`;

  monerisEnUrl = `https://www3.moneris.com/HPPtoken/index.php?id=htXY2OUEAI5RTRZ&pmmsg=true&css_body=background:white;
  &css_textbox=border-width:2px;
  &display_labels=2&css_textbox_exp=width:40px;

  &css_label_pan=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_pan=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;

  &css_label_exp=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_exp=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;

  &css_label_cvd=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_cvd=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;
  &enable_cvd=1&enable_exp=1&exp_label=Card Expiry Date (MMYY)&cvd_label=CVV`;



  monerisFrUrl = `https://www3.moneris.com/HPPtoken/index.php?id=htXY2OUEAI5RTRZ&pmmsg=true&css_body=background:white;
  &css_textbox=border-width:2px;
  &display_labels=2&css_textbox_exp=width:40px;

  &css_label_pan=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_pan=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;

  &css_label_exp=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_exp=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;

  &css_label_cvd=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_cvd=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;
  &enable_cvd=1&enable_exp=1&exp_label=Date d'expiration de la carte (MMYY)&cvd_label=CVV&pan_label=Numéro de carte`;


  monerisProdEnUrl = `https://www3.moneris.com/HPPtoken/index.php?id=htXY2OUEAI5RTRZ&pmmsg=true&css_body=background:white;
  &css_textbox=border-width:2px;
  &display_labels=2&css_textbox_exp=width:40px;

  &css_label_pan=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_pan=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;

  &css_label_exp=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_exp=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;

  &css_label_cvd=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_cvd=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;
  &enable_cvd=1&enable_exp=1&exp_label=Card Expiry Date (MMYY)&cvd_label=CVV`;



  monerisProdFrUrl = `https://www3.moneris.com/HPPtoken/index.php?id=htXY2OUEAI5RTRZ&pmmsg=true&css_body=background:white;
  &css_textbox=border-width:2px;
  &display_labels=2&css_textbox_exp=width:40px;

  &css_label_pan=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_pan=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;

  &css_label_exp=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_exp=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;

  &css_label_cvd=position:unset;font-family: Roboto, "Helvetica Neue", sans-serif;margin-bottom:5px;font-size: 0.9rem;
  &css_textbox_cvd=width:100%;margin-bottom:10px;padding:0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height:1.5;color:black;background-color:white;background-clip:padding-box;border:1px solid rgb(185 185 185);appearance:none;border-radius: 0.25rem;margin-top: 5px;
  &enable_cvd=1&enable_exp=1&exp_label=Date d'expiration de la carte (MMYY)&cvd_label=CVV&pan_label=Numéro de carte`;



  monerisDoc: any;

  @ViewChild('monerisFrame', { static: false }) iframe: ElementRef;
  hideButtons: boolean = false;

  savedCards: WebUserCardInfo[] = [];
  public isRemoveProcessing: boolean;
  public isDefaultProcessing: boolean;
  public selectedCard: WebUserCardInfo;
  public isRemoveActiveCardProcessing: boolean = false;

  @ViewChild('removeCardModal') activeCardRemoveModal: ElementRef;
  @ViewChild('removeSavedCardModal') activeSavedCardRemoveModal: ElementRef;
  @ViewChild('setSavedCardModal') setActiveCardModal: ElementRef;
  isSavedProcessing = false;

  constructor(
    private accountService: AccountService,
    private alertService: AlertService,
    private userService: UserService,
    private databaseMapService: DatabaseMapService,
    public configService: ConfigurationService,
    public logService: LogService,
    public dialog: MatDialog,
    public translateService: TranslateService,
    private paymentService: PaymentService,
    public monerisService: MonerisService,
    private scriptService: ScriptService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef
  ) {

    if (isDevMode()) {
      this.scriptService
        .loadScript('https://gatewayt.moneris.com/chkt/js/chkt_v1.00.js')
        .subscribe(() => { });
    } else {
      this.scriptService
        .loadScript('https://gateway.moneris.com/chkt/js/chkt_v1.00.js')
        .subscribe(() => { });
    }

  }

  ngAfterViewInit(): void {
    if (this.componentMode == ComponentMode.Emit) {
      if (!this.editMode) {
        this.editMode = true;
      }

      this.hideButtons = true;
      this.cd.detectChanges();
    }
  }

  monerisInit() {
    console.log('monerisInit');
    this.myCheckout = new monerisCheckout();

    if (isDevMode()) {
      this.myCheckout.setMode('qa');
    } else {
      this.myCheckout.setMode('prod');
    }

    this.myCheckout.setCheckoutDiv('monerisCheckout');
    this.myCheckout.setCallback('page_loaded', this.myPageLoad.bind(this));
    this.myCheckout.setCallback('cancel_transaction', this.myCancelTransaction.bind(this));
    this.myCheckout.setCallback('error_event', this.myErrorEvent.bind(this));
    this.myCheckout.setCallback('payment_receipt', this.myPaymentReceipt.bind(this));
    this.myCheckout.setCallback('payment_complete', this.myPaymentComplete.bind(this));
  }

  signupTokenPreload(account: Account) {
    this.paymentatus = new EventEmitter<any>();

    var monerisPreLoadQuery = new MonerisPreLoadQuery();
    monerisPreLoadQuery.amount = account.TotalInitialAmount;
    monerisPreLoadQuery.cust_id = account.UserLogonId;
    monerisPreLoadQuery.email = account.Email;
    monerisPreLoadQuery.firstName = account.FirstName;
    monerisPreLoadQuery.lastName = account.LastName;
    monerisPreLoadQuery.phone = account.PrimaryPhone;


    this.processing = true;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          return this.monerisService.signupTokenPreload(
            monerisPreLoadQuery
          );
        })
      )
      .subscribe(
        (ticket) => {
          this.processing = false;
          this.ticketNo = ticket;
          this.isTicketAvailable = true;
          this.monerisInit();
          setTimeout(() => {
            this.myCheckout.startCheckout(this.ticketNo);
            this.paymentatus.next(true);
            this.paymentatus.complete()
          }, 100);
        },
        (error) => {
          this.hideMonerisMCO();
          this.logService.logMessage('preLoad failed' + JSON.stringify(error));
          this.alertService.error('Unable to process your request at this time please try again later!', true);
          this.paymentatus.next(false);
          this.paymentatus.complete()
        }
      );
    return this.paymentatus.asObservable();

  }

  preLoad() {
    this.paymentatus = new EventEmitter<any>();

    this.processing = true;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          return this.monerisService.tokenPreload(
            this.account.AccountID,
            1,
            this.account.Language
          );
        })
      )
      .subscribe(
        (ticket) => {
          this.processing = false;
          this.ticketNo = ticket;
          this.isTicketAvailable = true;
          this.monerisInit();
          setTimeout(() => {
            this.myCheckout.startCheckout(this.ticketNo);

          }, 100);
        },
        (error) => {
          this.hideMonerisMCO();
          this.logService.logMessage('preLoad failed' + JSON.stringify(error));
          this.alertService.error(
            'Unable to process your request at this time please try again later!',
            true
          );
        }
      );
    return this.paymentatus.asObservable();

  }

  doMonerisSubmit() {

    this.paymentatus = new EventEmitter<any>();
    this.monerisDoc.postMessage(
      'tokenize',
      'https://www3.moneris.com/HPPtoken/index.php'
    );

    return this.paymentatus.asObservable();
  }

  ngOnInit(): void {
    this.isSavedProcessing = true;
    this.subs.add(
      this.accountService
        .getActiveAccount()
        .pipe(
          switchMap((account: Account) => {
            this.account = account;
            this.user = this.userService.getCurrentUser();
            return this.paymentService.getUserCardsInfo(this.account.AccountID);
          })
        )
        .subscribe((savedCards) => {
          if (this.account.PaymentNumber) {
            this.savedCards = savedCards?.filter((o) => {
              return (
                o.PaymentNum.substring(o.PaymentNum.length - 4) !==
                this.account?.PaymentNumber?.substring(
                  this.account.PaymentNumber.length - 4
                )
              );
            });
          } else {
            this.savedCards = savedCards
          }
          this.isLoading = false;
          this.isSavedProcessing = false;
          this.getAccountInfo();
        })
    );
  }

  ngDoCheck(): void {
    if (this.monerisDoc) {
      // console.log(this.monerisDoc.document.getElementById("monerisDataInput").value);
    }
  }

  myPageLoad(data) {
    console.log('myPageLoad', data);
  }

  myCancelTransaction(data) {
    this.hideMonerisMCO();
    console.log('myCancelTransaction', data);

    if (this.componentMode == ComponentMode.Emit) {
      this.onTransactionCancel.emit(data);
    }
  }

  myErrorEvent(data) {
    console.log('myErrorEvent', data);

    if (this.componentMode == ComponentMode.Emit) {
      this.onTransactionCancel.emit(data);
    }
  }

  myPaymentReceipt(data) {
    console.log('myPaymentReceipt', data);
  }

  hideMonerisMCO() {
    this.isTicketAvailable = false;
    this.processing = false;
  }

  myPaymentComplete(res: any) {
    if (this.componentMode == ComponentMode.Emit) {
      this.onTransactionCancel.emit(JSON.parse(res));
    } else {
      var strIntoObj = JSON.parse(res);
      this.processing = true;
      this.configService.CheckDbStatus().subscribe(
        (receiptResponse) => {
          // this.processing = false;
          this.SaveCardTokenInfo(strIntoObj.ticket);
        },
        (error) => {
          this.isTicketAvailable = true;
          this.processing = false;
          this.logService.logMessage(
            'CheckDbStatus failed' + JSON.stringify(error)
          );
          this.alertService.error('Unable to process your request at this time please try again later!', true);
        }
      );
    }


  }

  getAccountInfo() {
    this.configService
      .getAccountProfiles(false)
      .subscribe((accountProfiles) => {
        this.accountProfileOptions = accountProfiles;
        const profile = this.accountProfileOptions.find(
          (o) => o.AccountProfileID === this.account.AccountProfileID
        );

        if (profile) {
          this.balanceReplenishAmountLimit = Math.abs(profile.BalanceReplenishAmount);
          this.balanceReplenishThresholdLimit = Math.abs(profile.BalanceReplenishThreshold);
          this.BalanceReplenishAmount = this.balanceReplenishAmountLimit;
          this.BalanceReplenishThreshold = this.balanceReplenishThresholdLimit;
        }
      });
  }

  processPreauthToken() {
    const user = this.userService.getCurrentUser();
    this.processing = true;
    this.alertService.clear();

    const query: CardQueryWithExpiry = new CardQueryWithExpiry();
    query.FirstName = this.account.FirstName;
    query.LastName = this.account.LastName;
    query.Email = user.EmailAddress;
  }

  removeActiveCard() {
    this.isRemoveActiveCardProcessing = true;
    this.creditCardQuery = new CreditCard();
    this.creditCardQuery.UserId = this.user.UserId;
    this.creditCardQuery.UserLogonId = this.user.UserName;
    // this.creditCardQuery.PaymentLastName = this.account.LastName;
    this.creditCardQuery.AccountId = this.user.AccountId;
    this.creditCardQuery.BalanceReplenishAmount = this.BalanceReplenishAmount;
    this.creditCardQuery.BalanceReplenishThreshold = this.BalanceReplenishThreshold;
    this.creditCardQuery.BalanceWarningThreshold = Math.abs(this.account.BalanceWarning);
    this.creditCardQuery.PaymentNum = this.account.PaymentNumber;
    this.creditCardQuery.PreauthStatus = this.databaseMapService.ePreAuthStatus.disabled;
    // this.creditCardQuery.PaymentCardExpiryMonth = this.account.PreAuthCardExpiryMonth;
    // this.creditCardQuery.PaymentCardExpiryYear = this.account.PreAuthCardExpiryYear;
    this.creditCardQuery.CardBankTokenId = this.account.BankCardTokenId;
    // this.creditCardQuery.PaymentType = this.databaseMapService.ePaymentTypeMap.CreditCard;
    // this.creditCardQuery.PaymentCardType = this.account.PaymentCardType;
    this.creditCardQuery.PaymentNum = this.account.PaymentNumber;


    this.monerisService.updatePaymentDetailsWithMoneris(this.creditCardQuery).subscribe(
      () => {
        this.accountService
          .loadActiveAccount(this.account.AccountID)
          .subscribe(() => {
            if (this.editMode) {
              this.editMode = false;
            }
            this.processing = false;
            this.alertService.success(
              'Credit card has been successfully removed.',
              true
            );
            this.isRemoveActiveCardProcessing = false;
            this.modalService.dismissAll();
            this.cd.detectChanges();
          });
      },
      () => {
        this.modalService.dismissAll();
        this.isRemoveActiveCardProcessing = false;

      }
    );
  }

  isPreAuthEnabled(): boolean {
    return (
      this.account?.PreAuthStatus !== undefined &&
      this.account?.PreAuthStatus ===
      this.databaseMapService.ePreAuthStatus.active
    );
  }

  setCardActive(card: WebUserCardInfo) {
    this.isDefaultProcessing = true;

    this.selectedCard = card;
    var expDate = new Date(card.BankCardTokenExpiryDate);
    var year = expDate.getFullYear() - 2000;
    var month = ('0' + (expDate.getMonth() + 1)).slice(-2);

    this.creditCardQuery = new CreditCard();
    this.creditCardQuery.UserId = this.user.UserId;
    this.creditCardQuery.UserLogonId = this.user.UserName;
    this.creditCardQuery.AccountId = this.user.AccountId;
    this.creditCardQuery.PaymentType = this.databaseMapService.ePaymentTypeMap.CreditCard;
    this.creditCardQuery.PaymentCardType = card.CardType;
    this.creditCardQuery.PaymentNum = card.PaymentNum;
    this.creditCardQuery.PaymentCardExpiryYear = year;
    this.creditCardQuery.PaymentCardExpiryMonth = +month;
    this.creditCardQuery.CardBankTokenId = card.BankCardTokenId;
    this.creditCardQuery.PaymentLastName = this.account.LastName;
    this.creditCardQuery.BalanceReplenishAmount = this.BalanceReplenishAmount;
    this.creditCardQuery.BalanceReplenishThreshold = this.BalanceReplenishThreshold;
    this.creditCardQuery.BalanceWarningThreshold = Math.abs(this.account.BalanceWarning);
    this.creditCardQuery.PreauthStatus = this.isPreAuthEnabled()
      ? this.databaseMapService.ePreAuthStatus.active
      : this.databaseMapService.ePreAuthStatus.suspended;

    this.accountService.updatePaymentDetails(this.creditCardQuery).subscribe(
      () => {
        this.accountService
          .loadActiveAccount(this.account.AccountID)
          .subscribe(() => {
            this.isDefaultProcessing = false;
            if (this.editMode) {
              this.editMode = false;
            }

            this.processing = false;
            this.alertService.success('Card information successfully updated', false);
            this.cardChanged.emit(true);
            this.modalService.dismissAll();
            this.cd.detectChanges();
          });
      },
      () => {
        this.modalService.dismissAll();
        this.isDefaultProcessing = false;
        this.processing = false;
        // this.alertService.error('Error occured in updating account ', true);
      }
    );
  }

  removeActiveCardConfirmation() {
    var activeModal = this.modalService.open(this.activeCardRemoveModal, { ariaLabelledBy: 'remove-active-card' });
    activeModal.shown.subscribe(() => {
      console.log('removeActiveCardConfirmation modal showen')
    });
  }

  removeSavedCardConfirmation(card: WebUserCardInfo) {
    this.selectedCard = card;
    var activeModal = this.modalService.open(this.activeSavedCardRemoveModal, { ariaLabelledBy: 'remove-saved-card' });
    activeModal.shown.subscribe(() => {
      console.log('removeSavedCardConfirmation modal showen')
    });
  }

  setActiveCardConfirmation(card: WebUserCardInfo) {
    this.selectedCard = card;
    var activeModal = this.modalService.open(this.setActiveCardModal, { ariaLabelledBy: 'set-saved-card-active' });
    activeModal.shown.subscribe(() => {
      console.log('setActiveCardConfirmation modal showen')
    });
  }

  removeCardActive(card: WebUserCardInfo) {
    this.selectedCard = card;
    card.AccountId = this.account.AccountID.toString();
    card.CardAlias = '';
    card.CardStatus = false;
    this.isRemoveProcessing = true;
    this.paymentService.setUserCardsInfo(card).subscribe(
      (data) => {
        this.paymentService.getUserCardsInfo(this.account.AccountID).subscribe(
          (savedCards) => {
            if (this.account.PaymentNumber) {
              this.savedCards = savedCards?.filter((o) => {
                return (
                  o.PaymentNum.substring(o.PaymentNum.length - 4) !==
                  this.account?.PaymentNumber?.substring(
                    this.account.PaymentNumber.length - 4
                  )
                );
              });
            } else {
              this.savedCards = savedCards
            }
            this.isRemoveProcessing = false;
            this.alertService.successTranslate(
              'CARD_REMOVED_SUCCESS_MSG',
              true
            );
            this.modalService.dismissAll();
          },
          (error) => {
            this.modalService.dismissAll();
            this.isRemoveProcessing = false;
          }
        );
      },
      (error) => {
        this.isRemoveProcessing = false;
      }
    );
  }

  getMaskedCardNumber(cc: string) {
    var last4 = cc.substring(cc.length - 4);
    return '************' + last4;
  }

  editModeEnabled() {
    this.editMode = !this.editMode;
    this.cd.detectChanges();
  }


  @HostListener('window:message', ['$event'])
  message(event: any) {
    if (event.data) {
      try {
        const obj = JSON.parse(event.data);
        if (obj.dataKey) {
          if (this.componentMode == ComponentMode.Self) {
            this.SaveCardTokenInfo(obj.dataKey);
          } else {
            this.paymentatus.emit(obj.dataKey);
          }
        }

        if (obj.errorMessage) {
          this.displayError(obj);
          this.paymentatus.emit(obj.dataKey);
        }
      } catch (error) {
        this.paymentatus.emit(error);
      }
    }
  }

  displayError(errorObj: any) {
    var errorMsg = '';

    for (let index = 0; index < errorObj.responseCode.length; index++) {
      const element = errorObj.responseCode[index];

      if (element == '943') {
        if (errorMsg == '') {
          errorMsg = this.translateService.instant('CARD_NUMBER_INVALID');
        } else {
          errorMsg = errorMsg + ',' + this.translateService.instant('CARD_NUMBER_INVALID');
        }
      }

      if (element == '944') {
        if (errorMsg == '') {
          errorMsg = this.translateService.instant('CARD_DATE_INVALID');
        } else {
          errorMsg = errorMsg + ', ' + this.translateService.instant('CARD_DATE_INVALID');
        }
      }

      if (element == '945') {
        if (errorMsg == '') {
          errorMsg = this.translateService.instant('CARD_CVV_INVALID');
        } else {
          errorMsg = errorMsg + ', ' + this.translateService.instant('CARD_CVV_INVALID');
        }
      }
    }
    this.alertService.error(errorMsg, false);
  }

  @HostListener('window:onmessage', ['$event'])
  onmessage(event: KeyboardEvent) {
    console.log('onmessage', event);
  }

  SaveCardTokenInfo(dataKey: string) {
    this.processing = true;
    this.creditCardQuery = new CreditCard();
    this.creditCardQuery.UserId = this.user.UserId;
    this.creditCardQuery.UserLogonId = this.user.UserName;
    this.creditCardQuery.AccountId = this.user.AccountId;
    this.creditCardQuery.PaymentType = this.databaseMapService.ePaymentTypeMap.CreditCard;
    this.creditCardQuery.CardBankTokenId = dataKey;
    this.creditCardQuery.BalanceReplenishAmount = this.BalanceReplenishAmount;
    this.creditCardQuery.BalanceReplenishThreshold =
      this.BalanceReplenishThreshold;
    this.creditCardQuery.BalanceWarningThreshold = Math.abs(this.account.BalanceWarning);
    this.creditCardQuery.PreauthStatus = this.isPreAuthEnabled()
      ? this.databaseMapService.ePreAuthStatus.active
      : this.databaseMapService.ePreAuthStatus.suspended;
    this.monerisService
      .updatePaymentDetailsWithMoneris(this.creditCardQuery)
      .subscribe(
        () => {
          this.accountService
            .loadActiveAccount(this.account.AccountID)
            .subscribe(() => {
              this.hideMonerisMCO();
              if (this.editMode) {
                this.editMode = false;
              }

              this.processing = false;
              this.alertService.success(this.translateService.instant('CARD_INFO_UPDATED'), true);
              this.cardChanged.emit(true);
              this.cd.detectChanges();
            });
        },
        () => {
          this.hideMonerisMCO();
          if (this.editMode) {
            this.editMode = false;
          }
          this.processing = false;
          // this.alertService.error('Error occured in updating account ', true);
        }
      );
  }

  onLoad(iframe) {
    if (iframe) {
      this.monerisDoc = iframe.nativeElement.contentWindow;
      // this.monerisDoc.document.getElementById("monerisDataInput").value
    }
  }

  displayReceipt(txnResp: TransactionResponse): Observable<boolean> {
    var subject = new Subject<boolean>();
    if (txnResp.ResponseOrderID) {
      const softHyphen = '-'; // &shy; does not work here for some reasons
      let a = txnResp.ResponseOrderID;
      let count = Math.floor(a.length / 12);
      while (count > 0) {
        const position = count * 12;
        if (a.length > position) {
          a = [a.slice(0, position), softHyphen, a.slice(position)].join('');
        }
        count--;
      }
      txnResp.ResponseOrderID = a;
    }
    const dialogRef = this.dialog.open(MonerisReceiptComponent, {
      height: '285px',
      width: '450px',
      data: {
        transactionResponse: txnResp,
        currentDate: new Date(),
      },
      panelClass: 'moneris-receipt-dialog',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      subject.next(null);
      subject.complete();
    });

    return subject.asObservable();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
