import { TransponderType } from "./transponderType";


export class TransponderFee extends TransponderType {
  TransponderFeeId: number;
  TransponderFeeName: string;
  AccountProfileId: number;
  AccountFeeRate: number;
  CurrenctType: string;
}
