<div class="container-12">

  <form [formGroup]="addressInfoFormGroup">
    <div class="row">
      <div class="col-12">
        <h3 for="name"
            class="account-label mb-3">
          <b>
            {{ 'MAILING_ADDRESS' | translate }}
          </b>
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group mb-3">
          <label for="mailAddress"
                 class="form-label mb-1"><!-- this form is called on edit -->
            {{ 'ADDRESS' | translate }}
            <span class="non-ng-invalid-mark">*</span>
            <span *ngIf="canEditAccount()">
              {{ 'ADDRESS_APPARTMENT_NOTE' | translate | translateCut:0 }} 
              <span [ngStyle]="{'font-weight': 'bold', 'font-size': 'larger', 'color': 'red'}">{{ 'ADDRESS_APPARTMENT_NOTE' | translate | translateCut:1 }}</span> 
              {{ 'ADDRESS_APPARTMENT_NOTE' | translate | translateCut:2 }} 
            </span>
          </label>
          <input type="text"
                 class="form-control mb-1"
                 google-places-autocomplete
                 #places="gx-places"
                 [options]="options"
                 (onAddressChange)="onChange($event)"
                 id="mailAddress"
                 #mailAddress
                 formControlName="mailAddress"
                 name="mailAddress"
                 aria-label="Mailing Address"
                 maxlength="100"
                 onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                 onDrag="return false"
                 onDrop="return false"
                 onPaste="return false"
                 placeholder=""
                 required />

          <div *ngIf="
              canEditAccount() &&
              addressInfo.mailAddress.invalid &&
              (addressInfo.mailAddress.dirty || addressInfo.mailAddress.touched)
            "
               class="text-danger fade show ps-2"
               role="alert">
            <div *ngIf="addressInfo.mailAddress?.errors?.required">
              <small>{{ 'MAILING_ADDRESS_REQUIRED' | translate }} </small>
            </div>
            <div *ngIf="addressInfo.mailAddress?.errors?.maxlength">
              <small>{{
                'MAILING_ADDRESS_REQUIRED_100_CHARACTERS' | translate
                }}</small>
            </div>
          </div>
        </div>


      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group mb-3">
          <label for="mailCity"
                 class="form-label mb-1">
            {{ 'CITY' | translate }}
            <span class="non-ng-invalid-mark">*</span>

          </label>
          <input type="text"
                 class="form-control mb-1"
                 id="mailCity"
                 formControlName="mailCity"
                 name="mailCity"
                 aria-label="Mailing City"
                 maxlength="30"
                 onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                 onDrag="return false"
                 onDrop="return false"
                 onPaste="return false"
                 required />

          <div *ngIf="
              canEditAccount() &&
              addressInfo.mailCity.invalid &&
              (addressInfo.mailCity.dirty || addressInfo.mailCity.touched)
            "
               class="text-danger fade show ps-2"
               role="alert">
            <div *ngIf="addressInfo.mailCity?.errors?.required">
              <small>{{ 'CITY_REQUIRED' | translate }}</small>
            </div>
            <div *ngIf="addressInfo.mailCity?.errors?.maxlength">
              <small>{{ 'CITY_REQUIRED_30_CHARACTERS' | translate }}</small>
            </div>
          </div>
        </div>


      </div>
      <div class="col-sm-12 col-md-6">
        <label for="mailProvince"
               class="form-label mb-1">
          {{'STATE_PROVINCE' | translate}}
          <span class="non-ng-invalid-mark">*</span>
        </label>
        <select class="form-control mb-1 form-select"
                id="mailProvince"
                formControlName="mailProvince"
                aria-label="Mailing Province"
                name="mailProvince"
                required
                [ngClass]="{'remove-form-select-dropdown': checkStatus('mailProvince')}">
          <optgroup *ngIf="mailingProvinces.length > 0">
            <option *ngFor="let option of mailingProvinces"
                    [ngValue]="option.Id">
              {{ option.Name | translate }}
            </option>
          </optgroup>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group mb-3">
          <label for="mailCountry"
                 class="form-label mb-1">
            {{ 'COUNTRY' | translate }}
            <span class="non-ng-invalid-mark">*</span>
          </label>
          <select class="form-control form-select"
                  id="mailCountry"
                  formControlName="mailCountry"
                  name="mailCountry"
                  aria-label="Mailing Country"
                  required
                  [ngClass]="{'remove-form-select-dropdown': checkStatus('mailCountry')}">
            <option *ngFor="let option of countryOptions"
                    [ngValue]="option.Id">
              {{ option.Name | translate }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group mb-3">
          <label for="mailPostalCode"
                 class="form-label mb-1">
            {{'ZIP_POSTAL_CODE' | translate}}
            <span class="non-ng-invalid-mark">*</span>
          </label>
          <input type="text"
                 [readonly]="!canEditAccount()"
                 class="form-control mb-1"
                 id="mailPostalCode"
                 aria-label="Mailing PostalCode"
                 name="mailPostalCode"
                 formControlName="mailPostalCode"
                 onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                 onDrag="return false"
                 onDrop="return false"
                 onPaste="return false"
                 [pattern]="getPostalCodePattern(addressInfo.mailPostalCode.value)"
                 required />

          <div *ngIf="
            canEditAccount() &&
            addressInfo.mailPostalCode.invalid &&
            (addressInfo.mailPostalCode.dirty ||
              addressInfo.mailPostalCode.touched)
          "
               class="text-danger fade show ps-2"
               role="alert">
            <div *ngIf="addressInfo.mailPostalCode?.errors?.required">
              <small>{{ 'POSTAL_CODE_REQUIRED' | translate }}</small>
            </div>
            <div *ngIf="addressInfo.mailPostalCode?.errors?.pattern">
              <small> {{ 'INVALID_ZIP_POSTAL_CODE' | translate }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <form [formGroup]="addressInfoFormGroup">
    <fieldset>
      <legend class="form-label account-label mb-1 h3 sr-only ">
        <b>
          {{ 'BILLING_ADDRESS' | translate }}
        </b>
      </legend>

      <div class="row mt-3">
        <div class="col-sm-4">
          <span for="name"
                class="account-label mb-1 h3">
            <b>
              {{ 'BILLING_ADDRESS' | translate }}
            </b>
          </span>
        </div>
        <div class="col-sm-8">
          <div class="form-check form-check-inline float-sm-end">
            <label class="form-check-label form-check-label-sm">
              <input type="checkbox"
                     class="form-check-input mb-1"
                     formControlName="sameBillingAsMailing"
                     aria-label="Same Billing As Mailing"
                     name="billing" />
              {{ 'SAME_AS_MAILING_ADDRESS' | translate }}
              <span class="non-ng-invalid-mark">*</span>

            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group mb-3">
            <label for="billAddress"
                   class="form-label mb-1">
              {{ 'ADDRESS' | translate }}
              <span class="non-ng-invalid-mark">*</span>
              <span *ngIf="canEditAccount()">
                {{ 'ADDRESS_APPARTMENT_NOTE' | translate | translateCut:0 }} 
                <span [ngStyle]="{'font-weight': 'bold', 'font-size': 'larger', 'color': 'red'}">{{ 'ADDRESS_APPARTMENT_NOTE' | translate | translateCut:1 }}</span> 
                {{ 'ADDRESS_APPARTMENT_NOTE' | translate | translateCut:2 }} 
              </span>
            </label>
            <input type="text"
                   [readonly]="!canEditAccount() || sameBillingAsMailingChecked"
                   class="form-control mb-1"
                   google-places-autocomplete
                   #places="gx-places"
                   [options]="options"
                   (onAddressChange)="onBillingChange($event)"
                   aria-label="Billing Address"
                   id="billAddress"
                   formControlName="billAddress"
                   name="billAddress"
                   onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                   onDrag="return false"
                   onDrop="return false"
                   onPaste="return false" />

            <div *ngIf="
              canEditAccount() &&
              addressInfo.billAddress.invalid &&
              (addressInfo.billAddress.dirty || addressInfo.billAddress.touched)
            "
                 class="text-danger fade show ps-2"
                 role="alert">
              <div *ngIf="addressInfo.billAddress?.errors?.required">
                <small>{{ 'BILLING_ADDRESS_REQUIRED' | translate }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6 mb-3">
          <label for="billCity"
                 class="form-label mb-1">
            {{'CITY' | translate}}
            <span class="non-ng-invalid-mark">*</span>
          </label>
          <input type="text"
                 [readonly]="!canEditAccount() || sameBillingAsMailingChecked"
                 class="form-control mb-1"
                 aria-label="Billing City"
                 id="billCity"
                 formControlName="billCity"
                 name="billCity"
                 onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                 onDrag="return false"
                 onDrop="return false"
                 onPaste="return false"
                 required />

          <div *ngIf="
            canEditAccount() &&
            addressInfo.billCity.invalid &&
            (addressInfo.billCity.dirty || addressInfo.billCity.touched)
          "
               class="text-danger fade show ps-2"
               role="alert">
            <div *ngIf="addressInfo.billCity?.errors?.required">
              <small>{{ 'BILLING_CITY_REQUIRED' | translate }}</small>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 mb-3">
          <label for="billProvince"
                 class="form-label mb-1">
            {{'STATE_PROVINCE' | translate}}
            <span class="non-ng-invalid-mark">*</span>
          </label>
          <select class="form-control mb-1 form-select"
                  id="billProvince"
                  formControlName="billProvince"
                  aria-label="Billing Province"
                  [ngClass]="{'remove-form-select-dropdown': checkStatus('billProvince')}"
                  name="billProvince">
            <optgroup *ngIf="bilingProvinces.length > 0">
              <option *ngFor="let option of bilingProvinces"
                      [ngValue]="option.Id">
                {{ option.Name | translate }}
              </option>
            </optgroup>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <label for="billCountry"
                 class="form-label mb-1">
            {{ 'COUNTRY' | translate }}
            <span class="non-ng-invalid-mark">*</span>
          </label>
          <select class="form-control mb-1 form-select"
                  id="billCountry"
                  formControlName="billCountry"
                  aria-label="Billing Country"
                  [ngClass]="{'remove-form-select-dropdown': checkStatus('billCountry')}"
                  name="billCountry">
            <option *ngFor="let option of countryOptions"
                    [ngValue]="option.Id">
              {{ option.Name | translate }}
            </option>
          </select>
        </div>
        <div class="col-sm-12 col-md-6">
          <label for="billPostalCode"
                 class="form-label mb-1">
            {{'ZIP_POSTAL_CODE' | translate}}
            <span class="non-ng-invalid-mark">*</span>
          </label>
          <input type="text"
                 [readonly]="!canEditAccount() || sameBillingAsMailingChecked"
                 class="form-control mb-1"
                 id="billPostalCode"
                 formControlName="billPostalCode"
                 name="billPostalCode"
                 aria-label="Billing PostalCode"
                 onkeypress="return event.charCode != 32 || event.target.selectionStart != 0"
                 onDrag="return false"
                 onDrop="return false"
                 onPaste="return false"
                 [pattern]="getPostalCodePattern(addressInfo.billPostalCode.value)"
                 required />

          <div *ngIf="
              canEditAccount() &&
              addressInfo.billPostalCode.invalid &&
              (addressInfo.billPostalCode.dirty ||
                addressInfo.billPostalCode.touched)
            "
               class="text-danger fade show ps-2"
               role="alert">
            <div *ngIf="addressInfo.billPostalCode?.errors?.required">
              <small>{{ 'BILLING_POSTAL_CODE_REQUIRED' | translate }}</small>
            </div>
            <div *ngIf="addressInfo.billPostalCode?.errors?.pattern">
              <small> {{ 'INVALID_ZIP_POSTAL_CODE' | translate }}</small>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
