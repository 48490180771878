<div cdkTrapFocus
     [cdkTrapFocusAutoCapture]="true">
  <div class="modal-header">
    <h4 class="modal-title pull-start m-0"
        id="orderConfirmationModalLabel">
        <span *ngIf="(workMode === 1)">{{ 'ORDER_TRANSPONDER' | translate }}</span>
        <span *ngIf="(workMode === 2)">{{ 'ACTIVATE_TRANSPONDER' | translate }}</span>
    </h4>
    <button type="button"
            role="button"
            id="model-close-btn"
            class="btn-close btn-close-white pull-right"
            (click)="activeModal.close('Close click')"
            aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <!-- Bulk Activate -->
    <div class="tabs"
         *ngIf="(workMode === 2)">
      <div class="tab-content"
           id="transponderTabContent"
           >
        <div class="tab-pane fade show active"
             id="new"
             role="tabpanel"
             aria-labelledby="new-tab">
          <div class="row">
            <div class="col-12 mb-2">
              You have {{ transponderList?.length }} transponders in inactive state. Here you can activate them all.
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button submitbtn
                (click)="activateAllTransponders()"
                class="btn btn-app-primary px-4 m-1 mb-1 me-auto float-end"
                type="button"
                 > <!--                 [ngClass]="{ disabled: transponderList?.length > 0 }"
                  [attr.aria-disabled]="transponderList?.length > 0" btnDisabled -->
                  {{ 'ACTIVATE_ALL_BUTTON' | translate }}
                  <i class="fa fa-lg fa-spinner fa-pulse align-middle"
                    *ngIf="isRequesting"></i>
              </button>

              <button cancelbtn
                      class="btn btn-app-secondary px-4 mt-1 mb-1 float-start"
                      (click)="activeModal.close('Close click')"
                      type="button">
                {{ 'CANCEL' | translate }}
              </button>
            </div>
          </div>

          
        </div>
      </div>
    </div>

    <!-- PERSONAL Account -->
    <div class="tabs"
         *ngIf="(workMode === 1) && AccountProfileType?.AccountType === 'P'">
      <ul class="nav nav-tabs nav-justified"
          id="transponderTab"
          role="tablist"
          *ngIf="AccountProfileType?.AccountType === 'P'">
        <li class="nav-item"
            role="presentation">
          <button class="nav-link active"
                  id="new-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#new"
                  type="button"
                  role="tab"
                  aria-controls="new"
                  aria-selected="true"
                  ngbAutofocus>
            {{ 'NEW_TAG' | translate }}
          </button>
        </li>
        <li class="nav-item"
            role="presentation">
          <button class="nav-link"
                  id="own-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#own"
                  type="button"
                  role="tab"
                  aria-controls="own"
                  aria-selected="false">
            {{ 'ADD_EXISTING_TAG' | translate }}
          </button>
        </li>
      </ul>

      <div class="tab-content"
           id="transponderTabContent"
           *ngIf="AccountProfileType?.AccountType === 'P'">
        <div class="tab-pane fade show active"
             id="new"
             role="tabpanel"
             aria-labelledby="new-tab">
             <!-- New Transponder -->
          <newtransponder [transponder]="transponder"
                          (validationStatus)="validationStatus($event)"
                          (onLoaded)="onNewTransponderLoaded()"
                          (newtransponderStatus)="setNewTransponderStatus($event)"
                          *ngIf="!isEditTransponder">
            <!--Request button -->
            <button *ngIf="!isEditTransponder"
                    submitbtn
                    id="requestBtn"
                    class="btn btn-app-primary float-end px-4 mt-1 mb-2 me-auto"
                    (click)="addNewVehicle()"
                    type="button"
                    [ngClass]="{ disabled: isNewTransponderRequest() }"
                    [attr.aria-disabled]="isNewTransponderRequest()"
                    btnDisabled
                    aria-label="REQUEST">
              {{ 'REQUEST' | translate }}
              <i class="fa fa-lg fa-spinner fa-pulse align-middle"
                 aria-hidden="true"
                 *ngIf="isRequesting"></i>
            </button>

            <button cancelbtn
                    class="btn btn-app-secondary px-4 mt-1 mb-2 float-start"
                    (click)="closeModal()"
                    aria-label="CANCEL"
                    type="button">
              {{ 'CANCEL' | translate }}
            </button>
          </newtransponder>
        </div>
        <div class="tab-pane fade"
             id="own"
             role="tabpanel"
             aria-labelledby="own-tab">
          <!-- Existing (Own) Transponder -->
          <owntransponder (OwnTransponderStatus)="setOwnTransponderStatus($event)"
                          (onLoaded)="onOwnTransponderLoaded()">
            <button submitbtn
                    (click)="addOwnTransponder()"
                    class="btn btn-app-primary px-4 m-1 mb-1 me-auto float-end"
                    type="button"
                    [ngClass]="{ disabled: !ownTansponder?.ownTransponderValid || ownTansponder?.ownTransponderInfo.serialNumber.invalid }"
                    [attr.aria-disabled]="!ownTansponder?.ownTransponderValid"
                    btnDisabled>
              {{ 'REQUEST' | translate }}
              <i class="fa fa-lg fa-spinner fa-pulse align-middle"
                 *ngIf="isRequesting"></i>
            </button>

            <button cancelbtn
                    class="btn btn-app-secondary px-4 mt-1 mb-1 float-start"
                    (click)="activeModal.close('Close click')"
                    type="button">
              {{ 'CANCEL' | translate }}
            </button>
          </owntransponder>
        </div>
      </div>
    </div>
    <!-- BUISNESS Account -->
    <div class="tabs"
         *ngIf="(workMode === 1) && AccountProfileType?.AccountType === 'B'">
      <ul class="nav nav-tabs nav-justified"
          id="transponderTab"
          role="tablist"
          *ngIf="AccountProfileType?.AccountType === 'B'">
        <li class="nav-item"
            role="presentation">
          <button class="nav-link active"
                  id="bulk-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#bulk"
                  type="button"
                  role="tab"
                  aria-controls="bulk"
                  aria-selected="true"
                  ngbAutofocus>
            {{ 'BULK_REQUEST' | translate }}
          </button>
        </li>
        <li class="nav-item"
            role="presentation">
          <button class="nav-link"
                  id="own-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#own"
                  type="button"
                  role="tab"
                  aria-controls="own"
                  aria-selected="false">
            {{ 'ADD_EXISTING_TAG' | translate }}
          </button>
        </li>
      </ul>

      <div class="tab-content"
           id="transponderTabContent"
           *ngIf="AccountProfileType?.AccountType === 'B'">
        <div class="tab-pane fade show active"
             id="bulk"
             role="tabpanel"
             aria-labelledby="bulk-tab">
          <!-- Bulk Transponder Request -->
          <bluk-transponder (validationStatus)="validationStatus($event)"
                            (onLoaded)="onBulkTransponderLoaded()"
                            (blukTransponderStatus)="setblukTransponderStatus($event)">
            <button cancelbtn
                    class="btn btn-app-secondary px-4 mt-1 float-start"
                    (click)="closeModal()"
                    type="button">
              {{ 'CANCEL' | translate }}
            </button>
            <button submitbtn
                    id="requestBtn"
                    class="btn btn-app-primary float-end px-4 mt-1 me-auto"
                    (click)="addBlukTransponder()"
                    [ngClass]="{ disabled: isBulkTransponderRequest() }"
                    [attr.aria-disabled]="isBulkTransponderRequest()"
                    type="button"
                    btnDisabled>
              {{ 'REQUEST' | translate }}
              <i class="fa fa-lg fa-spinner fa-pulse align-middle"
                 *ngIf="isRequesting"></i>
            </button>

          </bluk-transponder>
        </div>
        <div class="tab-pane fade"
             id="own"
             role="tabpanel"
             aria-labelledby="own-tab">
          <owntransponder (OwnTransponderStatus)="setOwnTransponderStatus($event)"
                          (onLoaded)="onOwnTransponderLoaded()">
            <button cancelbtn
                    class="btn btn-app-secondary px-4 mt-1"
                    (click)="closeOwnModal()"
                    type="button">
              {{ 'CANCEL' | translate }}
            </button>

            <button submitbtn
                    (click)="addOwnTransponder()"
                    [ngClass]="{ disabled: !ownTansponder?.ownTransponderValid }"
                    [attr.aria-disabled]="!ownTansponder?.ownTransponderValid"
                    class="btn btn-app-primary float-end px-4 mt-1"
                    type="button"
                    btnDisabled>
              {{ 'REQUEST' | translate }}
              <i class="fa fa-lg fa-spinner fa-pulse align-middle"
                 *ngIf="isRequesting"></i>
            </button>
          </owntransponder>
        </div>
      </div>
    </div>
  </div>
</div>
