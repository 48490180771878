import { Directive, Input } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[InvalidInput]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: InvalidInputDirective, multi: true
    }
  ]
})

export class InvalidInputDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors {
    const nameRegexp: RegExp = /<[%&<>\/]>/;
    if (control.value && nameRegexp.test(control.value)) {
      return { invalidValidaChar: true };
    }
  }
}
