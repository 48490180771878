import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  LoginGuard,
  InActiveAccountGuard,
  SignupGuard,
} from '@ibitoll/toll-core';
import { AccountAgreementComponent } from './core/account-agreement/account-agreement.component';
import { CsrguardGuard } from './guards/csrguard.guard';
import { MaintenancePageComponent } from './modules/maintenance-page/maintenance-page.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/user-home/user-home.module').then(
        (m) => m.UserHomeModule
      ),
    canActivate: [AuthGuard],
    data: { title: 'Home' },
  },
  {
    path: 'transponder',
    loadChildren: () =>
      import(
        './modules/user-transponder-info/user-transponder-info.module'
      ).then((m) => m.UserTransponderInfoModule),
    canActivate: [AuthGuard],
    data: { title: 'Tag' },
  },
  {
    path: 'activity',
    loadChildren: () =>
      import(
        './modules/user-account-activity/user-account-activity.module'
      ).then((m) => m.UserAccountActivityModule),
    canActivate: [AuthGuard],
    data: { title: 'Account Activity' },
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./modules/user-payment/user-payment.module').then(
        (m) => m.UserPaymentModule
      ),
    //canActivate: [AuthGuard, InActiveAccountGuard],
    data: { title: 'Payment' },
  },
  {
    path: 'statement',
    loadChildren: () =>
      import('./modules/user-statement/user-statement.module').then(
        (m) => m.UserStatementModule
      ),
    canActivate: [AuthGuard],
    data: { title: 'Statement' },
  },
  {
    path: 'accountinfo',
    loadChildren: () =>
      import('./modules/user-account-info/user-account-info.module').then(
        (m) => m.UserAccountInfoModule
      ),
    canActivate: [AuthGuard],
    data: { title: 'My Account' },
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./modules/user-ticket/user-ticket.module').then(
        (m) => m.UserTicketModule
      ),
    canActivate: [AuthGuard],
    data: { title: 'Support' },
  },
  {
    path: 'userpassword',
    loadChildren: () =>
      import('./modules/user-password/user-password.module').then(
        (m) => m.UserPasswordModule
      ),
    data: { title: 'Change Password' },
  },
  {
    path: 'forgotusername',
    loadChildren: () =>
      import('./modules/account-username/account-username.module').then(
        (m) => m.AccountUsernameModule
      ),
    data: { title: 'Forgot Username' },
  },
  {
    path: 'forgotpassword',
    loadChildren: () =>
      import('./modules/account-password/account-password.module').then(
        (m) => m.AccountPasswordModule
      ),
    data: { title: 'Forgot Password' },
  },
  {
    path: 'ChangePassword/:accessCode',
    loadChildren: () =>
      import('./modules/account-password/account-password.module').then(
        (m) => m.AccountPasswordModule
      ),
    data: { title: 'Change Password' },
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./modules/account-signup/account-signup.module').then(
        (m) => m.AccountSignupModule
      ),
    canActivate: [SignupGuard],
    data: { title: 'Signup' },
  },
  {
    path: 'webaccess',
    loadChildren: () =>
      import('./modules/account-webaccess/account-webaccess.module').then(
        (m) => m.AccountWebaccessModule
      ),
    data: { title: 'Register' },
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('./modules/terms-conditions/terms-conditions.module').then(
        (m) => m.TermsConditionsModule
      ),
    data: { title: 'Terms' },
  },
  {
    path: 'accountclosure',
    loadChildren: () =>
      import('./modules/account-closure/account-closure.module').then(
        (m) => m.AccountClosureModule
      ),
    data: { title: 'Account Closure' },
  },
  {
    path: 'accountagreement',
    component: AccountAgreementComponent,
    data: { title: 'Account Agreement' },
  },
  {
    path: 'ActivateWebAccount/:accessCode',
    loadChildren: () =>
      import('./modules/account-activation/account-activation.module').then(
        (m) => m.AccountActivationModule
      ),
    data: { title: 'Activate Web-Account' },
  },

  {
    path: 'login',
    loadChildren: () =>
      import('./modules/account-login/account-login.module').then(
        (m) => m.AccountLoginModule
      ),
    canActivate: [LoginGuard],
    data: { title: 'Login' },
  },
  {
    path: 'message/msg',
    loadChildren: () =>
      import('./modules/messageadmin/messageadmin.module').then(
        (m) => m.MessageadminModule
      ),
    canActivate: [CsrguardGuard],
    data: { title: 'Message' },
  },
  {
    path: 'closure/closure',
    loadChildren: () =>
      import('./modules/closureadmin/closuradmin.module').then(
        (m) => m.ClosuradminModule
      ),
    canActivate: [CsrguardGuard],
    data: { title: 'Closure' },
  },
  {
    path: 'maintenance',
    component: MaintenancePageComponent
  },
  {
    path: 'login',
    redirectTo: '/login',
    pathMatch: 'full',
    data: { title: 'Login' },
  },
  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      useHash: false,
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
