import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DatabaseMapService {
  constructor() { }

  eTokenPageMap = {
    1: 'Suspended',
    2: 'Active',
  };

  eTransponderRestrictionMap = {
    1: 'Suspended',
    2: 'Active',
  };

  eAccountStatusMap = {
    // update gr.json if altering this mapping (string value here is the key there)
    1: 'Incomplete',
    2: 'Inactive',
    3: 'Active Partial',
    4: 'Active',
    5: 'Closed',
  };

  eAccountRestrictionStatusMap = {
    S: 'Suspended',
    N: 'None',
  };

  eAccountStatusDMap = {
    // update gr.json if altering this mapping (string value here is the key there)
    Incomplete: 1,
    Inactive: 2,
    'Active Partial': 3,
    Active: 4,
    Closed: 5,
  };

  eptAccountStatusDMap = {
    // update gr.json if altering this mapping (string value here is the key there)
    1: 'Violation',
    2: 'Warning',
    3: 'Good',
  };

  eListTransponderStatusMap = {
    1: 'Good',
    2: 'Warning',
    3: 'Violation',
  };

  eVatTypeStatusMap = {
    // actual values from 0 to 3
    1: 'Standard',
    2: 'ATTIKI ODOS SA',
    3: 'Paid by ATTIKI ODOS SA',
    4: 'Government',
  };

  eCardTypeMap = {
    other: 0,
    visa: 1,
    mastercard: 2,
    maestro: 3,
    amex: 4,
    diners: 5,
  };

  eCardTypShorteMap = {
    other: 0,
    visa: 1,
    mc: 2,
    maestro: 3,
    amex: 4,
    diners: 5,
  };

  eOCardTypeMap = {
    0: 'other',
    1: 'visa',
    2: 'mastercard',
    3: 'maestro',
    4: 'amex',
    5: 'diners',
  };

  eAuthorizationStatusTypeMap = {
    approved: 'A',
    declined: 'D',
  };

  eAccountTransactionTypeMap = {
    payment: '01',
    toll_charge: '02',
    transponder_charge: '03',
    administration_charge: '04',
    adjustment: '05',
    refund: '06',
    tax: '07',
    adjustment_credit: '08',
    adjustment_debit: '09',
  };

  eAccountTransactionSourceMap = {
    roadside_toll: 1,
    parkade_parking: 2,
    curbside_parking: 3,
  };

  eAccountTransactionSubTypeMap = {
    ORIGINAL_TRANSACTION: '01',
    TRANSACTION_VOID: '02',
    TRANSACTION_REVERSAL: '03',
    ACCOUNT_ADMINISTRATION: '04',
    TRANSPONDER_ADMINISTRATION: '05',
    TRANSPONDER_DISTRIBUTION: '06',
    LOST_STOLEN_TRANSPONDER: '07',
    DEFECTIVE_TRANSPONDER: '08',
    MISUSED_ABUSED_TRANSPONDER: '09',
    RETURNED_FOR_BATTERY_REPLACEMENT: '10',
    RETURNED_NOLONGER_REQUIRED_TPND: '11',
    TOLL_CHARGE: '12',
    COMMUNICATION_FEE: '13',
    ACCESS_RESTRICTION_FEE: '14',
    SUBSCRIPTION_FEE: '15',
    DISCOUNT_FEE: '16',
    EVENT_FEE: '17',
  };

  eAdjustmentSubTypeMap = {
    ACCOUNT_BALANCE_ADJUSTMENT: '1',
    ADMINISTRATION_CHARGE: '2',
    CUSTOMER_SATISFACTION_ADJUSTMENT: '3',
    CHARGEBACK: '4',
    REFUND: '5',
  };
  eAdjustmentSubTypeTextMap = {
    '1':'AST_ACCOUNT_BALANCE_ADJUSTMENT',
    '2':'AST_ADMINISTRATION_CHARGE',
    '3':'AST_CUSTOMER_SATISFACTION_ADJUSTMENT',
    '4':'AST_CHARGEBACK',
    '5':'AST_REFUND',
  };

  ePaymentMethodMap = {
    CA: 'Cash',
    CH: 'Check',
    DC: 'Credit Card',
    DB: 'Direct Bank',
    PB: 'Pre-auth Bank Account',
    PC: 'Pre-auth Credit Card',
    SP: 'Service Provider',
  };

  ePaymentTypeMap = {
    BankAccount: 'B',
    CreditCard: 'C',
  };

  eAccountTypeMap = {
    O: 'Other',
    P: 'Personal',
    B: 'Business',
  };

  eCurrencyTypeMap = {
    D: 'Domestic',
    F: 'Foreign',
  };

  eAccountSubTypeMap = {
    Standard: '0',
    Employer: '1',
    Employee: '2',
  };

  ePreAuthStatus = {
    disabled: 0,
    active: 1,
    suspended: 2,
  };

  eBalanceStatusMap = {
    1: 'Good',
    2: 'Low',
    3: 'Bad',
  };

  eTransponderStatusMap = {
    3: 'lost',
    4: 'stolen',
    5: 'defective',
    7: 'battery_replacement',
    8: 'returned'
  };

  eLanguageMap = {
    1: 'English',
    2: 'French'
  };


  getMapValue(key: any, mapType: any): string {
    switch (mapType) {
      case 'eTransponderRestrictionMap':
        return this.eTransponderRestrictionMap[key];
      case 'eAccountStatusMap':
        return this.eAccountStatusMap[key];
      case 'eListTransponderStatusMap':
        return this.eListTransponderStatusMap[key];
      case 'eAccountTypeMap':
        return this.eAccountTypeMap[key];
      case 'eVatTypeStatusMap':
        return this.eVatTypeStatusMap[key];
      case 'eptAccountStatusDMap':
        return this.eptAccountStatusDMap[key];
      case 'eBalanceStatusMap':
        return this.eBalanceStatusMap[key];
      case 'eTransponderStatusMap':
        return this.eTransponderStatusMap[key];
      case 'eAccountRestrictionStatusMap':
        return this.eAccountRestrictionStatusMap[key];
      case 'eLanguageMap':
        return this.eLanguageMap[key];
    }
  }

  getCardType(type: string): number {
    if (type && type.toLowerCase() in this.eCardTypeMap) {
      return this.eCardTypeMap[type.toLowerCase()];
    } else {
      return this.eCardTypeMap.other;
    }
  }

  getShortCardType(type: string): number {
    if (type && type.toLowerCase() in this.eCardTypShorteMap) {
      return this.eCardTypShorteMap[type.toLowerCase()];
    } else {
      return this.eCardTypShorteMap.other;
    }
  }

  getCardTypeString(value: number): string {
    const cardType = Object.keys(this.eCardTypeMap).find(
      (key) => this.eCardTypeMap[key] === value
    );
    if (cardType) {
      const capitalizedCardType =
        cardType.charAt(0).toUpperCase() + cardType.slice(1);
      return capitalizedCardType;
    } else {
      return cardType;
    }
  }

  getAuthorizationStatusType(message: string): string {
    if (message && message.toLowerCase() in this.eAuthorizationStatusTypeMap) {
      return this.eAuthorizationStatusTypeMap[message.toLowerCase()];
    } else {
      return this.eAuthorizationStatusTypeMap.declined;
    }
  }

  getAuthorizationStatusTypeMapByValue(value: string): string {
    const statusType = Object.keys(this.eAuthorizationStatusTypeMap).find(
      (key) => this.eAuthorizationStatusTypeMap[key] === value
    );
    if (statusType) {
      const capitalizedStatusType =
        statusType.charAt(0).toUpperCase() + statusType.slice(1);
      return capitalizedStatusType;
    } else {
      return statusType;
    }
  }
}
