import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Address } from '@ibitoll/toll-core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'hhb-address-confirmation',
  templateUrl: './address-confirmation.component.html',
  styleUrls: ['./address-confirmation.component.scss'],
})
export class AddressConfirmationComponent {
  @Output() confirmStatus: EventEmitter<boolean> = new EventEmitter();
  @Input() mailingAddressComponents: Address;

  isRequesting = false;

  constructor(public modal: NgbActiveModal) {}

  confirm() {
    this.isRequesting = true;
    this.confirmStatus.emit(true);
  }

  cancel() {
    this.confirmStatus.emit(false);
  }

  getAddress() {
    // eslint-disable-next-line no-debugger
    if (this.mailingAddressComponents.address_components.length === 0) {
      return '';
    } else {
      return this.mailingAddressComponents.formatted_address;
    }
  }

  getCity() {
    if (this.mailingAddressComponents.address_components.length === 0) {
      return '';
    } else {
      return `${this.mailingAddressComponents[2].address_components.long_name}`;
    }
  }

  getProvince() {
    if (this.mailingAddressComponents.address_components.length === 0) {
      return '';
    } else {
      return `${this.mailingAddressComponents[3].long_name}`;
    }
  }

  getPostal() {
    if (this.mailingAddressComponents.address_components.length === 0) {
      return '';
    } else {
      return `${this.mailingAddressComponents[4].long_name}`;
    }
  }
}
