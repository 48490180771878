/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core';
import { ConfigurationService } from '@ibitoll/toll-core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Bowser from 'bowser';

@Component({
  selector: 'hhb-out-dated-browser',
  templateUrl: './out-dated-browser.component.html',
  styleUrls: ['./out-dated-browser.component.scss'],
})
export class OutDatedBrowserComponent {
  isMac = false;
  isWindows = false;
  constructor(
    public modal: NgbActiveModal,
    public configurationService: ConfigurationService
  ) {
    const browser = Bowser.getParser(window.navigator.userAgent);

    if (browser.getOS().name === 'Windows') {
      this.isWindows = true;
    }

    if (browser.getOS().name === 'macos') {
      this.isMac = true;
    }

  }
}
