<div class="modal-content modal-warning">
  <div class="modal-header">
    <div class="modal-title mb-0 h3" id="removeTransponderModalLabel">
      {{ 'TRANSPONDER_REQUEST_REMOVE_NO' | translate }}
    </div>
    <button type="button " class="btn-close btn-close-white pull-right" (click)="activeModal.dismiss()"
      aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="transponderRemoveInfoFormGroup">
      <div class="mb-3" disabled>
        <label id="plate-transponder-label" for="plate-transponder-input"
          class="form-label">{{'PLATE' | translate}}</label>
        <input type="text" class="form-control" id="plate-transponder-input" formControlName="plate" name="plate">
      </div>

      <div class="mb-3" disabled>
        <label for="transponderId" class="form-label">{{'TRANSPONDERS_SMARTCARD' | translate}}</label>
        <input type="text" class="form-control" id="transponderId" formControlName="transponderId" name="transponderId">
      </div>
      <!-- <div class="mb-3">
        <label for="removeReason" class="form-label">{{ 'REMOVE_REASON' | translate }}</label>
        <select class="form-control form-select" id="removeReason" formControlName="removeReason" name="removeReason">
          <option *ngFor="let option of tagRemovalStaus" [ngValue]="option.NameId">
            {{ option.Name | translate }}
          </option>
        </select>
      </div> -->

    </form>

    <b class="h3 common-bold">
      <span class="text-danger">{{ 'TRANSPONDER_REQUEST_REMOVE_MSG' | translate | translateCut:0 }}</span>
      <span> {{ 'TRANSPONDER_REQUEST_REMOVE_MSG' | translate | translateCut:1 }}</span>
    </b>


  </div>
  <div class="modal-footer">
    <button data-dismiss="modal" class="btn btn-app-secondary px-4 m-1 float-start me-auto"
      (click)="activeModal.dismiss()">
      {{ 'CANCEL' | translate }}
    </button>
    <button cancelbtn class="btn btn-app-primary px-4 m-1 float-end" (click)="onTransponderRemove(transponder)"
      [ngClass]="{'disabled': !transponderRemoveInfoFormGroup?.valid}"
      [attr.aria-disabled]="!transponderRemoveInfoFormGroup?.valid" btnDisabled>
      {{ 'CONFIRM' | translate }}
      <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isTagRemoving"></i>
    </button>
  </div>
</div>
