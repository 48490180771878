import { Directive, Input } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

@Directive({
  selector: '[mustSelect][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MustSelectDirective, multi: true },
  ],
})
export class MustSelectDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    if (control.value == -1) {
      control.setErrors({ mustSelect: true });
      return { mustSelect: true };
    } else {
      control.setErrors(null);
      return null;
    }
  }
}
