<div class="container gx-0" *ngIf="!MaintenanceFlag">
  <div class="my-router-outlet">
    <div class="sub-container">
      <header>
        <a href="#content"
           class="skip-to-content-link sr-only sr-only-focusable"
           (click)="skipToMain()"
           tabindex="0">
          Skip to main content
        </a>
      </header>
      <mdot-header *ngIf="hideNavBar && configurationService.config.NavBarType === 1"></mdot-header>
      <header-type-two *ngIf="hideNavBar && configurationService.config.NavBarType === 2"></header-type-two>
      <main role="main"
            id="content"
            tabindex="-1"
            #content
            aria-live="polite">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
        <app-alert></app-alert>
        <user-inactivity></user-inactivity>
      </main>
    </div>
    <mdot-footer></mdot-footer>
  </div>
</div>
<div id="smcx-sdk">

<router-outlet *ngIf="MaintenanceFlag"></router-outlet>

