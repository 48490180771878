import { Account } from './account';
import { CreditCard } from './creditCard';
import { TransponderRequest } from './transponderRequest';
import { AccountDocuments } from './accountDocuments';
import { EvalonTxnResponse } from './evalonTxnResponse';

export class NewAccountRequest {
  public Account: Account;
  public CreditCard: CreditCard;
  public TransponderRequest: Array<TransponderRequest>;
  public AccountDocuments: AccountDocuments;
  public TxnResponse: EvalonTxnResponse;

  constructor() {
    this.Account = new Account();
    this.CreditCard = new CreditCard();
    this.TransponderRequest = new Array<TransponderRequest>();
    this.AccountDocuments = new AccountDocuments();
    this.TxnResponse = new EvalonTxnResponse();
  }
}
