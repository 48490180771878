<div class="card mb-3 moneris-z-index">
  <div class="card-header">
    {{ 'ONE-TIME-PAYMENT' | translate }}
  </div>
  <div class="card-body p-0">
    <div class="container-12">
      <ngb-accordion [closeOthers]="true"
                     #acc="ngbAccordion"
                     activeIds="paybycard"
                     *ngIf="componentMode !== mode">
        <!-- Pay using saved card -->
        <ngb-panel *ngIf="cardExist"
                   id="paybycard">
          <ng-template ngbPanelTitle>
            <button class="btn  break-text px-0">
              <i class="fa custom pr-2 "></i>
              <i class="fa fa-credit-card"></i>
              {{'PAYMENT_WITH_SAVED' | translate}} {{account?.PaymentNumber}}
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <form class="m-2"
                  
                  [formGroup]="paymentForm"><!--(ngSubmit)="processSavedPayment()"-->
              <div class="form-group row">
                <div class="col-12 mb-3" *ngIf="!showConfirmPayment">
                  <label for="amount"
                         class="form-label">{{ 'AMOUNT' | translate }}
                  </label>
                  <div class="input-group">
                    <span class="input-group-text">$</span>
                    <input id="amount"
                           name="amount"
                           type="text"
                           mask="separator.2"
                           [allowNegativeNumbers]="false"
                           class="form-control rounded-end"
                           formControlName="amount"
                           placeholder="Enter amount to pay"
                           aria-describedby="invalidAmount"
                           [ngClass]="{'is-invalid' : paymentForm?.controls?.amount.errors?.min}">

                    <div id="invalidAmount"
                         class="invalid-feedback">  
                      {{ replaceWithValue('AMOUNT_SHOULD_BE_GRATHER_THAN_TEXT', 10) }}
                    </div>
                  </div>
                </div>

                <div class="col-12 " *ngIf="showConfirmPayment">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <b>{{ replaceWithValue('CONFIRM_PAYMENT_OF', paymentForm?.controls?.amount?.value) }}:</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" style="padding-right:4px;">
                    <button *ngIf="!processing"
                            (click)="processSavedPayment()"
                            class="btn btn-app-primary topbutton"
                            
                            [disabled]="!paymentForm.valid">
                            {{ replaceWithValue('CONFIRM_AMOUNT', paymentForm?.controls?.amount?.value) }}
                    </button></div>
                    <div class="col-6">
                    <button *ngIf="!processing"
                            (click)="cancelSavedPayment()"
                            class="btn btn-app-primary topbutton"
                            [disabled]="!paymentForm.valid">
                      {{'CANCEL' | translate}}
                    </button>
                    <button *ngIf="processing"
                            class="btn btn-app-primary topbutton"
                            disabled>
                      {{'PROCESSING_LOADING' | translate}}
                      <i class="fa fa-spinner fa-pulse"></i>
                    </button>
                    </div>
                  </div>  
                </div>

                <div class="col-12 " *ngIf="!showConfirmPayment">
                  <button *ngIf="!processing"
                      (click)="confirmPayment()"
                          class="btn btn-app-primary topbutton"
                          [disabled]="!paymentForm.valid">
                    {{'SUBMIT' | translate}}
                  </button>
                </div>
              </div>
            </form>
          </ng-template>
        </ngb-panel>
        <!-- /Pay using saved card -->
        <!-- Pay with a new card -->
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <button class="btn  break-text px-0 collapsed">
              <i class="fa fa-credit-card"></i>
              {{'PAY_WITH_NEW_CARD' | translate}}
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row p-3"
                 *ngIf="!isTicketAvailable">
              <form [formGroup]="chaseFormGroup">
                <div class="col-8"  *ngIf="!showConfirmPayment">
                  <!-- Amount Starts-->
                  <div class="form-group">
                    <label for="amountmoneris"
                           class="form-label">{{ 'AMOUNT' | translate }}
                    </label>
                    <div class="input-group">
                      <span class="input-group-text"
                            for="amountmoneris">
                        <currency-type></currency-type>{{ configService.GetCurrencyCode() }}
                      </span>
                      <input required
                             id="amountmoneris"
                             name="amount"
                             mask="separator.2"
                             [allowNegativeNumbers]="false"
                             formControlName="amount"
                             aria-label="amount"
                             onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46 || event.key === "Backspace"'
                             class="form-control rounded-end"
                             aria-describedby="invalidAmount"
                             placeholder="{{ 'AMOUNT' | translate }}"
                             [ngClass]="{'is-invalid' : chaseFormGroup?.controls?.amount.errors?.min}" />

                      <div id="invalidAmount"
                           class="invalid-feedback">
                           {{ replaceWithValue('AMOUNT_SHOULD_BE_GRATHER_THAN_TEXT', 10) }}
                      </div>
                    </div>

                  </div>
                </div>
                <!-- Amount Ends-->
              </form>

              <div class="col-12 " *ngIf="showConfirmPayment">
                <div class="row">
                  <div class="col-12 mb-3">
                    <b>{{ replaceWithValue('CONFIRM_PAYMENT_OF', chaseFormGroup?.controls?.amount?.value) }}:</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6" style="padding-right:4px;">
                    <button class="btn btn-app-primary"
                      (click)="preLoad()"
                      type="button"
                      InActiveAccount
                      [disabled]="!enableCCSubmit">
                      <span *ngIf="!processing">
                        {{ replaceWithValue('CONFIRM_AMOUNT', chaseFormGroup?.controls?.amount?.value) }}
                      </span>

                      <span *ngIf="processing">
                        <span class="spinner-border spinner-border-sm"
                              role="status"></span>
                        {{ 'PROCESSING' | translate }}
                      </span>
                    </button>
                  </div>
                  <div class="col-6">
                  <button *ngIf="!processing"
                          (click)="cancelSavedPayment()"
                          class="btn btn-app-primary topbutton"
                          [disabled]="!chaseFormGroup.valid">
                    {{'CANCEL' | translate}}
                  </button>
                  <button *ngIf="processing"
                          class="btn btn-app-primary topbutton"
                          disabled>
                    {{'PROCESSING_LOADING' | translate}}
                    <i class="fa fa-spinner fa-pulse"></i>
                  </button>
                  </div>
                </div>  
              </div>

              <!-- <div class="mt-2 col-12">
                <div submit
                     class="col-12 pr-5">
                  <button class="btn btn-app-primary"
                          (click)="preLoad()"
                          type="button"
                          InActiveAccount
                          [disabled]="!enableCCSubmit">
                    <span *ngIf="!processing"> {{ 'SUBMIT' | translate }} </span>

                    <span *ngIf="processing">
                      <span class="spinner-border spinner-border-sm"
                            role="status"></span>
                      {{ 'PROCESSING' | translate }}
                    </span>
                  </button>
                </div>
              </div> -->

              <div class="mt-2 col-12 " *ngIf="!showConfirmPayment">
                <button *ngIf="!processing"
                    (click)="confirmPayment()"
                        class="btn btn-app-primary topbutton"
                        [disabled]="!chaseFormGroup.valid">
                  {{'SUBMIT' | translate}}
                </button>
              </div>


            </div>

            

            <div *ngIf="isTicketAvailable">
              <div id="secureFrameWrapper">
                <iframe id="secureFrame"
                        class="secureFrame"
                        height="450px"
                        width="100%"
                        [src]="url | safe : 'resourceUrl'">
                </iframe>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <!-- /Pay with a new card -->
      </ngb-accordion>


      <div class="row p-3"
           *ngIf="!isTicketAvailable && componentMode === mode">
        <form [formGroup]="chaseFormGroup">
          <div class="col-8">
            <!-- Amount Starts-->
            <div class="form-group">
              <label for="amountmoneris"
                     class="col-form-label col-form-label-sm">{{ 'AMOUNT' | translate }}
              </label>
              <div class="input-group">
                <span class="input-group-text"
                      for="amountmoneris">
                  <currency-type></currency-type>{{ configService.GetCurrencyCode() }}
                </span>
                <input required
                       id="amountmoneris"
                       name="amount"
                       formControlName="amount"
                       aria-label="amount"
                       onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46 || event.key === "Backspace"'
                       class="form-control"
                       placeholder="{{ 'AMOUNT' | translate }}"
                       aria-describedby="invalidAmount"
                       [ngClass]="{'is-invalid' : chaseFormGroup?.controls?.amount.errors?.min}" />
              </div>
              <div id="invalidAmount"
                   class="invalid-feedback">
                {{ replaceWithValue('AMOUNT_SHOULD_BE_GRATHER_THAN_TEXT', 10) }}
              </div>
            </div>
          </div>
          <!-- Amount Ends-->
        </form>

        <div class="mt-2 col-12">
          <div submit
               class="col-12 pr-5">
            <button class="btn btn-app-primary"
                    (click)="preLoad()"
                    type="button"
                    InActiveAccount
                    [disabled]="!enableCCSubmit">
              <span *ngIf="!processing"> {{ 'SUBMIT' | translate }} </span>

              <span *ngIf="processing">
                <span class="spinner-border spinner-border-sm"
                      role="status"></span>
                {{ 'PROCESSING' | translate }}
              </span>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>

  <ng-template #errorModal
               let-modal
               let-c="close"
               let-d="dismiss"
               class="errorModal">
    <div *ngIf="errorObj">
      <h6 class="
          modal-header
          text-center text-danger
          align-middle
          px-2
          d-flex
          justify-content-center
        ">
        <div class="d-inline align-middle">
          <h3 class="m-0">{{ errorObj.title }}</h3>
        </div>
      </h6>
      <p class="modal-body text-center">
        {{ errorObj.message }}
      </p>
      <div class="modal-footer p-0">
        <button class="m-2 btn btn-danger float-end"
                data-dismiss="errorModal"
                aria-label="Close"
                (click)="modal.close()">
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</div>


<ngx-loading [show]="processing"
             [config]="{
  animationType: ngxLoadingAnimationTypes.circleSwish,
  backdropBorderRadius: '3px'}">
</ngx-loading>
