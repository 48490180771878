import { switchMap, map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AccountService } from '../providers/account.service';
import { SubSink } from 'subsink';
import { Account } from '../models/account';

@Injectable({
  providedIn: 'root',
})
export class InActiveAccountGuard implements CanActivate, OnDestroy {
  private subs = new SubSink();

  constructor(private accountService: AccountService, private router: Router,
  ) { }

  canActivate(): Observable<boolean> | boolean {
    return this.accountService.getActiveAccount().pipe(
      map((account: Account) => {
        if (account) {
          if (account.AccountStatus === 5 ) {
            this.router.navigate(['/home']);
            return false;
          } else {
            return true;
          }
        } else {
          this.router.navigate(['/home']);
          return false;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
