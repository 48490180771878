<div cdkTrapFocus> <!--  [cdkTrapFocusAutoCapture]="true" -->
  <div class="modal-content modal-warning">
    <div class="modal-header">
      <div class="modal-title mb-0 h3" id="unSupendAlertModalModalLabel">
        {{ 'Transponder Request Approved' 
        }} <!-- ...{{ transponderReq?.TransponderSerialNumber?.substring(transponderReq?.TransponderSerialNumber?.length-6) }} -->
      </div>
      <button type="button " class="btn-close btn-close-white pull-right" (click)="activeModal.dismiss()"
        aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <span>
        {{ 'Your transponder ' }} 
        {{ transponderReq?.TransponderSerialNumber }}
        {{ ' has successfully been added to your account and is now active.' }} 
        <!--...{{ transponderReq?.TransponderSerialNumber?.substring(transponderReq?.TransponderSerialNumber?.length-6) }}?-->
      </span>
    </div>
    <div class="modal-footer">
      <button data-dismiss="modal" class="btn btn-app-primary px-4 m-1 me-auto" (click)="activeModal.dismiss()">
        {{ 'OK'  }}
      </button>
      <!--<button cancelbtn class="btn btn-app-primary px-4 m-1" (click)="onTransponderUnSuspended(transponderReq)">
        {{ 'CONFIRM'  }} 
        
        <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isRequesting"></i>
      </button>-->
    </div>
  </div>
</div>
