import { OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LogService } from '@ibitoll/toll-core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as Bowser from 'bowser'; // TypeScript
import { OutDatedBrowserComponent } from '../modals/out-dated-browser/out-dated-browser.component';

@Injectable({
  providedIn: 'root',
})
export class OutdateBrowserCheckService implements OnInit {
  oDModal: NgbModalRef;
  constructor(
    public logService: LogService,
    private modalService: NgbModal,
    private titleService: Title
  ) {}

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit(): void {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isNotValidBrowser = browser.satisfies({
      desktop: {
        chrome: '<103',
        safari: '<12',
        firefox: '<102',
        edge: '<90',
      },
      mobile: {
        safari: '<12',
        'android browser': '<=3.10',
        chrome: '<101',
        opera: '<60',
      },
    });

    this.logService.logMessage(
      `OS: ${browser.getOSName()}, OS Version: ${browser.getOSVersion()}, BrowserName: ${browser.getBrowserName()}, Version: ${browser.getBrowserVersion()}, Invalid: ${isNotValidBrowser}`
    );

    if (isNotValidBrowser) {
      this.oDModal = this.modalService.open(OutDatedBrowserComponent, {
        centered: true,
        fullscreen: true,
        animation: true,
        keyboard: false,
      });
      // setTimeout(() => {
      //   this.titleService.setTitle(
      //     'MacPass - Your Browser is no longer supported'
      //   );
      // }, 800);
    }
  }
}
