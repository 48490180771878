import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatabaseMapService, NameIdNameConfig, Transponder } from '@ibitoll/toll-core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mdot-remove-transponder-modal',
  templateUrl: './remove-transponder-modal.component.html',
  styleUrls: ['./remove-transponder-modal.component.scss']
})
export class RemoveTransponderModalComponent implements OnInit, AfterContentInit {

  @Input() transponder: Transponder;

  @Output() onTransponderRemoveOutput: EventEmitter<Transponder> = new EventEmitter();

  public transponderRemoveInfoFormGroup: FormGroup;
  public tagRemovalStaus: NameIdNameConfig[];
  isTagRemoving: boolean = false;

  constructor(
    public databaseMapService: DatabaseMapService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
  ) { }


  ngOnInit(): void {
    this.isTagRemoving = false

    this.transponderRemoveInfoFormGroup = this.formBuilder.group({
      plate: [{ value: '', disabled: true, }],
      transponderId: [{ value: '', disabled: true, }],
      removeReason: [''],
    });


    this.tagRemovalStaus = new Array<NameIdNameConfig>();
    const tagRemovalStausKeys = Object.keys(
      this.databaseMapService.eTransponderStatusMap
    );

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < tagRemovalStausKeys.length; i++) {
      this.tagRemovalStaus.push({
        NameId: tagRemovalStausKeys[i],
        Name: this.databaseMapService.eTransponderStatusMap[tagRemovalStausKeys[i]],
      });
    }
  }

  ngAfterContentInit(): void {
    this.transponderRemoveInfoFormGroup.controls.plate.setValue(this.transponder.PlateNum);
    this.transponderRemoveInfoFormGroup.controls.transponderId.setValue(this.transponder.TransponderDisplayId);
  }



  onTransponderRemove(transponder: Transponder) {
    this.isTagRemoving = true
    this.transponder.VehicleStatus = 7; // HHB-1716 - awaiting battery replacement
    this.onTransponderRemoveOutput.emit(transponder)
  }

}
