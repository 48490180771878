import { Pipe, PipeTransform } from '@angular/core';
import { DatabaseMapService } from '../providers/databasemap.service';

@Pipe({ name: 'mapType' })
export class MapKeyValuePipe implements PipeTransform {
  constructor(private databaseMapService: DatabaseMapService) {}

  transform(key: number | string, mapType: any): string {
    if (key) {
      return this.databaseMapService.getMapValue(key, mapType);
    }
  }
}
