import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-ts';
import { STORAGE_KEY } from '../models/keyConstand';

@Injectable({
  providedIn: 'root',
})
export class SecureSessionStorageService {
  isCrypto: boolean;
  cryptoString: string;
  keyUnencodedList: any[];

  constructor() {
    this.isCrypto = true;
    this.cryptoString = STORAGE_KEY;
    this.keyUnencodedList = [];
  }

  setData(key, dataValue) {
    let dataToString;
    if (typeof dataValue !== 'string' || typeof dataValue !== 'number') {
      dataToString = JSON.stringify(dataValue);
    } else {
      dataToString = dataValue;
    }
    if (
      this.isCrypto &&
      dataToString !== undefined &&
      this.keyUnencodedList.indexOf(key) === -1
    ) {
      dataToString = AES.encrypt(dataToString, this.cryptoString).toString();
    }
    sessionStorage.setItem(key, dataToString);
  }

  getData(key) {
    if (this.checkIsExist) {
      if (this.checkDataIsValid) {
        /** @type {?} */
        const dataVal = sessionStorage.getItem(key);
        if (this.isCrypto && this.keyUnencodedList.indexOf(key) === -1) {
          /** @type {?} */
          const data = this.decryptData(dataVal);
          return this.checkAsJSON(data) ? JSON.parse(data) : data;
        }
        return this.checkAsJSON(dataVal) ? JSON.parse(dataVal) : dataVal;
      } else {
        return null; // this.setError(TypeErrorStorage.DataCorrupted);
      }
    } else {
      return null; // this.setError(TypeErrorStorage.KeyNotFound);
    }
  }

  removeItems(...keys) {
    for (const key of keys) {
      sessionStorage.removeItem(key);
    }
  }

  destroy() {
    sessionStorage.clear();
  }

  decryptData(data) {
    if (data) {
      /** @type {?} */
      const bytes = AES.decrypt(data.toString(), this.cryptoString);
      return bytes.toString(enc.Utf8);
    }
    return null;
  }

  checkIsExist(key) {
    return (
      sessionStorage.getItem(key) !== undefined &&
      sessionStorage.getItem(key) !== null
    );
  }

  checkAsJSON(data) {
    try {
      JSON.parse(data);
    } catch (err) {
      return false;
    }
    return true;
  }

  checkDataIsValid(key) {
    let b;
    try {
      if (
        sessionStorage.getItem(key) !== undefined ||
        sessionStorage.getItem(key) !== null
      ) {
        b = true;
      } else {
        b = false;
      }
    } catch (err) {
      b = false;
    }
    return b;
  }
}
