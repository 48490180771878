import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CountryInfo } from '../models/countryInfo';
import { AccountProfileConfig } from '../models/idNameConfig';
import { ProvinceInfo } from '../models/provinceInfo';
import { TollCategoryInfo } from '../models/tollCategoryInfo';

@Injectable({
  providedIn: 'root',
})
export class Globals {
  appName = ''; // will be set in GetLocalClientConfiguration
  countries: CountryInfo[] = null;
  provinces: ProvinceInfo[] = null;
  tollCategories: TollCategoryInfo[] = null;

  accountProfiles: AccountProfileConfig[] = null;
  accountProfilesObservable: Observable<AccountProfileConfig[]> = null;
  settings: any = {};
}
