export class Account {
  LoadTime: Date; // internal time when instance was loaded
  AccountID: number;
  ContractProvider: string;
  ContractProviderID: number;
  AccountProfile: string;
  AccountProfileID: number;
  AccountType: string;
  AccountSubType: string;
  AccountStatus: number;
  AccountStatusDatetime: string;
  FirstName: string;
  LastName: string;
  Language: number;
  ContactPersonTitle: string;
  ContactPersonLastName: string;
  ContactPersonFirstName: string;
  PhoneBusinessContact: string;
  PhoneBusinessContactExt: string;
  MailingAddress: string;
  MailingCity: string;
  MailingProvinceID: number;
  MailingProvinceName: string;
  MailingProvince: string;
  MailingCountryID: number;
  MailingPostalCode: string;
  SameBillingAsMailingAddress: boolean;
  BillingAddress: string;
  BillingCity: string;
  BillingProvinceID: number;
  BillingProvinceName: string;
  BillingProvince: string;
  BillingCountryID: number;
  BillingPostalCode: string;
  PrimaryPhone: string;
  PrimaryPhoneExt: string;
  AlternatePhone: string;
  AlternatePhoneExt: string;
  MobilePhone: string;
  Fax: string;
  Sms: string;
  Email: string;
  EmailNotification: string;
  Newsletter: string;
  AltEmail: string;
  UserLogonId: string;
  Password: string;
  Question: string;
  Answer: string;
  TempPasswordCeaseDatetime: string;
  // payment info
  PaymentType: string;
  PaymentCardType: number;
  TotalInitialAmount: number;

  PreAuthStatus: number;
  LowBalanceAmount: number; // balanceReplenishThreshold
  TopUpAmount: number; // balanceReplenishAmt
  PadDayOfMonth: number;
  CurrencyType: string;

  // credit card
  PaymentNumber: string; // masked with *
  BankCardTokenId: string;
  BankAccountType: number;
  BankAccountNumber: number;
  BankTransitNumber: number;
  BalanceWarning: number;
  BalanceViolation: number;
  BankName: string;
  BankAddress: string;
  BankAccountFirstName: string;
  BankAccountLastName: string;
  BankBranchSortingCode: string;
  BankAccountDocument: string;
  BankAccountName: string;
  // transponders
  TranspondersToPurchase: string;
  OwnTransponder: string;

  // pre-auth
  IsPreAuthSet: boolean;
  PreAuthCreditCardType: string;
  PreAuthCardNumber: string;
  PreAuthCardExpiryMonth: number;
  PreAuthCardExpiryYear: number;
  PreAuthCardHolder: string;
  PaymentProvider: string;
  LastPreAuthRequestStatus: string;
  TwoFactorAuthEnabled: boolean;

  // Summary
  LastPaymentAmount: number;
  BillingDate: string;
  LastPaymentDateTime: string;
  BalanceStatus: number;
  AccountBalance: number;
  TranspondersDistributed: number;
  TranspondersRequested: number;
  RegisteredVehicles: number;
  IsManualReplenInitPayCCSet: boolean;
  NationalInsNumber: string;
  UserGroupName: string;
  EInvoiceStatus: string;

  // Tax info
  TaxFiscalId: string;
  TaxAuthorityId: string;

  // Primary/Secondary web user
  PrimaryWebUser: boolean;
  FatherFirstName: string;
  FatherLastName: string;
  Profession: string;
  AccountHolderType: number;
  IsFreelancer: boolean;
  StmtDistributionActive: boolean;
  ReceiveMarketingEmails: boolean;
  RestrictionStatus: string;
  MacPassPluse: boolean;
}
