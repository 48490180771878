import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { WebUserCardInfo } from '../models/webUserCardInfo';
import { DatabaseMapService } from './databasemap.service';
import { LocalstorageService } from './localstorage.service';
import { ProxyService } from './proxy.service';
import { ReCaptchaService } from './re-captcha.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService implements OnDestroy {
  private paymentEndpoint = 'api/payment';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private subs = new SubSink();
  private endpointUrl: string;

  constructor(
    private http: HttpClient,
    private proxy: ProxyService,
    private databaseMapService: DatabaseMapService,
    private reCaptchaService: ReCaptchaService,
    private localstorageService: LocalstorageService
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  getUserCardsInfo(AccountId: number): Observable<WebUserCardInfo[]> {
    const params = new HttpParams().set('accountId', AccountId.toString());
    return this.reCaptchaService.refresh('GetUserCardsInfo').pipe(
      switchMap(() => {
        return this.http.get<WebUserCardInfo[]>(
          this.endpointUrl + this.paymentEndpoint + '/GetUserCardsInfo',
          { headers: this.headers, params }
        );
      })
    );
  }

  setUserCardsInfo(webUserCard: WebUserCardInfo): Observable<boolean> {
    return this.reCaptchaService.refresh('SetUserCardsInfo').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl + this.paymentEndpoint + '/SetUserCardsInfo',
          JSON.stringify(webUserCard),
          { headers: this.headers }
        );
      })
    );
  }

  getPaymentReceipt(params): Observable<any> {
    return this.http.get(
      this.endpointUrl + this.paymentEndpoint + '/GetPaymentReceiptPDF',
      { responseType: 'arraybuffer', params }
    );
  }

  getActivityPaymentReceipt(params): Observable<any> {
    return this.http.get(
      this.endpointUrl + this.paymentEndpoint + '/GetActivityPaymentReceiptPDF',
      { responseType: 'arraybuffer', params }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
