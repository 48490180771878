export class TokenRequest {
  ssl_merchant_id: string;
  ssl_user_id: string;
  ssl_pin: string;
  ssl_transaction_type: string;
  ssl_amount: number;
  constructor(
    merchant_id: string,
    user_id: string,
    pin: string,
    transaction_type: string,
    amount: number
  ) {
    this.ssl_merchant_id = merchant_id;
    this.ssl_user_id = user_id;
    this.ssl_pin = pin;
    this.ssl_transaction_type = transaction_type;
    this.ssl_amount = amount;
  }
  public static createBlankReq() {
    return new TokenRequest('', '', '', '', 0);
  }
}
