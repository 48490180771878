import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SubSink } from 'subsink';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import {
  ConfigurationService,
  Account,
  PasswordValidation,
  AuthServiceProvider,
  WebAccessService,
  FormValidation,
} from '@ibitoll/toll-core';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  first,
  map,
  switchMap,
} from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit, OnDestroy {
  public hideUsernameInput = false;

  editAccount = true;

  webInfoFormGroup: FormGroup;

  passwordValidRegex = '^(?!.* )(?=.{8,})(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*$';

  @Input() standAlone = false;

  public account: Account;
  private subs = new SubSink();
  regexp = /^\S*$/;
  nameRegexp = /[%&<>/ ]/;
  validUsername = false;
  checkingUserName = false;
  cHide = true;
  nHide = true;
  executeNameCheck = false;

  constructor(
    private formBuilder: FormBuilder,
    public configurationService: ConfigurationService,
    public authService: AuthServiceProvider,
    private webAccessService: WebAccessService
  ) {}

  get webInfo() {
    return this.webInfoFormGroup.controls;
  }

  ngOnInit(): void {
    this.webInfoFormGroup = this.formBuilder.group(
      {
        userName: [
          '',
          [
            Validators.required,
            Validators.minLength(5),
            Validators.pattern(this.regexp),
            FormValidation.InvalidValidator.bind(this),
            this.checkSpace.bind(this),
          ],
          this.checkUsername.bind(this),
        ], // 
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(this.passwordValidRegex),
            // check whether the entered password has a number
            PasswordValidation.patternValidator(/\d/, {
              hasNumber: true,
            }),
            // check whether the entered password has upper case letter
            PasswordValidation.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // check whether the entered password has a lower case letter
            PasswordValidation.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            Validators.minLength(8),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: PasswordValidation.MatchPassword,
      }
    );
    //this.webInfoFormGroup?.controls['userName'].setValue('akbtestactivate');
    this.webInfoFormGroup?.controls['userName'].updateValueAndValidity();

    //this.checkUsername(this.webInfoFormGroup.controls['userName']);
    console.log('webInfoFormGroup='+ this.webInfoFormGroup?.controls['userName'].value);
  }

  checkSpace(control: AbstractControl) {
    if (control.value) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;

      if (isValid) {
        this.validUsername = false;
        return control.setErrors({ whiteSpace: true });
      } else {
        return control.setErrors(null);
      }
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  hideUsername() {
    this.hideUsernameInput = true;
    this.webInfoFormGroup.removeControl('userName');
  }

  onKeyUp(event: KeyboardEvent) {
    if (this.webInfoFormGroup?.controls['userName'].value.length > 3) {
      this.executeNameCheck = true;
      //this.checkUsername(this.webInfoFormGroup?.controls['userName']);
    } else {
      this.executeNameCheck = false;
    }
    console.log('Username.KeyIp: Go=' + this.executeNameCheck + '; event=' + JSON.stringify(event) + '; VAL=' + this.webInfoFormGroup?.controls['userName'].value);
  }
  
  checkUsername(control: AbstractControl) {
    if (this.executeNameCheck) {
    //if (control.touched) { // Only perform the validation if the field has been touched
      return control.valueChanges.pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        switchMap((username) => {
          this.validUsername = false;
          this.checkingUserName = true;
          return this.webAccessService.checkUsername(control.value.trim());
        }),
        map(
          (unique: boolean) => {
            this.checkingUserName = false;
            if (unique) {
              setTimeout(() => {
                this.webInfoFormGroup.updateValueAndValidity(); // this function will trigger statusChange one more time.
              });
              return { unique: true };
            } else {
              this.authService.setUserLogonId(control.value.trim());
              this.validUsername = true;
              setTimeout(() => {
                this.webInfoFormGroup.updateValueAndValidity(); // this function will trigger statusChange one more time.
              });
              return null;
            }
          },
          (error) => {
            this.checkingUserName = false;
            return of({ unverfied: true });
          }
        ),
        catchError((err) => {
          this.checkingUserName = false;
          return of({ unverfied: true });
        }),
        first()
      );
    } else {
      return null; // If the field hasn't been touched, return null to indicate no validation errors
    }
  }

  canEditAccount(): boolean {
    return this.editAccount;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
