/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hhb-chase-saved-card',
  templateUrl: './chase-saved-card.component.html',
  styleUrls: ['./chase-saved-card.component.scss']
})
export class ChaseSavedCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
