export class CarMake {
  Id: string;
  idCarType: string;
  Name: string;
  DateCreate: Date;
  DateUpdate: Date;
}

export class CarModel {
  Id: string;
  IdCarMake: string;
  Year: string;
  Name: string;
  DateCreate: Date;
  DateUpdate: Date;
  idCarType: string;
}


