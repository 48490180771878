export class AccountDocuments {
  public DriversLicenseDataType: string;
  public DriversLicenseContent: string;
  public UtilityBillDataType: string;
  public UtilityBillContent: string;
  public NIBDataType: string;
  public NIBContent: string;
  public VehicleOwnershipDataByte: string;
  public VehicleOwnershipContent: string;
}
