export class SuspendTagQuery {
  AccountId: number;
  ManufacturerId: number;
  TransponderId: number;
  TransponderStatus: number;
  TagList: string;
}

export class SuspendTagQueryList {
  //TagList: SuspendTagQuery[];
  TagList: string;
}