import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import csv from 'csvtojson';
import * as ExcelProper from 'exceljs';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';
import { SubSink } from 'subsink';

import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { TransactionRecord } from '../../models/transactionRecord';
import { ActivityQuery } from '../../models/activityQuery';
import { TollCategoryInfo } from '../../models/tollCategoryInfo';
import { Transponder } from '../../models/transponder';
import { AccountService } from '../../providers/account.service';
import { DateTimeService } from '../../providers/date-time.service';
import { UtilsService } from '../../providers/utils.service';
import { ConfigurationService } from '../../providers/configuration.service';
import { DatabaseMapService } from '../../providers/databasemap.service';
import { ThemeService } from '../../providers/theme.service';
import { Account } from '../../models/account';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'card-activity',
  templateUrl: './card-activity.component.html',
  styleUrls: ['./card-activity.component.scss'],
})
export class CardActivityComponent implements OnInit, OnDestroy {
  @Input()
  integrated: boolean;

  paymentChecked: boolean;
  transactionChecked: boolean;
  feeChecked: boolean;
  adjustmentChecked: boolean;
  refundChecked: boolean;

  loadingData = true;

  transactions: TransactionRecord[];
  searchFilter: ActivityQuery;
  tollCategories: TollCategoryInfo[];

  private subs = new SubSink();

  dateRangePickerOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: 'mm/dd/yyyy',
  };

  dModel: IMyDateModel = null;

  transponderOptions: Transponder[];
  allTransponderOption: any = {
    TransponderText: 'ALL_TRANSPONDERS_SMARTCARDS',
    value: '',
  };
  selectedTransponder = this.allTransponderOption.value;
  isMba: boolean = this.utilsService.isMackinac();
  account: Account;

  constructor(
    private accountService: AccountService,
    public dateTimeService: DateTimeService,
    private utilsService: UtilsService,
    private configurationService: ConfigurationService,
    public databaseMapService: DatabaseMapService,
    public themeService: ThemeService,
    @Inject(DOCUMENT) private _document: Document,
    public translate: TranslateService
  ) {
    this.searchFilter = new ActivityQuery();
  }

  ngOnInit() {
    this.subs.add(
      this.accountService.activeAccount.subscribe((account: Account) => {
        this.account = account;
        this.paymentChecked = true;
        this.transactionChecked = true;
        this.feeChecked = true;
        this.adjustmentChecked = true;
        this.refundChecked = true;

        this.searchFilter.AccountId = account.AccountID;

        const searchStartDate = new Date();
        searchStartDate.setDate(searchStartDate.getDate() - 7);
        searchStartDate.setHours(0);
        searchStartDate.setMinutes(0);
        searchStartDate.setSeconds(0);

        const searchEndDate = new Date();
        searchEndDate.setDate(searchEndDate.getDate());
        searchEndDate.setHours(0);
        searchEndDate.setMinutes(0);
        searchEndDate.setSeconds(0);

        this.dModel = {
          isRange: true,
          singleDate: null,
          dateRange: {
            beginJsDate: searchStartDate,
            endJsDate: searchEndDate,
          },
        };

        this.configurationService
          .getTollCategories()
          .subscribe((tollCategories) => {
            this.tollCategories = tollCategories;
            console.log('tollCategories' + JSON.stringify(this.tollCategories));
            this.setSearchDateFilter();
            this.transactions = [];

            this.getAccountRecentActivities();
            this.getTransponderVehicleInfo();
          });
      })
    );
  }

  getAccountRecentActivities() {
    this.loadingData = true;
    this.accountService.getAccountRecentActivities(this.searchFilter).subscribe(
      (resp) => {
        this.transactions = resp.sort(
          (a, b) =>
            new Date(b.TransactionLDateTime).getTime() -
            new Date(a.TransactionLDateTime).getTime()
        );
        this.loadingData = false;
      },
      (error) => {
        console.log('error in GetAccount: ' + error);
      }
    );
  }

  setSearchDateFilter() {
    this.searchFilter.BeginLDateTime = this.dModel.dateRange.beginJsDate;
    this.searchFilter.EndLDateTime = this.dModel.dateRange.endJsDate;
    this.searchFilter.BeginLDateTimeStr =
      this.dModel.dateRange.beginJsDate.toString();
    this.searchFilter.EndLDateTimeStr =
      this.dModel.dateRange.endJsDate.toString();

    if (this.searchFilter.BeginLDateTime != null) {
      this.searchFilter.EndLDateTime.setDate(
        this.searchFilter.EndLDateTime.getDate() + 1
      );
    }
  }

  displayCategory(categoryName: string): string {
    if (this.isMba) {
      let forDisplay = categoryName;
      if (categoryName) {
        if (forDisplay.indexOf('(') > 0) {
          forDisplay = forDisplay.substring(0, forDisplay.indexOf('(')).trim();
          categoryName = this.tollCategories.find((c) => {
            return c.Name === forDisplay;
          }).GroupName;
        }
        return categoryName;
      }
      return categoryName;
    } else {
      return categoryName;
    }
  }

  onSearchDateRangeChanged(event: IMyDateModel) {
    this.dModel = event;
    this.setSearchDateFilter();
    this.getAccountRecentActivities();
  }

  getTransponderVehicleInfo() {
    console.log(this.account.AccountID);

    this.accountService
      .getTransponderVehicleInfo(
        this.account.AccountID
      )
      .subscribe(
        (resp) => {
          this.transponderOptions = resp;
        },
        (error) => {
          console.log('error in GetTransponderVehicleInfo: ' + error);
        }
      );
  }

  downloadFile(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });

    FileSaver.saveAs(blob, fileName);
  }

  downloadCsv() {
    const table = this._document.getElementById(
      'data-table'
    ) as HTMLTableElement;
    let data = '';

    for (let i = 0; i < table.rows.length; i++) {
      const row = table.rows.item(i);

      for (let j = 0; j < row.cells.length; j++) {
        const cell = row.cells.item(j);

        data += cell.innerText.replace(',', '.');

        if (j !== row.cells.length - 1) {
          data += ',';
        }
      }

      data += '\n';
    }
    const fileStartDate = this.dModel.dateRange.beginJsDate;
    const fileEndDate = this.dModel.dateRange.endJsDate;
    fileEndDate.setDate(fileEndDate.getDate() - 1);

    const fileName =
      this.dateTimeService.getShortDateString(fileStartDate) +
      ' to ' +
      this.dateTimeService.getShortDateString(fileEndDate);
    this.downloadFile(data, fileName + '.csv');
  }

  downloadXlsx() {
    console.log('downloadXlsx Clicked');
    const table = this._document.getElementById(
      'data-table'
    ) as HTMLTableElement;
    const workbook: ExcelProper.Workbook = new Excel.Workbook();
    const columns = [];
    let data = '';
    for (let i = 0; i < table.rows.length; i++) {
      const row = table.rows.item(i);

      for (let j = 0; j < row.cells.length; j++) {
        const cell = row.cells.item(j);
        data += cell.innerText.replace(',', '.');
        if (i === 0) {
          columns.push({
            header: cell.innerText.replace(',', '.'),
            key: cell.innerText.replace(',', '.'),
            width: 20,
          });
        }
        if (j !== row.cells.length - 1) {
          data += ',';
        }
      }
      data += '\n';
    }
    const fileStartDate = this.dModel.dateRange.beginJsDate;
    const fileEndDate = this.dModel.dateRange.endJsDate;
    fileEndDate.setDate(fileEndDate.getDate() - 1);

    const fileName =
      this.dateTimeService.getShortDateString(fileStartDate) +
      ' to ' +
      this.dateTimeService.getShortDateString(fileEndDate);
    const worksheet = workbook.addWorksheet(fileName);
    worksheet.columns = columns;
    csv({
      noheader: false,
    })
      .fromString(data)
      .then((csvRow) => {
        csvRow.forEach((row) => {
          worksheet.addRow(row);
        });
        workbook.xlsx.writeBuffer().then((res) => {
          const blob = new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          FileSaver.saveAs(blob, fileName + '.xlsx');
        });
      });
  }

  parseAmount(value: number) {
    if (this.isMba) {
      return -value;
    } else {
      return Math.abs(value);
    }
  }

  isCredit(value: number) {
    if (value >= 0) {
      // red
      // return value;
    } else if (!this.isMba) {
      // green
      return 'CR';
    }
  }

  getTransactionType(transaction: TransactionRecord) {
    let transactionText = transaction.TransactionText;

    if (transaction.PaymentMethod) {
      const paymentMethod =
        this.databaseMapService.ePaymentMethodMap[transaction.PaymentMethod]; // cash, credit card
      transactionText += ' ' + paymentMethod;
    }

    if (transaction.BankAuthorizationStatusDB) {
      const statusText =
        this.databaseMapService.getAuthorizationStatusTypeMapByValue(
          transaction.BankAuthorizationStatusDB
        ); // approved, declined
      transactionText += ' (' + statusText + ')';
    }
    return transactionText;
  }

  getDescription(transaction: TransactionRecord) {
    // Only for MBA
    const tdate = new Date(transaction.TransactionLDateTime);
    if (transaction.UserComment) {
      if (
        transaction.UserComment === 'Balance Transfer' &&
        tdate.getMonth() === 8 &&
        tdate.getDate() === 9
      ) {
        return `Balance Transfer`;
      }
    }

    const transactionSubType = transaction.TransactionSubType;

    if (
      transactionSubType !==
      this.databaseMapService.eAccountTransactionSubTypeMap.ORIGINAL_TRANSACTION
    ) {
      return transaction.DescriptionText;
    }
  }

  getPopUpDescription(transaction: TransactionRecord) {
    try {
      if (
        transaction.TransactionSubType ===
        this.databaseMapService.eAccountTransactionSubTypeMap.TOLL_CHARGE
      ) {
        const userComments = transaction.UserComment.split(';');
        const typeCat = userComments[5].split('>');
        const amounts = userComments[6].split('>');
        return `For transaction of ${typeCat[0]}, Plaze ${
          userComments[1]
        }, Lane ${userComments[2]},
         with category ${typeCat[1]}, transponder(${userComments[0]}),
          on ${this.dateTimeService.getMackinacShortDateTimeString(
            new Date(userComments[3])
          )}
          <br> Adjustment for ${userComments[0]}|
          ${this.dateTimeService.getMackinacShortDateTimeString(
            new Date(userComments[3])
          )}|($${amounts[0]})`;
      }
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  getLocation(transaction: TransactionRecord) {
    if (
      transaction.TransactionType ===
      this.databaseMapService.eAccountTransactionTypeMap.administration_charge
    ) {
      return 'Customer Service';
    } else {
      return transaction.LaneText;
    }
  }

  getDate(date: string): Date {
    return new Date(date);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
