import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from "@angular/core";
import { fromEvent } from "rxjs";
import { SubSink } from "subsink";

@Directive({
  selector: "[btnDisabled]"
})
export class ClickStopPropagation implements OnInit, OnDestroy {

  private subs = new SubSink();

  constructor(private elementRef: ElementRef) {
  }


  ngOnInit(): void {
    const el = this.elementRef.nativeElement;

    this.subs.add(
      fromEvent(el.parentNode, 'click', { capture: true })
        .subscribe((e: any) => {
          if (e.target === el) {
            if (el.classList.contains('disabled')) {
              e.stopImmediatePropagation();
              e.preventDefault();
              e.stopPropagation()

              if (!el.classList.contains('shake')) {
                el.classList.add('shake');
                setTimeout(() => {
                  el.classList.remove('shake');
                }, 200);
              }
            }
          }
        })
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
