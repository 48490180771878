import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  constructor(public datepipe: DatePipe) { }

  dateFormat: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  dateFormatOffset: RegExp =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}-\d{2}:\d{2}$/;
  dateFormatUTC: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  monthNamesShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  monthNamesGreek = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  reviver = (key, value) => {
    let valueData: string[];

    if (typeof value === 'string' && this.dateFormat.test(value)) {
      valueData = value.split(/\D+/);

      return new Date(
        parseInt(valueData[0], 10),
        parseInt(valueData[1], 10) - 1,
        parseInt(valueData[2], 10),
        parseInt(valueData[3], 10),
        parseInt(valueData[4], 10),
        parseInt(valueData[5], 10)
      );
    } else if (typeof value === 'string' && this.dateFormatOffset.test(value)) {
      valueData = value.split(/\D+/);

      return new Date(
        parseInt(valueData[0], 10),
        parseInt(valueData[1], 10) - 1,
        parseInt(valueData[2], 10),
        parseInt(valueData[3], 10),
        parseInt(valueData[4], 10),
        parseInt(valueData[5], 10)
      );
    } else if (typeof value === 'string' && this.dateFormatUTC.test(value)) {
      valueData = value.split(/\D+/);

      return new Date(
        Date.UTC(
          parseInt(valueData[0], 10),
          parseInt(valueData[1], 10) - 1,
          parseInt(valueData[2], 10),
          parseInt(valueData[3], 10),
          parseInt(valueData[4], 10),
          parseInt(valueData[5], 10)
        )
      );
    } else {
      return new Date(value);
    }
  };

  getTimeString(date: Date): string {
    if (date == null) {
      return '';
    }
    date = this.reviver(0, date);
    return (
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2) +
      ':' +
      ('0' + date.getSeconds()).slice(-2)
    );
  }

  getShortDateString(date: Date): string {
    if (date == null) {
      return '';
    }
    date = this.reviver(0, date);
    return (
      this.monthNamesShort[date.getMonth()] +
      ' ' +
      date.getDate() +
      ' ' +
      date.getFullYear()
    );
  }

  getMackinaShortDateString(date: Date) {
    if (date == null) {
      return '';
    }

    date = this.reviver(0, date);
    const sDay = date.getDate().toString().padStart(2, '0');

    return (
      this.monthNamesGreek[date.getMonth()] +
      '/' +
      sDay +
      '/' +
      date.getFullYear()
    );
  }

  getMonthYearDateString(date: Date) {
    if (date == null) {
      return '';
    }
    date = this.reviver(0, date);
    return this.monthNames[date.getMonth() - 1] + '-' + date.getFullYear();
  }

  getGreekDateString(date: Date): string {
    if (date == null) {
      return '';
    }

    date = this.reviver(0, date);
    const sDay = date.getDate().toString().padStart(2, '0');

    return (
      sDay +
      '/' +
      this.monthNamesGreek[date.getMonth()] +
      '/' +
      date.getFullYear()
    );
  }

  getDateString(date: Date): string {
    if (date == null) {
      return '';
    }
    date = this.reviver(0, date);
    return (
      this.monthNames[date.getMonth()] +
      ' ' +
      date.getDate() +
      ' ' +
      date.getFullYear()
    );
  }

  getShortDateTimeString(date: Date): string {
    return this.getShortDateString(date) + ' ' + this.getTimeString(date);
  }

  getMackinacShortDateTimeString(date: Date): string {
    return (
      this.getMackinaShortDateString(date) + ' ' + this.getTimeString(date)
    );
  }


  getMdotDateTimeString(date: Date): string {
    return this.datepipe.transform(date, 'MM/dd/yyyy hh:mm');
  }

  getShortMdotDateTimeString(date: Date): string {
    return this.datepipe.transform(date, 'MMM-dd-yyyy');
  }


  getGreekDateTimeString(date: Date): string {
    return this.getGreekDateString(date) + ' ' + this.getTimeString(date);
  }
}
