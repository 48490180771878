export class Employee {
  AccountId: number;
  VehicleId: number;
  PlateNum: string;
  ProvinceName: string;
  VehicleMake: string;
  VehicleModel: string;
  VehicleYear: string;
  LastName: string;
  FirstName: string;
  ManufacturerId: number;
  TransponderId: number;
  TransponderTypeName: string;
  TollCategoryName: string;
  TripCount: number;
  TransponderText: string;
}
