<div *ngIf="!integrated" class="section-header mb-4">
  <h4>{{ 'TRANSPONDERS_SMARTCARDS' | translate }}</h4>
</div>

<div class="mx-3">
  <div class="row mt-4" *ngIf="!isEmployee && configurationService.config.RequestTransponder">
    <div class="col-6 pl-4" *ngIf="!isLoading">
      <h4 *ngIf="integrated">{{ 'TRANSPONDERS_SMARTCARDS' | translate }}</h4>
    </div>
    <div class="col-6" *ngIf="!isLoading">
      <button class="btn btn-app-primary float-sm-right" (click)="launchTransponderRequest()" InActiveAccount>
        <i class="fa fa-plus-square"></i>
        {{ 'REQUEST_TRANSPONDERS_SMARTCARDS' | translate }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col p-0">
      <div class="text-center d-flex justify-content-center align-self-center" *ngIf="isLoading">
        <i class="fa fa-4x fa-spinner fa-pulse"></i>
      </div>
      <div class="
          table-responsive
          conditionalScroll
          d-none d-md-block d-lg-block d-xl-block
        " id="scroll-bar" *ngIf="!isLoading">
        <table class="table mt-2">
          <thead aria-hidden="true">
            <tr>
              <th scope="col">{{ 'TRANSPONDERS_SMARTCARD' | translate }}</th>
              <th scope="col">
                {{ 'VEHICLE_MAKE' | translate }}
              </th>
              <th scope="col">
                {{ 'VEHICLE_MODEL' | translate }}
              </th>
              <th scope="col">{{ 'COLOR' | translate }}</th>
              <th scope="col">
                {{ 'VEHICLE_PLATE' | translate }}
              </th>
              <th class="d-none d-md-block" scope="col">
                {{ 'VEHICLE_CATEGORY' | translate }}
              </th>
              <th scope="col">{{ 'STATUS' | translate }}</th>
              <th *ngIf="isEmployee" scope="col">
                {{ 'TRIP_COUNT' | translate }}
              </th>
              <th scope="col" InActiveAccount>{{ 'ACTION' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let transponder of transponders">
              <td>{{ transponder.TransponderDisplayId }}</td>
              <td>{{ transponder.VehicleMake }}</td>
              <td>{{ transponder.VehicleModel }}</td>
              <td>{{ transponder.VehicleColor }}</td>
              <td>{{ transponder.PlateNum }}</td>
              <td class="d-none d-md-block">
                {{ getCategoryName(transponder.TollCategoryId) }}
              </td>
              <td class="">
                <span>
                  <i class="fa" [ngClass]="
                      transponder?.RestrictionStatus != 2
                        ? 'fa-exclamation-circle hov-alert'
                        : 'fa-check-circle-o hov-ok'
                    "></i>
                  {{
                    transponder.RestrictionStatus
                      | mapType: 'eTransponderRestrictionMap'
                      | translate
                  }}</span>
              </td>
              <td *ngIf="isEmployee">{{ transponder.TripCount }}</td>
              <td>
                <button InActiveAccount type="button" class="btn btn-warning btn-sm"
                  *ngIf="transponder.RestrictionStatus == 2" (click)="suspendTransponder(transponder)">
                  <i class="fa far fa-pause-circle"></i>
                  {{ 'SUSPEND' | translate }}
                </button>
              </td>
            </tr>
            <tr *ngFor="let transponder of pendingTransponders">
              <td>Unassigned</td>
              <td>{{ transponder.VehicleMake }}</td>
              <td>{{ transponder.VehicleModel }}</td>
              <td>{{ transponder.VehicleColor }}</td>
              <td>{{ transponder.PlateNum }}</td>
              <td>{{ getCategoryName(transponder.TollCategoryId) }}</td>
              <td *ngIf="transponder.VehicleStatus === 'R'">Ordered</td>
              <td *ngIf="isEmployee">{{ transponder.TripCount }}</td>
              <td InActiveAccount>-</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="d-md-none">
        <ul class="list-group list-group-flush mt-2">
          <li class="list-group-item mb-1" *ngFor="let transponder of transponders">
            <div class="row">
              <div class="col">
                <div class="h5">
                  {{ transponder.TransponderDisplayId }}
                </div>
              </div>
              <div class="col">
                <span class="float-right">
                  <i class="fa" [ngClass]="
                      transponder.RestrictionStatus != 2
                        ? 'fa-exclamation-circle hov-alert'
                        : 'fa-check-circle-o hov-ok'
                    "></i>
                  {{
                    transponder.RestrictionStatus
                      | mapType: 'eTransponderRestrictionMap'
                      | translate
                  }}
                </span>
              </div>
            </div>
            <div>
              <button type="button" class="btn btn-warning btn-sm float-right"
                *ngIf="transponder.RestrictionStatus == 2" (click)="suspendTransponder(transponder)">
                <i class="fa far fa-pause-circle"></i>
                {{ 'SUSPEND' | translate }}
              </button>
              <div>
                <b> {{ 'PLATE' | translate }}:</b> {{ transponder.PlateNum }}
              </div>
              <div>
                <b> {{ 'CATEGORY' | translate }}:</b>
                {{ getCategoryName(transponder.TollCategoryId) }}
              </div>
            </div>
          </li>
          <li class="list-group-item mb-1" *ngFor="let transponder of pendingTransponders">
            <div class="row">
              <div class="col">
                <div>
                  <b> Ordered</b>
                </div>
                <div>
                  <b> {{ 'PLATE' | translate }}:</b> {{ transponder.PlateNum }}
                </div>
                <div>
                  <b> {{ 'CATEGORY' | translate }}:</b>
                  {{ getCategoryName(transponder.TollCategoryId) }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <request-transponders (onTransponderRequest)="onTransponderRequest($event)"></request-transponders>
  <suspend-transponder-confirmation [transponder]="transponder"
    (onTransponderSuspended)="onTransponderSuspended($event)"></suspend-transponder-confirmation>
</div>
