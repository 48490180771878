import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Config } from '../models/config';
import { EURO_CURRENCY_CODE, LANGUAGE_KEY } from '../models/keyConstand';
import { Property } from '../models/theme';
import { AppState } from './appstate.service';
import { LocalstorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public active: Config;
  adoc: any;

  constructor(
    public appState: AppState,
    private localstorageService: LocalstorageService,
    @Inject(DOCUMENT) adoc
  ) {
    this.adoc = adoc
  }

  setActiveTheme(theme: Config): void {
    this.active = theme;
    if (this.active.Properties) {
      this.updateActiveTheme();
    }
  }

  updateActiveTheme(): void {
    this.active.Properties.forEach((property) => {
      this.setStyle(property);
    });
  }

  private setStyle(property: Property) {
    this.adoc.documentElement.style.setProperty(property.Name, property.Value);
  }

  public GetCurrencyCode(): any {
    if (this.active.DefaultCurrencyCode === EURO_CURRENCY_CODE) {
      return '€';
    } else {
      return '$';
    }
  }

  public SetClientLanguage(language: string): void {
    this.localstorageService.setItem(LANGUAGE_KEY, language);
  }

  public GetClientLanguage(): string {
    if (this.localstorageService.check(LANGUAGE_KEY)) {
      return this.localstorageService.getItem(LANGUAGE_KEY);
    } else {
      return this.active.DefaultLanguage;
    }
  }
}
