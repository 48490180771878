<div class="container-12">
  <div class="col-12 p-0">
    <form [formGroup]="transponderInfoFormGroup">
      <div class="card new-transponder">
        <div class="card-body p-1">
          <div class="row">
            <div class="col-12 ps-4">
              <h2 class="mb-0 text-start mt-2">
                {{ 'PLEASE_ADD_VEHICLE' | translate }}
                <div class="req-text text-muted float-end fw-light">
                  <small *ngIf="false" class="small">
                    <span class="non-ng-invalid-mark">*</span>{{ 'DEF_REQUIRED' | translate }}
                  </small>
                </div>
              </h2>
            </div>
            <div class="col-12 text-start px-4">
              <h3 class="form-label account-label">
                <b>
                  {{ 'VEHICLE' | translate }}
                </b>
              </h3>

              <div class="mb-3"
                *ngIf="!hideTransponderType" ><!--  -->
                <label id="transponderFee-newtransponder-label"
                       for="transponderFee-newtransponder-{{ unique }}"
                       class="form-label">
                  {{ 'TAG_TYPE' | translate }}
                </label>
                <select class="form-control form-select"
                        id="transponderFee-newtransponder-{{ unique }}"
                        name="transponderType"
                        formControlName="transponderType"
                        [ngClass]="{
                    'remove-form-select-dropdown':
                      checkStatus('transponderType')
                  }">
                  <option *ngFor="
                      let transponderFee of transponderFees | orderBy: order : true
                        "[ngValue]="transponderFee?.TransponderTypeId" 
                          class="auto-dropdown">
                    {{ transponderFee.TransponderTypeName | translate }}
                  </option>
                </select>
              </div>
              <div class="mb-3"
                   *ngIf="!hideCategory">
                <label id="category-newtransponder-label"
                       for="category-{{ unique }}ss"
                       class="form-label">{{ 'CATEGORY' | translate }}</label>
                <select class="form-control form-select"
                        id="category-{{ unique }}s"
                        name="category"
                        formControlName="category"
                        required>
                  <option *ngFor="let tollCategory of tollCategories | orderBy: order"
                          [ngValue]="tollCategory.Id"
                          class="auto-dropdown">
                    {{ tollCategory.GroupName }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="provinces-select-{{ unique }}"
                       class="form-label">{{
                  'STATE_PROVINCE' | translate
                  }}</label>
                <select class="form-control form-select"
                        id="provinces-select-{{ unique }}"
                        name="provinces-select"
                        formControlName="province">
                  <optgroup *ngIf="preferedProvinces.length > 0">
                    <option *ngFor="let province of preferedProvinces"
                            [ngValue]="province.Id">
                      {{ province.Name }}
                    </option>
                  </optgroup>
                  <optgroup label="__________________"
                            *ngIf="provinceOptions.length > 0">
                    <option *ngFor="let province of provinceOptions; index as i"
                            [ngValue]="province.Id">
                      {{ province.Name }}
                    </option>
                  </optgroup>
                </select>
              </div>

              <div class="mb-3">
                <label for="plateNewtransponderInput-{{ unique }}"
                       class="form-label">{{ 'PLATE' | translate }}</label>
                <input class="form-control"
                       name="plate"
                       id="plateNewtransponderInput-{{ unique }}"
                       type="text"
                       formControlName="plate"
                       onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                       onDrag="return false"
                       onDrop="return false"
                       onPaste="return false"
                       appInputRestriction="noSpecialChars"
                       noCopy />
              </div>

              <div class="mb-3">
                <label id="make-newtransponder-label-{{ unique }}"
                       for="make-{{ unique }}"
                       class="form-label">{{ 'MAKE' | translate }}</label>

                <ng-select [items]="carMakes"
                           bindLabel="Name"
                           autofocus
                           bindValue="Name"
                           name="make"
                           labelForId="make-{{ unique }}"
                           id="make-{{ unique }}"
                           formControlName="make">
                </ng-select>

              </div>

              <div class="mb-3">
                <label for="model-{{ unique }}"
                       class="form-label">{{
                  'MODEL' | translate
                  }}</label>

                <ng-select [items]="carModel"
                           [loading]="tokensAreLoading"
                           bindLabel="Name"
                           autofocus
                           bindValue="Name"
                           name="model"
                           labelForId="model-{{ unique }}"
                           id="model-{{ unique }}"
                           loadingText="Loading..."
                           formControlName="model">
                </ng-select>


              </div>

              <div class="row mb-3">
                <div class="col-6">
                  <label for="color-{{ unique }}"
                         class="form-label">{{
                    'COLOR' | translate
                    }}</label>
                  <input class="form-control"
                         name="color"
                         id="color-{{ unique }}"
                         type="text"
                         formControlName="color"
                         onkeypress="return event.charCode != 32 || event.target.selectionStart !=0"
                         appInputRestriction="noSpecialChars"
                         noCopy />
                </div>

                <div class="col-6">
                  <label for="yearNewtransponderInput-{{ unique }}"
                         class="form-label">{{ 'YEAR' | translate }}</label>
                  <input class="form-control"
                         id="yearNewtransponderInput-{{ unique }}"
                         name="yearNewtransponderInput"
                         type="text"
                         minlength="4"
                         mask="0000"
                         [showMaskTyped]="false"
                         formControlName="year"
                         onkeypress='return event.charCode >= 48 && event.charCode <= 57 ||
                       event.key==="Backspace"'
                         noCopy />
                  <div id="yearMaxValue" *ngIf="tagInfo.year?.errors?.max"
                         class="text-danger">
                         <!---->
                      {{'YEAR_VALUE_TOO_BIG' | translate}}
                  </div>
                  <div id="yearMaxValue" *ngIf="tagInfo.year?.errors?.min"
                         class="text-danger">
                         <!---->
                      {{'YEAR_VALUE_TOO_SMALL' | translate}}
                  </div>
                </div>
              </div>

              <div class="pt-2">
                <ng-content select="[total]"></ng-content>
              </div>

              <div class="pt-2 text-center">
                <ng-content select="[insufficientNote]"></ng-content>
              </div>

              <div class="pt-1 pb-4">
                <ng-content select="[cancelbtn]"></ng-content>
                <ng-content select="[submitbtn]"></ng-content>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
