<footer class="footer" role="contentinfo">
  <ul class="nav justify-content-center p-1 mx-auto">
    <li *ngFor="let aLink of configService.config.FooterLinks">
      <div>
        <a *ngIf="isUrl(aLink.Url); else url_from_resource" 
          class="nav-item nav-link footerStyle brd-rds fw-light" routerLinkActive="active" routerLink="{{ aLink.Url }}" 
          target="{{ aLink.Target }}" rel="noopener noreferrer">{{ aLink.Name | translate }}
        </a>

        <ng-template #url_from_resource>
          <a *ngIf="aLink.Name === 'HOME'" class="nav-item nav-link footerStyle brd-rds fw-light" routerLinkActive="active" href="{{ aLink.Url | translate }}?v={{ seconds }}"
            target="{{ aLink.Target }}" rel="noopener">{{ aLink.Name | translate }}
          </a>
          <a *ngIf="aLink.Name !== 'HOME'" class="nav-item nav-link footerStyle brd-rds fw-light" routerLinkActive="active" href="{{ aLink.Url | translate }}"
            target="{{ aLink.Target }}" rel="noopener">{{ aLink.Name | translate }}
          </a>
        </ng-template>
      </div>
    </li>
  </ul>
  <div class="row justify-content-center">
    <div class="col-2 text-end"></div>
    <div class="col-8 text-center text-break">
      <span class="copyright">
        <small class="copyright fw-light">
          <b>MACPASS Customer Service · Tel:</b> (902) 463-3100 <b>· Email:</b> <a href="mailto:macpass@hdbc.ns.ca">macpass@hdbc.ns.ca</a><br />
          © {{ year }} <a href="{{configService.config.FooterHyperLink}}">{{ configService.config.FooterName }}.
            {{ 'ALL_RIGHT_RESERVED' | translate }}</a>
        </small>
      </span>
    </div>
    <div class="col-2 text-end fw-light">
      <div class="version">{{ configService.config.Version }} </div><!--env: {{ envAppVersion }} -->
    </div>
  </div>
</footer>
