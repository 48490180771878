import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  Account,
  AccountService,
  AuthServiceProvider,
  ConfigurationService,
  DatabaseMapService,
  LocalstorageService,
  User,
  UserService,
} from '@ibitoll/toll-core';
import { filter, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'header-type-two',
  templateUrl: './header-type-two.component.html',
  styleUrls: ['./header-type-two.component.scss'],
})
export class HeaderTypeTwoComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @ViewChildren('submenu') submenu: ElementRef;
  dontToggle: boolean = true;
  title: string = 'Home';
  account: Account;
  isLoggedIn = false;
  NavLinks: any[] = [];

  constructor(
    public authService: AuthServiceProvider,
    public accountService: AccountService,
    public configService: ConfigurationService,
    public userService: UserService,
    public router: Router,
    private localstorageService: LocalstorageService,
    private renderer: Renderer2,
    private databaseMapService: DatabaseMapService
  ) {
  }

  ngOnInit(): void {
    console.log('header2.init: '+ JSON.stringify(this.configService.config.NavLinks));

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.title = this.getNestedRouteTitles().join(' | ');
    });


    setTimeout(() => {
      this.subs.add(
        this.accountService.activeAccount.subscribe((acc) => {
          this.account = acc;
          if (this.authService.loggedIn()) {
            this.isLoggedIn = this.authService.loggedIn();
            this.NavLinks = this.configService.config.NavLinks.filter((o) => {
              return o.ShowUser === true && this.checkUser(o);
            });
          } else {
            this.isLoggedIn = this.authService.loggedIn();
            this.NavLinks = this.configService.config.NavLinks.filter((o) => {
              return o.ShowUser === false;
            });
          }
        })
      );
    }, 500);

  }

  checkUser(o: any): boolean {
    if (this.isAccountActive()) {
      return true;
    } else {
      return !o.Hide;
    }
  }

  isAccountActive(): boolean {
    try {
      return (
        this.account.AccountStatus === this.databaseMapService.eAccountStatusDMap['Active'] ||
        this.account.AccountStatus === this.databaseMapService.eAccountStatusDMap['Active Partial'] ||
        this.account.AccountStatus === this.databaseMapService.eAccountStatusDMap['Inactive']
      );
    } catch {
      return false;
    }
  }


  getNestedRouteTitles(): string[] {
    let currentRoute = this.router.routerState.root.firstChild;
    const titles: string[] = [];

    while (currentRoute) {
      if (currentRoute.snapshot.routeConfig.data?.title) {
        titles.push(currentRoute.snapshot.routeConfig.data.title);
      }

      currentRoute = currentRoute.firstChild;
    }

    return titles;
  }

  isUrl(url: string): boolean {
    if (url.charAt(0) === '/') {
      return true;
    } else {
      return false;
    }
  }

  logOut() {
    this.subs.add(
      this.userService
        .logout()
        .pipe(
          switchMap(() => {
            return this.authService.logout();
          })
        )
        .subscribe(
          () => {
            this.router.navigateByUrl('/login', { replaceUrl: true });
            // window.location.reload();

          },
          (error) => {
            this.localstorageService.clear()
            this.router.navigateByUrl('/login', { replaceUrl: true });
            // window.location.reload();

          }
        )
    );
  }

  showNavOnHelp(): boolean {
    return (
      !this.authService.loggedIn() &&
      (this.router.url === '/Help' ||
        this.router.url.includes('/ChangePassword/'))
    );
  }

  getCurrentUser(): User {
    return this.userService.getCurrentUser();
  }

  toggleSubmenu(event) {
    var leftMenu = document.getElementById('subMenunavbarDropdown');
    if (this.dontToggle && leftMenu) {
      leftMenu.click();
    }
  }

  mouseenter() {
    this.dontToggle = false
  }

  mouseleave() {
    this.dontToggle = true;
  }

  getCurrentPage(path: string) {
    if (this.router.url == path) {
      return 'page';
    } else {
      return false;
    }
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.authService.softLogout();
  }
}
