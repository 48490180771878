export class Plaza {
  PlazaId: number;
  PlazaName: string;
  PlazaDescription: string;
  PlazaType: string;
  TollFareGroup: TollFareGroup[];
}

export class TollFareGroup {
  Id: number;
  Name: string;
  Description: string;
  TollFareList: TollFare[];
}

export class TollFare {
  CategoryId: number;
  InEffect: boolean;
  CashFare: number;
  EtcFare: number;
  SmartCardFare: number;
}
