// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ga: 'G-PSM3CHNJRQ',
  config: {
    Name: 'hhb',
    ClientName: 'MACPASS',
    ClientId: '123',
    LogoImageUrl: 'assets/images/logo.png',
    LoginLogoImageUrl: 'assets/images/logo.png',
    BackgroundImageUrl: 'assets/images/background.jpg',
    FooterName: 'Halifax Harbour Bridges',
    HasLanguage: false,
    NavBarType: 1,
    DefaultLanguage: 'en',
    DualCurrency: false,
    DefaultCurrencyType: 'CAD',
    DefaultCurrency: '$',
    DefaultCurrencyCode: 'en-US',
    RequestTransponder: true,
    PaymentProvider: 'elavon',
    CSREmail: 'macpass@hdbc.ns.ca',
    DefaultLang: 'en',
    UseDefaultLang: 'en',
    webRequestOptions: [
      {
        Name: 'Account Number',
        Value: 'accountNumber',
      },
      {
        Name: 'Transponder Number',
        Value: 'transponderNumber',
      },
    ],
    NavLinks: [
      {
        Name: 'HOME',
        Url: '/home',
        ShowUser: true,
        Hide: false,
      },
      {
        Name: 'TRANSPONDERS',
        Url: '/transponder',
        ShowUser: true,
        Hide: false,
      },
      {
        Name: 'ACCOUNT_ACTIVITY',
        Url: '/activity',
        ShowUser: true,
        Hide: false,
      },
      {
        Name: 'PAYMENTS',
        Url: '/payment',
        ShowUser: true,
        Hide: false,
      },
      {
        Name: 'STATEMENTS',
        Url: '/statement',
        ShowUser: true,
        Hide: false,
      },
      {
        Name: 'MY_ACCOUNT',
        Url: '/accountinfo',
        ShowUser: true,
        Hide: false,
      },
      {
        Name: 'COMPLAIN',
        Url: '/support',
        ShowUser: true,
        Hide: false,
      },
    ],
    QuickLinks: [
      {
        Name: 'TRANSPONDERS',
        Url: '/transponder',
        ShowUser: true,
        Hide: false,
      },
      {
        Name: 'ACCOUNT_ACTIVITY',
        Url: '/activity',
        ShowUser: true,
        Hide: false,
      },
      {
        Name: 'PAYMENTS',
        Url: '/payment',
        ShowUser: true,
        Hide: true,
      },
      {
        Name: 'STATEMENTS',
        Url: '/statement',
        ShowUser: true,
        Hide: false,
      },
      {
        Name: 'MY_ACCOUNT',
        Url: '/accountinfo',
        ShowUser: true,
        Hide: false,
      },
    ],
    FooterLinks: [
      {
        Name: 'HOME',
        Url: '/home',
        Target: '_self',
      },
      {
        Name: 'MY_ACCOUNT',
        Url: '/accountinfo',
        Target: '_self',
      },
      {
        Name: 'ABOUT',
        Url: 'https://www.halifaxharbourbridges.ca/macpass/',
        Target: '_blank',
      },
      {
        Name: 'POLICIES',
        Url: 'https://www.halifaxharbourbridges.ca/privacy-policy/',
        Target: '_blank',
      },
      {
        Name: 'FAQ',
        Url: 'https://www.halifaxharbourbridges.ca/macpass/macpass-faqs/',
        Target: '_blank',
      },
    ],
    Properties: [],
    customeComponets: [],
    GoogleAnalyticsKey: 'G-SS62F8WB5W',
    ChaseHPFLink: 'https://chase-var.hostedpaymentservice.net/hpf/?uid=',
    GoogleRecaptch: '',
    SessionTimeOut: 301,
    ProductionCdnScripts: [],
    Version: '1.4.26-Dev',
    appVersion: require('../../../../../package.json').version + '-dev',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
