export class BankAccountQuery {
  FirstName: string;
  LastName: string;
  Email: string;
  Amount: number;
  PaymentType: number;
  AccountType: number;
  AccountNumber: string;
  TransitNumber: string;
  AccountID: number;
  UserID: string;
  UserLogonId: string;
  Custom_Reference_Number: string;
  Custom_Customer_Number: string;
}

export class CardQuery extends BankAccountQuery {
  CreditCardNumber: string;
  NameOnCard: string;
  Cvv: string;
  RecurringTokenId: string;
}
export class CardQueryWithExpiry extends CardQuery {
  ExpiryMonth: number;
  ExpiryYear: number;
}
