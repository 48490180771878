<div class="dropdown" *ngIf="configService.config.HasLanguage">
  <button class="btn btn-primary dropdown-toggle" type="button" id="languageDropdownMenuButton"
    [ngClass]="{'languageDropdownColor' : swipMode}" data-bs-toggle="dropdown">
    {{ currentLanguage | translate }}
  </button>
  <ul class="dropdown-menu" [ngClass]="{
      'dropdown-menu-end ': loggedIn() == false,
      'dropdown-menu-start': loggedIn() == true
    }" aria-labelledby="languageDropdownMenuButton">
    <li tabindex="0">
      <a class="dropdown-item ps-2" (click)="updateLanguage('en')">
        English
      </a>
    </li>
    <li tabindex="0">
      <a class="dropdown-item ps-2" (click)="updateLanguage('fr')">
        Français
      </a>
    </li>
  </ul>
</div>
