import { Directive, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[noDblClick]',
})
export class NoDblClickDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.srcElement.setAttribute('disabled', true);
    setTimeout(() => {
      event.srcElement.removeAttribute('disabled');
    }, 2000);
  }
}
