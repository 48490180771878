import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { LogService } from './log.service';
import * as StackTrace from 'stacktrace-js';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ErrorhandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else {
      const loggingService = this.injector.get(LogService);

      const location = this.injector.get(LocationStrategy);

      const message = error.message ? error.message : error.toString();

      const url =
        location instanceof PathLocationStrategy ? location.path() : '';

      // get the stack trace, lets grab the last 10 stacks only
      StackTrace.fromError(error).then(
        (stackframes) => {
          const stackString = stackframes
            .splice(0, 20)
            .map(function (sf) {
              return sf.toString();
            })
            .join('\n');
          // log on the server
          loggingService.logMessage('ErrorhandlerService.Error1 ' +
            JSON.stringify({ message, url, stack: stackString })
          );
        },
        (error) => {
          // log on the server
          loggingService.logMessage('ErrorhandlerService.Error2 ' +error);
        }
      );

      throw error;
    }
  }
}
