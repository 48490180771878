<div cdkTrapFocus
     [cdkTrapFocusAutoCapture]="true">

  <div class="modal-body text-center">
    <h1>
      We Welcome Your Feedback
    </h1>

    <h2>
      Help us improve your experience by taking our short survey
    </h2>
    <div class="mt-3 mb-3 pb-4">
      <button type="button"
              class="btn btn-app-secondary  btn-primary pull-left"
              (click)="noThanks()"
              aria-label="Continue">
        No Thanks
      </button>
      <button type="button"
              class="btn btn-app-primary pull-right"
              (click)="giveFeedBack()"
              aria-label="Log Off">
        Give Feedback
      </button>
    </div>
  </div>
</div>
