<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div class="modal-content modal-warning">
    <div class="modal-header">
      <div class="modal-title mb-0 h3" id="unSupendAlertModalModalLabel">
        {{ 'TRANSPONDER_REQUEST_UNSUSPEND_NO' | translate
        }} ...{{ transponder?.TransponderDisplayId?.substring(transponder?.TransponderDisplayId?.length-6) }}
      </div>
      <button type="button " class="btn-close btn-close-white pull-right" (click)="activeModal.dismiss()"
        aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <span>
        {{ 'TRANSPONDER_REQUEST_UNSUSPEND' | translate }}
        ...{{ transponder?.TransponderDisplayId?.substring(transponder?.TransponderDisplayId?.length-6) }}?
      </span>
    </div>
    <div class="modal-footer">
      <button data-dismiss="modal" class="btn btn-app-secondary px-4 m-1 me-auto" (click)="activeModal.dismiss()">
        {{ 'CANCEL' | translate }}
      </button>
      <button cancelbtn class="btn btn-app-primary px-4 m-1" (click)="onTransponderUnSuspended(transponder)">
        {{ 'CONFIRM' | translate }} 
        
        <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isRequesting"></i>
      </button>
    </div>
  </div>
</div>
