<div cdkTrapFocus
     [cdkTrapFocusAutoCapture]="true">
  <div class="modal-body">
    <div class="card m-auto mt-5 w-45"
         style="border: 1px solid black; margin-top: 40px;">
      <div class="card-body text-center">


        <div class="text-center mb-2 ms-3">
          <img class="logo"
               src="{{ configurationService.config.LogoImageUrl | translate }}"
               alt="{{configurationService.config.ClientName}} Logo"
               tabindex="-1" />
        </div>
        <h2>
          <i class="fa fa-e fa-exclamation-triangle"
             aria-hidden="true"></i>
          <div class="pb-1">Your browser is no longer supported</div>
        </h2>
        <p class="card-text">
          To get the best experience using our site, you'll need to upgrade to a new browser
        </p>
        <div class="d-flex w-100 justify-content-between mt-4">
          <a href="https://www.google.com/intl/en_ca/chrome/"
             type="button"
             class="btn  btn-app-primary m-1">
            <i class="fa fa-download "></i>
            Download Chrome
          </a>

          <a href="https://www.microsoft.com/en-us/edge#evergreen"
             id="edge"
             type="button"
             class="btn  btn-app-primary m-1" *ngIf="isWindows">
            <i class="fa fa-download ">

            </i>
            Download Edge
          </a>

          <a href="https://www.mozilla.org/en-CA/firefox/windows/"
             type="button"
             class="btn  btn-app-primary m-1">
            <i class="fa fa-download"></i>
            Download Firefox
          </a>

          <a href="https://support.apple.com/en-us/HT204416"
             id="safari"
             type="button"
             class="btn  btn-app-primary m-1" *ngIf="isMac">
            <i class="fa fa-download"></i>
            Update Safari
          </a>
        </div>
      </div>
    </div>

  </div>
</div>
