import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { Observable } from "rxjs";
import { SubSink } from "subsink";
import { BridgeAlert } from "../models/bridge-alert";
import { ProxyService } from "./proxy.service";

@Injectable({
    providedIn: 'root',
  })
  export class BridgeAlertsService implements OnDestroy {
    private endpointUrl: string;

    private subs = new SubSink();

    private alertsEndpoint = 'api/feed';
    public alertEvent = new EventEmitter<Array<BridgeAlert>>();


    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
    });
      
    constructor(
        private proxy: ProxyService,
        private http: HttpClient
    ) {
        this.subs.add(
            this.proxy.getEndpoint().subscribe((url) => {
              this.endpointUrl = url;
            })
        );
    }

    getAlerts(): Observable<BridgeAlert[]> {
        return this.http.get<BridgeAlert[]>(
          this.endpointUrl + this.alertsEndpoint + '/getAlerts',
          //'http://localhost:8888/api/Alerts/Get',
          { headers: this.headers }
        );
    }

    refreshAlerts() {
      this.http.get<BridgeAlert[]>(
        this.endpointUrl + this.alertsEndpoint + '/getAlerts',
        { headers: this.headers }
      ).subscribe(alerts => {
        this.alertEvent.emit(alerts);
      });
    }

    ngOnDestroy(): void {
    }
  }