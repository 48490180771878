import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { SubSink } from 'subsink';
import { ComponentMode } from '../../enum/component-mode.enum';
import { Account } from '../../models/account';
import { CardQueryWithExpiry } from '../../models/cardQuery';
import { ConvergeCommandResponse } from '../../models/convergeCommandResponse';
import { CreditCard } from '../../models/creditCard';
import { AccountProfileConfig } from '../../models/idNameConfig';
import { User } from '../../models/user';
import { WebUserCardInfo } from '../../models/webUserCardInfo';
import { AccountService } from '../../providers/account.service';
import { AlertService } from '../../providers/alert.service';
import { ConfigurationService } from '../../providers/configuration.service';
import { DatabaseMapService } from '../../providers/databasemap.service';
import { EvalonPaymentService } from '../../providers/evalonpayment.service';
import { LogService } from '../../providers/log.service';
import { PaymentService } from '../../providers/payment.service';
import { UserService } from '../../providers/user.service';
import { CreditCardComponent } from '../credit-card/credit-card.component';

declare var ConvergeEmbeddedPayment: any;

@Component({
  selector: 'evalon-auto-rebill',
  templateUrl: './evalon-auto-rebill.component.html',
  styleUrls: ['./evalon-auto-rebill.component.scss'],
})
export class EvalonAutoRebillComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() componentMode: ComponentMode = ComponentMode.Self;

  @Input() cardMode: boolean = true;

  @ViewChild('errorModal') errorModal: ElementRef;

  @ViewChild('removeCardModal') activeCardRemoveModal: ElementRef;
  @ViewChild('removeSavedCardModal') activeSavedCardRemoveModal: ElementRef;
  @ViewChild('setSavedCardModal') setActiveCardModal: ElementRef;

  @Output() cardChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  user: User;
  account: Account;
  subs = new SubSink();
  creditCardQuery: CreditCard;
  accountProfileOptions: AccountProfileConfig[];

  BalanceReplenishAmount = 0;
  BalanceReplenishThreshold = 0;

  oldBalanceReplenishAmount = 0;
  oldBalanceReplenishThreshold = 0;

  balanceReplenishAmountLimit = 50;
  balanceReplenishThresholdLimit = 20;

  invoiceNumber: string;
  sequenceNumber: string;

  isLoading = false;
  editMode = false;
  enableCCSubmit: boolean = false;
  hideButtons: boolean = false;
  requestProcessed: boolean;
  errorObj: { title: string; message: string };

  callback: any;

  @ViewChild(CreditCardComponent) creditCard: CreditCardComponent;
  processing: boolean;
  subject: Subject<any>;
  savedCards: WebUserCardInfo[] = [];
  public isRemoveProcessing: boolean;
  public isDefaultProcessing: boolean;
  public selectedCard: WebUserCardInfo;
  public isRemoveActiveCardProcessing: boolean = false;
  isSavedProcessing = false;

  constructor(
    private modalService: NgbModal,
    private accountService: AccountService,
    private alertService: AlertService,
    private userService: UserService,
    private databaseMapService: DatabaseMapService,
    public logService: LogService,
    private cdr: ChangeDetectorRef,
    public configurationService: ConfigurationService,
    private paymentService: PaymentService,
    private elavonPaymentService: EvalonPaymentService,
    private liveAnnouncer: LiveAnnouncer
  ) { }

  ngOnInit(): void {
    this.isSavedProcessing = true;
    this.subs.add(
      this.accountService
        .getActiveAccount()
        .pipe(
          switchMap((account: Account) => {
            this.account = account;
            this.user = this.userService.getCurrentUser();
            return this.paymentService.getUserCardsInfo(this.account.AccountID);
          })
        )
        .subscribe((savedCards) => {
          if (this.account.PaymentNumber && savedCards.length > 0) {
            this.editMode = false;
            if (this.account.PaymentNumber) {
              this.savedCards = savedCards?.filter((o) => {
                return (
                  o.PaymentNum.substring(o.PaymentNum.length - 4) !==
                  this.account?.PaymentNumber?.substring(
                    this.account.PaymentNumber.length - 4
                  )
                );
              });
            } else {
              this.savedCards = savedCards
            }
          } else if (this.account.PaymentNumber == null && savedCards.length !== 0) {
            this.editMode = false;
            this.savedCards = savedCards;
          } else {
            this.savedCards = savedCards;
            this.editMode = false;
          }

          this.isSavedProcessing = false;
          this.isLoading = false;
          this.getAccountInfo()
        })
    );

    this.callback = {};
    this.callback.onError = this.PaymentCallbackError.bind(this);
    this.callback.onDeclined = this.PaymentCallbackDeclined.bind(this);
    this.callback.onApproval = this.PaymentCallbackApproval.bind(this);
  }

  ngAfterViewInit() {
    this.creditCard.showAmount = false;
    this.creditCard.creditCardFormGroup.get('amount').setValidators([]);
    this.creditCard.creditCardFormGroup.get('amount').updateValueAndValidity();

    this.creditCard.creditCardFormGroup.valueChanges.subscribe(() => {
      this.enableCCSubmit = this.creditCard.creditCardFormGroup.valid;
    });
    this.cdr.detectChanges();

    if (this.componentMode == ComponentMode.Emit) {
      this.editMode = true;
      this.hideButtons = true;
    }
  }

  getAccountInfo() {
    this.configurationService
      .getAccountProfiles(false)
      .subscribe((accountProfiles) => {
        this.accountProfileOptions = accountProfiles;
        const profile = this.accountProfileOptions.find(
          (o) => o.AccountProfileID === this.account.AccountProfileID
        );

        if (profile) {
          this.balanceReplenishAmountLimit = Math.abs(profile.BalanceReplenishAmount);
          this.balanceReplenishThresholdLimit = Math.abs(profile.BalanceReplenishThreshold);


          this.BalanceReplenishAmount = this.balanceReplenishAmountLimit;
          this.BalanceReplenishThreshold = this.balanceReplenishThresholdLimit;
        }
      });
  }

  processPreauthToken() {
    this.processing = true;
    this.alertService.clear();

    const query: CardQueryWithExpiry = new CardQueryWithExpiry();
    const user = this.userService.getCurrentUser();

    query.Email = user.EmailAddress;
    query.NameOnCard =
      this.creditCard.creditCardFormGroup.get('nameOnCard').value;
    query.CreditCardNumber =
      this.creditCard.creditCardFormGroup.get('cardNumber').value;
    query.ExpiryMonth = Number(
      this.creditCard.creditCardFormGroup.get('cardmonth').value
    );
    query.ExpiryYear = Number(
      this.creditCard.creditCardFormGroup.get('cardyear').value
    );
    query.Cvv = this.creditCard.creditCardFormGroup.get('cardcvv').value;
    query.FirstName = this.account.FirstName;
    query.LastName = this.account.LastName;

    this.elavonPaymentService.getPreauthToken(query).subscribe(
      (resp) => {
        console.log('processPreauthToken: ' + JSON.stringify(resp));
        this.SaveCardTokenInfo(resp);
      },
      (respError) => {
        this.processing = false;
        this.alertService.error(
          'Cannot aquire preauth token. Please make sure you provided valid credit card information.',
          false
        );
        console.log('error in processPreauthToken: ' + respError);
      }
    );
  }

  processPreauthLocalToken() {
    this.processing = true;
    this.alertService.clear();
    let paymentToken = '';

    this.elavonPaymentService.getPaymentToken(0, 4, this.account?.CurrencyType).subscribe(
      (resp) => {
        paymentToken = resp;

        let year2d =
          this.creditCard.creditCardFormGroup.controls['cardyear'].value;
        if (year2d > 2000) {
          year2d = year2d - 2000;
        }

        var paymentData = {
          ssl_first_name: this.account.AccountType === 'P' ? this.account.FirstName : null,
          ssl_last_name: this.account.LastName,
          ssl_txn_auth_token: paymentToken,
          ssl_card_number:
            this.creditCard.creditCardFormGroup.controls['cardNumber'].value,
          ssl_exp_date:
            this.creditCard.creditCardFormGroup.controls['cardmonth'].value
              .toString()
              .padStart(2, '0') + year2d.toString().padStart(2, '0'),
          ssl_cvv2cvc2:
            this.creditCard.creditCardFormGroup.controls['cardcvv'].value,
          ssl_verify: 'Y',
          ssl_add_token: 'Y',
          ssl_email: this.account?.Email,
          custom_reference_number: this.invoiceNumber,
          custom_userloginid: this.account?.UserLogonId,
          ssl_avs_address: this.account?.SameBillingAsMailingAddress
            ? this.account?.MailingAddress
            : this.account?.BillingAddress,
          ssl_avs_zip: this.account?.SameBillingAsMailingAddress
            ? this.account?.MailingPostalCode?.replace('-', '')
            : this.account?.BillingPostalCode?.replace('-', ''),
        };

        // if (this.configurationService.config.Name === 'iba') {
        //   paymentData['ssl_transaction_currency'] =
        //     this.account?.CurrencyType === 'D' ? 'USD' : 'CAD';
        // }

        ConvergeEmbeddedPayment.pay(paymentData, this.callback);
      },
      (error) => {
        this.processing = false;
        this.logService.logMessage(
          'payment process failed at getInvoiceNumber' + JSON.stringify(error)
        );
        // this.alertService.error(
        //   'Unable to process your request at this time please try again later!',
        //   true
        // );
      }
    );
  }

  processPreauthTokenSigup(account: Account) {
    this.processing = true;
    this.alertService.clear();

    const query: CardQueryWithExpiry = new CardQueryWithExpiry();

    query.NameOnCard =
      this.creditCard.creditCardFormGroup.get('nameOnCard').value;
    query.CreditCardNumber =
      this.creditCard.creditCardFormGroup.get('cardNumber').value;
    query.ExpiryMonth = Number(
      this.creditCard.creditCardFormGroup.get('cardmonth').value
    );
    query.ExpiryYear = Number(
      this.creditCard.creditCardFormGroup.get('cardyear').value
    );
    query.Cvv = this.creditCard.creditCardFormGroup.get('cardcvv').value;

    query.FirstName = account.FirstName;
    query.LastName = account.LastName;
    query.Email = account.Email;

    return this.elavonPaymentService.getPreauthToken(query).pipe(
      catchError((error) => {
        this.processing = false;
        this.alertService.error(
          'Cannot aquire preauth token. Please make sure you provided valid credit card information.',
          false
        );
        console.log('error in processPreauthToken: ' + error);
        return of('error', error);
      })
    );
  }

  SaveCardTokenInfo(convergeResponse: ConvergeCommandResponse) {
    const cardNumber = convergeResponse.CardNumber;

    this.creditCardQuery = new CreditCard();
    this.creditCardQuery.UserId = this.user.UserId;
    this.creditCardQuery.UserLogonId = this.user.UserName;
    this.creditCardQuery.AccountId = this.user.AccountId;
    this.creditCardQuery.PaymentType =
      this.databaseMapService.ePaymentTypeMap.CreditCard;
    this.creditCardQuery.PaymentCardType =
      this.databaseMapService.getShortCardType(
        convergeResponse.CardShortDescription
      );
    this.creditCardQuery.PaymentNum = cardNumber;
    this.creditCardQuery.PaymentCardExpiryYear =
      +convergeResponse.ExpDate.slice(2);
    this.creditCardQuery.PaymentCardExpiryMonth =
      +convergeResponse.ExpDate.slice(0, 2);
    this.creditCardQuery.CardBankTokenId = convergeResponse.Token;
    this.creditCardQuery.PaymentLastName = this.creditCard.creditCardFormGroup.get('nameOnCard').value;

    this.creditCardQuery.BalanceReplenishAmount = this.BalanceReplenishAmount;
    this.creditCardQuery.BalanceReplenishThreshold = this.BalanceReplenishThreshold;
    this.creditCardQuery.BalanceWarningThreshold = Math.abs(this.account.BalanceWarning);

    this.creditCardQuery.PreauthStatus = this.isPreAuthEnabled()
      ? this.databaseMapService.ePreAuthStatus.active
      : this.databaseMapService.ePreAuthStatus.suspended;

    this.accountService.updatePaymentDetails(this.creditCardQuery).subscribe(
      () => {
        this.accountService
          .loadActiveAccount(this.account.AccountID)
          .subscribe(() => {
            this.editMode = false;
            this.processing = false;
            this.alertService.success(
              'Card information successfully updated',
              true
            );
            this.cardChanged.emit(true);
            this.creditCard.creditCardFormGroup.reset();
          });
      },
      () => {
        this.processing = false;
        this.alertService.error('Error occured in updating account ', true);
      }
    );
  }

  setCardActive(card: WebUserCardInfo) {
    this.selectedCard = card;
    this.isDefaultProcessing = true;
    var expDate = new Date(card.BankCardTokenExpiryDate);
    var year = expDate.getFullYear() - 2000;
    var month = ('0' + (expDate.getMonth() + 1)).slice(-2);

    this.creditCardQuery = new CreditCard();
    this.creditCardQuery.UserId = this.user.UserId;
    this.creditCardQuery.UserLogonId = this.user.UserName;
    this.creditCardQuery.AccountId = this.user.AccountId;
    this.creditCardQuery.PaymentType =
      this.databaseMapService.ePaymentTypeMap.CreditCard;
    this.creditCardQuery.PaymentCardType = card.CardType;
    this.creditCardQuery.PaymentNum = card.PaymentNum;
    this.creditCardQuery.PaymentCardExpiryYear = year;
    this.creditCardQuery.PaymentCardExpiryMonth = +month;
    this.creditCardQuery.CardBankTokenId = card.BankCardTokenId;
    this.creditCardQuery.PaymentLastName = this.account.LastName;
    this.creditCardQuery.BalanceReplenishAmount = this.BalanceReplenishAmount;
    this.creditCardQuery.BalanceReplenishThreshold =
      this.BalanceReplenishThreshold;
    this.creditCardQuery.BalanceWarningThreshold = Math.abs(
      this.account.BalanceWarning
    );

    this.creditCardQuery.PreauthStatus = this.isPreAuthEnabled()
      ? this.databaseMapService.ePreAuthStatus.active
      : this.databaseMapService.ePreAuthStatus.suspended;

    this.accountService.updatePaymentDetails(this.creditCardQuery).subscribe(
      () => {
        this.accountService
          .loadActiveAccount(this.account.AccountID)
          .subscribe(() => {
            this.selectedCard = null;
            this.editMode = false;
            this.isDefaultProcessing = false;
            this.processing = false;
            this.alertService.success(
              'Card information successfully updated',
              true
            );
            this.cardChanged.emit(true);

            this.creditCard.creditCardFormGroup.reset();
            this.modalService.dismissAll();
          });
      },
      () => {
        this.modalService.dismissAll();
        this.selectedCard = null;
        this.isDefaultProcessing = false;
        this.processing = false;
        this.alertService.error('Error occured in updating account ', true);
      }
    );
  }
  /**
   * This code is setting the selectedCard variable to the card that was clicked on.
   * @param {WebUserCardInfo} card - WebUserCardInfo
   */

  removeSavedCardActive(card: WebUserCardInfo) {
    this.selectedCard = card;
    card.AccountId = this.account.AccountID.toString();
    card.CardAlias = '';
    card.CardStatus = false;
    this.isRemoveProcessing = true;
    this.paymentService.setUserCardsInfo(card).subscribe(
      (data) => {
        this.paymentService.getUserCardsInfo(this.account.AccountID).subscribe(
          (savedCards) => {
            this.savedCards = savedCards?.filter((o) => {
              return (
                o.PaymentNum.substring(o.PaymentNum.length - 4) !==
                this.account?.PaymentNumber?.substring(
                  this.account?.PaymentNumber?.length - 4
                )
              );
            });
            this.selectedCard = null;
            this.isRemoveProcessing = false;
            this.alertService.successTranslate(
              'CARD_REMOVED_SUCCESS_MSG',
              true
            );
            this.modalService.dismissAll();
          },
          (error) => {
            this.modalService.dismissAll();
            this.selectedCard = null;
            this.isRemoveProcessing = false;
          }
        );
      },
      (error) => {
        this.selectedCard = null;
        this.isRemoveProcessing = false;
      }
    );
  }

  getMaskedCardNumber(cc: string) {
    var last4 = cc.substring(cc.length - 4);
    return '************' + last4;
  }

  PaymentCallbackError(response: any): void {
    this.logService.logMessage(
      'payment process failed Error:' + JSON.stringify(response)
    );
    if (this.account) {
      this.processing = false;
      this.alertService.error(
        'Cannot aquire preauth token. Please make sure you provided valid credit card information.',
        false
      );
    } else {
      this.subject.next(response);
      this.subject.complete();
    }
  }

  PaymentCallbackDeclined(response: any): void {
    this.logService.logMessage(
      'payment process failed Declined:' + JSON.stringify(response)
    );

    if (this.account) {
      this.processing = false;
      this.alertService.error(
        'Cannot aquire preauth token. Please make sure you provided valid credit card information.',
        false
      );
    } else {
      this.subject.next(response);
      this.subject.complete();
    }
  }

  PaymentCallbackApproval(response: any): void {
    this.logService.logMessage(
      'payment process approved:' + JSON.stringify(response)
    );
    response.ssl_transaction_currency = this.account.CurrencyType === 'D' ? 'USD' : 'CAD';
    if (this.account) {
      this.SaveCardTokenLocalInfo(response);
    } else {
      this.subject.next(response);
      this.subject.complete();
    }
  }

  SaveCardTokenLocalInfo(convergeResponse: any) {

    const cardNumber = convergeResponse.ssl_card_number;

    this.creditCardQuery = new CreditCard();
    this.creditCardQuery.UserId = this.user.UserId;
    this.creditCardQuery.UserLogonId = this.user.UserName;
    this.creditCardQuery.AccountId = this.user.AccountId;
    this.creditCardQuery.PaymentType =
      this.databaseMapService.ePaymentTypeMap.CreditCard;
    this.creditCardQuery.PaymentCardType =
      this.databaseMapService.getShortCardType(
        convergeResponse.ssl_card_short_description
      );
    this.creditCardQuery.PaymentNum = cardNumber;
    this.creditCardQuery.PaymentCardExpiryYear =
      +convergeResponse.ssl_exp_date.slice(2);
    this.creditCardQuery.PaymentCardExpiryMonth =
      +convergeResponse.ssl_exp_date.slice(0, 2);
    this.creditCardQuery.CardBankTokenId = convergeResponse.ssl_token;
    this.creditCardQuery.PaymentLastName =
      this.creditCard.creditCardFormGroup.get('nameOnCard').value;
    this.creditCardQuery.BalanceReplenishAmount = this.BalanceReplenishAmount;
    this.creditCardQuery.BalanceReplenishThreshold =
      this.BalanceReplenishThreshold;
    this.creditCardQuery.BalanceWarningThreshold = Math.abs(
      this.account.BalanceWarning
    );

    this.creditCardQuery.PreauthStatus = this.isPreAuthEnabled()
      ? this.databaseMapService.ePreAuthStatus.active
      : this.databaseMapService.ePreAuthStatus.suspended;

    this.accountService.updatePaymentDetails(this.creditCardQuery).subscribe(
      () => {
        this.accountService
          .loadActiveAccount(this.account.AccountID)
          .subscribe(() => {
            this.editMode = false;
            this.processing = false;
            this.alertService.success(
              'Card information successfully updated',
              true
            );
            this.creditCard.creditCardFormGroup.reset();
          });
      },
      () => {
        this.processing = false;
        this.alertService.error('Error occured in updating account ', true);
      }
    );
  }


  removeActiveCard() {
    this.isRemoveActiveCardProcessing = true;
    this.creditCardQuery = new CreditCard();
    this.creditCardQuery.UserId = this.user.UserId;
    this.creditCardQuery.UserLogonId = this.user.UserName;
    this.creditCardQuery.AccountId = this.user.AccountId;
    this.creditCardQuery.BalanceReplenishAmount = this.BalanceReplenishAmount;
    this.creditCardQuery.BalanceReplenishThreshold =
      this.BalanceReplenishThreshold;
    this.creditCardQuery.BalanceWarningThreshold = Math.abs(
      this.account.BalanceWarning
    );
    this.creditCardQuery.PaymentNum = this.account.PaymentNumber;
    this.creditCardQuery.PreauthStatus = this.databaseMapService.ePreAuthStatus.disabled

    this.accountService.updatePaymentDetails(this.creditCardQuery).subscribe(
      () => {
        this.accountService
          .loadActiveAccount(this.account.AccountID)
          .subscribe(() => {
            this.editMode = false;
            this.processing = false;
            this.alertService.success(
              'Credit card has been successfully removed.',
              true
            );
            this.cardChanged.emit(true);
            this.creditCard.creditCardFormGroup.reset();
            this.isRemoveActiveCardProcessing = false;
            this.modalService.dismissAll();
          });
      },
      () => {
        this.modalService.dismissAll();
        this.isRemoveActiveCardProcessing = false;
        this.alertService.error('Error occured in updating account ', true);
      }
    );
  }

  isPreAuthEnabled(): boolean {
    return (
      this.account?.PreAuthStatus !== undefined &&
      this.account?.PreAuthStatus ===
      this.databaseMapService.ePreAuthStatus.active
    );
  }

  editModeEnabled() {
    this.editMode = !this.editMode;
  }

  displayError(msg: any) {
    if (msg) {
      this.requestProcessed = false; // hide form and show error
      this.errorObj = {
        title: '',
        message: '',
      };

      this.errorObj.title = 'Payment Incomplete!';
      this.errorObj.message = `The payment information provided in the authorization request appears to be invalid.
      Please check account and transit/routing number details and retry.`;

      if (msg.errorMessage) {
        this.errorObj.title = msg.errorName;
        this.errorObj.message = msg.errorMessage;
      }

      if (msg.ErrorCode || msg.errorMessage) {
        this.errorObj.title = 'Payment Incomplete!';

        switch (msg.ErrorCode || msg.errorCode) {
          case '5106' || '5105':
            this.errorObj.message = `The bank information provided in the authorization request appears to be invalid.
             Please check account and transit/routing number details and retry.`;
            break;

          case '5001':
            this.errorObj.message =
              'The credit card expiration date entered is invalid';
            break;

          case '5000':
            this.errorObj.message = 'The credit card number entered is invalid';
            break;

          case '5021':
            this.errorObj.message =
              'The CVV2 number should be 3 or 4 digits in length';
            break;

          case '5022':
            this.errorObj.message =
              'The CVV2 number should be numeric character only.';
            break;

          default:
            this.errorObj.message = `Payment incomplete. The payment information provided in the authorization
             request appears to be invalid.
            Please check payment details and try again.`;
            break;
        }
      }

      if (msg.ssl_result_message === 'DECLINED') {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = `The payment declined. Please check payment details and try again.`;
      }

      if (msg.ssl_result_message === 'DECLINED: NSF') {
        this.errorObj.title = 'Payment Declined!';
        this.errorObj.message = `The payment declined. Please check payment details and try again.`;
      }

      if (msg.ssl_result_message === 'SERV NOT ALLOWED') {
        this.errorObj.title = 'Invalid Payment';
        this.errorObj.message = `The payment information provided appears to be invalid.
       Please check payment details and try again.`;
      }

      setTimeout(() => {
        this.modalService.open(this.errorModal, { size: 'lg' });
      });
    }

    this.processing = false;
  }

  removeActiveCardConfirmation() {
    var activeModal = this.modalService.open(this.activeCardRemoveModal, { ariaLabelledBy: 'remove-active-card' });
    activeModal.shown.subscribe(() => {
      console.log('removeActiveCardConfirmation modal showen')
    });
  }
  removeSavedCardConfirmation(card: WebUserCardInfo) {

    this.selectedCard = card;
    var activeModal = this.modalService.open(this.activeSavedCardRemoveModal, { ariaLabelledBy: 'remove-saved-card' });
    activeModal.shown.subscribe(() => {
      console.log('removeSavedCardConfirmation modal showen')
    });
  }


  setActiveCardConfirmation(card: WebUserCardInfo) {
    this.selectedCard = card;
    var activeModal = this.modalService.open(this.setActiveCardModal, { ariaLabelledBy: 'set-saved-card-active' });
    activeModal.shown.subscribe(() => {
      console.log('setActiveCardConfirmation modal showen')
    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
