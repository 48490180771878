import { OnDestroy } from '@angular/core';
import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { SubSink } from 'subsink';

@Directive({
  selector: '[appMarkAsterisk]',
})
export class MarkAsteriskDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() turnOn: boolean = false;
  private subs = new SubSink();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.applyChange();
    }, 100);
  }

  ngAfterViewInit(): void {
    this.applyChange();
  }

  private applyChange() {
    this.subs.add(
      this.formGroup.controls[this.controlName]?.valueChanges.subscribe(() => {
        if (this.formGroup.controls[this.controlName]?.enabled) {
          this.renderer.setProperty(
            this.elementRef.nativeElement,
            'innerHTML',
            '*'
          );
          this.renderer.setStyle(
            this.elementRef.nativeElement,
            'border',
            'none'
          );
          this.renderer.setStyle(
            this.elementRef.nativeElement,
            'color',
            '#BC170D'
          );
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            'ng-invalid'
          );
          this.renderer.addClass(
            this.elementRef.nativeElement,
            'non-ng-invalid'
          );
        } else {
          this.renderer.setProperty(
            this.elementRef.nativeElement,
            'innerHTML',
            ''
          );
          this.renderer.setStyle(
            this.elementRef.nativeElement,
            'border',
            'none'
          );
          this.renderer.setStyle(
            this.elementRef.nativeElement,
            'color',
            'none'
          );
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            'ng-invalid'
          );
          this.renderer.addClass(
            this.elementRef.nativeElement,
            'non-ng-invalid'
          );
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
