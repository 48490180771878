import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProxyService {
  urlSub = new ReplaySubject<string>();

  constructor() {
    this.urlSub.next('');
  }

  getEndpoint(): Observable<string> {
    return this.urlSub.asObservable().pipe(shareReplay(1));
  }

  setEndpoint(url: string) {
    if (url) {
      this.urlSub.next(url);
    } else {
      this.urlSub.next('');
    }
  }
}
