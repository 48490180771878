export class TransponderRequest {
  WebUserLogonId: number;
  AccountId: number;
  VehicleId: number;
  InsuranceNumber: string;
  VehicleMake: string;
  VehicleModel: string;
  VehicleYear: number;
  VehicleColor: string;
  PlateHolderLastName: string;
  PlateHolderFirstName: string;
  PlateNum: string;
  TollCategoryId: number;
  TransponderTypeId: number;
  PricePerTransponder: number;
  TransponderPrice: number;
  TransponderSerialNumber: string;
  TransponderId: number;
  ManufacturerId: number;
  PlateProvinceId: number;
  PlateCountryId: number;
  TransponderProvider: string;
  TransponderQuantity: number;
  Enabled = true;
}
