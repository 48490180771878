export class Transponder { 
  VehicleId: number;
  PlateNum: string;
  PlateNumAutoGenerated: boolean;
  VehicleStatus: any;
  VehicleMake: string;
  VehicleModel: string;
  VehicleColor: string;
  VehicleYear: number;
  PlateProvinceId: number;
  TollCategoryId: number;
  ManufacturerId: number;
  TransponderId: number;
  TransponderDisplayId: string;
  TransponderTypeId: number;
  DistributedLDateTime: string;
  TripStatus: string;
  WebRequestStatus: string;
  RestrictionStatus: number;
  RestrictionStatusLDateTime: string;
  RestrictionStatusUserId: number;
  TransponderRequesrQty: number;
  TransponderText: string;
  TripCount: number;
  TransponderSerialNo: string;
  CreatedDateTime: Date;
}

export class TransponderValidationResult {
  CanBeAdded: boolean;
  Tag: Transponder;
}