export class AccountTicketDto {
  AccountId: number;
  CommentId: number;
  CommentPriorityLevel: PriorityLevel;
  CommentText: string;
  CommentType: CommentType;
  CreateLocalDateTime: Date;
  FollowUpCommentId: number;
  FollowUpRequired: string;
  IssueContent: string; // attachmnet byte array
  AttachmentFilename: string; 
  IssueStatus: IssueStatus | string;
  IssueStatusLocalDateTime: Date;
  IsFollowUp: string;
  CreateWebUserId: number;
  CreateWebUserName: string;
  Tickets: AccountTicketDto[];
  AccountCommentTitleId: number;
  AccountCommentTitleText: string;
  IsVisible: boolean = false;
}

export enum PriorityLevel {
  All = -1,
  Low,
  Normal,
  High,
}

export enum CommentType {
  RegularComment = 1,
  AdditionalAccountInformation,
}

export enum IssueStatus {
  All = '\0',
  Open = 'O',
  ProviderAction = 'A',
  CustomerAction = 'P',
  Delete = 'D',
  Closed = 'C',
  InProgress = 'I'
}

export enum IssueStatusCheck {
  All,
  Open,
  ProviderAction,
  CustomerAction,
  Delete,
  Closed,
}
