import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { REFRESH_TOKEN } from '../models/keyConstand';
import { AuthServiceProvider } from '../providers/auth.service';
import { LocalstorageService } from '../providers/localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthServiceProvider,
    private localstorageService: LocalstorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (
      !this.authService.loggedIn() &&
      this.localstorageService.check(REFRESH_TOKEN)
    ) {
      const subject = new Subject<boolean>();
      this.authService.refreshToken().subscribe(
        () => {
          subject.next(true);
          subject.complete();
        },
        (error) => {

          if(state.url == "/home"){
            this.router.navigate(['/login']);
          } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          }
          subject.next(false);
          subject.complete();
        }
      );
      return subject.asObservable();
    } else if (!this.authService.loggedIn()) {
      if(state.url == "/home"){
        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      }
      return false;
    } else {
      return true;
    }
  }
}
