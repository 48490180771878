<div class="container-12">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="webInfoFormGroup">
        <fieldset>
          <legend class="form-label account-label h3 mb-1">
            <ng-content select="[passwordtitle]"></ng-content>
          </legend>
          <div class="row mb-3">
            <div class="col-sm-12 col-md-6">
              <label for="username"
                     class="form-label mb-1"
                     *ngIf="!hideUsernameInput">
                {{ 'USERNAME' | translate }}
                <span class="non-ng-invalid-mark">*</span>
              </label>
              <div class="input-group mb-0"
                   *ngIf="!hideUsernameInput">
                <input class="form-control is-loading is-loading-sm"
                       id="username"
                       type="text"
                       formControlName="userName"
                       [ngClass]="{ 'is-valid': validUsername }"
                       required
                       minlength="5"
                       maxlength="30"
                       placeholder="{{ 'USERNAME' | translate }}"
                       (keyup)="onKeyUp($event)"
                       required
                       aria-required="true" /><!---->

                <div class="icon-container"
                     *ngIf="checkingUserName">
                  <i class="loader"></i>
                </div>


                <span class="input-group-text rounded-end"
                      type="button"
                      id="btn-show-hide">
                  <i aria-hidden="true"
                     class="fa fa-user"></i>
                </span>


              </div>


              <label class="text-danger align-middle mt-1">
                <div *ngIf="webInfo?.userName?.errors?.minlength">
                  <i class="material-icons"
                     aria-hidden="true">{{ 'cancel' }}</i>
                  {{ 'ENTER_USERNAME' | translate }}
                </div>

                <div *ngIf="webInfo?.userName?.errors?.invalidValidaChar">
                  <i class="material-icons"
                     aria-hidden="true">{{ 'cancel' }}</i>
                  {{ 'INVALID_CHAR' | translate }}
                </div>

                <div *ngIf="webInfo?.userName?.errors?.unique">
                  <i class="material-icons"
                     aria-hidden="true">{{ 'cancel' }}</i>
                  {{ 'UNIQUE_USERNAME' | translate }}
                </div>

                <div *ngIf="webInfo?.userName?.errors?.whiteSpace">
                  <i class="material-icons"
                     aria-hidden="true">{{ 'cancel' }}</i>
                  {{ 'NO_SPACE' | translate }}
                </div>

                <div *ngIf="webInfo?.userName?.errors?.pattern">
                  <i class="material-icons"
                     aria-hidden="true">{{ 'cancel' }}</i>
                  {{ 'NO_SPACE' | translate }}
                </div>

                <div *ngIf="webInfo?.userName?.errors?.unverfied">
                  <i class="material-icons"
                     aria-hidden="true">{{ 'cancel' }}</i>
                  {{ 'UNABLE_TO_VERIFY' | translate }}
                </div>
              </label>

            </div>
          </div>

          <div class="row mb-3"
               *ngIf="!standAlone">
            <div class="col-sm-12 col-md-6">
              <label for="password"
                     class="form-label mb-1">
                {{'NEW_PASSWORD' | translate}}
                <span class="non-ng-invalid-mark">*</span>
              </label>
              <div class="input-group">
                <input id="password"
                       [type]="nHide ? 'password' : 'text'"
                       class="form-control"
                       placeholder="{{ 'NEW_PASSWORD' | translate }}"
                       formControlName="password"
                       maxlength="40"
                       required
                       aria-required="true"
                       autocomplete="off" />
                <button class="input-group-text rounded-end"
                        id="btn-show-password"
                        type="button"
                        role="button"
                        aria-label="Show New Password"
                        (click)="nHide = !nHide">
                  <i class="fa fa-eye"
                     *ngIf="!nHide"
                     aria-hidden="true"
                     title="Show New Password"></i>
                  <i class="fa fa-eye-slash"
                     *ngIf="nHide"
                     aria-hidden="true"
                     title="Show New Password"></i>
                  <span class="sr-only">Show New Password:</span>
                </button>
              </div>
            </div>

          </div>
          <div class="row mb-3"
               *ngIf="!standAlone">
            <div class="col-sm-12 col-md-6">
              <label for="confirmPassword"
                     class="form-label mb-1">
                {{'CONFIRM_PASSWORD' | translate}}
                <span class="non-ng-invalid-mark">*</span>
              </label>
              <div class="input-group"><!-- password -->
                <input id="confirmPassword"
                       [type]="cHide ? 'password' : 'text'"
                       class="form-control"
                       placeholder="{{ 'CONFIRM_PASSWORD' | translate }}"
                       formControlName="confirmPassword"
                       maxlength="40"
                       required
                       aria-required="true"
                       autocomplete="off" />
                <button class="input-group-text rounded-end"
                        type="button"
                        id="btn-show-chide"
                        (click)="cHide = !cHide"
                        role="button"
                        aria-label="Show Confirm Password">
                  <i class="fa fa-eye"
                     *ngIf="!cHide"
                     aria-hidden="true"
                     title="Show New Password"></i>
                  <i class="fa fa-eye-slash"
                     *ngIf="cHide"
                     aria-hidden="true"
                     title="Show New Password"></i>
                  <span class="sr-only">Show Confirm Password</span>
                </button>
              </div>
              <div id="confirmPasswordNode"
                   class="form-text">
                <ng-content select="[passwordnote]"></ng-content>
              </div>
              <div class="form-text">
                <div class="text-danger fade show"
                     *ngIf="
                  ((webInfo.confirmPassword?.dirty ||
                    webInfo.confirmPassword?.touched) &&
                    webInfo.confirmPassword?.errors?.MatchPassword) ||
                  (webInfo.password.dirty && webInfo.password.errors?.pattern)
                ">
                  <label class="px-0 m-0">
                    <i class="material-icons"
                       aria-hidden="true">cancel</i>{{ 'PASSWORD_INVALID' | translate }}</label>
                </div>

                <div class="text-danger fade show"
                     *ngIf="
                  ((webInfo.confirmPassword?.dirty ||
                    webInfo.confirmPassword?.touched) &&
                    webInfo.confirmPassword?.errors?.MatchPassword) ||
                  (webInfo.password.dirty && webInfo.password.errors?.pattern)
                ">
                  <label class="px-0 m-0"
                         *ngIf="
                    (webInfo.confirmPassword.dirty ||
                      webInfo.confirmPassword.touched) &&
                    webInfo.confirmPassword.errors?.MatchPassword
                  ">
                    <i class="material-icons"
                       aria-hidden="true">cancel</i>{{ 'PASSWORD_NOT_MATCH' | translate }}
                  </label>
                </div>

                <div *ngIf="webInfo.password.dirty || webInfo.password.touched">
                  <div class="form-group m-0">
                    <label class="col px-0 m-0"
                           [ngClass]="
                      webInfo.password.hasError('required') ||
                      webInfo.password.hasError('minlength')
                        ? 'text-danger'
                        : 'text-success'
                    ">
                      <i class="material-icons"
                         aria-hidden="true">{{
                        webInfo.password.hasError('required') ||
                        webInfo.password.hasError('minlength')
                        ? 'cancel'
                        : 'check_circle'
                        }}</i>

                      <span>{{ 'PASSWORD_8_CHARATERS' | translate }}</span>
                    </label>
                    <br />
                    <label class="col px-0 m-0"
                           [ngClass]="
                      webInfo.password.hasError('required') ||
                      webInfo.password.hasError('hasNumber')
                        ? 'text-danger'
                        : 'text-success'
                    ">
                      <i class="material-icons"
                         aria-hidden="true">{{
                        webInfo.password.hasError('required') ||
                        webInfo.password.hasError('hasNumber')
                        ? 'cancel'
                        : 'check_circle'
                        }}</i>
                      <span>{{'MUST_CONTAIN_1_NUMBER' | translate}}</span>
                    </label>
                    <br />
                    <label class="col px-0 m-0"
                           [ngClass]="
                      webInfo.password.hasError('required') ||
                      webInfo.password.hasError('hasCapitalCase')
                        ? 'text-danger'
                        : 'text-success'
                    ">
                      <i class="material-icons"
                         aria-hidden="true">{{
                        webInfo.password.hasError('required') ||
                        webInfo.password.hasError('hasCapitalCase')
                        ? 'cancel'
                        : 'check_circle'
                        }}</i>
                      <span>{{'MUST_CONTAIN_1_CAPITAL' | translate}}</span>
                    </label>
                    <br />
                    <label class="col px-0 m-0"
                           [ngClass]="
                      webInfo.password.hasError('required') ||
                      webInfo.password.hasError('hasSmallCase')
                        ? 'text-danger'
                        : 'text-success'
                    ">
                      <i class="material-icons"
                         aria-hidden="true">{{
                        webInfo.password.hasError('required') ||
                        webInfo.password.hasError('hasSmallCase')
                        ? 'cancel'
                        : 'check_circle'
                        }}</i>
                      <span>{{'MUST_CONTAIN_1_SMALL' | translate}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3"
               *ngIf="standAlone">
            <div class="col-12 mb-3">
              <label for="password"
                     class="form-label mb-1">
                {{'NEW_PASSWORD' | translate}}
                <span class="non-ng-invalid-mark">*</span>
              </label>
              <div class="input-group">
                <input id="password"
                       [type]="nHide ? 'password' : 'text'"
                       class="form-control"
                       placeholder="{{ 'NEW_PASSWORD' | translate }}"
                       formControlName="password"
                       maxlength="40"
                       required
                       aria-required="true"
                       autocomplete="off" />
                <span class="input-group-text rounded-end"
                      id="btn-show-password"
                      type="button"
                      (click)="nHide = !nHide">
                  <i class="fa fa-eye"
                     *ngIf="!nHide"
                     aria-hidden="true"
                     title="Show New Password"></i>
                  <i class="fa fa-eye-slash"
                     *ngIf="nHide"
                     aria-hidden="true"
                     title="Show New Password"></i>
                  <span class="sr-only">Show New Password:</span>
                </span>
              </div>
            </div>

            <div class="col-12">
              <label for="confirmPassword"
                     class="form-label mb-1">
                {{'CONFIRM_PASSWORD' | translate}}
                <span class="non-ng-invalid-mark">*</span>
              </label>
              <div class="input-group">
                <input id="confirmPassword"
                       [type]="cHide ? 'password' : 'text'"
                       class="form-control"
                       placeholder="{{ 'CONFIRM_PASSWORD' | translate }}"
                       formControlName="confirmPassword"
                       maxlength="40"
                       aria-required="true"
                       autocomplete="off" />
                <span class="input-group-text rounded-end"
                      type="button"
                      id="btn-show-chide"
                      (click)="cHide = !cHide">
                  <i class="fa fa-eye"
                     *ngIf="!cHide"
                     aria-hidden="false"
                     title="Show New Password"
                     role="img"></i>
                  <i class="fa fa-eye-slash"
                     *ngIf="cHide"
                     aria-hidden="false"
                     title="Show New Password"
                     role="img"></i>
                  <span class="sr-only">Show Confirm Password:</span>
                </span>
              </div>
              <div id="confirmPasswordNode"
                   class="form-text">
                <ng-content select="[passwordnote]"></ng-content>
              </div>
              <div class="form-text">
                <div class="text-danger fade show"
                     *ngIf="
                  ((webInfo.confirmPassword?.dirty ||
                    webInfo.confirmPassword?.touched) &&
                    webInfo.confirmPassword?.errors?.MatchPassword) ||
                  (webInfo.password.dirty && webInfo.password.errors?.pattern)
                ">
                  <label class="px-0 m-0">
                    <i class="material-icons">cancel</i>{{ 'PASSWORD_INVALID' | translate }}
                  </label>
                </div>

                <div class="text-danger fade show"
                     *ngIf="
                  ((webInfo.confirmPassword?.dirty ||
                    webInfo.confirmPassword?.touched) &&
                    webInfo.confirmPassword?.errors?.MatchPassword) ||
                  (webInfo.password.dirty && webInfo.password.errors?.pattern)
                ">
                  <label class="px-0 m-0"
                         *ngIf="
                    (webInfo.confirmPassword.dirty ||
                      webInfo.confirmPassword.touched) &&
                    webInfo.confirmPassword.errors?.MatchPassword
                  ">
                    <i class="material-icons">cancel</i>{{ 'PASSWORD_NOT_MATCH' | translate }}
                  </label>
                </div>

                <div *ngIf="webInfo.password.dirty || webInfo.password.touched">
                  <div class="form-group m-0">
                    <label class="col px-0 m-0"
                           [ngClass]="
                      webInfo.password.hasError('required') ||
                      webInfo.password.hasError('minlength')
                        ? 'text-danger'
                        : 'text-success'
                    ">
                      <i class="material-icons">{{
                        webInfo.password.hasError('required') ||
                        webInfo.password.hasError('minlength')
                        ? 'cancel'
                        : 'check_circle'
                        }}</i>

                      <span>{{ 'PASSWORD_8_CHARATERS' | translate }}</span>
                    </label>
                    <br />
                    <label class="col px-0 m-0"
                           [ngClass]="
                      webInfo.password.hasError('required') ||
                      webInfo.password.hasError('hasNumber')
                        ? 'text-danger'
                        : 'text-success'
                    ">
                      <i class="material-icons">{{
                        webInfo.password.hasError('required') ||
                        webInfo.password.hasError('hasNumber')
                        ? 'cancel'
                        : 'check_circle'
                        }}</i>
                      <span>{{'MUST_CONTAIN_1_NUMBER' | translate}}</span>
                    </label>
                    <br />
                    <label class="col px-0 m-0"
                           [ngClass]="
                      webInfo.password.hasError('required') ||
                      webInfo.password.hasError('hasCapitalCase')
                        ? 'text-danger'
                        : 'text-success'
                    ">
                      <i class="material-icons">{{
                        webInfo.password.hasError('required') ||
                        webInfo.password.hasError('hasCapitalCase')
                        ? 'cancel'
                        : 'check_circle'
                        }}</i>
                      <span>{{'MUST_CONTAIN_1_CAPITAL' | translate}}</span>
                    </label>
                    <br />
                    <label class="col px-0 m-0"
                           [ngClass]="
                      webInfo.password.hasError('required') ||
                      webInfo.password.hasError('hasSmallCase')
                        ? 'text-danger'
                        : 'text-success'
                    ">
                      <i class="material-icons">{{
                        webInfo.password.hasError('required') ||
                        webInfo.password.hasError('hasSmallCase')
                        ? 'cancel'
                        : 'check_circle'
                        }}</i>
                      <span>{{'MUST_CONTAIN_1_SMALL' | translate}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</div>
