import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fareFilterPipe',
})
export class FareFilterPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(items: any[], filter: string): any {
    if (!items || !filter) {
      return items;
    }
    // filter array
    filter = filter.toLowerCase();
    return items.filter(
      (item) => item.description.toLowerCase().indexOf(filter) !== -1
    );
  }
}
