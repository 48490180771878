import { TransactionResponse } from './transactionResponse';
import { ValidateAccountQuery } from './validateAccountQuery';

export class WebAccessQuery {
  UserLogonId: string;
  AccountId: string;
  UserId: string;
  UserEmail: string;
  AccountEmail: string;
  LinkExpiryDateTime: string;
  VerificationCode: string;
  Language: string;
  WebUserId: string;
  Uid: string;
  ParentAccEmail: string;
  AccessCode: string;
  ValidateAccountQuery: ValidateAccountQuery;
  TransactionResponse: TransactionResponse;
}
