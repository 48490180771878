/* eslint-disable @angular-eslint/component-selector */
import { find } from 'lodash-es';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  Account,
  AccountProfileConfig,
  AccountService,
  AlertService,
  ConfigurationService,
  ProvinceInfo,
  SuspendTagQuery,
  SuspendTagQueryList,
  Transponder,
  TransponderFee,
  TransponderRequest,
  UserService,
} from '@ibitoll/toll-core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SubSink } from 'subsink';
import { BlukTransponderComponent } from '../../../bluk-transponder/bluk-transponder.component';
import { TRANSPONDERTYPE } from '../../../constants/key.constants';
import { NewtransponderComponent } from '../../../newtransponder/newtransponder.component';
import { OwntransponderComponent } from '../../../owntransponder/owntransponder.component';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { MinBalanceModalComponent } from '../min-balance-modal/min-balance-modal.component';
import { TransponderComponent } from '../../transponder.component';
import { TagAddConfirmationComponent } from '../tag-add-confirmation/tag-add-confirmation.component';

@Component({
  selector: 'mdot-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
})
export class OrderConfirmationComponent implements OnDestroy {
  @ViewChild(NewtransponderComponent, { static: false })
  newtransponderComponent: NewtransponderComponent;

  @ViewChild(OwntransponderComponent, { static: false })
  ownTansponder: OwntransponderComponent;

  @ViewChild(BlukTransponderComponent, { static: false })
  blukTransponderComponent: BlukTransponderComponent;

  @Input() account: Account = new Account();
  @Input() transponder: Transponder;
  @Input() AccountProfileType: AccountProfileConfig;
  @Input() transponderFees: TransponderFee[] = [];
  @Input() workMode: number = 1; // 1 add transponder; 2 - bulk activate;
  @Input() transponderList: Transponder[] = [];
  @Input() requestedTransponders: Transponder[];

  pendingTransponders: Transponder[];
  transponders: Transponder[];

  @Output() pendingTranspondersOutput: EventEmitter<Transponder[]> =
    new EventEmitter();

  canRequest = false;
  pricePerTag: TransponderFee;

  ownTansponderFormValid = false;
  isRequesting = false;
  isEditTransponder = false;
  isUpdateProcessing = false;

  isLoading = true;
  blukTagquantity = 0;
  enableTableRead = false;

  canBluckRequest = false;
  pricePerBlukTag: TransponderFee;
  currentEditBtnId: string;
  currentTime = new Date();
  defaultPlateProvinceId = 1;
  isAutoApproveRequest = false;

  private subs = new SubSink();

  modalRef?: BsModalRef;
  minBalanceModal: NgbModalRef;
  tagReqApprovedModal: NgbModalRef;
  //tagRequest: TransponderRequest;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public configurationService: ConfigurationService,
    public accountService: AccountService,
    private cd: ChangeDetectorRef,
    public userService: UserService,
    public alertService: AlertService
  ) {}

  ngOnInit(): void {
    console.log('requestedTransponders: ' + JSON.stringify(this.requestedTransponders) );
    this.LoadDefaultPlateProvince();
  }

  onNewTransponderLoaded() {
    if (this.AccountProfileType.AccountType === 'P') {
      this.newtransponderComponent.resetActiveForm();
      this.newtransponderComponent?.lockTransponderProvince(
        this.account.MailingProvinceID
      );

      this.newtransponderComponent.transponderFees = this.transponderFees;
      this.newtransponderComponent.transponderFeesOg = this.transponderFees;

      // this.newtransponderComponent.transponderInfoFormGroup?.controls?.year?.setValue(
      //   this.currentTime.getFullYear()
      // );
    }

    this.subs.add(
      this.newtransponderComponent?.transponderInfoFormGroup.valueChanges.subscribe(
        (data) => {
          if (this.newtransponderComponent.transponderInfoFormGroup.valid) {
            this.canRequest =
              this.newtransponderComponent.transponderInfoFormGroup.valid;
          } else {
            this.canRequest = false;
          }
        }
      )
    );
  }

  onBulkTransponderLoaded() {
    if (this.AccountProfileType.AccountType === 'B') {
      this.blukTransponderComponent.resetActiveForm();
      this.blukTransponderComponent.transponderFees = this.transponderFees;
    }

    this.subs.add(
      this.blukTransponderComponent?.blukTransponderInfoFormGroup.valueChanges.subscribe(
        () => {
          if (
            this.blukTransponderComponent.blukTransponderInfoFormGroup.valid
          ) {
            this.canBluckRequest =
              this.blukTransponderComponent.blukTransponderInfoFormGroup.valid;
          } else {
            this.canBluckRequest = false;
          }
        }
      )
    );
  }

  onOwnTransponderLoaded() {
    this.subs.add(
      this.ownTansponder?.ownTransponderInfoFormGroup.controls[
        'serialNumber'
      ].valueChanges.subscribe(() => {
        this.ownTansponderFormValid =
          this.ownTansponder?.ownTransponderInfoFormGroup.valid;
      })
    );
  }

  // NEW TRANSPONDER

  validationStatus(status) {
    this.canRequest = status;
  }

  setNewTransponderStatus(newtransponderComponent: NewtransponderComponent) {
    this.newtransponderComponent = newtransponderComponent;

    this.newtransponderComponent?.transponderInfoFormGroup.valueChanges.subscribe(
      () => {
        if (this.newtransponderComponent.transponderInfoFormGroup.valid) {
          this.canRequest =
            this.newtransponderComponent.transponderInfoFormGroup.valid;
        } else {
          this.canRequest = false;
        }
      }
    );
  }

  addNewVehicle() {
    console.log('New Transponder Requests starts...');
    const transponder = new TransponderRequest();
    const transponderTypeId =
      this.newtransponderComponent.transponderInfoFormGroup.controls[
        'transponderType'
      ].value;
    this.pricePerTag = this.newtransponderComponent.transponderFees.find(
      (o) =>
        o.TransponderTypeId === transponderTypeId &&
        o.AccountProfileId === this.account.AccountProfileID
    );

    transponder.TransponderTypeId =
      this.newtransponderComponent.transponderInfoFormGroup.controls[
        'transponderType'
      ].value;
    transponder.PlateNum =
      this.newtransponderComponent.transponderInfoFormGroup.controls[
        'plate'
      ].value;
    transponder.VehicleMake =
      this.newtransponderComponent.transponderInfoFormGroup.controls[
        'make'
      ].value;
    transponder.VehicleModel =
      this.newtransponderComponent.transponderInfoFormGroup.controls[
        'model'
      ].value;
    transponder.VehicleColor =
      this.newtransponderComponent.transponderInfoFormGroup.controls[
        'color'
      ].value;
    transponder.VehicleYear =
      this.newtransponderComponent.transponderInfoFormGroup.controls[
        'year'
      ].value;
    transponder.TollCategoryId =
      this.newtransponderComponent.transponderInfoFormGroup.controls[
        'category'
      ].value;
    transponder.PlateProvinceId = 
      this.newtransponderComponent.transponderInfoFormGroup.controls[
        'province'
      ].value;

    this.requestTransponder(transponder);
  }

  requestTransponder(transponder: TransponderRequest) {
    console.log('Request tag: ' + JSON.stringify(transponder));
    this.isRequesting = true;

    console.log('requestTransponder2. req=' + JSON.stringify(transponder) + '; transponders: ' + JSON.stringify(this.requestedTransponders) );
    if (transponder?.PlateNum &&
      find(this.requestedTransponders, (o) => {
        return (
          o.PlateNum?.toUpperCase() ===
            transponder?.PlateNum?.toUpperCase() 
            //&& o.PlateProvinceId == transponder?.PlateProvinceId
        );
      })) 
    {
      //console.log('Plate being added ' + transponder?.PlateNum + ' already requested');
      this.alertService.error('Transponder with such plate number ' + transponder?.PlateNum + ' already requested', false);
      this.minBalanceModal?.close();
      this.isRequesting = false;
      //this.closeModal();
    } else {

      console.log('Good to go with Plate ' + transponder?.PlateNum );
      transponder.WebUserLogonId = +this.userService.getCurrentUser().UserId;
      transponder.AccountId = this.userService.getCurrentUser().AccountId;

      //this.tagRequest = transponder;
      if (transponder?.TransponderId && transponder?.ManufacturerId) {
        this.isAutoApproveRequest = true;
      }

      this.accountService.requestTransponder(transponder).subscribe(
        (result) => {
          this.isRequesting = false;
          if (result) {
            console.log('TagRequestedSuccess');

            if (this.isAutoApproveRequest) {
              this.notifyTagAutoApproved(transponder);
            }

            this.refreshTransponders();
            this.alertService.success('Transponders Requested', false);

            this.minBalanceModal?.close();
            this.closeModal();
          }
        },
        () => {
          this.minBalanceModal?.close();
          this.closeModal();
          this.isRequesting = false;
        }
      );
    }
  }

  notifyTagAutoApproved(transponderReq: TransponderRequest) {
    //this.transponder = transponder;
    if (transponderReq?.TransponderId && transponderReq?.ManufacturerId) {
      console.log('TagAutoReq:' + JSON.stringify(transponderReq));
      this.alertService.clear();
      this.tagReqApprovedModal = this.modalService.open(TagAddConfirmationComponent, { animation: true });
      this.tagReqApprovedModal.componentInstance.transponderReq = transponderReq;
      this.tagReqApprovedModal.componentInstance.onTransponderUnSuspendedOut.subscribe(() => {
        //this.onTransponderUnSuspended(transponder)
      })
    } else {
      console.log('not an TagAutoReqest');
    }
  }

  isNewTransponderRequest() {
    if (this.canRequest) {
      return false;
    } else {
      return true;
    }
  }

  // NEW TRANSPONDER

  // OWN TRANSPONDER //
  setOwnTransponderStatus(ownTansponder: OwntransponderComponent) {
    this.ownTansponder = ownTansponder;

    this.subs.add(
      this.ownTansponder?.ownTransponderInfoFormGroup.controls[
        'serialNumber'
      ].valueChanges.subscribe(() => {
        this.ownTansponderFormValid =
          this.ownTansponder?.ownTransponderInfoFormGroup.valid;
      })
    );
  }

  addOwnTransponder() {
    if (this.ownTansponder?.ownTransponderValid) {
      const transponder = new TransponderRequest();
      transponder.TransponderSerialNumber =
      //this.ownTansponder.getTranspoderSerial(
        this.ownTansponder.prefixValue +
        '' +
        this.ownTansponder.ownTransponderInfoFormGroup.controls['serialNumber']
          .value;
      transponder.TransponderTypeId =  this.ownTansponder.getTransponderTypeId(); //TRANSPONDERTYPE.Own; 
      transponder.TollCategoryId = this.ownTansponder.getTollCategoryId();
      transponder.ManufacturerId = this.ownTansponder.getManufacturerId(
        transponder.TransponderSerialNumber
      );
      transponder.TransponderId = this.ownTansponder.getTransponderId(
        transponder.TransponderSerialNumber
      );
      transponder.PlateProvinceId = this.defaultPlateProvinceId;
      console.log('OC.addOwnTransponder: serial: ' + transponder.TransponderSerialNumber + '; TrId.: ' + transponder.TransponderId + '; Manuf.: ' + transponder.ManufacturerId);
      this.requestTransponder(transponder);
      this.ownTansponder.ownTransponderInfoFormGroup.controls[
        'serialNumber'
      ].reset();
    }
  }

  activateAllTransponders()
  {
    this.OnBulkUnSuspended();
  }


  public OnBulkUnSuspended() { // activate
    console.log('Activating all starts...');
    this.isRequesting = true;
    const accId = this.userService.getCurrentUser().AccountId; // this.accountService.currentAccount.AccountID

    var suspendTagQuery: SuspendTagQuery[] = this.transponderList.map((t) => { 
      return {AccountId:accId, ManufacturerId:t.ManufacturerId, TransponderId:t.TransponderId, TransponderStatus:t.RestrictionStatus, TagList: 'none' }
    });
    const queryList = new SuspendTagQuery();
    queryList.AccountId = accId;
    queryList.ManufacturerId = 3;
    queryList.TransponderId = 347971;
    queryList.TagList = JSON.stringify(suspendTagQuery);

    this.accountService.updateUnSuspendTagList(queryList).subscribe(
      (result) => {
        console.log('updateUnSuspendTagList status ' + result);
        this.isRequesting = false;
        if (result === suspendTagQuery.length) {
          this.alertService.success('Transponders Activated', false);
        } else if (result === 0) {
          this.alertService.error('No Transponders were Activated', false);
        } else {
          this.alertService.warn('Not all Transponders were Activated (' + result + ' out of ' + suspendTagQuery.length + ')', false);
        }

        this.refreshTransponders();
        //this.minBalanceModal?.close();
        this.closeModal();
      },
      (error) => {
        console.log('updateUnSuspendTagList Error' + error);
        //this.minBalanceModal?.close();
        this.closeModal();
        this.isRequesting = false;
      }


      // () => {
      //   // this.getTransponderVehicleInfoOnly(accId, true).subscribe(
      //   //   (resp) => {
      //   //     this.transponders = resp;
      //   //     // this.unSupendAlertModal.dismiss();
      //   //     // this.isRequesting = false;
      //   //     // this.alertService.success(this.translateService.instant('TRANSPONDER_UNSUSPENDED_MSG'), false);
      //   //   },
      //   //   () => {
      //   //     this.enableTableRead = true
      //   //   }
      //   // );
      //   this.isRequesting = false;

      // },
      // () => {
      //   // this.unSupendAlertModal.dismiss();
      //   this.isRequesting = false;
      // }
    );
  }


  closeOwnModal() {
    this.ownTansponder?.resetActiveForm();
    this.activeModal.close();
    this.isEditTransponder = false;
    this.transponder = null;
  }

  // OWN TRANSPONDER //

  // BULK TRANSPONDER //

  setblukTransponderStatus(blukTransponderComponent: BlukTransponderComponent) {
    this.blukTransponderComponent = blukTransponderComponent;

    this.subs.add(
      this.blukTransponderComponent?.blukTransponderInfoFormGroup.valueChanges.subscribe(
        () => {
          if (
            this.blukTransponderComponent.blukTransponderInfoFormGroup.valid
          ) {
            this.canBluckRequest =
              this.blukTransponderComponent.blukTransponderInfoFormGroup.valid;
          } else {
            this.canBluckRequest = false;
          }
        }
      )
    );
  }

  isBulkTransponderRequest() {
    if (this.canBluckRequest) {
      return false;
    } else {
      return true;
    }
  }

  addBlukTransponder() {
    console.log('Requesting-bulk-tags...');
    const transponderTypeId =
      this.blukTransponderComponent?.blukTransponderInfoFormGroup.controls[
        'transponderType'
      ].value;

    // get default province
    var defaultProvinceId = 1;
    this.configurationService.getProvinces().subscribe((data) => {

      var defaultProvince:ProvinceInfo[] = data.filter((p) => {
        return (
          p.Name === 'Nova Scotia'
        )
      });
      if (defaultProvince) {
        defaultProvinceId = defaultProvince[0].Id;
      }

    });

    console.log('bulk-tags-defaultProvinceId='+defaultProvinceId);

    this.pricePerBlukTag = this.blukTransponderComponent?.transponderFees.find(
      (o) =>
        o.TransponderTypeId === transponderTypeId &&
        o.AccountProfileId === this.account.AccountProfileID
    );

    this.isRequesting = true;
    const transponder = new TransponderRequest();
    transponder.WebUserLogonId = +this.userService.getCurrentUser().UserId;
    transponder.AccountId = this.userService.getCurrentUser().AccountId;
    transponder.TransponderQuantity =
      this.blukTransponderComponent.blukTransponderInfoFormGroup.controls[
        'quantity'
      ].value;
    transponder.TollCategoryId =
      this.blukTransponderComponent.transponderRequest.TollCategoryId;
    transponder.TransponderTypeId = this.pricePerBlukTag?.TransponderTypeId;
    transponder.PlateProvinceId = defaultProvinceId;

    this.accountService.requestTransponder(transponder).subscribe(
      (result) => {
        this.isRequesting = false;
        if (result) {
          this.refreshTransponders();
          this.alertService.success('Transponders Requested', false);
          this.minBalanceModal?.close();
          this.closeModal();
        }
      },
      () => {
        this.minBalanceModal?.close();
        this.closeModal();
        this.isRequesting = false;
      }
    );
  }

  // BULK TRANSPONDER //

  // COMMON //

  closeModal() {
    this.blukTagquantity = 0;
    this.blukTransponderComponent?.resetActiveForm();
    this.isEditTransponder = false;
    this.transponder = null;
    this.activeModal.dismiss();
  }

  private refreshTransponders() {
    if (this.workMode == 1) {
      if (this.AccountProfileType.AccountType === 'P') {
        this.newtransponderComponent.resetActiveForm();
      }

      if (this.AccountProfileType.AccountType === 'B') {
        this.blukTransponderComponent.resetActiveForm();
      }
      this.accountService.resetCachedRequestedVehicleInfo();
    }
    if (this.isAutoApproveRequest) {
      this.getAprrovedTransponderVehicleInfo(this.account.AccountID, true);
    } else {
      this.getTransponderVehicleInfo(this.account.AccountID, true);
    }

  }

  getTransponderVehicleInfo(accountID: number, _forceRefresh: boolean = false) {
    // query for Requested but not yet approved tags
    console.log('getTransponderVehicleInfoFor ' + accountID);
    this.isLoading = true;
    this.accountService.getRequestedVehicleInfo(accountID).subscribe(
      (sresp) => {
        this.pendingTransponders = sresp;
        this.isLoading = false;
        this.minBalanceModal?.close();
        setTimeout(() => {
          this.enableTableRead = true;
          this.pendingTranspondersOutput.emit(sresp);
        }, 500);
      },
      () => {
        this.enableTableRead = true;
        this.isLoading = false;
      }
    );
  }

  private getAprrovedTransponderVehicleInfo(accountID: number, _forceRefresh: boolean = false) {
    // query for Requested but not yet approved tags
    console.log('getAprrovedTransponderVehicleInfo ' + accountID);
    this.isLoading = true;
    this.accountService.fetchTransponderVehicleInfo(accountID).subscribe(
      (sresp) => {
        this.pendingTransponders = sresp;
        this.isLoading = false;
        this.minBalanceModal?.close();
        setTimeout(() => {
          this.enableTableRead = true;
          this.pendingTranspondersOutput.emit(sresp);
        }, 500);
      },
      () => {
        this.enableTableRead = true;
        this.isLoading = false;
      }
    );
  }

  LoadDefaultPlateProvince() {
    this.defaultPlateProvinceId = 1;
    this.configurationService.getProvinces().subscribe((data) => {
      var defaultProvince:ProvinceInfo[] = data.filter((p) => {
        return (
          p.Name === 'Nova Scotia'
        )
      });
      if (defaultProvince) {
        this.defaultPlateProvinceId = defaultProvince[0].Id;
      }
    });
  }
  

  // COMMON //

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
