import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { CenposParams } from '../models/cenposParams';
import { PaymentResponse } from '../models/paymentResponse';
import { SiteVerifyResponse } from '../models/siteVerifyResponse';
import { TransactionResponse } from '../models/transactionResponse';
import { ConfigurationService } from './configuration.service';
import { ErrorhandlerService } from './errorhandler.service';
import { ProxyService } from './proxy.service';

@Injectable({
  providedIn: 'root',
})
export class CenposService implements OnDestroy {
  simpleWebPay_useToken_url =
    'https://www3.cenpos.net/simplewebpay/cards/api/UseToken/';
  simpleWebPay_useCryptoToken_url =
    'https://www3.cenpos.net/simplewebpay/cards/api/UseCrypto/';
  simpleWebPay_verifyingPost_url =
    'https://www3.cenpos.net/simplewebpay/cards/?app=genericcontroller&action=siteVerify';
  simpleWebPay_getToken_url =
    'https://www3.cenpos.net/simplewebpay/cards/api/GetToken/';
  simpleWebPay_deleteToken_url =
    'https://www3.cenpos.net/simplewebpay/cards/api/DeleteToken/';
  simpleWebPay_merchant: string;
  simpleWebPay_secretKey: string;

  webPay_verifyingPost_url =
    'https://www3.cenpos.net/webpay/v7/html5/?app=genericcontroller&action=siteVerify';
  webPay_merchant: string;
  webPay_secretKey: string;

  private endpointUrl: string;
  private cenposEndpoint = 'api/Cenpos';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private subs = new SubSink();

  constructor(
    private http: HttpClient,
    private configurationService: ConfigurationService,
    private errorhandlerService: ErrorhandlerService,
    private proxy: ProxyService
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );

    this.getCenposConfig();
  }

  getCenposConfig() {
    this.configurationService.getWebPayConfig().subscribe(
      (resp) => {
        this.webPay_merchant = resp.Merchant;
        this.webPay_secretKey = resp.SecretKey;
      },
      (error) => {
        console.log(error);
      }
    );

    this.configurationService.getSimpleWebPayConfig().subscribe(
      (resp) => {
        this.simpleWebPay_merchant = resp.Merchant;
        this.simpleWebPay_secretKey = resp.SecretKey;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // ********************************************/
  // Cenpos call in the backend
  // ********************************************/
  simpleWebPayPostPayment_backend(
    cenposParams: CenposParams
  ): Observable<TransactionResponse> {
    return this.http
      .post<TransactionResponse>(
        this.endpointUrl + this.cenposEndpoint + '/PostPayment',
        JSON.stringify(cenposParams),
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  // ********************************************/
  // WebPay Cenpos api calls
  // ********************************************/
  createWebPayObject(email: string): Observable<any> {
    const params = new URLSearchParams();
    params.set('secretkey', this.webPay_secretKey);
    params.set('merchant', this.webPay_merchant);
    params.set('email', email);

    return this.webPayVerifyingPost(params);
  }

  webPayVerifyingPost(params: URLSearchParams): Observable<string> {
    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
    };

    return this.http
      .post(this.webPay_verifyingPost_url, params.toString(), options)
      .pipe(
        map(
          (value: SiteVerifyResponse) => {
            return value.Data;
          },
          () => {
            console.log('Error occured in verifyingPost');
          }
        )
      );
  }

  // ********************************************/
  // Simple WebPay Cenpos api calls
  // ********************************************/

  simpleWebPayVerifyingPost(params: URLSearchParams): Observable<string> {
    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
    };

    return this.http
      .post(this.simpleWebPay_verifyingPost_url, params.toString(), options)
      .pipe(
        map((value: SiteVerifyResponse) => {
          return value.Data;
        })
      );
  }

  // create simple web pay object
  createSimpleWebPayObject(email: string): Observable<any> {
    const params = new URLSearchParams();
    params.set('secretkey', this.simpleWebPay_secretKey);
    params.set('merchant', this.simpleWebPay_merchant);
    params.set('email', email); // necessary

    return this.simpleWebPayVerifyingPost(params);
  }

  simpleWebPayPostPayment(
    email: string,
    token: string,
    amount: string,
    invoiceNumber: string
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
    };
    const params = new URLSearchParams();
    params.set('amount', amount);
    params.set('type', 'Sale');
    params.set('email', email);
    params.set('secretkey', this.simpleWebPay_secretKey);
    params.set('merchant', this.simpleWebPay_merchant);
    params.set('invoicenumber', invoiceNumber);
    params.set('tokenid', token);

    return this.simpleWebPayVerifyingPost(params).pipe(
      mergeMap((resp) => {
        const params2 = new URLSearchParams();
        params2.set('verifyingpost', resp);
        params2.set('tokenid', token);
        // call use token to submit the payment
        return this.http.post<PaymentResponse>(
          this.simpleWebPay_useToken_url,
          params2.toString(),
          options
        );
      }),
      mergeMap((response: PaymentResponse) => {
        // when token is actually removed from cenpos, response Ref number is null and message is (Invalid recurring sale token identifier)
        if (response.ReferenceNumber == null) {
          return throwError(
            'Transaction could not be processed for this card. Please try again!'
          );
        }
        return of(response);
      })
    );
  }

  simpleWebPayDeleteToken(token: string): Observable<any> {
    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
    };
    const params = new URLSearchParams();
    params.set('secretkey', this.simpleWebPay_secretKey);
    params.set('merchant', this.simpleWebPay_merchant);
    params.set('tokenid', token);

    return this.simpleWebPayVerifyingPost(params).pipe(
      mergeMap((resp) => {
        const params2 = new URLSearchParams();
        params2.set('verifyingpost', resp);
        params2.set('tokenid', token);

        // call delete token to delete the card
        return this.http.post(
          this.simpleWebPay_deleteToken_url,
          params2.toString(),
          options
        );
      }),
      mergeMap((response: any) => {
        if (response.Message === 'Approved') {
          return of(true);
        } else {
          return of(false);
        }
      })
    );
  }

  simpleWebPayGetToken(email: string): Observable<any> {
    const options = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
    };
    const params = new URLSearchParams();
    params.set('secretkey', this.simpleWebPay_secretKey);
    params.set('merchant', this.simpleWebPay_merchant);
    params.set('email', email);

    return this.simpleWebPayVerifyingPost(params).pipe(
      mergeMap((resp) => {
        const params2 = new URLSearchParams();
        params2.set('verifyingpost', resp);

        // call get token to retrieve the token value for the stored cardholder account
        return this.http
          .post(this.simpleWebPay_getToken_url, params2.toString(), options)
          .pipe(
            catchError((error) => {
              return throwError(error);
            })
          );
      }),
      mergeMap(() => {
        return of(true);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
