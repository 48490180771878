import { PaymentViolationInfo } from './paymentViolation';

export class TransactionReceipt {
  TransactionId: string;
  OrderId: string;
  NodeId: number;
  DateTime: Date;
  Status: string;
  Amount: number;
  CardType: number;
  CardNumber: string;
  Message: string;
  IouList: PaymentViolationInfo[];
}
