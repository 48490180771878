export class PrepPaymentQuery {
  AccountId: string;
  OrderId: string;
  OrderDescription: string;
  MerchantId: string;
  Lang: string;
  Currency: string;
  DeviceCategory: string;
  Amount: string;
  Email: string;
  PhoneNo: string;
  BillingCountryCode: string;
  BillingProvinceId: string;
  BillingPostalCode: string;
  BillingCity: string;
  BillingAddress: string;
  CardType: string;
  CssUrl: string;
  SuccessUrl: string;
  FailUrl: string;
  ExtTokenOptions: string;
  ExtToken: string;
  PostUrl: string;
  Digest: string;
}
