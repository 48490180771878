import { Account } from './../../../models/account';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { NgForm } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, find, remove } from 'lodash';
import { SubSink } from 'subsink';
import { TollCategoryInfo } from '../../../models/tollCategoryInfo';
import { ProvinceInfo } from '../../../models/provinceInfo';
import { IdNameConfig } from '../../../models/idNameConfig';
import { TransponderRequest } from '../../../models/transponderRequest';
import { UserService } from '../../../providers/user.service';
import { AccountService } from '../../../providers/account.service';
import { ConfigurationService } from '../../../providers/configuration.service';
import { AlertService } from '../../../providers/alert.service';
import { UtilsService } from '../../../providers/utils.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'transponder-info',
  templateUrl: './transponder-info.component.html',
  styleUrls: ['./transponder-info.component.scss'],
})
export class TransponderInfoComponent implements OnInit, OnDestroy {
  subs = new SubSink();

  @ViewChild('macPassModal') macPassModal: ElementRef;

  newMode = true;

  tollCategories: TollCategoryInfo[] = [];
  provinces: ProvinceInfo[] = [];
  transponderTypes: IdNameConfig[] = [];

  transponderRequest: TransponderRequest;
  transponderRequestList: TransponderRequest[] = [];
  addedTranspCount = 0;

  requestTransponderEnabled = true;
  vehicleCategoryText = 'CATEGORY';

  @Output()
  requestedTransponderQuantityChanged = new EventEmitter<number>();

  @Input()
  isEmployer = false;

  @Input()
  newAccountMode = false;

  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onTransponderRequested: EventEmitter<boolean> = new EventEmitter<boolean>();

  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onTransponderRequestCancelled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(NgForm)
  transponderForm: NgForm;

  preferedProvinces: ProvinceInfo[] = [];
  fullProvinces: ProvinceInfo[] = [];

  isRequesting = false;
  errorObj: { status: boolean; title: string; message: string };

  constructor(
    private userService: UserService,
    private accountService: AccountService,
    private configurationService: ConfigurationService,
    private alertService: AlertService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private utilsService: UtilsService
  ) {
    this.transponderRequest = new TransponderRequest();
    this.transponderRequest.Enabled = true;
    this.transponderRequestList = new Array<TransponderRequest>();
  }

  ngOnInit() {
    this.configurationService
      .getTollCategories()
      .subscribe((tollCategories) => {
        const distinctCat = new Array<TollCategoryInfo>();
        const flags = new Array<any>();
        const len = tollCategories.length;
        // console.log('ngOnInit; len=' + len);
        let i: number;
        for (i = 0; i < len; i++) {
          if (flags[tollCategories[i].GroupName]) {
            continue;
          }
          flags[tollCategories[i].GroupName] = true;
          distinctCat.push(tollCategories[i]);
        }
        this.tollCategories = distinctCat;
      });

    this.configurationService.getProvinces().subscribe((data) => {
      var provinces = cloneDeep(data);
      const usProvince = provinces
        .filter((p) => p.CountryId === 7)
        .sort((a, b) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        });
      const cadProvince = provinces
        .filter((p) => p.CountryId === 1)
        .sort((a, b) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        });

      const fProvince = usProvince.concat(cadProvince);
      this.fullProvinces = cloneDeep(fProvince);
      this.provinces = cloneDeep(fProvince);

      this.preferedProvinces = this.fullProvinces.filter((p) => {
        return (
          p.Name === 'Michigan' ||
          p.Name === 'Ontario' ||
          p.Name === 'Government'
        );
      });

      remove(this.provinces, (p) => {
        return (
          p.Name === 'Michigan' ||
          p.Name === 'Ontario' ||
          p.Name === 'Government'
        );
      });
    });

    this.configurationService
      .getTransponderTypes()
      .subscribe((transponderTypes) => {
        this.transponderTypes = transponderTypes;
      });

    this.translate
      .get('VEHICLE_GROUP', { value: 'Vehicle group' })
      .subscribe((res: string) => {
        this.vehicleCategoryText = res;
      });

    setTimeout(() => {
      this.transponderRequest.TollCategoryId = null;
      this.transponderRequest.PlateProvinceId = null;
    }, 500);
  }

  getSubNotes(vehicleCategoryText: string) {
    if (vehicleCategoryText === 'Auto') {
      return 'passenger car, van, motorcycle, station wagon, SUV, pick-up truck, and school bus.';
    }

    if (vehicleCategoryText === 'Truck') {
      return 'tractor trailers, buses and step or cube vans.';
    }

    if (vehicleCategoryText === 'RV') {
      return 'motor homes.';
    }
  }

  requestTransponder() {
    this.isRequesting = true;
    this.transponderRequest.WebUserLogonId =
      +this.userService.getCurrentUser().UserId;
    this.transponderRequest.AccountId =
      this.userService.getCurrentUser().AccountId;

    this.accountService.requestTransponder(this.transponderRequest).subscribe(
      (result) => {
        if (result === true) {
          this.isRequesting = false;
          this.refreshTransponders();
          this.transponderRequest = new TransponderRequest();
          this.onTransponderRequested.emit(true);
          this.translate
            .get('TRANSPONDER_REQUEST_SUCCESSFUL', {
              value: 'MacPass request successfully sent!',
            })
            .subscribe((res: string) => {
              this.errorObj = {
                status: true,
                title: res,
                message: ` You have successfully placed an order for MacPass Tag(s). Your tag(s) will be mailed within 7-10 business
              days. You will receive an email when the MacPass(es) have been assigned to your account.`,
              };
              setTimeout(() => {
                this.modalService.open(this.macPassModal, { size: 'lg' });
              });
            });
        } else {
          this.isRequesting = false;
          this.translate
            .get('TRANSPONDER_REQUEST_FAIL', {
              value:
                'MacPass could not be requested. Please contact customer Service!',
            })
            .subscribe(() => {
              this.errorObj = {
                status: false,
                title: 'MacPass could not be requested',
                message: `MacPass could not be requested. Please contact customer Service!`,
              };
              setTimeout(() => {
                this.modalService.open(this.macPassModal, { size: 'lg' });
              });
            });
        }
      },
      () => {
        this.isRequesting = false;
        this.translate
          .get('TRANSPONDER_REQUEST_FAIL', {
            value:
              'Transponder could not be requested. Please contact customer Service!',
          })
          .subscribe((res: string) => {
            this.alertService.error(res, false);
          });
      }
    );
  }

  private refreshTransponders() {
    this.accountService.resetCachedRequestedVehicleInfo();
  }

  cancelRequest() {
    this.onTransponderRequestCancelled.emit(true);
  }

  addVehicle() {
    if (
      !find(this.transponderRequestList, {
        PlateNum: this.transponderRequest.PlateNum,
        PlateProvinceId: this.transponderRequest.PlateProvinceId,
      })
    ) {
      this.transponderRequestList.push(this.transponderRequest);
      this.addedTranspCount = this.transponderRequestList.length;
      this.requestedTransponderQuantityChanged.emit(this.addedTranspCount);
      this.transponderRequest = new TransponderRequest();
    } else {
      this.alertService.error(
        `Vehicle with plate number ${this.transponderRequest.PlateNum} is already exists.`,
        true
      );
    }
  }

  removeVehicle(index: number) {
    console.log('removing vehicle ' + index);
    this.transponderRequest = this.transponderRequestList[index];
    this.transponderRequestList.splice(index, 1);
    this.addedTranspCount = this.transponderRequestList.length;
    this.requestedTransponderQuantityChanged.emit(this.addedTranspCount);
  }

  getTransponderTypeText(id: number): string {
    let text = '';
    this.transponderTypes.forEach((t) => {
      if (t.Id === id) {
        text = t.Name;
        return;
      }
    });

    return text;
  }

  getCategoryText(id: number): string {
    let text = '';
    if (this.tollCategories != null) {
      text = this.tollCategories.find((item) => item.Id === id).GroupName;
    }
    return text;
  }

  getProvince(id: number): string {
    let text = '';
    if (this.fullProvinces != null) {
      text = this.fullProvinces.find((item) => item.Id === id).Name;
    }
    return text;
  }

  updateTransponder() { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
