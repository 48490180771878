import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReportActivityService {
  constructor() {}

  // not working
  // downloadReport(reportRecordId: number): Observable<Object> {
  // 	return this.http
  // 		.get(this.endpointUrl + `/DownloadReportRecord/${reportRecordId}?token=`
  // + encodeURI(this.localstorageService.getItem("token")), { headers: this.headers, responseType: ResponseContentType.Blob })
  // 		.map((r: Response) => {
  // 			return r.blob();
  // 		})
  // 		.catch(error => {
  // 			console.log(error);

  // 			return Observable.of<Object>();
  // 		});
  // }
}
