import { Injectable } from '@angular/core';
import { KMS, LANGUAGE_KEY } from './../models/keyConstand';
import { SecureSessionStorageService } from './securesessionstroage.service';
import { SecureStorageService } from './securestroage.service';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  public toggleStorage: boolean = false;
  constructor(public secureStorageService: SecureStorageService, public secureSessionStorageService: SecureSessionStorageService) {

    if (localStorage.getItem(KMS)) {

      this.toggleStorage = JSON.parse(localStorage.getItem(KMS));
    }
  }

  load() {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  setItem(key: string, value: string) {
    if (!this.toggleStorage) {
      this.secureSessionStorageService.setData(key, value);
    } else {
      this.secureStorageService.setData(key, value);
    }
  }

  getItem(key: string): string {
    if (!this.toggleStorage) {
      return  this.secureSessionStorageService.getData(key);
    } else {
      return this.secureStorageService.getData(key);
    }
  }

  removeItem(key: string) {
    if (!this.toggleStorage) {
      this.secureSessionStorageService.removeItems(key);
    } else {
      this.secureStorageService.removeItems(key);
    }
  }

  check(key: string): boolean {
    if (!this.toggleStorage) {
      return this.secureSessionStorageService.checkIsExist(key);
    } else {
      return this.secureStorageService.checkIsExist(key);
    }
  }

  clear() {
    if (this.secureStorageService.checkIsExist(LANGUAGE_KEY)) {
      const value = this.getItem(LANGUAGE_KEY);
      if (!this.toggleStorage) {
        this.secureSessionStorageService.destroy();
      } else {
        this.secureStorageService.destroy();
      }
      this.setItem(LANGUAGE_KEY, value);
    } else {
      if (!this.toggleStorage) {
        this.secureSessionStorageService.destroy();
      } else {
        this.secureStorageService.destroy();
      }
    }
  }
}
