import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'employeeFilterPipe' })
export class EmployeeFilterPipe implements PipeTransform {
  transform(items: Array<any>, filter: string): Array<any> {
    if (!items || !filter) {
      return items;
    }
    let resultArray = items;

    filter = filter.toLowerCase();
    const filterArray = filter.split(' ');

    for (let i = 0; i < filterArray.length; i++) {
      resultArray = this.filterItems(resultArray, filterArray[i]);
    }
    return resultArray;
  }

  filterItems(items: Array<any>, filter: string): Array<any> {
    if (!items || !filter) {
      return items;
    }
    return items.filter(
      (item) =>
        this.stringifyItemValues(item).toLowerCase().indexOf(filter) !== -1
    );
  }

  stringifyItemValues(item: any): string {
    let stringifyItem = '';

    // tslint:disable-next-line: forin
    for (const key in item) {
      stringifyItem += item[key] + ',';
    }
    return stringifyItem;
  }
}
