<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div class="modal-header">
    <div class="modal-title pull-start m-0 h3">
      {{ 'EDIT_TRANSPONDER' | translate }}
    </div>
    <button type="button " class="btn-close btn-close-white pull-right" (click)="activeModal.close()"
      aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <newtransponder #editTransponderComp [transponder]="transponder" [isEditTransponder]="isEditTransponder"
      (validationStatus)="validationStatus($event)">

      <div class="mb-3" submitbtn>
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6">
            <div class="float-end small text-end">If you want to permanently remove a transponder from your account, first click the "Deactivate" button.  Once confirmed, you will need to click the "Remove" button and confirm that as well.</div>
          </div>
        </div>
        <button cancelbtn class="btn btn-app-secondary px-4 mt-3 mb-2 float-start" (click)="closeEditModal()"
          type="button" role="button" aria-label="CANCEL">
          {{ 'CANCEL' | translate }}
        </button>
        <div class="float-end">
          
          <button *ngIf="isEditTransponder && transponder.RestrictionStatus == 2" class="btn btn-danger px-4 mt-3 me-1 "
            (click)="showSupendAlertModal()" type="button" role="button" aria-label="SUSPEND" id="supendTransponder">
            {{ 'DEACTIVATE' | translate }}
            <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isRequesting"></i>
          </button>

          <button *ngIf="isEditTransponder" class="btn btn-app-primary px-4 mt-3" (click)="updateTransponder()"
            type="button" role="button" aria-label="UPDATE"
            [ngClass]="{ disabled: !isFormValid() }"
            [attr.aria-disabled]="!isFormValid()" >
            {{ 'UPDATE' | translate }}
            <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isUpdateProcessing"></i>
          </button>
        </div>

      </div>
    </newtransponder>
  </div>
</div>
