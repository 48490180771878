import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthServiceProvider,
  ConfigurationService,
  User,
  UserService,
  UtilsService,
  AccountService,
  Account,
  DatabaseMapService,
} from '@ibitoll/toll-core';
import { switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mdot-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  loginUserName: string;
  loginPassword: string;
  loginClicked = false;

  isLoggedIn = false;
  NavLinks: any[] = [];

  private subs = new SubSink();
  account: Account;
  dontToggle = true;
  isSingleClick: Boolean = true; 

  constructor(
    public configService: ConfigurationService,
    private authService: AuthServiceProvider,
    public utilsService: UtilsService,
    public userService: UserService,
    private accountService: AccountService,
    private router: Router,
    private databaseMapService: DatabaseMapService
  ) {}

  ngOnInit(): void {
    console.log('header1.init: '+ JSON.stringify(this.configService.config.NavLinks));
    this.subs.add(
      this.accountService.activeAccount.subscribe((acc) => {
        this.account = acc;
        if (this.authService.loggedIn()) {
          this.isLoggedIn = this.authService.loggedIn();
          this.NavLinks = this.configService.config.NavLinks.filter((o) => {
            return o.ShowUser === true && this.checkUser(o);
          });
        } else {
          this.isLoggedIn = this.authService.loggedIn();
          this.NavLinks = this.configService.config.NavLinks.filter((o) => {
            return o.ShowUser === false;
          });
        }
      })
    );
  }

  checkUser(o: any): boolean {
    if (this.isAccountActive()) {
      return true;
    } else {
      return !o.Hide;
    }
  }

  isAccountActive(): boolean {
    try {
      return (
        this.account.AccountStatus ===
          this.databaseMapService.eAccountStatusDMap['Active'] ||
        this.account.AccountStatus ===
          this.databaseMapService.eAccountStatusDMap['Active Partial']
      );
    } catch {
      return false;
    }
  }

  getCurrentUser(): User {
    return this.userService.getCurrentUser();
  }
  logOut() {
    this.subs.add(
      this.userService
        .logout()
        .pipe(
          switchMap(() => {
            return this.authService.logout();
          })
        )
        .subscribe(
          () => {
            this.router.navigateByUrl('/login', { replaceUrl: true });
          },
          (error) => {
            // window.location.reload();
            this.router.navigateByUrl('/login', { replaceUrl: true });
          }
        )
    );
  }

  logoClick() {
    console.log("logoClick");
    this.isSingleClick = true;
         setTimeout(()=>{
             if (this.isSingleClick) {
                  //do for single click;
             }
          },250)
  }
  logoDoubleClick(){
    console.log("logoDoubleClick");
    this.isSingleClick = false;
    this.logOut();
  }


  toggleSubmenu(event) {
    const leftMenu = document.getElementById('navbarDropdownMenuLink');
    if (this.dontToggle && leftMenu) {
      leftMenu.click();
    }
  }

  mouseenter() {
    this.dontToggle = false;
  }

  mouseleave() {
    this.dontToggle = true;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
