import { Component, OnInit, Output, EventEmitter } from '@angular/core';

declare var jQuery: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'request-transponders',
  templateUrl: './request-transponders.component.html',
  styleUrls: ['./request-transponders.component.scss'],
})
export class RequestTranspondersComponent implements OnInit {
  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onTransponderRequest: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onTransponderRequested(response: boolean) {
    this.onTransponderRequest.emit(true);
    jQuery('#newTransponderModal').modal('hide');
  }

  onTransponderRequestCancelled(response: boolean) {
    jQuery('#newTransponderModal').modal('hide');
  }
}
