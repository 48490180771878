export class PaymentResponse {
  Result: number;
  Message: string;
  AccountBalanceAmount: string;
  AutorizationNumber: string;
  ReferenceNumber: string;
  TraceNumber: string;
  Amount: string;
  CardType: string;
  Discount: string;
  DiscountAmount: string;
  OriginalAmount: string;
  ParameterValidationResultList: ParameterValidationResultList[];
  PartiallyAuthorizedAmount: string;
  Surcharge: string;
  SurchargeAmount: string;
  RecurringTokenId: string;
  ProtectedCardNumber: string;
  ExpirationCard: string;
  ProcessedTransactionType: string;
  LastCardNumber: string;
  InvoiceNumber: string;
  ProcessPaypalResponse: ProcessPaypalResponse;
  NameOnCard: string;
  Field1: string;
  Field2: string;
  Field3: string;
  Field4: string;
  ACSUrl: string;
  Payload: string;
  TransactionID: string;
  View3D: string;
}

export class ParameterValidationResultList {
  ExtensionData: string;
  Name: string;
  Result: string;
}

export class ProcessPaypalResponse {
  Amount: string;
  AutorizationNumber: string;
  OriginalAmount: string;
  PayerId: string;
  RedirectUrl: string;
  ReferenceNumber: string;
  SuccessPageRedirectRequested: string;
  Token: string;
  TraceNumber: string;
  ExtensionData: string;
  Message: string;
  Result: string;
}

export class DirectPaymentResponse {
  Result: number;
  Message: string;
  AccountBalanceAmount: string;
  AutorizationNumber: string;
  ReferenceNumber: string;
  TraceNumber: string;
  Amount: string;
  CardType: string;
  Discount: string;
  DiscountAmount: string;
  OriginalAmount: string;
  ParameterValidationResultList: string;
  PartiallyAuthorizedAmount: string;
  Surcharge: string;
  SurchargeAmount: string;
  RecurringTokenId: string;
  ProtectedCardNumber: string;
  ExpirationCard: string;
  ProcessedTransactionType: string;
  LastCardNumber: string;
  InvoiceNumber: string;
  ProcessPaypalResponse: DirectProcessPaypalResponse;
  NameOnCard: string;
  Field1: string;
  Field2: string;
  Field3: string;
  Field4: string;
}

export class DirectProcessPaypalResponse {
  InvoiceNumber: string;
  Amount: string;
  AutorizationNumber: string;
  OriginalAmount: string;
  PayerId: string;
  RedirectUrl: string;
  ReferenceNumber: string;
  SuccessPageRedirectRequested: string;
  Token: string;
  TraceNumber: string;
  ExtensionData: string;
  Message: string;
  Result: string;
}
