export class Closure {
  Id: number;
  BridgeID: number;
  BridgeDirection: number;
  ClosureStartTime: Date;
  ClosureEndTime: Date;
  NotificationTime: Date;
  ClosureHeader: string;
  ClosureContent: string;
  Active: boolean;
  Pushed: boolean;
  PushedTime: Date;
  Key: string;
  Viewed: boolean;
}
