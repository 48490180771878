import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Transponder } from '../../../models/transponder';

declare var jQuery: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'suspend-transponder-confirmation',
  templateUrl: './suspend-transponder-confirmation.component.html',
  styleUrls: ['./suspend-transponder-confirmation.component.scss'],
})
export class SuspendTransponderConfirmationComponent implements OnInit {
  @Input()
  transponder: Transponder;

  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onTransponderSuspended: EventEmitter<Transponder> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  confirmSuspension() {
    this.onTransponderSuspended.emit(this.transponder);
    jQuery('#suspendTransponderModal').modal('hide');
  }
}
