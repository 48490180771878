import { CdkStepper } from '@angular/cdk/stepper';
import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'stepper-component',
  templateUrl: './stepper-component.component.html',
  styleUrls: ['./stepper-component.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponentComponent }],
})
export class StepperComponentComponent extends CdkStepper {

  _animationDone = new Subject<AnimationEvent>();

  @Input() activeClass = 'active';
  @Input() hideHeader : boolean = true;

}
