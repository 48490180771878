
export class Address {
    address_components: AddressComponentC[];
    adr_address: string;
    formatted_address: string;
    formatted_phone_number: string;
    html_attributions: string[];
    icon: string;
    id: string;
    international_phone_number: string;
    name: string;
    permanently_closed: boolean;
    place_id: string;
    price_level: number;
    rating: number;
    types: string[];
    url: string;
    utc_offset: number;
    vicinity: string;
    website: string;
}

export interface AddressComponentC {
  long_name: string;
  short_name: string;
  types: string[];
}
