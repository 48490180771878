import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServiceProvider } from '../providers/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SignupGuard implements CanActivate {
  constructor(
    private authService: AuthServiceProvider  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    this.authService.logOutClearCacheNotRedirect();
    return true;
  }
}
