import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProxyService } from './proxy.service';
import { SubSink } from 'subsink';
import { SupportRequest } from '../models/SupportRequest';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SupportRequestService {
  private endpointUrl: string;

  private configurationEndpoint = 'api/customerSupport';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  private subs = new SubSink();

  constructor(private http: HttpClient, private proxy: ProxyService) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  sendSupportRequest(request: SupportRequest): Observable<boolean> {
    return this.http
      .post(
        this.endpointUrl + this.configurationEndpoint + '/SendSupportRequest',
        request,
        { headers: this.headers }
      )
      .pipe(
        map((resp: boolean) => {
          return resp;
        })
      );
  }
}
