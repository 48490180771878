export class AccountCommConfigWeb {
  AccountId: number;
  AccountProfileID: number;
  CeaseDateTime: Date;
  CommunicationMethod: string;
  CommunicationTypeId: number;
  CommunicationTypeName: string;
  EffectiveDatetime: Date;
  EmailConfigType: string;
  IsActive: string;
  MailConfigType: string;
}
