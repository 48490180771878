<div cdkTrapFocus
     [cdkTrapFocusAutoCapture]="true">
  <div class="modal-header">
    <div class="modal-title mb-0 h3"
         id="preTagRequestModalLabel">
      Address Confirmation
    </div>
    <button type="button"
            role="button"
            id="model-close-btn"
            class="btn-close btn-close-white pull-right"
            (click)="cancel()"
            aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <span> Please confirm your address</span>
    {{' '}}
    <div class="container-12 mt-2">
      <div class="row">
        <div class="col-2">
          Address:
        </div>
        <div class="col-10">
          {{getAddress()}}
        </div>
      </div>

      <div class="row">
        <div class="col-4">

        </div>
        <div class="col-8">

        </div>
      </div>
    </div>
    {{' '}}
  </div>
  <div class="modal-footer">
    <button data-dismiss="modal"
            class="btn btn-app-secondary px-4 m-1 me-auto"
            (click)="modal.dismiss()">
      {{ 'CANCEL' | translate }}
    </button>
    <button cancelbtn
            class="btn btn-app-primary px-4 m-1"
            (click)="confirm()"
            aria-disabled="false">
      {{ 'CONFIRM' | translate }}
      <i class="fa fa-lg fa-spinner fa-pulse align-middle"
         *ngIf="isRequesting"></i>
    </button>
  </div>
</div>
