<div class="container-12">
  <div class="no-gutters">
    <div class="" [ngClass]="{ 'card mb-3': cardMode }">
      <div class="card-header" *ngIf="cardMode">
        {{ 'CARD_INFO' | translate }}
      </div>
      <div class="" [ngClass]="{ 'card-body': cardMode }">
        <div [hidden]="this.editMode" *ngIf="!isTicketAvailable">
          <div class="text-danger pl-1 col-12" *ngIf="!account?.PaymentNumber">
            <strong>
              <h4>{{'AUTO_REPLENISHMENT' | translate}}</h4>
            </strong>
          </div>
          <div>
            <div class="card mb-1">
              <div class="row cc-header mx-0">
                <div class="col-5">
                  <div class="label px-3 py-2">
                    {{ 'CARD_NUMBER_TITLE' | translate }}
                  </div>
                </div>
                <div class="col-4 text-center">
                  <div class="label px-3 py-2">
                    {{ 'MONTH_YEAR' | translate }}
                  </div>
                </div>
                <div class="col-3 text-center">
                  <div class="label px-3 py-2">
                    {{ 'STATUS' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-1" *ngIf="account?.PaymentNumber">
              <div class="row">
                <div class="col-5 align-middle">
                  <div class="label px-2 py-3 fs-6 fw-bolder">
                    <img src="./assets/images/cc-logo-visa.png" alt="visa" class="pe-1"
                      *ngIf="account?.PreAuthCreditCardType == '1'" />
                    <img src="./assets/images/cc-logo-mastercard.png" alt="visa" class="pe-1"
                      *ngIf="account?.PreAuthCreditCardType == '2'" />
                    <img src="./assets/images/cc-logo-maestro.png" alt="visa" class="pe-1"
                      *ngIf="account?.PreAuthCreditCardType == '3'" />
                    <img src="./assets/images/cc-logo-maestro.png" alt="visa" class="pe-1"
                      *ngIf="account?.PreAuthCreditCardType == '4'" />
                    <img src="./assets/images/cc-logo-dinners.png" alt="visa" class="pe-1"
                      *ngIf="account?.PreAuthCreditCardType == '5'" />{{ account?.PaymentNumber }}
                  </div>
                </div>
                <div class="col-3 text-center align-middle">
                  <div class="label p-3 py-4 pt-4 fs-6 fw-bolder">
                    {{ account?.PreAuthCardExpiryMonth | number: '2.0-0' }}/{{
                      account?.PreAuthCardExpiryYear
                    }}
                  </div>
                </div>
                <div class="col-4 text-center">
                  <div class="label p-3 fs-6 default-txt">
                    {{ 'DEFAULT' | translate }}
                    <button type="button" class="btn btn-labeled btn-danger btn-circle ms-1" role="button"
                      aria-label="remove-active-card" (click)="removeActiveCardConfirmation()">
                      <span class="btn-label">
                        <i aria-hidden="true" class="fa fa-times-circle"></i>
                        <i aria-hidden="true" class="fa fa-spinner fa-pulse ml-3"
                          *ngIf="isRemoveActiveCardProcessing"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center d-flex justify-content-center align-self-center" *ngIf="isSavedProcessing">
              <i class="fa fa-3x fa-spinner fa-pulse"></i>
            </div>
            <div class="card mb-1" *ngFor="let card of savedCards">
              <div class="row">
                <div class="col-5 align-middle">
                  <div class="label p-2 fs-6 fw-bolder">
                    <img src="./assets/images/cc-logo-visa.png" alt="visa" class="pe-1" *ngIf="card.CardType == 1" />
                    <img src="./assets/images/cc-logo-mastercard.png" alt="visa" class="pe-1"
                      *ngIf="card.CardType == 2" />
                    <img src="./assets/images/cc-logo-maestro.png" alt="visa" class="pe-1" *ngIf="card.CardType == 3" />
                    <img src="./assets/images/cc-logo-maestro.png" alt="visa" class="pe-1" *ngIf="card.CardType == 4" />
                    <img src="./assets/images/cc-logo-dinners.png" alt="visa" class="pe-1" *ngIf="card.CardType == 5" />
                    {{ getMaskedCardNumber(card?.PaymentNum) }}
                  </div>
                </div>
                <div class="col-3 text-center align-middle">
                  <div class="label p-3 py-4 pt-4 fs-6 fw-bolder">
                    {{ card?.BankCardTokenExpiryDate | date: 'MM/yy' }}
                  </div>
                </div>
                <div class="col-4 text-center">
                  <div class="btn-group mx-auto py-3">
                    <button type="button" class="btn btn-labeled btn-success btn-circle me-1"
                      (click)="setActiveCardConfirmation(card)">
                      <span class="btn-label">
                        <i class="fa fa-check"></i>
                        <i class="fa fa-spinner fa-pulse ml-3" *ngIf="
                            isDefaultProcessing &&
                            selectedCard?.CardId == card?.CardId
                          "></i>
                      </span>
                    </button>
                    <button id="remove-saved-card" class="btn btn-labeled btn-danger btn-circle ms-2" role="button"
                      aria-label="remove-saved-card" (click)="removeSavedCardConfirmation(card)">
                      <span class="btn-label">
                        <i class="fa fa-times-circle"></i>
                        <i class="fa fa-spinner fa-pulse ml-3" *ngIf="
                            isRemoveProcessing &&
                            selectedCard?.CardId == card?.CardId
                          "></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0" *ngIf="cardMode">
            <div class="form-group">
              <button class="btn btn-app-primary topbutton float-left" (click)="preLoad()">
                <span *ngIf="!processing ">
                  {{ 'ADD_CREDIT_CARD' | translate }}
                </span>
                <span *ngIf="processing">
                  <span class="spinner-border spinner-border-sm" role="status"></span>
                  {{ 'PROCESSING' | translate }}
                </span>
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="isTicketAvailable">
          <div id="outerDiv" style="height: 630px">
            <div id="monerisCheckout"></div>
          </div>
        </div>

        <div class="col-12 p-0" [hidden]="!this.editMode" *ngIf="cardMode">
          <div class="row">
            <div class="col-12">
              <div *ngIf="isTicketAvailable">
                <div id="outerDiv" style="height: 630px">
                  <div id="monerisCheckout"></div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="!hideButtons">
              <button type="button" class="btn btn-app-secondary me-2" (click)="hideMonerisMCO()"
                [disabled]="processing">
                {{ 'CANCEL' | translate }}
              </button>

              <button type="button" class="btn btn-app-primary float-end" (click)="preLoad()">
                <span *ngIf="!processing">
                  {{ 'SUBMIT' | translate }}
                </span>

                <span *ngIf="processing">
                  {{ 'PROCESSING' | translate }}
                </span>

                <i class="fa fa-spinner fa-pulse" *ngIf="processing"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- REMOVE ACTIVE CARD MODAL -->
<ng-template #removeCardModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title mb-0">{{'REMOVE-ACTIVE-CARD' | translate}}</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body" [attr.aria-label]="'REMOVE-ACTIVE-CARD-MSG' | translate">
    <p>{{'REMOVE-ACTIVE-CARD-MSG' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button class="m-2 btn btn-app-secondary float-start me-auto" data-dismiss="errorModal" aria-label="Close"
      (click)="modal.close()">
      {{ 'CANCEL' | translate }}
    </button>
    <button cancelbtn class="btn btn-app-primary px-4 m-1 float-end" (click)="removeActiveCard()">
      {{ 'CONFIRM' | translate }}
      <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isRemoveActiveCardProcessing"></i>
    </button>
  </div>
</ng-template>
<!-- REMOVE ACTIVE CARD MODAL -->

<!-- REMOVE SAVED ACTIVE CARD MODAL -->
<ng-template #removeSavedCardModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title mb-0">{{'REMOVE-ACTIVE-CARD' | translate}}</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body" [attr.aria-label]="'REMOVE-ACTIVE-CARD-MSG' | translate">
    <p>{{'REMOVE-ACTIVE-CARD-MSG' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button class="m-2 btn btn-app-secondary float-start me-auto" data-dismiss="errorModal" aria-label="Close"
      (click)="modal.close()">
      {{ 'CANCEL' | translate }}
    </button>
    <button cancelbtn class="btn btn-app-primary px-4 m-1 float-end" (click)="removeCardActive(selectedCard)">
      {{ 'CONFIRM' | translate }}
      <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isRemoveActiveCardProcessing"></i>
    </button>
  </div>
</ng-template>
<!-- REMOVE ACTIVE CARD MODAL -->

<!-- SET ACTIVE CARD MODAL -->
<ng-template #setSavedCardModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title mb-0">
      {{'SET-ACTIVE-CARD' | translate}}
    </h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body" [attr.aria-label]="'SET-ACTIVE-CARD-MSG' | translate">
    <p>{{'SET-ACTIVE-CARD-MSG' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button class="m-2 btn btn-app-secondary float-start me-auto" data-dismiss="errorModal" aria-label="Close"
      (click)="modal.close()">
      {{ 'CANCEL' | translate }}
    </button>
    <button cancelbtn class="btn btn-app-primary px-4 m-1 float-end" (click)="setCardActive(selectedCard)">
      {{ 'CONFIRM' | translate }}
      <i class="fa fa-lg fa-spinner fa-pulse align-middle" *ngIf="isDefaultProcessing"></i>
    </button>
  </div>
</ng-template>
<!-- SET ACTIVE CARD MODAL -->
