export class TokenizedStoredCard {
  Id: number;
  WebUserId: number;
  CardNumber: string;
  Token: string;
  ExpiryDate: Date;
  Alias: string;
  CardTypeId: number;
  IsActive: boolean;
  GeneratedMethod: string;
}
