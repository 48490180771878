import {
  AfterContentInit,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  QueryList,
  Renderer2,
  SimpleChanges,
  HostBinding
} from "@angular/core";
import { Subscription } from "rxjs";


import {
  Event,
  NavigationEnd,
  RouterLink,
  RouterLinkWithHref,
  Router
} from "@angular/router";

@Directive({
    selector: "[ariaCurrent]"
  })
export class AriaCurrent implements OnChanges, OnDestroy, AfterContentInit {
  // TODO(issue/24571): remove '!'.
  @ContentChildren(RouterLink, { descendants: true })
  links!: QueryList<RouterLink>;
  // TODO(issue/24571): remove '!'.
  @ContentChildren(RouterLinkWithHref, { descendants: true })
  linksWithHrefs!: QueryList<RouterLinkWithHref>;

  @HostBinding("attr.aria-current") get ariaCurrent() {
    return this.isActive ? "Page" : undefined;
  }
  private classes: string[] = [];
  private subscription: Subscription;
  public readonly isActive: boolean = false;

  @Input() routerLinkActiveOptions: { exact: boolean } = { exact: false };


  constructor(
    private router: Router,
    private element: ElementRef,
    private renderer: Renderer2,
    @Optional() private link?: RouterLink,
    @Optional() private linkWithHref?: RouterLinkWithHref
  ) {
    this.subscription = router.events.subscribe((s: Event) => {
      if (s instanceof NavigationEnd) {
        this.update();
      }
    });
  }

  ngAfterContentInit(): void {
    this.links.changes.subscribe(_ => this.update());
    this.linksWithHrefs.changes.subscribe(_ => this.update());
    this.update();
  }

  @Input()
  set routerLinkActive(data: string[] | string) {
    const classes = Array.isArray(data) ? data : data.split(" ");
    this.classes = classes.filter(c => !!c);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  private update(): void {
    if (!this.links || !this.linksWithHrefs || !this.router.navigated) return;
    Promise.resolve().then(() => {
      const hasActiveLinks = this.hasActiveLinks();
      if (this.isActive !== hasActiveLinks) {
        (this as any).isActive = hasActiveLinks;
        this.classes.forEach(c => {
          if (hasActiveLinks) {
            this.renderer.addClass(this.element.nativeElement, c);
          } else {
            this.renderer.removeClass(this.element.nativeElement, c);
          }
        });
      }
    });
  }

  private isLinkActive(
    router: Router
  ): (link: RouterLink | RouterLinkWithHref) => boolean {
    return (link: RouterLink | RouterLinkWithHref) =>
      router.isActive(link.urlTree, this.routerLinkActiveOptions.exact);
  }

  private hasActiveLinks(): boolean {
    const isActiveCheckFn = this.isLinkActive(this.router);
    return (
      (this.link && isActiveCheckFn(this.link)) ||
      (this.linkWithHref && isActiveCheckFn(this.linkWithHref)) ||
      this.links.some(isActiveCheckFn) ||
      this.linksWithHrefs.some(isActiveCheckFn)
    );
  }


}
