export class EnableWebUserAccountQuery {
  WebUserLogonId: string;
  WebRequestType: string;
  GeneralInfo: string;
  SecurityQuestion1: string;
  SecurityAnswer1: string;
  SecurityQuestion2: string;
  SecurityAnswer2: string;
  WebUserPassword: string;
  AccountId: string;
  EmailAddress: string;
  PaymentNum: string;
  PaymentCardExpiryYear: string;
  PaymentCardExpiryMonth: string;
  LinkExpiryDateTime: string;
  AccessCode: string;
}
