import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { TransponderRequest } from '../models/transponderRequest';
import { ProxyService } from './proxy.service';

@Injectable({
  providedIn: 'root',
})
export class TransponderService implements OnDestroy {
  private endpointUrl: string;
  private transponderEndpoint = 'api/ReaderData';
  private subs = new SubSink();

  constructor(private http: HttpClient, private proxy: ProxyService) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  getTransponders() {
    return this.http.get(
      this.endpointUrl + this.transponderEndpoint + '/GetTransponders',
      { headers: this.headers }
    );
  }

  requestTransponder(transponderRequest: TransponderRequest) {
    return this.http.post(
      this.endpointUrl + this.transponderEndpoint + '/TransponderRequest',
      transponderRequest,
      { headers: this.headers }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
