<div class="card mb-3 moneris-z-index">
  <div class="card-header">
    {{ 'ONE-TIME-PAYMENT' | translate }}
  </div>
  <div class="card-body p-0">
    <div class="container-12">
      <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="paybycard" *ngIf="componentMode !== mode">
        <ngb-panel *ngIf="cardExist" id="paybycard">
          <ng-template ngbPanelTitle>
            <button class="btn  break-text px-0">
              <i class="fa custom pr-2 "></i>
              <i class="fa fa-credit-card"></i>
              {{'PAYMENT_WITH_SAVED' | translate}} {{account?.PaymentNumber}}
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <form class="m-2" (ngSubmit)="processSavedPayment()" [formGroup]="paymentForm">
              <div class="form-group row">
                <div class="col-12 mb-3">
                  <label for="amount" class="form-label">{{ 'AMOUNT' | translate }}
                  </label>
                  <div class="input-group">
                    <span class="input-group-text">$</span>
                    <input id="amount" name="amount" type="text" mask="separator.2" [allowNegativeNumbers]="false"
                      class="form-control rounded-end" formControlName="amount" placeholder="Enter amount to pay"
                      aria-describedby="invalidAmount"
                      [ngClass]="{'is-invalid' : paymentForm?.controls?.amount.errors?.min}">

                    <div id="invalidAmount" class="invalid-feedback">
                      {{'AMOUNT_SHOULD_BE_GRATHER_THAN_TEXT' | translate}}
                      ${{this.accountProfile?.BalanceReplenishAmount  * -1}}
                    </div>
                  </div>
                </div>

                <div class="col-12 ">
                  <button *ngIf="!processing" class="btn btn-app-primary topbutton" [disabled]="!paymentForm.valid">
                    {{'MAKE_PAMENT' | translate}}
                  </button>
                  <button *ngIf="processing" class="btn btn-app-primary topbutton" disabled>
                    {{'PROCESSING_LOADING' | translate}}
                    <i class="fa fa-spinner fa-pulse"></i>
                  </button>
                </div>
              </div>
            </form>
          </ng-template>
        </ngb-panel>
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <button class="btn  break-text px-0 collapsed">
              <i class="fa fa-credit-card"></i>
              {{'PAY_WITH_NEW_CARD' | translate}}
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row p-3" *ngIf="!isTicketAvailable">
              <form [formGroup]="monerisFormGroup">
                <div class="col-8">
                  <!-- Amount Starts-->
                  <div class="form-group">
                    <label for="amountmoneris" class="form-label">{{ 'AMOUNT' | translate }}
                    </label>
                    <div class="input-group">
                      <span class="input-group-text" for="amountmoneris">
                        <currency-type></currency-type>{{ configService.GetCurrencyCode() }}
                      </span>
                      <input required id="amountmoneris" name="amount" mask="separator.2" [allowNegativeNumbers]="false"
                        formControlName="amount" aria-label="amount"
                        onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46 || event.key === "Backspace"'
                        class="form-control rounded-end" aria-describedby="invalidAmount"
                        placeholder="{{ 'AMOUNT' | translate }}"
                        [ngClass]="{'is-invalid' : monerisFormGroup?.controls?.amount.errors?.min}" />

                      <div id="invalidAmount" class="invalid-feedback">
                        {{'AMOUNT_SHOULD_BE_GRATHER_THAN_TEXT' | translate}}
                        ${{this.accountProfile?.BalanceReplenishAmount  * -1}}
                      </div>
                    </div>

                  </div>
                </div>
                <!-- Amount Ends-->
              </form>

              <div class="mt-2 col-12">
                <div submit class="col-12 pr-5">
                  <button class="btn btn-app-primary" (click)="preLoad()" type="button" InActiveAccount
                    [disabled]="!enableCCSubmit">
                    <span *ngIf="!processing"> {{ 'SUBMIT' | translate }} </span>

                    <span *ngIf="processing">
                      <span class="spinner-border spinner-border-sm" role="status"></span>
                      {{ 'PROCESSING' | translate }}
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div *ngIf="isTicketAvailable">
              <div id="outerDiv" style="height: 703px">
                <div id="monerisCheckout"></div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>


      <div class="row p-3" *ngIf="!isTicketAvailable && componentMode == mode">
        <form [formGroup]="monerisFormGroup">
          <div class="col-8">
            <!-- Amount Starts-->
            <div class="form-group">
              <label for="amountmoneris" class="col-form-label col-form-label-sm">{{ 'AMOUNT' | translate }}
              </label>
              <div class="input-group">
                <span class="input-group-text" for="amountmoneris">
                  <currency-type></currency-type>{{ configService.GetCurrencyCode() }}
                </span>
                <input required id="amountmoneris" name="amount" formControlName="amount" aria-label="amount"
                  onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46 || event.key === "Backspace"'
                  class="form-control" placeholder="{{ 'AMOUNT' | translate }}" aria-describedby="invalidAmount"
                  [ngClass]="{'is-invalid' : monerisFormGroup?.controls?.amount.errors?.min}" />
              </div>
              <div id="invalidAmount" class="invalid-feedback">
                {{'AMOUNT_SHOULD_BE_GRATHER_THAN_TEXT' | translate}}
                ${{this.accountProfile?.BalanceReplenishAmount  * -1}}
              </div>
            </div>
          </div>
          <!-- Amount Ends-->
        </form>

        <div class="mt-2 col-12">
          <div submit class="col-12 pr-5">
            <button class="btn btn-app-primary" (click)="preLoad()" type="button" InActiveAccount
              [disabled]="!enableCCSubmit">
              <span *ngIf="!processing"> {{ 'SUBMIT' | translate }} </span>

              <span *ngIf="processing">
                <span class="spinner-border spinner-border-sm" role="status"></span>
                {{ 'PROCESSING' | translate }}
              </span>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>

  <ng-template #errorModal let-modal let-c="close" let-d="dismiss" class="errorModal">
    <div *ngIf="errorObj">
      <h6 class="
          modal-header
          text-center text-danger
          align-middle
          px-2
          d-flex
          justify-content-center
        ">
        <div class="d-inline align-middle">
          <i class="d-inline align-middle px-2 fa fa-times-circle fa-2x"></i>
          <h3 class="m-0">{{ errorObj.title }}</h3>
        </div>
      </h6>
      <p class="modal-body text-center">
        {{ errorObj.message }}
      </p>
      <div class="modal-footer p-0">
        <button class="m-2 btn btn-danger float-end" data-dismiss="errorModal" aria-label="Close"
          (click)="modal.close()">
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
