<div class="container-12">
  <div class="row>">
    <div class=""
         [ngClass]="{ 'card mb-3': cardMode }">
      <div class="card-header"
           *ngIf="cardMode">
        <span class="h4"> {{ 'CREDIT_CARD' | translate }}</span>
      </div>
      <!-- AKB HHB -->
      <div class=""
           [ngClass]="{ 'card-body': cardMode }">
        <form [formGroup]="creditCardFormGroup">

          <ng-content select="[autoreptxt]"></ng-content>

          <!-- Amount Starts-->
          <div class="form-group row"
               [hidden]="!showAmount">
            <div class="col-6">
              <label for="amount-{{componentId}}"
                     class="form-label col-form-label col-form-label-sm">{{ 'AMOUNT' | translate }}
                <span class="non-ng-invalid-mark">*</span>
              </label>
              <div class="input-group has-validation">
                <span class="input-group-text">
                  <currency-type></currency-type>{{ configurationService.GetCurrencyCode() }}
                </span>
                <input required
                       id="amount-{{componentId}}"
                       name="amount"
                       type="text"
                       formControlName="amount"
                       aria-label="amount"
                       mask="separator.2"
                       [allowNegativeNumbers]="false"
                       thousandSeparator=","
                       class="form-control"
                       placeholder="{{ 'AMOUNT' | translate }}"
                       [ngClass]="{'is-invalid' : creditCardFormGroup?.controls?.amount.errors?.min}"
                       aria-describedby="invalidCCAmount"
                       required />


                <div id="invalidCCAmount"
                     class="invalid-feedback">
                  {{'AMOUNT_SHOULD_BE_GRATHER_THAN_TEXT' | translate}} ${{this.minAmount}}
                </div>

              </div>
            </div>
          </div>

          <!-- Amount Ends-->

          <!-- Credit Card Starts-->

          <!-- Name On the Card -->
          <div class="form-group row pt-1">
            <div class="col-12"> 
              <label for="nameOnCard-{{componentId}}"
                     class="col-form-label col-form-label-sm">{{ 'NAME_ON_CARD' | translate }}
                <span class="non-ng-invalid-mark">*</span>

              </label>

              <div class="input-group clearfix">
                <input style="min-width: 150px"
                       required
                       name="nameOnCard"
                       formControlName="nameOnCard"
                       id="nameOnCard-{{componentId}}"
                       type="text"
                       class="form-control col-12"
                       placeholder="{{ 'NAME_ON_CARD' | translate }}" />

                <div class="text-danger col-12 no-gutters p-0"
                     *ngIf="
                    creditCardForm.nameOnCard.invalid &&
                    (creditCardForm.nameOnCard.dirty ||
                      creditCardForm.nameOnCard.touched)
                  ">

                  <div>
                    <small>{{ 'NAME_ON_CARD_REQUIRED' | translate }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Name On the Card -->

          <!-- Card Number -->
          <div class="form-group row pt-1">
            <div class="col-12">
              <label for="cardNumber-{{componentId}}"
                     class="col-form-label col-form-label-sm">{{ 'CARD_NUMBER_TITLE' | translate }}
                <span class="non-ng-invalid-mark">*</span>

              </label>

              <div class="input-group clearfix">
                <input style="min-width: 150px"
                       required
                       minlength="15"
                       name="cardNumber"
                       formControlName="cardNumber"
                       id="cardNumber-{{componentId}}"
                       id="cardNumber"
                       type="text"
                       class="form-control col-12"
                       placeholder="{{ 'CARD_NUMBER' | translate }}" />
              </div>
              <div class="text-danger col-12 no-gutters p-0"
                   *ngIf="
                  creditCardForm.cardNumber.invalid &&
                  (creditCardForm.cardNumber.dirty ||
                    creditCardForm.cardNumber.touched)
                ">
                <div *ngIf="!creditCardForm.cardNumber?.errors.invalidCreditCard">
                  <small>{{ 'CARD_NUMBER_REQUIRED' | translate }}</small>
                </div>
                <div *ngIf="creditCardForm.cardNumber?.errors.invalidCreditCard">
                  <small>{{
                    'CARD_NUMBER_INVALID_CARD_TYPE' | translate
                    }}</small>
                </div>
                <div *ngIf="creditCardForm.cardNumber?.errors.minlength">
                  <small>{{ 'CARD_NUMBER_15_DIGITS' | translate }}</small>
                </div>
              </div>
            </div>
          </div>
          <!-- Card Number -->

          <!-- Card Expiry -->
          <div class="form-group row pt-1">
            <div class="col-6">
              <label for="cardmonth-{{componentId}}"
                     class="col-form-label col-form-label-sm">{{ 'CARD_EXPIRY_MONTH' | translate }}
                <span class="non-ng-invalid-mark">*</span>

              </label>
              <select required
                      name="cardmonth"
                      appForbiddenName="-1"
                      mustSelectReactive
                      formControlName="cardmonth"
                      id="cardmonth-{{componentId}}"
                      class="form-control mr-2 clearfix form-select">
                <option *ngFor="let month of months; let i = index"
                        [attr.value]="month.id">
                  {{ month.name | translate }}
                </option>
              </select>

              <div class="text-danger col-12 no-gutters p-0"
                   *ngIf="
                  creditCardForm.cardmonth.invalid &&
                  (creditCardForm.cardmonth.dirty ||
                    creditCardForm.cardmonth.touched)
                ">
                <div *ngIf="creditCardForm.cardmonth?.errors.mustSelectReactive">
                  <small> {{ 'EXPIRY_MONTH_REQUIRED' | translate }}</small>
                </div>
                <div *ngIf="creditCardForm.cardmonth?.errors.invalidmonth">
                  <small> {{ 'INVALID_MONTH' | translate }}</small>
                </div>
              </div>
            </div>

            <div class="col-6">
              <label for="cardyear-{{componentId}}"
                     class="col-form-label col-form-label-sm">{{ 'CARD_EXPIRY_YEAR' | translate }}
                <span class="non-ng-invalid-mark">*</span>

              </label>

              <select required
                      name="cardyear"
                      formControlName="cardyear"
                      appForbiddenName="-1"
                      mustSelectReactive
                      id="cardyear-{{componentId}}"
                      class="form-control clearfix form-select">
                <option *ngFor="let year of years; let i = index"
                        value="{{ year.id }}">
                  {{ year.text | translate }}
                </option>
              </select>

              <div class="text-danger no-gutters p-0"
                   *ngIf="
                  creditCardForm.cardyear.invalid &&
                  (creditCardForm.cardyear.dirty ||
                    creditCardForm.cardyear.touched)
                ">
                <div *ngIf="creditCardForm.cardyear?.errors.mustSelectReactive">
                  <small> {{ 'EXPIRY_YEAR_REQUIRED' | translate }}</small>
                </div>
              </div>
            </div>
          </div>
          <!-- Card Expiry -->

          <!-- Card CVV -->
          <div class="form-group row pt-1">
            <div class="col-6">
              <label for="cvvd-{{componentId}}"
                     class="col-form-label col-form-label-sm">{{ 'CARD_CVV' | translate }}
                <span class="non-ng-invalid-mark">*</span>
              </label>

              <input required
                     name="cvv"
                     type="text"
                     formControlName="cardcvv"
                     aria-label="cvv"
                     onPaste="return false"
                     id="cvvd-{{componentId}}"
                     onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"'
                     class="form-control clearfix"
                     placeholder="CVV" />

              <div *ngIf="
                  creditCardForm?.cardcvv.invalid &&
                  (creditCardForm?.cardcvv?.dirty ||
                    creditCardForm?.cardcvv?.touched)
                "
                   class="text-danger no-gutters p-0">
                <div *ngIf="creditCardForm?.cardcvv?.errors.required">
                  <small> {{ 'CVV_NUMBER_REQUIRED' | translate }}</small>
                </div>
              </div>
            </div>
          </div>
          <!-- Card CVV -->

          <!-- Credit Card Ends-->
          <div class="form-group row pt-1">
            <div class="col-xs-12 col-md-12 p-0">
              <div class="text-left">
                <ng-content select="[submit]"></ng-content>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
