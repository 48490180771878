<div>
  <div class="d-flex flex-row mb-3">
    <div class="flex-1 header align-self-center">Bank Account</div>
  </div>

  <img *ngIf="editMode" src="../../../assets/images/canadian_sample_cheque.png" class="d-block mx-auto my-3" aria-label="sample_chequ" />

  <form [formGroup]="bankingInfoForm">

    <div class="form-group mb-3">
      <label for="accountType" class="form-label">
        {{ 'BANK_ACCOUNT_TYPE' | translate }}
      </label>
      <div *ngIf="editMode">
        <select class="form-control form-select mb-1" id="accountType" formControlName="accountType"
          aria-label="accountType" name="accountType" (change)="onAccountTypeChange()">
          <option *ngFor="let option of bankAccountOptions" [ngValue]="option.id">
            {{ option.name | translate }}
          </option>
        </select>
      </div>

      <div *ngIf="!editMode">
        <div class="form-info">{{getBankAccountTypeName(bankingInfoForm.value['accountType'])}}</div>
      </div>
    </div>

    <div *ngIf="bankingInfoForm.value['accountType'] === BankAccountTypeEnum.Business" class="form-group mb-3">
      <label for="companyName" class="form-label">
        {{ 'COMPANY_NAME' | translate }}
      </label>
      <div *ngIf="editMode">
        <input name="companyName" type="text" formControlName="companyName" aria-label="companyName"
          class="form-control col-12" placeholder="{{ 'COMPANY_NAME' | translate }}" />

        <div
          *ngIf="bankingInfoForm.controls.companyName.invalid && (bankingInfoForm.controls.companyName.dirty || bankingInfoForm.controls.companyName.touched || submitted)"
          class="alert alert-danger mt-1">
          <div *ngIf="bankingInfoForm.controls.companyName.errors?.['required']">
            Company name is required.
          </div>
        </div>
      </div>

      <div *ngIf="!editMode">
        <div class="form-info">{{bankingInfoForm.value['companyName']}}</div>
      </div>
    </div>

    <div *ngIf="bankingInfoForm.value['accountType'] === BankAccountTypeEnum.Personal" class="d-flex mb-3" style="gap:10px">
      <div class="form-group flex-1">
        <label for="firstName" class="form-label">
          {{ 'FIRST_NAME' | translate }}
        </label>

        <div *ngIf="editMode">
          <input name="firstName" type="text" formControlName="firstName" aria-label="firstName" class="form-control"
            placeholder="{{ 'FIRST_NAME' | translate }}" />

          <div
            *ngIf="bankingInfoForm.controls.firstName.invalid && (bankingInfoForm.controls.firstName.dirty || bankingInfoForm.controls.firstName.touched || submitted)"
            class="alert alert-danger mt-1">
            <div *ngIf="bankingInfoForm.controls.firstName.errors?.['required']">
              First name is required.
            </div>
          </div>
        </div>

        <div *ngIf="!editMode">
          <div class="form-info">{{bankingInfoForm.value['firstName']}}</div>
        </div>
      </div>

      <div class="form-group flex-1">
        <label for="lastName" class="form-label">
          {{ 'LAST_NAME' | translate }}
        </label>

        <div *ngIf="editMode">
          <input name="lastName" type="text" formControlName="lastName" aria-label="lastName" maxlength="120" class="form-control"
            placeholder="{{ 'LAST_NAME' | translate }}" />

          <div
            *ngIf="bankingInfoForm.controls.lastName.invalid && (bankingInfoForm.controls.lastName.dirty || bankingInfoForm.controls.lastName.touched || submitted)"
            class="alert alert-danger mt-1">
            <div *ngIf="bankingInfoForm.controls.lastName.errors?.['required']">
              Last name is required.
            </div>
          </div>
        </div>

        <div *ngIf="!editMode">
          <div class="form-info" style="word-break: break-all">{{bankingInfoForm.value['lastName']}}</div>
        </div>
      </div>
    </div>

    <div class="form-group mb-3">
      <label for="accountNumber" class="form-label">
        {{ 'BANK_ACCOUNT_NUMBER' | translate }}
      </label>

      <div *ngIf="editMode">
        <input name="accountNumber" type="text" formControlName="accountNumber" aria-label="accountNumber"
          class="form-control col-12" placeholder="{{ 'BANK_ACCOUNT_NUMBER' | translate }}" maxlength="12"/>

        <div
          *ngIf="bankingInfoForm.controls.accountNumber.invalid && (bankingInfoForm.controls.accountNumber.dirty || bankingInfoForm.controls.accountNumber.touched || submitted)"
          class="alert alert-danger mt-1">
          <div *ngIf="bankingInfoForm.controls.accountNumber.errors?.['required']">
            Account number is required.
          </div>
          <!-- <div *ngIf="bankingInfoForm.controls.accountNumber.errors?.['minlength']">
            Account number must be at least 12 characters long.
          </div> -->
          <div *ngIf="bankingInfoForm.controls.accountNumber.errors?.['pattern']">
            Account number must be numeric.
          </div>
          <div *ngIf="bankingInfoForm.controls.accountNumber.errors?.['allZerosNotAllowed']">
            Account number must not consist entirely of zeros.
          </div>          
        </div>
      </div>

      <div *ngIf="!editMode">
        <div class="form-info">{{bankingInfoForm.value['accountNumber']}}</div>
      </div>
    </div>

    <!-- <pre>{{ bankingInfoForm.controls.accountNumber.errors | json }}</pre> -->

    <div class="form-group mb-3">
      <label for="transitNumber" class="form-label">
        {{ 'TRAINSIT_NUMBER' | translate }}
      </label>

      <div *ngIf="editMode">
        <input name="transitNumber" type="text" formControlName="transitNumber" aria-label="transitNumber"
          class="form-control col-12" placeholder="{{ 'TRAINSIT_NUMBER' | translate }}" maxlength="5"/>

        <div
          *ngIf="bankingInfoForm.controls.transitNumber.invalid && (bankingInfoForm.controls.transitNumber.dirty || bankingInfoForm.controls.transitNumber.touched || submitted)"
          class="alert alert-danger mt-1">
          <div *ngIf="bankingInfoForm.controls.transitNumber.errors?.['required']">
            Transit/Branch number is required.
          </div>
          <div *ngIf="bankingInfoForm.controls.transitNumber.errors?.['minlength'] || bankingInfoForm.controls.transitNumber.errors?.['maxlength']">
            Transit/Branch number must be 5 characters long.
          </div>
          <div *ngIf="bankingInfoForm.controls.transitNumber.errors?.['pattern']">
            Transit/Branch number must be numeric.
          </div>
          <div *ngIf="bankingInfoForm.controls.transitNumber.errors?.['allZerosNotAllowed']">
            Transit/Branch number must not consist entirely of zeros.
          </div>          
        </div>
      </div>

      <div *ngIf="!editMode">
        <div class="form-info">{{bankingInfoForm.value['transitNumber']}}</div>
      </div>
    </div>
    
    <div class="form-group mb-3">
      <label for="institutionNumber" class="form-label">
        {{ 'INSTITUTION_NUMBER' | translate }}
      </label>

      <div *ngIf="editMode">
        <input name="institutionNumber" type="text" formControlName="institutionNumber" aria-label="institutionNumber"
          class="form-control col-12" placeholder="{{ 'INSTITUTION_NUMBER' | translate }}" maxlength="3"/>

        <div
          *ngIf="bankingInfoForm.controls.institutionNumber.invalid && (bankingInfoForm.controls.institutionNumber.dirty || bankingInfoForm.controls.institutionNumber.touched || submitted)"
          class="alert alert-danger mt-1">
          <div *ngIf="bankingInfoForm.controls.institutionNumber.errors?.['required']">
            Institution number is required.
          </div>
          <div *ngIf="bankingInfoForm.controls.institutionNumber.errors?.['minlength'] || bankingInfoForm.controls.institutionNumber.errors?.['maxlength']">
            Institution number must be 3 characters long.
          </div>
          <div *ngIf="bankingInfoForm.controls.institutionNumber.errors?.['pattern']">
            Institution number must be numeric.
          </div>
          <div *ngIf="bankingInfoForm.controls.institutionNumber.errors?.['allZerosNotAllowed']">
            Institution number must not consist entirely of zeros.
          </div>
          
        </div>
      </div>

      <div *ngIf="!editMode">
        <div class="form-info">{{bankingInfoForm.value['institutionNumber']}}</div>
      </div>
    </div>

    <div class="form-group mb-3">
      <label for="padDayOfMonth" class="form-label">
        Day of Month
      </label>
      <div *ngIf="editMode">
        <select class="form-control form-select mb-1" id="padDayOfMonth" formControlName="padDayOfMonth"
          aria-label="padDayOfMonth" name="padDayOfMonth">
          <option value="">Select a day</option>
          <option *ngFor="let day of monthDays" [ngValue]="day">
            {{day}}
          </option>
          <option value="0">Last day of the month</option>
        </select>

        <div
          *ngIf="bankingInfoForm.controls.padDayOfMonth.invalid && (bankingInfoForm.controls.padDayOfMonth.dirty || bankingInfoForm.controls.padDayOfMonth.touched || submitted)"
          class="alert alert-danger mt-1">
          <div *ngIf="bankingInfoForm.controls.padDayOfMonth.errors?.['required']">
            Day of the month is required.
          </div>
        </div>



      </div>

      <div *ngIf="!editMode"> 
        <div class="form-info">{{getFormattedDayOfMonth(bankingInfoForm.controls.padDayOfMonth.value)}} day of the month</div>
      </div>
    </div>

    <div class="form-group mb-3" *ngIf="editMode">
        <label class="form-label">Please review and accept the MACPASS Pre-Authorized Debit Agreement</label>
        <div  class="d-flex align-items-center" >
          <button (click)="openAgreementModal()" class="btn-agreement btn-link">
            View MACPASS PAD Agreement
          </button>
          <mat-icon *ngIf="this.agreementConfirmed" style="color: green;">check_circle</mat-icon>
          <!-- <span *ngIf="!this.agreementConfirmed" class="text-secondary">(Please confirm the agreement)</span> -->
        </div>
      
        <div
          *ngIf="!this.agreementConfirmed && submitted"
            class="alert alert-danger mt-1">
            The Agreement must be confirmed
        </div>

    </div>


    <button *ngIf="editMode" (click)="onSubmit()" class="btn btn-app-primary mr-2">
      <i *ngIf="updating" class="fa fa-lg fa-spinner fa-spin"></i> Confirm
    </button>
    <button *ngIf="!editMode" (click)="onDeleteBankInfoConfirm()" 
      class="btn btn-app-primary mr-2"
      aria-label="remove-bank-info-modal">
      <i *ngIf="updating" class="fa fa-lg fa-spinner fa-spin"></i> Remove Bank Info
    </button>
  </form>

</div>

<!-- REMOVE ACTIVE CARD MODAL -->
<ng-template #removeBankInfoModal
             let-modal>
  <div class="modal-header">
    <h4 class="modal-title mb-0">{{ 'REMOVE-BANK-PAYMENT-INFO' | translate }}</h4>
    <button type="button"
            class="btn-close btn-close-white"
            aria-label="Close"
            (click)="modal.close()"></button>
  </div>
  <div class="modal-body"
       [attr.aria-label]="'REMOVE-BANK-PAYMENT-MSG' | translate">
    <p>{{ 'REMOVE-BANK-PAYMENT-MSG' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button class="m-2 btn btn-app-secondary float-start me-auto"
            data-dismiss="errorModal"
            aria-label="Close"
            (click)="modal.close()">
      {{ 'CANCEL' | translate }}
    </button>
    <button cancelbtn
            class="btn btn-app-primary px-4 m-1 float-end"
            (click)="removeActiveBankInfo()">
      {{ 'CONFIRM' | translate }}
      <i class="fa fa-lg fa-spinner fa-pulse align-middle"
         *ngIf="isRemoveActiveBankInfoProcessing"></i>
    </button>
  </div>
</ng-template>
<!-- REMOVE ACTIVE CARD MODAL -->