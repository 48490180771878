/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit, OnDestroy } from '@angular/core'; // AfterViewChecked, ChangeDetectorRef, 
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  ConfigurationService,
  AccountService,
  Account,
  FormValidation,
} from '@ibitoll/toll-core';
import { SubSink } from 'subsink';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit, OnDestroy  { // , AfterViewChecked
  contactInfoFormGroup: FormGroup;

  public account: Account;
  private subs = new SubSink();

  editAccount = true;
  //emailValidRegex = '^(?!.* )(?=.{8,})(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*$';
  emailValidRegex = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z0-9]+$';

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    public configurationService: ConfigurationService,
    //private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  get contactInfo() {
    return this.contactInfoFormGroup.controls;
  }

  ngOnInit(): void {
    var pwdPattern = "^[a-z0-9_-]{8,15}$"; // "^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$";
    this.contactInfoFormGroup = this.formBuilder.group({
      primaryPhone: [
        '',
        [Validators.required, FormValidation.InvalidValidator.bind(this)],
      ],
      primaryPhoneExt: [''],
      altPhone: ['', [FormValidation.InvalidValidator.bind(this)]],
      altPhoneExt: [''],
      mobile: ['', [FormValidation.InvalidValidator.bind(this)]],
      sms: ['',[FormValidation.InvalidValidator.bind(this)]],
      emailMain: [
        '', 
        [Validators.required, Validators.pattern(this.emailValidRegex)]
      ], // , [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")] // , this.emailValidator // Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$") // pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$") // pattern(pwdPattern) // pattern('^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$')]
      altemail: ['', [Validators.pattern(this.emailValidRegex)]],
      fax: ['', [FormValidation.InvalidValidator.bind(this)]],
    });

    this.subs.add(
      this.accountService.activeAccount.subscribe((account: Account) => {
        this.account = account;
        if (account) {
          this.editAccount = false;
          this.updateForm();
        }
      })
    );

    this.contactInfoFormGroup.valueChanges.subscribe((data) => {
      console.log(this.contactInfoFormGroup);
    });
  }

  // ngAfterViewChecked(): void {
  //   this.changeDetectorRef.detectChanges();
  // }

  // emailValidator(control) {
  //   console.log('email=' + control.value);
  //   if (control.value) {
  //     const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
  //     return matches ? null : { 'invalidEmail': true };
  //   } else {
  //     return null;
  //   }
  // }

  updateForm() {
    this.contactInfoFormGroup.controls['primaryPhone'].setValue(
      this.account.PrimaryPhone
    );
    this.contactInfoFormGroup.controls['primaryPhone'].disable();

    this.contactInfoFormGroup.controls['primaryPhoneExt'].setValue(
      this.account.PrimaryPhoneExt
    );
    this.contactInfoFormGroup.controls['primaryPhoneExt'].disable();

    this.contactInfoFormGroup.controls['altPhone'].setValue(
      this.account.AlternatePhone
    );
    this.contactInfoFormGroup.controls['altPhone'].disable();

    this.contactInfoFormGroup.controls['altPhoneExt'].setValue(
      this.account.AlternatePhoneExt
    );
    this.contactInfoFormGroup.controls['altPhoneExt'].disable();

    this.contactInfoFormGroup.controls['mobile'].setValue(
      this.account.MobilePhone
    );
    this.contactInfoFormGroup.controls['mobile'].disable();

    this.contactInfoFormGroup.controls['sms'].setValue(this.account.Sms);
    this.contactInfoFormGroup.controls['sms'].disable();

    this.contactInfoFormGroup.controls['emailMain'].setValue(this.account.Email?.toLowerCase());
    this.contactInfoFormGroup.controls['emailMain'].disable();

    this.contactInfoFormGroup.controls['altemail'].setValue(
      this.account.AltEmail
    );
    this.contactInfoFormGroup.controls['altemail'].disable();

    this.contactInfoFormGroup.controls['fax'].setValue(this.account.Fax);
    this.contactInfoFormGroup.controls['fax'].disable();
  }

  editForm(enableEdit: boolean) {
    this.editAccount = enableEdit;
    this.updateControl('primaryPhone', this.editAccount);
    this.updateControl('primaryPhoneExt', this.editAccount);
    this.updateControl('altPhone', this.editAccount);
    this.updateControl('altPhoneExt', this.editAccount);
    this.updateControl('mobile', this.editAccount);
    this.updateControl('sms', this.editAccount);
    this.updateControl('emailMain', this.editAccount);
    this.updateControl('altemail', this.editAccount);
    this.updateControl('fax', this.editAccount);
  }

  disableForm() {
    this.updateControl('primaryPhone', this.editAccount);
    this.updateControl('primaryPhoneExt', this.editAccount);
    this.updateControl('altPhone', this.editAccount);
    this.updateControl('altPhoneExt', this.editAccount);
    this.updateControl('mobile', this.editAccount);
    this.updateControl('sms', this.editAccount);
    this.updateControl('emailMain', this.editAccount);
    this.updateControl('altemail', this.editAccount);
    this.updateControl('fax', this.editAccount);
  }

  canEditAccount(): boolean {
    return this.editAccount;
  }

  updateControl(name: string, editable: boolean) {
    if (editable) {
      this.contactInfoFormGroup.controls[name].enable();
      this.contactInfoFormGroup.controls[name].updateValueAndValidity();
    } else {
      this.contactInfoFormGroup.controls[name].disable();
      this.contactInfoFormGroup.controls[name].updateValueAndValidity();
    }
  }

  checkTwoEmail(control: FormControl) {
    if (control.value !== '' && control.value) {
      const format = /[ `!#$%^*()[\]{}':"\\|,<>/?~]/;

      const emails = control.value;
      const semail = format.test(emails);

      if (semail) {
        return {
          inValidSeparator: {
            inValidSeparator: true,
          },
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  checkMultiEmail(control: FormControl) {
    if (control.value !== '' && control.value) {
      const email = control.value;
      const tempEmail = email.split(';');
      const emailRex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (tempEmail.length !== 0) {
        let inValidEmailFound = false;
        tempEmail.forEach((element) => {
          if (!emailRex.test(element.trim())) {
            inValidEmailFound = true;
          }
        });

        if (inValidEmailFound) {
          return {
            inValidSecondEmail: {
              inValidSecondEmail: true,
            },
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getControllerStatus(controllerName: string) {
    if (this.contactInfoFormGroup.controls[controllerName].enabled) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
