<div class="container-12">

  <ngb-accordion [closeOthers]="true" #acc="ngbAccordion"
    activeIds="{{account?.PaymentNumber ? 'paybycard': 'paybynewcard'}}" *ngIf="componentMode !== mode">
    <ngb-panel *ngIf="cardExist" id="paybycard">
      <ng-template ngbPanelTitle>
        <button class="btn  break-text px-0">
          <i class="fa custom pr-2 "></i>
          <i class="fa fa-credit-card"></i>
          {{'PAYMENT_USEING_SAVED_CARD' | translate}} {{account?.PaymentNumber}}
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <form class="" (ngSubmit)="processSavedPayment()" [formGroup]="paymentForm">
          <div class="form-group row">
            <div class="col-md-12">
              <label for="amount-{{componentId}}"
                class="form-label col-form-label col-form-label-sm">{{ 'AMOUNT' | translate }}
                <span class="non-ng-invalid-mark">*</span>

              </label>
              <div class="input-group has-validation">
                <span class="input-group-text">
                  <currency-type></currency-type>{{ configService.GetCurrencyCode() }}
                </span>

                <input id="amount-{{componentId}}" name="amount" type="text" mask="separator.2"
                  [allowNegativeNumbers]="false" thousandSeparator="," class="form-control" formControlName="amount"
                  placeholder="Enter amount to pay" aria-describedby="invalidAmount" required
                  [ngClass]="{'is-invalid' : paymentForm?.controls?.amount.errors?.min}">

                <div id="invalidAmount" class="invalid-feedback">
                  {{'AMOUNT_SHOULD_BE_GRATHER_THAN_TEXT' | translate}}
                  ${{this.accountProfile?.BalanceReplenishAmount  * -1}}
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-3">
              <button *ngIf="!processing" class="btn btn-app-primary topbutton"
                [ngClass]="{'disabled': !paymentForm.valid}" [attr.aria-disabled]="!paymentForm.valid" btnDisabled>
                Make Payment
              </button>
              <button *ngIf="processing" class="btn btn-app-primary topbutton" disabled>Processing...
                <i class="fa fa-spinner fa-pulse"></i>
              </button>
            </div>
          </div>
        </form>
      </ng-template>
    </ngb-panel>
    <ngb-panel id="paybynewcard">
      <ng-template ngbPanelTitle>
        Pay with a new card
      </ng-template>
      <ng-template ngbPanelContent>
        <credit-card #ccCard [cardMode]="false" (creditCardStatus)="creditCardStatus($event)" [hideAmount]="hideAmt"
          (onLoaded)="onCreditCardLoaded($event)">


          <button submit type="button" class="btn btn-app-primary submit-btn" (click)="processPayment()" noDblClick
            [ngClass]="{'disabled': !enableCCSubmit}" [attr.aria-disabled]="!enableCCSubmit" btnDisabled
            *ngIf="!hideButtons">
            <span *ngIf="!processing">
              {{ 'SUBMIT' | translate }}
            </span>
            <span *ngIf="processing"> Processing... </span>
            <i class="fa fa-spinner fa-pulse" *ngIf="processing"></i>
          </button>
        </credit-card>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <credit-card #ccCard [cardMode]="cardMode" *ngIf="componentMode == mode" (creditCardStatus)="creditCardStatus($event)"
    [hideAmount]="hideAmt">
    <button submit type="button" class="btn btn-app-primary submit-btn" (click)="processPayment()" noDblClick
      *ngIf="!hideButtons" [ngClass]="{'disabled':!enableCCSubmit}" [attr.aria-disabled]="!enableCCSubmit" btnDisabled>
      <span *ngIf="!processing">
        {{ 'SUBMIT' | translate }}
      </span>
      <span *ngIf="processing"> Processing... </span>
      <i class="fa fa-spinner fa-pulse" *ngIf="processing"></i>
    </button>
  </credit-card>
</div>

<ng-template #errorModal let-modal let-c="close" let-d="dismiss" class="errorModal">
  <div *ngIf="errorObj">
    <h6 class="
        modal-header
        text-center text-danger
        align-middle
        px-2
        d-flex
        justify-content-center
      ">
      <div class="d-flex align-items-center">
        <em class="d-inline align-middle px-2 fa fa-times-circle fa-2x"> </em>
        <h3 class="m-0">{{ errorObj.title }}</h3>
      </div>
    </h6>
    <p class="modal-body text-center">{{ errorObj.message }}</p>
    <div class="modal-footer p-0">
      <button class="m-2 btn btn-danger float-end" data-dismiss="errorModal" aria-label="Close" (click)="modal.close()">
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </div>
</ng-template>
