import { Language } from './language';
import { Account } from "./account";

export class AccountUpdateRequest {
  AccountID: number;
  ContactPersonTitle: string;
  ContactPersonLastName: string;
  ContactPersonFirstName: string;
  PhoneBusinessContact: string;
  PhoneBusinessContactExt: string;
  MailingAddress: string;
  MailingCity: string;
  MailingProvinceID: number;
  MailingCountryID: number;
  MailingPostalCode: string;
  SameBillingAsMailingAddress: boolean;
  BillingAddress: string;
  BillingCity: string;
  BillingProvinceID: number;
  BillingCountryID: number;
  BillingPostalCode: string;
  PrimaryPhone: string;
  PrimaryPhoneExt: string;
  AlternatePhone: string;
  AlternatePhoneExt: string;
  MobilePhone: string;
  Fax: string;
  Sms: string;
  Email: string;
  AltEmail: string;
  TaxAuthorityId: string;
  FatherLastName: string;
  Profession: string;
  StmtDistributionActive: boolean;
  ReceiveMarketingEmails: boolean;
  LowBalanceAmount: number;
  BalanceWarning: number;
  NationalInsNumber: string;
  Language: number;
  MacPassPluse: boolean;
  CheckHash: string;


  static fromAccount(account: Account) {
    const accDto = new AccountUpdateRequest();
    accDto.AccountID = account.AccountID;
    accDto.ContactPersonTitle = account.ContactPersonTitle;
    accDto.ContactPersonLastName = account.ContactPersonLastName;
    accDto.ContactPersonFirstName = account.ContactPersonFirstName;
    accDto.PhoneBusinessContact = account.PhoneBusinessContact;
    accDto.PhoneBusinessContactExt = account.PhoneBusinessContactExt;
    accDto.MailingAddress = account.MailingAddress;
    accDto.MailingCity = account.MailingCity;
    accDto.MailingProvinceID = account.MailingProvinceID;
    accDto.MailingCountryID = account.MailingCountryID;
    accDto.MailingPostalCode = account.MailingPostalCode;
    accDto.SameBillingAsMailingAddress = account.SameBillingAsMailingAddress;
    accDto.BillingAddress = account.BillingAddress;
    accDto.BillingCity = account.BillingCity;
    accDto.BillingProvinceID = account.BillingProvinceID;
    accDto.BillingCountryID = account.BillingCountryID;
    accDto.BillingPostalCode = account.BillingPostalCode;
    accDto.PrimaryPhone = account.PrimaryPhone;
    accDto.PrimaryPhoneExt = account.PrimaryPhoneExt;
    accDto.AlternatePhone = account.AlternatePhone;
    accDto.AlternatePhoneExt = account.AlternatePhoneExt;
    accDto.MobilePhone = account.MobilePhone;
    accDto.Fax = account.Fax;
    accDto.Sms = account.Sms;
    accDto.Email = account.Email;
    accDto.AltEmail = account.AltEmail;
    accDto.TaxAuthorityId = account.TaxAuthorityId;
    accDto.FatherLastName = account.FatherLastName;
    accDto.Profession = account.Profession;
    accDto.StmtDistributionActive = account.StmtDistributionActive;
    accDto.ReceiveMarketingEmails = account.ReceiveMarketingEmails;
    accDto.LowBalanceAmount = account.LowBalanceAmount;
    accDto.BalanceWarning = account.BalanceWarning
    accDto.NationalInsNumber = account.NationalInsNumber
    accDto.Language = account.Language
    accDto.MacPassPluse = account.MacPassPluse

    return accDto;
  }
}
