import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Transponder } from '@ibitoll/toll-core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mdot-unsupend-alert-modal',
  templateUrl: './unsupend-alert-modal.component.html',
  styleUrls: ['./unsupend-alert-modal.component.scss']
})
export class UnsupendAlertModalComponent implements OnInit {

  @Input() transponder: Transponder;

  @Output() onTransponderUnSuspendedOut: EventEmitter<Transponder> = new EventEmitter();

  isRequesting: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onTransponderUnSuspended(transponder) {
    this.isRequesting = true;
    this.onTransponderUnSuspendedOut.emit(this.transponder);
  }


}
