import { Injectable, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ScriptService } from 'ngx-script-loader';
import { switchMap } from 'rxjs/operators';
import { ReCaptchaService } from './re-captcha.service';
import { SubSink } from 'subsink';
import { ProxyService } from './proxy.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MonerisReceiptResponse } from '../models/monerisReceiptResponse';
import { TransactionResponse } from '../models/transactionResponse';
import { CreditCard } from '../models/creditCard';
import { MonerisPreLoadQuery } from '../models/monerisPreLoadQuery';

declare const monerisCheckout: any;

@Injectable({
  providedIn: 'root',
})
export class MonerisService implements OnDestroy {
  private endpointUrl: string;
  private paymentEndpoint = 'api/Moneris';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private subs = new SubSink();

  constructor(
    private scriptService: ScriptService,
    private http: HttpClient,
    private proxy: ProxyService,
    private reCaptchaService: ReCaptchaService
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );

    if (isDevMode()) {
      this.scriptService
        .loadScript('https://gateway.moneris.com/chkt/js/chkt_v1.00.js')
        .subscribe(() => { });
    } else {
      this.scriptService
        .loadScript('https://gateway.moneris.com/chkt/js/chkt_v1.00.js')
        .subscribe(() => { });
    }
  }

  preLoad(
    amount: number,
    accountId: number,
    preLoadType: number,
    language: number
  ): Observable<string> {
    return this.reCaptchaService.refresh('preLoad').pipe(
      switchMap(() => {
        return this.http.post<string>(
          this.endpointUrl + this.paymentEndpoint + `/preLoad`,
          { amount, accountId, preLoadType, language },
          { headers: this.headers }
        );
      })
    );
  }

  tokenPreload(
    accountId: number,
    preLoadType: number,
    language: number
  ): Observable<string> {
    return this.reCaptchaService.refresh('tokenPreload').pipe(
      switchMap(() => {
        return this.http.post<string>(
          this.endpointUrl + this.paymentEndpoint + `/TokenPreload`,
          { accountId, preLoadType, language },
          { headers: this.headers }
        );
      })
    );
  }

  signupTokenPreload(
    monerisPreLoadQuery: MonerisPreLoadQuery
  ): Observable<string> {
    return this.reCaptchaService.refresh('signupTokenPreload').pipe(
      switchMap(() => {
        return this.http.post<string>(
          this.endpointUrl + this.paymentEndpoint + `/signupTokenPreload`,
          JSON.stringify(monerisPreLoadQuery),
          { headers: this.headers }
        );
      })
    );
  }

  receiptRequest(
    ticket: string,
    accountId: number,
    amount: number
  ): Observable<TransactionResponse> {
    return this.reCaptchaService.refresh('preLoad').pipe(
      switchMap(() => {
        return this.http.post<TransactionResponse>(
          this.endpointUrl + this.paymentEndpoint + `/ReceiptRequest`,
          { accountId, ticket, amount },
          { headers: this.headers }
        );
      })
    );
  }

  updatePaymentDetailsWithMoneris(cardUpdate: CreditCard): Observable<boolean> {
    return this.reCaptchaService
      .refresh('updateMonerisPaymentDetails')
      .pipe(
        switchMap(() => {
          return this.http.post<boolean>(
            this.endpointUrl +
            this.paymentEndpoint +
            '/UpdateMonerisPaymentDetails',
            JSON.stringify(cardUpdate),
            { headers: this.headers }
          );
        })
      );
  }

  removeToken(creditCard: CreditCard): Observable<boolean> {
    return this.reCaptchaService.refresh('RemoveToken').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl + this.paymentEndpoint + '/RemoveToken',
          JSON.stringify(creditCard),
          { headers: this.headers }
        );
      })
    );
  }

  processPayment(
    accountID: number,
    amount: number
  ): Observable<TransactionResponse> {
    return this.reCaptchaService.refresh('ProcessPayment').pipe(
      switchMap(() => {
        return this.http.post<TransactionResponse>(
          this.endpointUrl + this.paymentEndpoint + `/ProcessPayment`,
          { accountID, amount },
          { headers: this.headers }
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
