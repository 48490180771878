<div class="card">
  <div class="card-header">
    <h1 class="mb-0 mt-1 align-middle h3 title-text">
      One-time payment
    </h1>
  </div>

  <div class="card-body">
    <br>
    <div class="amount">Amount: ${{ formatMoneyValue(amount) }}</div>
    <br>
    <div id="secureFrameWrapper">
      <iframe 
          id="secureFrame" 
          class="secureFrame" 
          height="450px" 
          width="100%" 
          [src]="url | safe : 'resourceUrl'">
      </iframe>

    </div>
  </div>
</div>
