import { NgModule } from '@angular/core';
import { MapKeyValuePipe } from '../pipes/map.keyvalue.pipe';
import { DateTimeFormatter } from '../pipes/date-time-formatter.pipe';
import { EmployeeFilterPipe } from '../pipes/employee.filter.pipe';
import { NoCommaPipe } from '../pipes/no-comma.pipe';
import { TransactionTypeFilter } from '../pipes/transaction.type.filter.pipe';
import { IbiCurrencyPipe } from '../pipes/ibi-currency.pipe';
import { FareFilterPipe } from '../pipes/fare-filter.pipe';
import { SafePipe } from '../pipes/safehtml.pipe';
import { Safe } from '../pipes/safehtml.pipe';
import { TrimPipe } from '../pipes/trim.pipe';

@NgModule({
  declarations: [
    MapKeyValuePipe,
    DateTimeFormatter,
    EmployeeFilterPipe,
    NoCommaPipe,
    TransactionTypeFilter,
    IbiCurrencyPipe,
    FareFilterPipe,
    SafePipe,
    Safe,
    TrimPipe
  ],
  imports: [],
  exports: [
    MapKeyValuePipe,
    DateTimeFormatter,
    EmployeeFilterPipe,
    NoCommaPipe,
    TransactionTypeFilter,
    IbiCurrencyPipe,
    FareFilterPipe,
    SafePipe,
    Safe,
    TrimPipe
  ],
})
export class PipeModule {}
