<div class="modal fade" id="suspendTransponderModal" tabindex="-1" role="dialog" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content modal-warning">
      <div class="modal-header">
        <div class="modal-title mb-0 h3" id="exampleModalLabel">
          {{ 'TRANSPONDER_REQUEST_SUSPEND_NO' | translate
          }}{{ transponder?.TransponderText }}
        </div>
      </div>
      <div class="modal-body">
        <span>{{ 'TRANSPONDER_REQUEST_SUSPEND' | translate }}</span>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" data-dismiss="modal">
          {{ 'CANCEL' | translate }}
        </button>
        <button class="btn btn-warning" (click)="confirmSuspension()">
          {{ 'CONFIRM' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
