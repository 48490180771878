<h2 mat-dialog-title>
  <i
    class="fa"
    [ngClass]="isApproved() ? 'fa-check-circle-o' : 'fa-exclamation-circle'"
  ></i>
  {{ (isApproved() ? 'TR_STATUS_A' : 'TR_STATUS_D') | translate }}
  {{ 'INTERNET_TRANSACTION' | translate }}
</h2>

<div class="mat-dialog-content">
  <div id="paymentRecieptModalBody">
    <div class="col border border-secondary">
      <div class="ps-2 my-1">
        <span>
          <b>{{ 'AMOUNT' | translate }}: </b>
        </span>
        <span
          >{{ parseAmount(transactionResponse?.ChargeTotal) | currency }}
        </span>
      </div>
      <div class="ps-2 my-1">
        <!-- *ngIf="!isAO" -->
        <span>
          <b>{{ 'CARD_NUMBER' | translate }}: </b>
        </span>
        <span>{{ transactionResponse.PaymentNumber }} </span>
      </div>

      <div class="ps-2 my-1">
        <span>
          <b>{{ 'AUTH_CODE' | translate }}: </b>
        </span>
        <span>{{
          transactionResponse?.BankApprovalCode
            ? transactionResponse?.BankApprovalCode
            : ('NOT_APPLICABLE' | translate)
        }}</span>
      </div>
      <div class="ps-2 my-1">
        <span>
          <b>{{ 'REFERENCE_NO' | translate }}: </b>
        </span>
        <span>{{ transactionResponse?.TraceNumber }}</span>
      </div>

      <div class="ps-2 my-1">
        <span>
          <b>{{ 'INVOICE_NO' | translate }}: </b>
        </span>
        <span>{{ transactionResponse?.ResponseOrderID }}</span>
      </div>
    </div>

    <div class="text-center">
      <span
        >{{ 'PROCESSED_ON' | translate }}:
        {{
          transactionResponse?.UDateTime
            ? (transactionResponse?.UDateTime | dateTimeFormatter)
            : (currentDate | dateTimeFormatter)
        }}
      </span>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="accent" (click)="downloadReceipt()">
    <mat-icon>cloud_download</mat-icon>
    {{ 'DOWNLOAD' | translate }}
  </button>

  <button
    mat-raised-button
    mat-dialog-close
    color="primary"
    (click)="afterModalClose()"
  >
    {{ 'OK' | translate }}
  </button>
</div>
