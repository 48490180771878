/* eslint-disable @angular-eslint/component-selector */
import { cloneDeep } from 'lodash-es';
import {
  Component,
  Input,
  OnDestroy,
  Inject,
  OnInit,
  ChangeDetectorRef,
  //ViewChild,
} from '@angular/core';
import {
  ConfigurationService,
  AccountService,
  UserService,
  AlertService,
  DatabaseMapService,
  Transponder,
  SuspendTagQuery,
  Account,
  AccountProfileConfig,
  NameIdNameConfig,
  TransponderFee,
  TollCategoryInfo,
} from '@ibitoll/toll-core';
// import {
//   AlertController,
//   IonItemSliding,
// } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';
import * as FileSaver from 'file-saver';
import { switchMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { OrderConfirmationComponent } from './modals/order-confirmation/order-confirmation.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EditTransponderModalComponent } from './modals/edit-transponder-modal/edit-transponder-modal.component';
import { UnsupendAlertModalComponent } from './modals/unsupend-alert-modal/unsupend-alert-modal.component';
import { RemoveTransponderModalComponent } from './modals/remove-transponder-modal/remove-transponder-modal.component';
import { ColInfo, utils, WorkSheet, write } from 'xlsx';
import { range } from 'rxjs';
import { SortByPipe } from 'ngp-sort-pipe';

@Component({
  selector: 'transponder',
  templateUrl: './transponder.component.html',
  styleUrls: ['./transponder.component.scss'],
})
export class TransponderComponent
  implements OnInit, OnDestroy {
  @Input()
  isSubComponent = true;

  // @ViewChild(IonItemSliding, { static: false })
  // slidingItem: IonItemSliding;

  ownTansponderFormValid = false;
  isRequesting = false;
  isEditTransponder = false;
  isUpdateProcessing = false;

  private subs = new SubSink();
  canRequest = false;
  currentTime = new Date()

  public AccountProfileType: AccountProfileConfig;
  public tagRemovalStaus: NameIdNameConfig[];
  public pricePerTag: TransponderFee;
  inSufficientBlukTransponderFunds = false;
  inSufficientNewTransponderFunds = false;
  canBluckRequest = false;
  pricePerBlukTag: TransponderFee;
  blukTagquantity = 0;
  currentEditBtnId: string;

  account: Account = new Account();
  vehicleCategories: TollCategoryInfo[];
  isLoading = true;
  enableTableRead = false;
  transponder: Transponder = null;
  transponders: Transponder[];
  pendingTransponders: Transponder[];
  isEmployee: boolean;
  bsModalRef: NgbModalRef;
  editTransponderModal: NgbModalRef;
  unSupendAlertModal: NgbModalRef;
  removeTransponderModal: NgbModalRef;
  transponderFeesOg: TransponderFee[] = [];
  transponderFees: TransponderFee[] = [];
  editLoading = false;
  selectedEditTransponder: string;
  loadingTag = false;
  isDocLoading = false;
  hasInactiveTags = false;

  

  constructor(
    public configurationService: ConfigurationService,
    public accountService: AccountService,
    public userService: UserService,
    public alertService: AlertService,
    //public alertController: AlertController,
    public databaseMapService: DatabaseMapService,
    @Inject(DOCUMENT) private _document,
    public translateService: TranslateService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private sortByPipe: SortByPipe
  ) {



  }

  ngOnInit() {
    this.configurationService
      .getTollCategories()
      .subscribe(
        (vehicleCategories) => (this.vehicleCategories = vehicleCategories)
      );

    this.subs.add(this.accountService
      .activeAccount
      .pipe(
        switchMap((account: Account) => {
          this.account = account;
          return this.configurationService.getTransponderFees()
        }),
        switchMap((transponderFee: TransponderFee[]) => {
          this.transponderFeesOg = cloneDeep(transponderFee);
          this.transponderFees = cloneDeep(transponderFee).filter(o => o.CurrenctType == this.account.CurrencyType && o.AccountProfileId === this.account.AccountProfileID);
          return this.configurationService.getAccountProfiles();
        })
      )
      .subscribe((AccountProfileConfig: AccountProfileConfig[]) => {
        this.AccountProfileType = AccountProfileConfig.find(
          (o) => o.AccountProfileID === this.account.AccountProfileID
        );

        if (this.account) {
          this.isEmployee = this.account.AccountSubType === this.databaseMapService.eAccountSubTypeMap.Employee;
          this.getTransponderVehicleInfo(this.account.AccountID);
        }
      }));

  }

  getTransponderVehicleInfo(accountID: number, forceRefresh: boolean = false) {
    console.log('getTransponderVehicleInfo: ' + accountID);
    this.isLoading = true;

    this.accountService
      .getTransponderVehicleInfo(accountID, forceRefresh)
      .subscribe(
        (resp) => {
          this.transponders = resp;
          this.updateActiveTransponderCount();
          this.accountService.getRequestedVehicleInfo(accountID).subscribe(
            (sresp) => {
              this.pendingTransponders = sresp;
              this.isLoading = false;
              setTimeout(() => {
                this.enableTableRead = true
              }, 500);
            },
            () => {
              this.enableTableRead = true
              this.isLoading = false;
            }
          );
        },
        () => {
          this.enableTableRead = true
          this.isLoading = false;
        }
      );
  }


  getCategoryName(categoryId: number): string {
    let categoryName = '-';

    if (this.vehicleCategories != null) {
      const categories = this.vehicleCategories.filter(
        (x) => x.Id === categoryId
      );

      if (categories.length > 0) {
        categoryName = categories[0].GroupName;
      }
    }

    return categoryName;
  }



  isNewTransponderRequest() {
    if (this.canRequest && !this.inSufficientNewTransponderFunds) {
      return false;
    } else {
      return true;
    }
  }

  isBulkTransponderRequest() {
    if (this.canBluckRequest && !this.inSufficientBlukTransponderFunds) {
      return false;
    } else {
      return true;
    }
  }

  //#region unSupendTransponder
  unSupendTransponder(transponder: Transponder) {
    this.alertService.clear();
    this.transponder = transponder;
    this.unSupendAlertModal = this.modalService.open(UnsupendAlertModalComponent, { animation: true });
    this.unSupendAlertModal.componentInstance.transponder = this.transponder;
    this.unSupendAlertModal.componentInstance.onTransponderUnSuspendedOut.subscribe(() => {
      this.onTransponderUnSuspended(transponder)
    })

  }

  onTransponderUnSuspended(transponder: Transponder) {
    this.isRequesting = true;
    const accId = this.userService.getCurrentUser().AccountId; // this.accountService.currentAccount.AccountID

    const suspendTagQuery: SuspendTagQuery = new SuspendTagQuery();
    suspendTagQuery.AccountId = accId;
    suspendTagQuery.ManufacturerId = transponder.ManufacturerId;
    suspendTagQuery.TransponderId = transponder.TransponderId;

    this.accountService.updateUnSuspendTag(suspendTagQuery).subscribe(
      () => {
        this.getTransponderVehicleInfoOnly(accId, true).subscribe(
          (resp) => {
            this.transponders = resp;
            this.updateActiveTransponderCount();
            this.unSupendAlertModal.dismiss();
            this.isRequesting = false;
            this.alertService.success(this.translateService.instant('TRANSPONDER_UNSUSPENDED_MSG'), false);
          },
          () => {
            this.enableTableRead = true
          }
        );
        this.isRequesting = false;

      },
      () => {
        this.unSupendAlertModal.dismiss();
        this.isRequesting = false;
      }
    );
  }

  // public OnBulkUnSuspended() { // activate
  //   this.isRequesting = true;
  //   const accId = this.userService.getCurrentUser().AccountId; // this.accountService.currentAccount.AccountID

  //   var suspendTagQuery: SuspendTagQuery[] = this.transponders.map((t) => { 
  //     return {AccountId:accId, ManufacturerId:t.ManufacturerId, TransponderId:t.TransponderId, TransponderStatus:t.RestrictionStatus }
  //   });

  //   this.accountService.updateUnSuspendTagList(suspendTagQuery).subscribe(
  //     () => {
  //       this.getTransponderVehicleInfoOnly(accId, true).subscribe(
  //         (resp) => {
  //           this.transponders = resp;
  //           this.unSupendAlertModal.dismiss();
  //           this.isRequesting = false;
  //           this.alertService.success(this.translateService.instant('TRANSPONDER_UNSUSPENDED_MSG'), false);
  //         },
  //         () => {
  //           this.enableTableRead = true
  //         }
  //       );
  //       this.isRequesting = false;

  //     },
  //     () => {
  //       this.unSupendAlertModal.dismiss();
  //       this.isRequesting = false;
  //     }
  //   );
  // }

  getTransponderVehicleInfoOnly(accountID: number, forceRefresh: boolean = false) {
    return this.accountService
      .getTransponderVehicleInfo(accountID, forceRefresh)

  }

  //#endregion unSupendTransponder

  //#region removeTransponder

  removeTransponder(transponder: Transponder) {
    this.alertService.clear();
    this.transponder = transponder;
    this.removeTransponderModal = this.modalService.open(RemoveTransponderModalComponent, { animation: true });
    this.removeTransponderModal.componentInstance.transponder = this.transponder;
    this.removeTransponderModal.componentInstance.onTransponderRemoveOutput.subscribe(() => {
      this.onTransponderRemove(transponder)
    })
  }

  onTransponderRemove(transponder: Transponder) {
    this.isRequesting = true;
    const accId = this.userService.getCurrentUser().AccountId; // this.accountService.currentAccount.AccountID

    const suspendTagQuery: SuspendTagQuery = {
      AccountId: accId,
      ManufacturerId: transponder.ManufacturerId,
      TransponderId: transponder.TransponderId,
      TransponderStatus: this.transponder.VehicleStatus,
      TagList: 'test2',

    };

    this.accountService.removeTag(suspendTagQuery).subscribe(
      () => {
        this.accountService.loadActiveAccount(this.account.AccountID).subscribe(() => {
          this.getTransponderVehicleInfoOnly(this.account.AccountID, true).subscribe((resp) => {
            this.transponders = resp
            this.transponder = new Transponder();
            this.removeTransponderModal.dismiss();
            this.isRequesting = false;
            this.alertService.success(this.translateService.instant('TRANSPONDER_REMOVE_MSG'), false);
          });
        })
      },
      () => {
        this.removeTransponderModal.dismiss();
        this.isRequesting = false;
        // this.alertService.error(this.translateService.instant('TRANSPONDER_REMOVE_FAILED_MSG'), false);
      }
    );
  }

  //#endregion removeTransponder


  checkPlate(transponder: Transponder): string {
    const pattern: RegExp = /^[NWnw]\d+-\d+$/;
    if (
      transponder.PlateNum &&
      transponder.PlateNum.includes(this.account.AccountID.toString())
    ) {
      return '-';
    } else if (!transponder.PlateNum) {
      return '-';
    } else if (transponder.PlateNum.includes('BULK') || transponder.PlateNum.includes('NEW') || transponder.PlateNum.includes('OWN')) {
      return '-';
    } else if (pattern.test(transponder.PlateNum)) {
      return '-';
    } else if (transponder.PlateNumAutoGenerated) {
      return '-';
    } else {
      return transponder.PlateNum;
    }
  }

  GetTranspondeRestrictionStatus(transponder: Transponder): string {
    var status = 'Active';
    if (transponder?.RestrictionStatus !== 2) {
      if (transponder?.RestrictionStatusUserId === 0) {
        status = 'Inactive';
      } else {
        status = 'Suspended';
      }
    }
    return status;
  }


  closeEditModal() {
    this.editTransponderModal.dismiss();
    this.isEditTransponder = false;
    this.transponder = null;
  }

  openModal() {
    //console.log('Requesting transponder modal this.account.BalanceStatus=' + this.account?.BalanceStatus);
    console.log('openModal: pendingTags='+JSON.stringify(this.pendingTransponders));
    this.alertService.clear();

    // if (this.account?.BalanceStatus == 3) { // account in violation
    //   this.alertService.error(this.translateService.instant('ACCOUNT_IS_IN_VIOLATION'), false);
    //   // this.presentSuspendAlertConfirm().then(
    //   //   (accpect) => {},
    //   //   (rej) => {}
    //   // );
    // } else {

      this.loadingTag = true
      this.alertService.clear();
      this.configurationService.getTransponderFees().pipe(switchMap(transponderFee => {
        this.transponderFeesOg = cloneDeep(transponderFee);
        this.transponderFees = cloneDeep(transponderFee).filter(o => o.CurrenctType == this.account.CurrencyType && o.AccountProfileId === this.account.AccountProfileID);
        return this.configurationService.GetTollCategoryInfoByAccountProfileID(this.account.AccountProfileID.toString())
      })).subscribe(() => {
        this.bsModalRef = this.modalService.open(OrderConfirmationComponent, { size: 'lg', animation: true });
        this.bsModalRef.componentInstance.account = this.account;
        this.bsModalRef.componentInstance.AccountProfileType = this.AccountProfileType;
        this.bsModalRef.componentInstance.transponderFees = this.transponderFees;
        this.bsModalRef.componentInstance.transponder = this.transponder;
        this.bsModalRef.componentInstance.workMode = 1; // add transponder
        this.bsModalRef.componentInstance.requestedTransponders = 
          this.pendingTransponders;

        this.subs.add(this.bsModalRef.componentInstance.pendingTranspondersOutput.subscribe((pendingTransponders: Transponder[]) => {
          console.log('ModalOrdConf closed. Len=' + pendingTransponders.length + '; pending: ' + JSON.stringify(pendingTransponders));
          if (pendingTransponders && pendingTransponders[0].TransponderId) { // auto-approved request
            this.refreshTransponders(); // to load auto-approved
          } else {
            this.pendingTransponders = pendingTransponders;
          }
        }));

        this.loadingTag = false;
    })
    //}
  }

  openModalForActivate() {
    console.log('Requesting transponder modal this.account.BalanceStatus=' + this.account?.BalanceStatus);
    this.alertService.clear();

      this.loadingTag = true
      this.alertService.clear();
      this.configurationService.getTransponderFees().pipe(switchMap(transponderFee => {
        this.transponderFeesOg = cloneDeep(transponderFee);
        this.transponderFees = cloneDeep(transponderFee).filter(o => o.CurrenctType == this.account.CurrencyType && o.AccountProfileId === this.account.AccountProfileID);
        return this.configurationService.GetTollCategoryInfoByAccountProfileID(this.account.AccountProfileID.toString())
      })).subscribe(() => {
        this.bsModalRef = this.modalService.open(OrderConfirmationComponent, { size: 'lg', animation: true });
        this.bsModalRef.componentInstance.account = this.account;
        this.bsModalRef.componentInstance.AccountProfileType = this.AccountProfileType;
        this.bsModalRef.componentInstance.transponderFees = this.transponderFees;
        this.bsModalRef.componentInstance.transponder = this.transponder;
        this.bsModalRef.componentInstance.workMode = 2; // bulk activate transponders
        this.bsModalRef.componentInstance.transponderList = this.transponders.filter(t => (t.RestrictionStatus === 1) && (t.RestrictionStatusUserId === 0) );
        this.bsModalRef.componentInstance.requestedTransponders = 
          this.pendingTransponders;

        this.subs.add(this.bsModalRef.componentInstance.pendingTranspondersOutput.subscribe((pendingTransponders: Transponder[]) => {
          console.log('Modal4activate closed');
          this.pendingTransponders = pendingTransponders;
          this.refreshTransponders();
        }));

        this.loadingTag = false;
    })
  }

  updateActiveTransponderCount() {
    var inactiveCount = this.transponders.filter(t => (t.RestrictionStatus === 1) && (t.RestrictionStatusUserId === 0)).length;
    console.log('Inactive count=' + inactiveCount);
    this.hasInactiveTags = (inactiveCount > 0);
  }

  // async presentSuspendAlertConfirm() {
  //   const alert = await this.alertController.create({
  //     cssClass: 'confirm-custom-class',
  //     header: `${this.translateService.instant(
  //       'TRANSPONDER_SUSPEND_CONFIRM_HEADER'
  //     )} `,
  //     message: this.translateService.instant('TRANSPONDER_SUSPEND_CONFIRM_MSG'),
  //     buttons: [
  //       {
  //         text: this.translateService.instant('CANCEL'),
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         handler: (blah) => {
  //           this.closeSlide();
  //         },
  //       },
  //       // {
  //       //   text: this.translateService.instant('CONFIRM'),
  //       //   handler: () => {
  //       //     this.updateSuspendTag(this.suspendTagQuery);
  //       //   },
  //       // },
  //     ],
  //   });

  //   await alert.present();
  // }

  // closeSlide() {
  //   this.slidingItem?.closeOpened();
  // }


  private refreshTransponders() {
    console.log('refreshTransponders3');
    this.accountService.resetCachedRequestedVehicleInfo();

    this.getTransponderVehicleInfo(this.account.AccountID, true);
  }


  /////////EDIT TRANSPONDER/////////

  openEditModal(transponder: Transponder) {
    this.editLoading = true;
    this.selectedEditTransponder = transponder.TransponderDisplayId

    this.configurationService.GetTollCategoryInfoByAccountProfileID(this.account.AccountProfileID.toString()).subscribe(() => {
      this.alertService.clear();
      this.transponder = transponder;
      this.isEditTransponder = true;
      this.editTransponderModal = this.modalService.open(EditTransponderModalComponent, { size: 'lg', animation: true });
      this.editTransponderModal.componentInstance.isEditTransponder = this.isEditTransponder;
      this.editTransponderModal.componentInstance.transponder = this.transponder;
      this.editTransponderModal.componentInstance.account = this.account;
      this.editTransponderModal.componentInstance.requestedTransponders = 
          this.pendingTransponders;
      this.editLoading = false;

      this.subs.add(this.editTransponderModal.componentInstance.refreshCloseOutput.subscribe(() => {
        this.refreshTranspondersCloseEdit();
      }));

      this.subs.add(this.editTransponderModal.componentInstance.onTransponderSuspendedOutput.subscribe((transponder: Transponder) => {
        this.onTransponderSuspended(transponder);
      }));

      this.subs.add(this.editTransponderModal.componentInstance.closeEditModalOutput.subscribe(() => {
        this.editLoading = true;
        this.selectedEditTransponder = "";
      }));
    })



  }

  refreshTranspondersCloseEdit() {
    console.log('refreshTranspondersCloseEdit');
    this.refreshTransponders();

    this.accountService.resetCachedRequestedVehicleInfo();
    this.getTransponderActiveTransponderOnly(this.account.AccountID, true);
  }

  getTransponderActiveTransponderOnly(accountID: number, forceRefresh: boolean = false) {
    this.isLoading = true;

    this.accountService
      .getTransponderVehicleInfo(accountID, forceRefresh)
      .subscribe(
        (resp) => {
          this.transponders = resp;
          this.enableTableRead = true
          this.isLoading = false;
          this.alertService.success(this.translateService.instant('TRANSPONDER_UPDATED_SUCCESSFUL_MSG'), false);
          this.editTransponderModal.dismiss();
        },
        () => {
          this.editTransponderModal.dismiss();
          this.enableTableRead = true
          this.isLoading = false;
        }
      );
  }


  /////////EDIT TRANSPONDER/////////


  /////////SUSPEND TRANSPONDER/////////


  onTransponderSuspended(transponder: Transponder) {
    this.isRequesting = true;
    const accId = this.userService.getCurrentUser().AccountId; // this.accountService.currentAccount.AccountID

    const suspendTagQuery: SuspendTagQuery = new SuspendTagQuery();
    suspendTagQuery.AccountId = accId;
    suspendTagQuery.ManufacturerId = transponder.ManufacturerId;
    suspendTagQuery.TransponderId = transponder.TransponderId;

    this.accountService.updateSuspendTag(suspendTagQuery).subscribe(
      () => {

        this.getTransponderVehicleInfoOnly(accId, true).subscribe(
          (resp) => {
            this.transponders = resp;
            this.updateActiveTransponderCount();
            this.isRequesting = false;
            this.editTransponderModal.dismiss();
            this.alertService.success(this.translateService.instant('TRANSPONDER_SUSPENDED_MSG'), false);
          },
          () => {
            this.enableTableRead = true
          }
        );


      },
      () => {
        this.isRequesting = false;
        this.editTransponderModal.dismiss();
        // this.alertService.error(
        //   this.translateService.instant('TRANSPONDER_SUSPENDED_FAILED_MSG'),
        //   false
        // );
      }
    );
  }

  /////////SUSPEND TRANSPONDER/////////




  validationStatus(status) {
    this.canRequest = status;
  }

  downloadExcelF() {
    this.isDocLoading = true;
    this.alertService.clear();
    this.accountService.downloadTagList(this.account.AccountID).subscribe(
      (resp) => {
        const fileName = `${this.account.AccountID}-TransponerList`;

        const blob = new Blob([resp], {
          type: 'application/vnd.ms-excel',
        });
        FileSaver.saveAs(blob, fileName + '.xls');
        this.isDocLoading = false;

      },
      (error) => {
        this.isDocLoading = false;
        console.error('error in getStatement: ' + error);
      }
    );
  }

  downloadTags() {

    if (this.transponders.length == 0){
      this.alertService.error(this.translateService.instant('NO_TAGS_MSG'), false);
      return
    }

    this.alertService.clear();
    let orderedTP = this.sortByPipe.transform(this.transponders, 'asc','TransponderDisplayId')
    const datas = orderedTP.map( row => ({
        Transponder: row.TransponderDisplayId,
        Make: row.VehicleMake,
        Model: row.VehicleModel,
        Colour: row.VehicleColor,
        Vehicle_Plate: this.checkPlate(row),
        Vehicle_Group: this.getCategoryName(row.TollCategoryId),
        Status: this.GetTranspondeRestrictionStatus(row)
    }))
      
    const worksheet = utils.json_to_sheet(datas);
    this.autofitColumns(worksheet);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const excelBuffer: any  = write(workbook, { bookType: 'xls', type: 'buffer' });

    const fileName = `${this.account.AccountID}-TransponderList`;

    const data: Blob = new Blob([excelBuffer], {
      type: 'application/vnd.ms-excel'
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + '.xls');



    // // previous approach // //


    // this.isDocLoading = true;
    // this.alertService.clear();
    // const user = this.userService.getCurrentUser();
    // this.accountService.getTagList(this.account.AccountID).subscribe(
    //   (resp) => {

    //     if (resp.length > 0) {


    //       const datas = resp.map( row => ({
    //         WEB_USER: user.UserId,
    //         USER_STATUS: "",
    //         WEB_USER_ACCOUNT_MAPPING_STATUS: "",
    //         VEHICLE_ID: row.VehicleId,
    //         PLATE_NUM: row.PlateNum,
    //         TOLL_CATEGORY_NAME: row.TollCategoryName,
    //         VEHICLE_STATUS: row.VehicleStatus,
    //         MANUFACTURER_ID: row.ManufacturerId,
    //         TRANSPONDER_ID: row.TransponderId,
    //         TRIP_STATUS: row.TripStatus,
    //         RESTRICTION_STATUS: row.RestrictionStatus,
    //         TRANSPONDER_PROFILE_ID: row.TransponderProfileID,
    //         TRANSPONDER_TYPE_ID: row.TransponderTypeId,
    //         TRANSPONDER: row.Transponder,
    //         VEHICLE_ACTIVE: row.VehicleStatus,
    //         PLATE_HOLDER_LAST_NAME: row.PlateHolderLastName,
    //         PLATE_HOLDER_FIRST_NAME: row.VehicleMake,
    //         VEHICLE_MAKE: row.VehicleMake,
    //         VEHICLE_MODEL: row.VehicleModel,
    //         VEHICLE_YEAR: row.VehicleYear,
    //         PLATE_PROVINCE_ID: row.PlateProvinceId,
    //         FULFILLED: row.FulFilled,
    //         FULFILLMENT_LDATETIME: row.FulFilledLDatetime,
    //         TRANSPONDER_DISPLAY_ID: row.TransponderDisplayId,
    //         PLATE_NUM_AUTO_GENERATED: "",
    //       }))

    //       const worksheet = utils.json_to_sheet(datas);
    //       this.autofitColumns(worksheet);
    //       const workbook = utils.book_new();
    //       utils.book_append_sheet(workbook, worksheet);

    //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //       const excelBuffer: any  = write(workbook, { bookType: 'xls', type: 'buffer' });

    //       const fileName = `${this.account.AccountID}-TransponerList`;

    //       const data: Blob = new Blob([excelBuffer], {
    //         type: 'application/vnd.ms-excel'
    //       });
    //       FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + '.xls');

    //     } else {
    //       this.alertService.error(this.translateService.instant('NO_TAGS_MSG'), false);

    //     }


    //     // let blob = new Blob([resp], {
    //     //   type: 'application/vnd.ms-excel',
    //     // });
    //     // FileSaver.saveAs(blob, fileName + '.xls');

    //     this.isDocLoading = false;

    //   },
    //   () => {
    //     this.isDocLoading = false;
    //   }
    // );
  }

 autofitColumns(worksheet: WorkSheet) {
  const objectMaxLength: ColInfo[] = [];
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [startLetter, endLetter] = worksheet['!ref']?.replace(/\d/, '').split(':');
    const ranges = range(startLetter.charCodeAt(0), endLetter.charCodeAt(0) + 1);
    ranges.forEach((c) => {
      const cell = String.fromCharCode(c);
      const cellLength = worksheet[`${cell}1`].v.length + 1;
      objectMaxLength.push({ width: cellLength + 3 });
    });
    worksheet['!cols'] = objectMaxLength;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}


