import { ScriptLoaderModule } from 'ngx-script-loader';
import { MobileLocalstorageService } from './providers/mobile.localstorage.service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomFormsModule } from 'ngx-custom-validators';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderModule } from 'ngx-order-pipe';
import { CommonModule, DatePipe, CurrencyPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { AlertComponent } from './components/alert/alert.component';
import { CheckUsernameDirective } from './directive/check-username.directive';
import { CustomMinMaxDirective } from './directive/custom-min-max.directive';
import { InActiveAccountDirective } from './directive/inactive-account.directive';
import { MustSelectDirective } from './directive/must-select.directive';
import { NoDblClickDirective } from './directive/nodblclick.directive';
import { AuthGuard } from './guards/auth.guard';
import { InActiveAccountGuard } from './guards/inactiveaccount.guard';
import { PipeModule } from './modules/pipe.module';
import { AccountService } from './providers/account.service';
import { AlertService } from './providers/alert.service';
import { AlphaPaymentService } from './providers/alphapayment.service';
import { AppState } from './providers/appstate.service';
import { AuthServiceProvider } from './providers/auth.service';
import { CenposService } from './providers/cenpos.service';
import { ConfigurationService } from './providers/configuration.service';
import { DatabaseMapService } from './providers/databasemap.service';
import { DateTimeService } from './providers/date-time.service';
import { ErrorService } from './providers/error.service';
import { ErrorhandlerService } from './providers/errorhandler.service';
import { EvalonPaymentService } from './providers/evalonpayment.service';
import { FaqService } from './providers/faq.service';
import { LocalstorageService } from './providers/localstorage.service';
import { LogService } from './providers/log.service';
import { NavigationService } from './providers/navigation.service';
import { PagerService } from './providers/pager.service';
import { ProxyService } from './providers/proxy.service';
import { ReCaptchaService } from './providers/re-captcha.service';
import { SupportRequestService } from './providers/support-request.service';
import { ThemeService } from './providers/theme.service';
import { TokenInterceptor } from './providers/tokenInterceptor';
import { TransponderService } from './providers/transponder.service';
import { UserService } from './providers/user.service';
import { UtilsService } from './providers/utils.service';
import { WebAccessService } from './providers/webAccess.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { TranslateModule } from '@ngx-translate/core';
import { TranspondersComponent } from './components/transponders/transponders.component';
import { SuspendTransponderConfirmationComponent } from './components/transponders/suspend-transponder-confirmation/suspend-transponder-confirmation.component';
import { TransponderInfoComponent } from './components/transponders/transponder-info/transponder-info.component';
import { RequestTranspondersComponent } from './components/transponders/request-transponders/request-transponders.component';
import { AccountSummaryComponent } from './components/account-summary/account-summary.component';
import { AlertMobileService } from './providers/alertmobile.service';
import { Globals } from './providers/globals.service';
import { SessionstorageService } from './providers/sessionstorage.service';
import { ChasePaymentService } from './providers/chasepayment.service';
import { MessageboardService } from './providers/messageboard.service';
import { MustSelecReactiveDirective } from './directive/must-select-reactive.directive';

export let options: Partial<IConfig> | (() => Partial<IConfig>);

import { OwnIpService } from './providers/ownip.service';
import { CardActivityComponent } from './components/card-activity/card-activity.component';
import { SecureStorageService } from './providers/securestroage.service';
import { EvalonAutoRebillComponent } from './components/evalon-auto-rebill/evalon-auto-rebill.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { CurrencyTypeComponent } from './components/currency-type/currency-type.component';
import { AccountTicketService } from './providers/accountticket.service';
import { PaymentReceiptComponent } from './components/payment-receipt/payment-receipt.component';
import { EvalonOneTimeComponent } from './components/evalon-one-time/evalon-one-time.component';
import { MonerisOneTimeComponent } from './components/moneris-one-time/moneris-one-time.component';
import { MonerisAutoRebillComponent } from './components/moneris-auto-rebill/moneris-auto-rebill.component';
import { MonerisService } from './providers/moneris.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MonerisReceiptComponent } from './components/moneris-receipt/moneris-receipt.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EvalonReceiptComponent } from './components/evalon-receipt/evalon-receipt.component';
import { MatIconModule } from '@angular/material/icon';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PaymentService } from './providers/payment.service';
import { SavedCardComponent } from './components/saved-card/saved-card.component';
import { SignupGuard } from './guards/signup.guard';
import { InvalidInputDirective } from './directive/invalid-input.directive';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastComponent } from './components/toast-component/toast-component.component';
import { A11yModule } from '@angular/cdk/a11y';
import { ClickStopPropagation } from './directive/disabled-btn.directive';
import { PaymentConfirmComponent } from './components/payment-confirm/payment-confirm.component';
import { MarkAsteriskDirective } from './directive/mark-asterisk.directive';
import { MonerisSignupComponent } from './components/moneris-signup-view/moneris-signup-view.component';
import { SecureSessionStorageService } from './providers/securesessionstroage.service';
import { ClosureService } from './providers/closure.service';
import { GooglePlaceDirective } from './directive/google-places-autocomplete.directive';
import { TermsViewComponent } from './components/terms-view/terms-view.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GooglePlaceMobileDirective } from './directive/google-places-autocomplete-mobile.directive';
import { BridgeAlertsService } from './providers/bridge-alerts.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { MobileVersionService } from './providers/mobile.version.service';


@NgModule({
  declarations: [
    // Component
    AlertComponent,
    CardActivityComponent,
    RequestTranspondersComponent,
    TransponderInfoComponent,
    SuspendTransponderConfirmationComponent,
    TranspondersComponent,
    AccountSummaryComponent,
    EvalonOneTimeComponent,
    EvalonAutoRebillComponent,
    CreditCardComponent,
    CurrencyTypeComponent,
    PaymentReceiptComponent,
    MonerisOneTimeComponent,
    MonerisAutoRebillComponent,
    MonerisReceiptComponent,
    MonerisSignupComponent,
    ToastComponent,

    // Directives
    NoDblClickDirective,
    MustSelectDirective,
    MustSelecReactiveDirective,
    CheckUsernameDirective,
    CustomMinMaxDirective,
    InActiveAccountDirective,
    EvalonReceiptComponent,
    SavedCardComponent,
    InvalidInputDirective,
    ClickStopPropagation,
    MarkAsteriskDirective,
    PaymentConfirmComponent,
    GooglePlaceDirective,
    GooglePlaceMobileDirective,
    TermsViewComponent
  ],
  imports: [
    // Core
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    // IBI Groups
    PipeModule,

    // ThirdParty
    NgIdleKeepaliveModule.forRoot(),
    ToastContainerModule,
    NgxCaptchaModule,
    FontAwesomeModule,
    AngularMyDatePickerModule,
    OrderModule,
    NgbModule,
    CustomFormsModule,
    NgxMaskModule.forRoot(options),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-full-width',
    }),
    TranslateModule.forChild(),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    TooltipModule.forRoot(),
    // Angular Material
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    A11yModule,
    PdfViewerModule
  ],
  providers: [
    // Guards
    AuthGuard,
    InActiveAccountGuard,

    // Pipe
    DatePipe,
    CurrencyPipe,
  ],
  exports: [
    // Component
    AlertComponent,
    CardActivityComponent,
    RequestTranspondersComponent,
    TransponderInfoComponent,
    SuspendTransponderConfirmationComponent,
    TranspondersComponent,
    AccountSummaryComponent,
    EvalonOneTimeComponent,
    EvalonAutoRebillComponent,
    CreditCardComponent,
    CurrencyTypeComponent,
    PaymentReceiptComponent,
    MonerisOneTimeComponent,
    MonerisAutoRebillComponent,
    MonerisReceiptComponent,
    MonerisSignupComponent,
    EvalonReceiptComponent,
    ToastComponent,

    // Directives
    NoDblClickDirective,
    MustSelectDirective,
    MustSelecReactiveDirective,
    CheckUsernameDirective,
    CustomMinMaxDirective,
    InActiveAccountDirective,
    InvalidInputDirective,
    MarkAsteriskDirective,
    ClickStopPropagation,
    GooglePlaceDirective,
    GooglePlaceMobileDirective,


    // IBI
    PipeModule,

    // Pipe
    DatePipe,
    CurrencyPipe,

    // Third Party
    TranslateModule,
    CustomFormsModule,
    NgIdleKeepaliveModule,
    ToastContainerModule,
    NgxCaptchaModule,
    FontAwesomeModule,
    AngularMyDatePickerModule,
    OrderModule,
    NgbModule,
    MatDialogModule,
    MatIconModule,
    ModalModule,
    CarouselModule,
    TooltipModule,
    A11yModule,

  ],
})
export class TollCoreModule {
  static forRoot(): ModuleWithProviders<TollCoreModule> {
    return {
      ngModule: TollCoreModule,
      providers: [
        AppState,
        AccountService,
        AccountTicketService,
        AlertService,
        AlertMobileService,
        AlphaPaymentService,
        ChasePaymentService,
        AuthServiceProvider,
        BridgeAlertsService,
        CenposService,
        ClosureService,
        SecureStorageService,
        SecureSessionStorageService,
        SignupGuard,
        DatabaseMapService,
        DateTimeService,
        ErrorService,
        ErrorhandlerService,
        EvalonPaymentService,
        FaqService,
        LocalstorageService,
        MonerisService,
        MobileLocalstorageService,
        SessionstorageService,
        ScriptLoaderModule,
        LogService,
        NavigationService,
        PaymentService,
        PagerService,
        ProxyService,
        ReCaptchaService,
        TokenInterceptor,
        ThemeService,
        TransponderService,
        SupportRequestService,
        UserService,
        WebAccessService,
        UtilsService,
        ConfigurationService,
        Globals,
        OwnIpService,
        MessageboardService,
        Globals,
        InAppBrowser,
        MobileVersionService,


        // Guards
        AuthGuard,
        InActiveAccountGuard,

        // Pipe
        DatePipe,
        CurrencyPipe,
      ],
    };
  }
}
