import { AfterViewInit, HostListener } from '@angular/core';
/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  isDevMode,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import {
  Account,
  AccountProfileConfig,
  AccountService,
  AlertService,
  ChasePaymentService,
  ComponentMode,
  ConfigurationService,
  CreditCard,
  ChaseResponse,
  CreditCardComponent,
  DatabaseMapService,
  LogService,
  ThemeService,
  PaymentService,
  User,
  UserService,
  WebUserCardInfo,
  UtilsService,
} from '@ibitoll/toll-core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { switchMap } from 'rxjs';
import { SubSink } from 'subsink';
import { ScriptService } from 'ngx-script-loader';
import jsPDF from 'jspdf';

@Component({
  selector: 'hhb-chase-preauth',
  templateUrl: './chase-preauth.component.html',
  styleUrls: ['./chase-preauth.component.scss'],
})
export class ChasePreauthComponent implements OnInit, AfterViewInit {
  @Input() componentMode: ComponentMode = ComponentMode.Self;

  @Input() cardMode = true;

  @Input() language = 'EN';

  @Output() paymentatus = new EventEmitter<any>();

  @Output() cardChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() onTransactionCancel = new EventEmitter<any>();

  @Output() onTransactionComplete = new EventEmitter<any>();

  user: User;
  account: Account;
  subs = new SubSink();
  creditCardQuery: CreditCard;
  accountProfileOptions: AccountProfileConfig[];

  isLoading = false;
  public editMode = false;
  isTicketAvailable = false;
  paymentToken: string;

  processing = false;
  ticketNo: any;

  myCheckout: any;

  hideButtons = false;

  cardExist = false;
  accountProfile: AccountProfileConfig;

  chaseTesting = true;

  savedCards: WebUserCardInfo[] = [];
  public isRemoveProcessing: boolean;
  public isDefaultProcessing: boolean;
  public selectedCard: WebUserCardInfo;
  public isRemoveActiveCardProcessing = false;
  public enableCCSubmit = false;
  public chaseResponse: ChaseResponse;

  @ViewChild('removeCardModal') activeCardRemoveModal: ElementRef;
  @ViewChild('removeSavedCardModal') activeSavedCardRemoveModal: ElementRef;
  @ViewChild('setSavedCardModal') setActiveCardModal: ElementRef;

  @ViewChild(CreditCardComponent) creditCard: CreditCardComponent;

  isSavedProcessing = false;

  link = this.themeService.active.ChaseHPFLink; // 'https://chase-var.hostedpaymentservice.net/hpf/?uid=';
  url = '';
  urlSafe: SafeResourceUrl;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  constructor(
    private accountService: AccountService,
    private modalService: NgbModal,
    public configService: ConfigurationService,
    public chasePaymentService: ChasePaymentService,
    private paymentService: PaymentService,
    public logService: LogService,
    private alertService: AlertService,
    private userService: UserService,
    private databaseMapService: DatabaseMapService,
    private scriptService: ScriptService,
    private utilsService: UtilsService,
    public themeService: ThemeService,
    private cd: ChangeDetectorRef
  ) {
    if (this.utilsService.isHHB()) {
      if (isDevMode()) {
        this.scriptService
          .loadScript(
            'https://www.chasepaymentechhostedpay-var.com/hpf/js/hpfParent.min.js' 
          )
          .subscribe(() => {});
      } else {
        this.scriptService
          .loadScript(
            'https://www.chasepaymentechhostedpay.com/hpf/js/hpfParent.min.js'
          )
          .subscribe(() => {});
      }
    }

  }

  // // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    console.log('ngOnInit cardMode=' + this.cardMode);
    this.accountService
      .getActiveAccount()
      .pipe(
        switchMap((account: Account) => {
          console.log('account='+JSON.stringify(account));
          this.account = cloneDeep(account);
          this.user = this.userService.getCurrentUser();
          this.cardExist = account.BankCardTokenId != null;
          return this.configService.getAccountProfiles();
        })
      )
      .subscribe((accountProfiles: AccountProfileConfig[]) => {
        this.accountProfile = accountProfiles?.find(
          (o) => o.AccountProfileID == this.account.AccountProfileID
        );

        this.paymentService
          .getUserCardsInfo(this.account.AccountID)
          .subscribe((savedCards) => {
            console.log('Saved Cards: ' + JSON.stringify(savedCards));
            if (this.account.PaymentNumber) {
              this.savedCards = savedCards?.filter((o) => {
                return (
                  o.PaymentNum.substring(o.PaymentNum.length - 4) !==
                  this.account?.PaymentNumber?.substring(
                    this.account.PaymentNumber.length - 4
                  )
                );
              });
            } else {
              this.savedCards = savedCards;
            }
          });
      });
      this.chaseResponse = new ChaseResponse();
      this.getChaseTestingMode();
  }

  ngAfterViewInit(): void {
    if (this.componentMode == ComponentMode.Emit) {
      if (!this.editMode) {
        this.editMode = true;
      }

      this.hideButtons = true;
      this.cd.detectChanges();
    }
  }

  removeActiveCardConfirmation() {
    const activeModal = this.modalService.open(this.activeCardRemoveModal, {
      ariaLabelledBy: 'remove-active-card',
    });
    activeModal.shown.subscribe(() => {
      console.log('removeActiveCardConfirmation modal showen');
    });
  }

  getMaskedCardNumber(cc: string) {
    const last4 = cc.substring(cc.length - 4);
    return '************' + last4;
  }

  setActiveCardConfirmation(card: WebUserCardInfo) {
    this.selectedCard = card;
    const activeModal = this.modalService.open(this.setActiveCardModal, {
      ariaLabelledBy: 'set-saved-card-active',
    });
    activeModal.shown.subscribe(() => {
      console.log('HHB.setActiveCardConfirmation modal shown');
    });
  }

  removeSavedCardConfirmation(card: WebUserCardInfo) {
    this.selectedCard = card;
    const activeModal = this.modalService.open(
      this.activeSavedCardRemoveModal,
      { ariaLabelledBy: 'remove-saved-card' }
    );
    activeModal.shown.subscribe(() => {
      console.log('removeSavedCardConfirmation modal showen');
    });
  }
  
  // Response callback from Chase Hosted Payment Form
  @HostListener('window:message', ['$event'])
  init(paymentResult: any): void {
    if (paymentResult.data['subject'] == 'init') {
      console.log('init', paymentResult);
    }

    if (paymentResult.data['subject'] == 'hpfReady') {
      console.log('hpfReady', paymentResult);
    }

    if (paymentResult.data['subject'] == 'startPayment') {
      console.log('startPayment', paymentResult);
    }

    if (paymentResult.data['subject'] == 'completePayment') {
      console.log('completePayment', paymentResult);

      console.log('Setting up card data: ' + JSON.stringify(paymentResult.data?.content));
      this.chaseResponse.exp_month = paymentResult.data?.content?.exp_month;
      this.chaseResponse.exp_year = paymentResult.data?.content?.exp_year;
      this.chaseResponse.card_brand = paymentResult.data?.content?.card_brand;
      this.chaseResponse.billing_name = paymentResult.data?.content?.billing_name;
      this.chaseResponse.mpan = paymentResult.data?.content?.mpan; // masked CC num
      this.chaseResponse.token_id = paymentResult.data?.content?.gateway?.customerRefNum; 
      var token_verify = paymentResult.data?.content?.token_id;

      console.log('Credit Card Token Acquired: ', );

      this.postResponse();
    }

    if (paymentResult.data['subject'] == 'cancelPayment') {
      this.isTicketAvailable = false;
      console.log('cancelPayment', paymentResult.data.content);
      this.logService.logMessage('[CP]cancel: acc=' + this.account?.AccountID + ' data: ' + JSON.stringify(paymentResult));
    }

    if (paymentResult.data['subject'] == 'handlePaymentErrors') {
      //this.isTicketAvailable = false;
      console.log('handlePaymentErrors', paymentResult.data.content);
      console.log('handlePaymentErrors data:' + JSON.stringify(paymentResult.data));
      this.logService.logMessage('[CP]handlePaymentErrors: acc=' + this.account?.AccountID + ' data: ' + JSON.stringify(paymentResult.data));

      this.alertService.error(
        this.displayError(paymentResult),
        true
      );
    }

    if (paymentResult.data['subject'] == 'handleTransactionErrors') {
      //this.isTicketAvailable = false;
      console.log('handleTransactionErrors', paymentResult.data.content);
      console.log('handleTransactionErrors data:' + JSON.stringify(paymentResult.data));
      this.logService.logMessage('[CP]handleTransactionErrors: acc=' + this.account?.AccountID + ' data: ' + JSON.stringify(paymentResult.data));

      this.alertService.error(
        this.displayError(paymentResult),
        true
      );
    }


  }

  displayError(msg: any) {
    console.log('[CP]Error: ' + JSON.stringify(msg));
    try {
      let error_message;
      if (msg.data.content && Array.isArray(msg.data.content) && msg.data.content.length > 0)
      {
        if (msg.data.content[0].status_code == '2300'){
          error_message = msg.data.content[0].gateway_message
        }
        else{
          error_message = msg.data.content[0].status_message
        }
        return `[CP] Transaction declined: ${error_message}. Please try again.`
      }
      else if (msg.data.content && msg.data.content.status_code) {

        if (msg.data.content.status_code == '2300'){
          error_message = msg.data.content.gateway_message
        }
        else{
          error_message = msg.data.content.status_message
        }
        return `[CP] Transaction declined: ${error_message}. Please try again.`
      }
      else{
        return `[CP] Transaction declined, Please try again.`
      }
    } catch (error) {
      return `[CP] Transaction declined, Please try again.`
    }
  }

  preLoad() {
    console.log('start preLoad: acc='+this.account.AccountID + '; lang='+this.account.Language + '; cardMode=' + this.cardMode);
    this.paymentatus = new EventEmitter<any>();

    this.processing = true;
    this.configService.CheckDbStatus().subscribe(
      (ticket) => {
        this.processing = false;
        this.isTicketAvailable = true;
        this.showChase();
      },
      (error) => {
        this.hideChase();
        this.logService.logMessage('preLoad failed' + JSON.stringify(error));
        this.alertService.error(
          'Unable to process your request at this time please try again later!',
          true
        );
      }
    );

    return this.paymentatus.asObservable();
  }


  completePayment(data){
    console.log("postMessage function completePayment is called. \n Response:" + JSON.stringify(data));
   //Orbital Gateway Hosted Payment Appendix D: Parent page code samples    Page 133 of 134
  }
   
  handlePaymentErrors(data){
    console.log("postMessage function handlePaymentErrors is called. \n Response:" + JSON.stringify(data));
  }

  hpfReady() {
    console.log('hpfReady is called')
  }

  addCardPreLoad() {
    // var e = (document.getElementById("chanseTestingCase")) as HTMLSelectElement;
    // var sel = e.selectedIndex;
    // var opt = e.options[sel];
    // var CurValue = (<HTMLSelectElement>opt).value;
    // var CurText = (<HTMLSelectElement>opt).text;

    let testValue = ''; 
    if (this.chaseTesting)
      testValue =(<HTMLSelectElement>document.getElementById('chanseTestingCase')).value;
    
    console.log('chanseTestingCase=' + testValue);

    console.log('start addCardPreLoad: acc='+this.account.AccountID + '; lang='+this.account.Language + '; cardMode=' + this.cardMode);
    this.processing = true;
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          console.log('call service addCardPreLoad: acc='+this.account.AccountID + '; lang='+this.account.Language);
          return this.chasePaymentService.addCardPreLoad(
            0,
            this.account.AccountID,
            1,
            this.account.Language,
            "OneTimePaymentMobile",
            testValue
          );
        })
      )
      .subscribe(
        (ticket) => {
          console.log('addCardPreLoad ticket='+ticket)
          this.processing = false;
          this.ticketNo = ticket;
          this.url = this.link + this.ticketNo;
          console.log('addCard Chase url ='+this.url)
          this.isTicketAvailable = true;
        },
        (error) => {
          this.processing = false;
          this.isTicketAvailable = false;

          this.hideChase();
          this.logService.logMessage('preLoad failed' + JSON.stringify(error));
          this.alertService.error(
            'Unable to process your request at this time please try again later!',
            true
          );
        }
      );
  }

  postResponse() {
    this.creditCardQuery = new CreditCard();

    this.creditCardQuery.UserId = this.user.UserId;
    this.creditCardQuery.UserLogonId = this.user.UserName;
    this.creditCardQuery.AccountId = this.user.AccountId;

    this.creditCardQuery.BalanceReplenishAmount = Math.abs(
      this.account.TopUpAmount
    );
    this.creditCardQuery.BalanceReplenishThreshold =
      this.account.LowBalanceAmount * -1;
    this.creditCardQuery.BalanceWarningThreshold =
      this.account.BalanceWarning * -1;

    this.creditCardQuery.PaymentNum = this.account.PaymentNumber;
    this.creditCardQuery.PreauthStatus =
      this.databaseMapService.ePreAuthStatus.suspended;
    this.creditCardQuery.CardBankTokenId = this.ticketNo;
    this.creditCardQuery.PaymentType =
      this.databaseMapService.ePaymentTypeMap.CreditCard;
    this.creditCardQuery.ActionCode = "ADDCC";

    this.processing = true;
    
    this.logService.logMessage(
      'AttemptWeb AddCC(' + this.user.AccountId + '):' + this.account?.PaymentNumber
    ); 
    this.configService
      .CheckDbStatus()
      .pipe(
        switchMap((data) => {
          return this.chasePaymentService.preAuthResponse(this.creditCardQuery);
        })
      )
      .subscribe({
        next: (receiptResponse) => {
          this.accountService
            .loadActiveAccount(this.account.AccountID)
            .subscribe({
              next: () => {
                this.isTicketAvailable = false;
                this.processing = false;
                // this.displayReceipt(receiptResponse);
                this.hideChase();
              },
            });
        },
        error: (error) => {
          this.isTicketAvailable = false;
          this.processing = false;
          this.hideChase();
        },
      });
  }

  removeActiveCard() {
    this.isRemoveActiveCardProcessing = true;
    this.creditCardQuery = new CreditCard();
    this.creditCardQuery.UserId = this.user.UserId;
    this.creditCardQuery.UserLogonId = this.user.UserName;
    this.creditCardQuery.AccountId = this.user.AccountId;

    this.creditCardQuery.BalanceReplenishAmount = Math.abs(this.account.TopUpAmount);
    this.creditCardQuery.BalanceReplenishThreshold = this.account.LowBalanceAmount * -1;
    this.creditCardQuery.BalanceWarningThreshold = this.account.BalanceWarning * -1;

    this.creditCardQuery.PaymentNum = this.account.PaymentNumber;
    this.creditCardQuery.PreauthStatus =
      this.databaseMapService.ePreAuthStatus.disabled; // 0
    this.creditCardQuery.CardBankTokenId = this.account.BankCardTokenId;
    this.creditCardQuery.PaymentNum = this.account.PaymentNumber;
    this.creditCardQuery.ActionCode = "DELAC";

    this.logService.logMessage(
      'AttemptWeb DeleteActiveCC(' + this.user.AccountId + '):' + this.account.PaymentNumber
    ); 
    this.chasePaymentService.preAuthResponse(this.creditCardQuery).subscribe(
      () => {
        this.accountService
          .loadActiveAccount(this.account.AccountID)
          .subscribe(() => {
            if (this.editMode) {
              this.editMode = false;
            }
            this.processing = false;
            this.alertService.success(
              'Credit card has been successfully removed.',
              true
            );
            this.isRemoveActiveCardProcessing = false;
            this.modalService.dismissAll();
            this.cd.detectChanges();
          });
      },
      () => {
        this.modalService.dismissAll();
        this.isRemoveActiveCardProcessing = false;
      }
    );
  }

  removeSavedCard(card: WebUserCardInfo) {
    this.selectedCard = card;
    card.AccountId = this.account.AccountID.toString();
    card.CardAlias = '';
    card.CardStatus = false;
    this.isRemoveProcessing = true;
    this.logService.logMessage(
      'AttemptWeb DeleteSavedCC(' + this.user.AccountId + '):' + card.PaymentNum
    ); 
    this.paymentService.setUserCardsInfo(card).subscribe(
      (data) => {
        this.paymentService.getUserCardsInfo(this.account.AccountID).subscribe(
          (savedCards) => {
            if (this.account.PaymentNumber) {
              this.savedCards = savedCards?.filter((o) => {
                return (
                  o.PaymentNum.substring(o.PaymentNum.length - 4) !==
                  this.account?.PaymentNumber?.substring(
                    this.account.PaymentNumber.length - 4
                  )
                );
              });
            } else {
              this.savedCards = savedCards;
            }
            this.isRemoveProcessing = false;
            this.alertService.successTranslate(
              'CARD_REMOVED_SUCCESS_MSG',
              true
            );
            this.modalService.dismissAll();
          },
          (error) => {
            this.modalService.dismissAll();
            this.isRemoveProcessing = false;
          }
        );
      },
      (error) => {
        this.isRemoveProcessing = false;
      }
    );
  }

  setCardActive(card: WebUserCardInfo) {
    this.isDefaultProcessing = true;

    this.selectedCard = card;
    const expDate = new Date(card.BankCardTokenExpiryDate);
    const year = expDate.getFullYear() - 2000;
    const month = ('0' + (expDate.getMonth() + 1)).slice(-2);

    this.creditCardQuery = new CreditCard();
    this.creditCardQuery.UserId = this.user.UserId;
    this.creditCardQuery.UserLogonId = this.user.UserName;
    this.creditCardQuery.AccountId = this.user.AccountId;
    this.creditCardQuery.PaymentType =
      this.databaseMapService.ePaymentTypeMap.CreditCard;
    this.creditCardQuery.PaymentCardType = card.CardType;
    this.creditCardQuery.PaymentNum = card.PaymentNum;
    this.creditCardQuery.PaymentCardExpiryYear = year;
    this.creditCardQuery.PaymentCardExpiryMonth = +month;
    this.creditCardQuery.CardBankTokenId = card.BankCardTokenId;
    this.creditCardQuery.PaymentLastName = this.account.LastName;
    if (!this.account.PaymentType) {
      this.creditCardQuery.BalanceReplenishAmount = Math.abs(this.accountProfile.BalanceReplenishAmount);
      this.creditCardQuery.BalanceReplenishThreshold = this.accountProfile.BalanceReplenishThreshold * -1;
    }
    else if (this.account.PaymentType == 'B'){
      this.creditCardQuery.BalanceReplenishAmount = Math.abs(this.account.TopUpAmount);
      this.creditCardQuery.BalanceReplenishThreshold = this.accountProfile.BalanceReplenishThreshold * -1;
    }
    else{
      this.creditCardQuery.BalanceReplenishAmount = Math.abs(this.account.TopUpAmount);
      this.creditCardQuery.BalanceReplenishThreshold = this.account.LowBalanceAmount * -1;
    }
    
    this.creditCardQuery.BalanceWarningThreshold = this.account.BalanceWarning * -1;

    this.creditCardQuery.PreauthStatus = this.isPreAuthEnabled()
      ? this.databaseMapService.ePreAuthStatus.active
      : this.databaseMapService.ePreAuthStatus.suspended;
    this.creditCardQuery.ActionCode = "SACC";

    this.logService.logMessage(
      'AttemptWeb SetActiveCC(' + this.user.AccountId + '):' + card.PaymentNum
    ); 
    this.accountService.updatePaymentDetails(this.creditCardQuery).subscribe(
      () => {
        this.accountService
          .loadActiveAccount(this.account.AccountID)
          .subscribe(() => {
            this.isDefaultProcessing = false;
            if (this.editMode) {
              this.editMode = false;
            }

            this.processing = false;
            this.alertService.success(
              'Card information successfully updated',
              false
            );
            this.cardChanged.emit(true);
            this.modalService.dismissAll();
            this.cd.detectChanges();
          });
      },
      () => {
        this.modalService.dismissAll();
        this.isDefaultProcessing = false;
        this.processing = false;
        // this.alertService.error('Error occured in updating account ', true);
      }
    );
  }

  isPreAuthEnabled(): boolean {
    return (
      this.account?.PreAuthStatus !== undefined &&
      this.account?.PreAuthStatus ===
        this.databaseMapService.ePreAuthStatus.active
    );
  }

  addCardWithHPF() { // add with Chase Hosted Pay Form
    this.processing = true;
    this.creditCardQuery = new CreditCard();
    this.creditCardQuery.UserId = this.user.UserId;
    this.creditCardQuery.UserLogonId = this.user.UserName;
    this.creditCardQuery.AccountId = this.user.AccountId;

    this.creditCardQuery.AccountId = this.account.AccountID;
    this.creditCardQuery.PaymentType =
    this.databaseMapService.ePaymentTypeMap.CreditCard;

    this.creditCardQuery.BalanceReplenishAmount = Math.abs(
      this.account.TopUpAmount
    );
    this.creditCardQuery.BalanceReplenishThreshold =
      this.account.LowBalanceAmount * -1;
    this.creditCardQuery.BalanceWarningThreshold =
      this.account.BalanceWarning * -1;

    this.creditCardQuery.PreauthStatus = this.isPreAuthEnabled()
      ? this.databaseMapService.ePreAuthStatus.active
      : this.databaseMapService.ePreAuthStatus.suspended;

    this.creditCardQuery.PaymentCardHolder = this.chaseResponse.billing_name;
    this.creditCardQuery.PaymentNum = this.chaseResponse.mpan; // masked CC
    this.creditCardQuery.PaymentCardExpiryMonth = +this.chaseResponse.exp_month;
    this.creditCardQuery.PaymentCardExpiryYear = +this.chaseResponse.exp_year;
    this.creditCardQuery.CardBankTokenId = this.chaseResponse.token_id;
    this.creditCardQuery.PaymentCardType = this.databaseMapService.getCardType(this.chaseResponse.card_brand);

    this.addCardToDb(this.creditCardQuery);
  }

  addCard() {
    this.processing = true;
    this.creditCardQuery = new CreditCard();
    this.creditCardQuery.UserId = this.user.UserId;
    this.creditCardQuery.UserLogonId = this.user.UserName;
    this.creditCardQuery.AccountId = this.user.AccountId;

    this.creditCardQuery.AccountId = this.account.AccountID;
    this.creditCardQuery.PaymentCardHolder =
      this.creditCard.creditCardFormGroup.controls['nameOnCard'].value;
    this.creditCardQuery.PaymentNum =
      this.creditCard.creditCardFormGroup.controls['cardNumber'].value;
    this.creditCardQuery.PaymentCardExpiryMonth =
      this.creditCard.creditCardFormGroup.controls['cardmonth'].value;
    this.creditCardQuery.PaymentCardExpiryYear =
      this.creditCard.creditCardFormGroup.controls['cardyear'].value;
    this.creditCardQuery.PaymentCardCvv =
      this.creditCard.creditCardFormGroup.controls['cardcvv'].value;
    this.creditCardQuery.PaymentType =
      this.databaseMapService.ePaymentTypeMap.CreditCard;

    this.creditCardQuery.BalanceReplenishAmount = Math.abs(
      this.account.TopUpAmount
    );
    this.creditCardQuery.BalanceReplenishThreshold =
      this.account.LowBalanceAmount * -1;
    this.creditCardQuery.BalanceWarningThreshold =
      this.account.BalanceWarning * -1;

    this.creditCardQuery.PreauthStatus = this.isPreAuthEnabled()
      ? this.databaseMapService.ePreAuthStatus.active
      : this.databaseMapService.ePreAuthStatus.suspended;

    this.addCardToDb(this.creditCardQuery);

    // this.chasePaymentService.addCard(this.creditCardQuery).subscribe({
    //   next: () => {
    //     this.processing = false;

    //     this.accountService.loadActiveAccount(this.account.AccountID).subscribe(
    //       () => {
    //         this.isTicketAvailable = false;
    //         this.alertService.success(
    //           'Card information successfully updated',
    //           false
    //         );
    //       },
    //       (error) => {
    //         this.processing = false;
    //         this.isTicketAvailable = false;
    //         this.logService.logMessage(
    //           'Add Card failed' + JSON.stringify(error)
    //         );
    //       }
    //     );
    //   },
    //   error: (error) => {
    //     this.processing = false;
    //     this.isTicketAvailable = false;
    //     this.logService.logMessage('Add Card failed' + JSON.stringify(error));
    //   },
    // });
  }

  addCardToDb(ccQuery: CreditCard) {
    console.log('addCardToDb=' + JSON.stringify(ccQuery));
    this.chasePaymentService.addCard(ccQuery).subscribe({
      next: () => {
        this.processing = false;

        this.accountService.loadActiveAccount(this.account.AccountID).subscribe(
          () => {
            this.isTicketAvailable = false;
            this.alertService.success(
              'Card information successfully updated',
              false
            );
          },
          (error) => {
            this.processing = false;
            this.isTicketAvailable = false;
            this.logService.logMessage(
              'Add Card failed' + JSON.stringify(error)
            );
          }
        );
      },
      error: (error) => {
        this.processing = false;
        this.isTicketAvailable = false;
        this.logService.logMessage('Add Card failed' + JSON.stringify(error));
      },
    });
  }

  cancelAddCard() {
    this.isTicketAvailable = false;
  }

  onCardLoaded() {
    this.creditCard.hideAmt();
    this.creditCard.creditCardFormGroup.valueChanges.subscribe(() => {
      this.enableCCSubmit = this.creditCard.creditCardFormGroup.valid;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hideChase() {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showChase() {}


  getChaseTestingMode() {
    this.configService
      .getConfigParameter('process_mode')
      .subscribe(
        (resp) => {
          this.chaseTesting = false;
          if (resp === 'ChaseTesting') 
            this.chaseTesting = true;
          console.log('Loaded: Chase_testing=' + this.chaseTesting);
        },
        (error) => {
          console.log(error);
        }
      );
  }

}
