export class TransactionPdfRecord {
  TransactionLDateTime: string;
  Description: string;
  Transponder: string;
  Location: string;
  VehicleType: string;
  NetAmount: string;
  HST: string;
  Amount: string;
  AccountBalance: string;
}

export class SummaryPdfRecord
{
   TypeName: string;
   Hst: string;
   Total: string;
}

export class ActivityPdfQuery {

  AccountId: number;
  AccountHolderName: string;
  DateGenerated: string;
  DateRange: string;
  TransponderFilterVal: string;
  FiltersUsed: string;
  summary: SummaryPdfRecord[];
  list: TransactionPdfRecord[];
}

export class SummaryAmount
{
   Hst: number;
   Total: number;
}
