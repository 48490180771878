import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './/app-routing.module';

import {
  TollCoreModule,
  ConfigurationService,
  AuthServiceProvider,
  TokenInterceptor,
  ErrorService,
} from '@ibitoll/toll-core';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { UserIdleModule } from 'angular-user-idle';
import { UserInactivityComponent } from './core/user-inactivity/user-inactivity.component';
import { Title } from '@angular/platform-browser';
import { NgxLoadingModule } from 'ngx-loading';
import { environment } from '../environments/environment';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SurveysComponent } from './core/modals/surveys/surveys.component';
import { SortByPipe } from 'ngp-sort-pipe';

export function configProviderFactory(provider: ConfigurationService) {
  return () => provider.GetLocalClientConfiguration();
}

export function configLocalConfig(provider: ConfigurationService) {
  return () => provider.LoadLocalConfiguration(environment.config);
}

export function initializeAccountProviderFactory(
  provider: AuthServiceProvider
) {
  return () => provider.initializeAccount();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    UserInactivityComponent,
    SurveysComponent,
  ],
  imports: [
    // Core
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,

    // IBI Core Lib
    TollCoreModule.forRoot(),
    TranslateModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule,
    UserIdleModule.forRoot({
      idle: environment.config.SessionTimeOut,
      timeout: 60,
      ping: 120,
    }),
    NgxLoadingModule.forRoot({}),
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: environment.config.DefaultCurrencyCode,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configLocalConfig,
      deps: [ConfigurationService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAccountProviderFactory,
      deps: [AuthServiceProvider],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorService,
      multi: true,
    },
    { provide: Window, useValue: window },
    Title,
    SortByPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
