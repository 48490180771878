import { ThemeService } from './../../providers/theme.service';
import { UtilsService } from './../../providers/utils.service';
import { ConfigurationService } from './../../providers/configuration.service';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import * as html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { PaymentViolationInfo } from '../../models/paymentViolation';
import { TransactionReceipt } from '../../models/transactionReceipt';
import { DatabaseMapService } from '../../providers/databasemap.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransactionResponse } from '../../models/transactionResponse';

export interface DialogData {
  transactionResponse: TransactionResponse;
  currentDate: Date;
}

@Component({
  selector: 'moneris-receipt',
  templateUrl: './moneris-receipt.component.html',
  styleUrls: ['./moneris-receipt.component.scss'],
})
export class MonerisReceiptComponent implements OnInit {
  @Input()
  transactionResponse: TransactionResponse;

  @Input()
  currentDate: Date;

  @Input()
  merchantId: string;

  @Output() ModalClose = new EventEmitter<any>(); // happens on receipt close

  pvInfo: PaymentViolationInfo[] = null;

  constructor(
    private databaseMapService: DatabaseMapService,
    private configurationService: ConfigurationService,
    public utilsService: UtilsService,
    public themeService: ThemeService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.transactionResponse = data.transactionResponse;
    this.currentDate = data.currentDate;
  }

  ngOnInit() {
    this.configurationService.getCenposMerchantId().subscribe((resp) => {
      this.merchantId = resp;
    });
  }

  public updatePvList(list: PaymentViolationInfo[]) {
    this.pvInfo = list;
  }

  isApproved() {
    return (
      this.transactionResponse &&
      this.transactionResponse.BankAuthorizationStatusDB === 'A' // this.databaseMapService.eAuthorizationStatusTypeMap.approved
    );
  }

  getCardType(value: number): string {
    return this.databaseMapService.getCardTypeString(value);
  }

  parseAmount(value: number) {
    return Math.abs(value);
  }

  downloadReceipt() {
    const doc = new jsPDF();

    // Add a title to your PDF
    // doc.setFontSize(20);
    // doc.text(12, 10, 'Bridge Authority');

    // https://rawgit.com/MrRio/jsPDF/master/
    // Create your table here (The dynamic table needs to be converted to canvas).
    /* tslint:disable */
    let element = <HTMLScriptElement>(
      document.getElementById('paymentRecieptModalBody')
    );
    // @ts-ignore
    html2canvas(element).then((canvas: any) => {
      doc.addImage(
        canvas.toDataURL('image/jpeg'),
        'JPEG',
        50,
        50,
        element.offsetWidth / 5,
        element.offsetHeight / 5
      );
      doc.save(`Transaction-${Date.now()}.pdf`);
    });
  }

  afterModalClose() {
    this.ModalClose.emit(true);
  }

  logInternalData(caller: string) {}
}
