import { OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import { ScriptService } from 'ngx-script-loader';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { CardQueryWithExpiry } from '../models/cardQuery';
import { CheckConvergeCommandResponse } from '../models/checkCheckConvergeCommandResponse';
import { ConvergeCommandResponse } from '../models/convergeCommandResponse';
import { EvalonTxnResponse } from '../models/evalonTxnResponse';
import { TransactionResponse } from '../models/transactionResponse';
import { ErrorhandlerService } from './errorhandler.service';
import { ProxyService } from './proxy.service';
import { ReCaptchaService } from './re-captcha.service';

@Injectable({
  providedIn: 'root',
})
export class EvalonPaymentService implements OnDestroy {
  private endpointUrl: string;
  private paymentEndpoint = 'api/Evalon';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private subs = new SubSink();

  constructor(
    private http: HttpClient,
    private reCaptchaService: ReCaptchaService,
    private scriptService: ScriptService,
    private proxy: ProxyService
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );

    if (isDevMode()) {
      this.scriptService
        .loadScript('https://api.demo.convergepay.com/hosted-payments/Checkout.js')
        .subscribe(() => { });
    } else {
      this.scriptService
        .loadScript('https://api.convergepay.com/hosted-payments/Checkout.js')
        .subscribe(() => { });
    }
  }

  getPaymentToken(amount: number, transactionType: number, currencyType: string): Observable<string> {
    return this.reCaptchaService.refresh('getPaymentToken').pipe(
      switchMap(() => {
        return this.http.post<string>(
          this.endpointUrl + this.paymentEndpoint + `/GetPaymentToken`,
          { amount, transactionType, currencyType },
          { headers: this.headers }
        );
      })
    );
  }

  /**
   * I'm using the reCaptchaService to refresh the reCaptcha token, then I'm using the http service to
   * post the query to the server, then I'm returning the response.
   *
   * @param {CardQueryWithExpiry} query - CardQueryWithExpiry
   * @returns Observable<CheckConvergeCommandResponse>
   */
  ProcessBankAccountPayment(
    query: CardQueryWithExpiry
  ): Observable<CheckConvergeCommandResponse> {
    return this.reCaptchaService.refresh('ProcessBankAccountPayment').pipe(
      switchMap(() => {
        return this.http
          .post<CheckConvergeCommandResponse>(
            this.endpointUrl +
            this.paymentEndpoint +
            `/ProcessBankAccountPayment`,
            JSON.stringify(query),
            { headers: this.headers }
          )
          .pipe(
            map((resp) => {
              console.log('getPreauthToken returns: ' + resp);
              return resp;
            })
          );
      })
    );
  }

  // ************************************************/
  // Post processed by Evalon transaction to IBI DB
  // ************************************************/
  PostEvalonPayment_backend(
    evalonTxnParams: EvalonTxnResponse
  ): Observable<TransactionResponse> {
    return this.http
      .post<TransactionResponse>(
        this.endpointUrl + this.paymentEndpoint + '/PostEvalonPayment',
        JSON.stringify(evalonTxnParams),
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getPreauthToken(
    query: CardQueryWithExpiry
  ): Observable<ConvergeCommandResponse> {
    return this.reCaptchaService.refresh('getPreauthToken').pipe(
      switchMap(() => {
        return this.http.post<ConvergeCommandResponse>(
          this.endpointUrl + this.paymentEndpoint + `/GetPreauthToken`,
          JSON.stringify(query),
          { headers: this.headers }
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
