/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { OnDestroy } from '@angular/core';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  Account,
  AccountService,
  AlertService,
  Transponder,
  UserService,
} from '@ibitoll/toll-core';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NewtransponderComponent } from '../../../newtransponder/newtransponder.component';
import { SupendAlertModalComponent } from '../supend-alert-modal/supend-alert-modal.component';

@Component({
  selector: 'mdot-edit-transponder-modal',
  templateUrl: './edit-transponder-modal.component.html',
  styleUrls: ['./edit-transponder-modal.component.scss'],
})
export class EditTransponderModalComponent implements AfterViewInit, OnDestroy {
  @Input() transponder: Transponder = null;
  @Input() isEditTransponder = false;
  @Input() account: Account = new Account();

  @ViewChild(NewtransponderComponent, { static: false })
  edittransponderComponent: NewtransponderComponent;

  @Output() closeEditModalOutput: EventEmitter<any> = new EventEmitter();
  @Output() refreshCloseOutput: EventEmitter<any> = new EventEmitter();
  @Output() onTransponderSuspendedOutput: EventEmitter<any> =
    new EventEmitter();
  @Output() onTransponderUnSuspendedOutput: EventEmitter<any> =
    new EventEmitter();

  canRequest = false;
  isRequesting = false;
  isUpdateProcessing = false;
  supendAlertModal: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    public accountService: AccountService,
    public userService: UserService,
    public translateService: TranslateService,
    public alertService: AlertService,
    public activeModal: NgbActiveModal
  ) {}

  ngAfterViewInit(): void {
    this.edittransponderComponent.transponderInfoFormGroup.controls[
      'plate'
    ].setValue(this.checkPlate(this.transponder));

    this.edittransponderComponent.transponderInfoFormGroup.controls[
      'transponderType'
    ].setValue(this.transponder.TransponderTypeId);
    this.edittransponderComponent.hideTransponderType = true;

    this.edittransponderComponent.transponderInfoFormGroup.controls[
      'make'
    ].setValue(this.transponder.VehicleMake);
    this.edittransponderComponent.transponderInfoFormGroup.controls[
      'model'
    ].setValue(this.transponder.VehicleModel);
    this.edittransponderComponent.transponderInfoFormGroup.controls[
      'color'
    ].setValue(this.transponder.VehicleColor);

    var year = this.transponder.VehicleYear == 0 ? '' : this.transponder.VehicleYear
    this.edittransponderComponent.transponderInfoFormGroup.controls[
      'year'
    ].setValue(year);

    this.edittransponderComponent.transponderInfoFormGroup.controls[
      'transponderType'
    ].setValue(this.transponder.TransponderTypeId);

    setTimeout(() => {
      this.edittransponderComponent.transponderInfoFormGroup.controls[
        'province'
      ].setValue(this.transponder.PlateProvinceId);
    }, 100);

    this.edittransponderComponent.transponderInfoFormGroup.controls[
      'category'
    ].setValue(this.transponder.TollCategoryId);

    this.edittransponderComponent.transponderInfoFormGroup.controls[
      'category'
    ].disable();

    const sTCId = this.edittransponderComponent.tollCategoriesOg.find(
      (o) => o.Id == this.transponder.TollCategoryId
    );
    const sTC = this.edittransponderComponent.tollCategories.find(
      (o) => o.GroupId == sTCId.GroupId
    );

    this.edittransponderComponent.transponderInfoFormGroup.controls[
      'category'
    ].setValue(sTC?.Id);
    this.edittransponderComponent.lockTransponderProvince(
      this.account.MailingProvinceID
    );
  }

  closeEditModal() {
    this.closeEditModalOutput.emit(true);
    this.activeModal.dismiss();
  }

  validationStatus(status) {
    this.canRequest = status;
  }

  showSupendAlertModal() {
    this.supendAlertModal = this.modalService.open(SupendAlertModalComponent, {
      modalDialogClass: 'minBalanceModal',
      animation: true,
    });
    this.supendAlertModal.componentInstance.transponder = this.transponder;
    this.supendAlertModal.componentInstance.onTransponderSuspended.subscribe(
      (transponder: Transponder) => {
        this.onTransponderSuspendedOutput.emit(transponder);
      }
    );

    this.supendAlertModal.hidden.subscribe(() => {});
  }

  updateTransponder() {
    this.isUpdateProcessing = true;
    this.edittransponderComponent.transponderRequest.TransponderSerialNumber =
      this.transponder.TransponderDisplayId;
    this.edittransponderComponent.transponderRequest.TransponderTypeId =
      this.transponder.TransponderTypeId;
    this.edittransponderComponent.transponderRequest.WebUserLogonId =
      +this.userService.getCurrentUser().UserId;
    this.edittransponderComponent.transponderRequest.AccountId =
      this.userService.getCurrentUser().AccountId;
    this.edittransponderComponent.transponderRequest.TollCategoryId =
      this.transponder.TollCategoryId;
    this.edittransponderComponent.transponderRequest.VehicleId =
      this.transponder.VehicleId;
    
    if (this.edittransponderComponent.transponderRequest.PlateNum == this.checkPlate(this.transponder)){
      this.edittransponderComponent.transponderRequest.PlateNum = this.transponder.PlateNum
    }

    this.accountService
      .updateTransponder(this.edittransponderComponent.transponderRequest)
      .subscribe(
        (result) => {
          this.isUpdateProcessing = false;
          if (result === true) {
            this.refreshCloseOutput.emit(result);
            // this.alertService.success(
            //   this.translateService.instant(
            //     'TRANSPONDER_UPDATED_SUCCESSFUL_MSG'
            //   ),
            //   false
            // );
            this.activeModal.dismiss();
          } else {
            this.alertService.error(
              this.translateService.instant('TRANSPONDER_UPDATED_FAILED_MSG'),
              false
            );
          }
        },
        () => {
          this.alertService.error(
            this.translateService.instant('TRANSPONDER_UPDATED_FAILED_MSG'),
            false
          );
          this.isUpdateProcessing = false;
        }
      );
  }

  checkPlate(transponder: Transponder): string {
    const pattern: RegExp = /^[NWnw]\d+-\d+$/;
    debugger
    if (
      transponder.PlateNum &&
      transponder.PlateNum.includes(this.account.AccountID.toString())
    ) {
      return '';
    } else if (!transponder.PlateNum) {
      return '';
    } else if (transponder.PlateNum.includes('BULK') || transponder.PlateNum.includes('NEW') || transponder.PlateNum.includes('OWN')) {
      return '';
    } else if (pattern.test(transponder.PlateNum)) {
      return '';
    } else if (transponder.PlateNumAutoGenerated) {
      return '';
    } else {
      return transponder.PlateNum;
    }
  }

  isFormValid() {
    if (!this.edittransponderComponent){
      return true
    }
    return this.edittransponderComponent.transponderInfoFormGroup.valid;
  }

  ngOnDestroy(): void {
    this.supendAlertModal?.dismiss();
  }
}
