import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransponderFee } from '@ibitoll/toll-core/src';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mdot-min-balance-modal',
  templateUrl: './min-balance-modal.component.html',
  styleUrls: ['./min-balance-modal.component.scss']
})
export class MinBalanceModalComponent implements OnInit {


  @Input() pricePerTag: TransponderFee;
  @Output() addTagStaus: EventEmitter<any> = new EventEmitter();


  isRequesting = false;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }


  addNewVehicle() {
    this.isRequesting = true;
    this.addTagStaus.emit(true);
  }

}
